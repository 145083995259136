<template>
    <b-container fluid>
        <PaymentMethodAlert v-if="renderServiceCards" @openModal="openModal"></PaymentMethodAlert>
        <b-row v-if="false">
            <WelcomeWidget></WelcomeWidget>
        </b-row>
        <b-row v-if="renderServiceCards">
            <b-col lg="12" ref="service-liveopens">
                <ServiceConsumptionWidget v-if="!$store.getters['Auth/isAdmin']" :service_id="1"
                    service_name="LiveOpens">
                </ServiceConsumptionWidget>
            </b-col>
            <b-col lg="12" ref="service-liveleads">
                <ServiceConsumptionWidget v-if="!$store.getters['Auth/isAdmin']" :service_id="2"
                    service_name="LiveLeads">
                </ServiceConsumptionWidget>
            </b-col>
        </b-row>

        <b-row>
            <div class="col-lg-7">
                <PaymentMethods ref="payment-methods" @openModal="openModal"></PaymentMethods>
            </div>

            <div class="col-lg-5">
                <AccountInvoices v-bind="{accountId: account_id}"></AccountInvoices>
            </div>
        </b-row>

        <!-- Modal Payment info -->
        <PaymentMethodModal ref="payment-method-modal" v-bind="{
            paymentMethod: editPaymentMethod,
            address,
            paymentsMethod}"
            @init-payment-method="$refs['payment-methods'].initPaymentMethod()"></PaymentMethodModal>

    </b-container>
</template>

<script>
import {
    sofbox
} from '../../config/pluginInit'
import ConsumptionBar from '@/components/charts/ConsumptionBar.vue'
// import GetEmailConsumption from '@/components/charts/GetEmailConsumption.vue'
import ServiceConsumptionWidget from '@/components/inboxgeek/widgets/ServiceConsumptionWidget.vue'
import PaymentMethodAlert from '@/components/inboxgeek/alerts/PaymentMethodAlert.vue'

import AccountInvoices from './children/AccountInvoices.vue'
import WelcomeWidget from '@/views/Account/widgets/WelcomeWidget.vue'

import PaymentMethods from './children/PaymentMethods';
import PaymentMethodModal from './modals/PaymentMethodModal';

import { loadStripe } from '@stripe/stripe-js'
import api from '@/api/RestClient'
import { mapGetters } from "vuex";
import moment from 'moment'
import FreeAccountProgress from '@/components/users/FreeAccountProgress'
import Multiselect from "vue-multiselect"
import { BILLING_COUNTRIES } from '@/utils/countries';
import { helper } from '@/helpers';

const LiveOpensServiceID = 1;
const LiveLeadsServiceID = 2;


export default {
    name: 'Billing',
    components: {
        PaymentMethodAlert,
        WelcomeWidget,
        ConsumptionBar,
        ServiceConsumptionWidget,
        AccountInvoices,
        FreeAccountProgress,
        Multiselect,
        PaymentMethods,
        PaymentMethodModal
    },
    beforeCreate() {
        sofbox.openLoader();
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            client: 'Auth/isClient',
            user: 'Auth/user',
            liveOpenersSubscribed: 'Auth/liveOpenersSubscribed',
            liveLeadsSubscribed: 'Auth/liveLeadsSubscribed',
            lastSubscriptions: 'Auth/lastSubscriptions'
        })
    },
    created() {
        this.account_id = this.user.account_id;
    },
    mounted() {
        sofbox.index();
        window.that = this;
        let name = this.user.name.split(' ');
        this.address = {
            first_name: name[0],
            last_name: name[1],
            address: this.user.address,
        }
        //this.currentPlan();
        this.initPaymentMethod();
        this.iniInvoices();


        if (this.accountAlreadyUsedService(LiveLeadsServiceID)) {
            this.isAllowedAccount = true;
        }


    },
    data() {
        return {
            isAllowedAccount: false,
            plan: [],
            errors: [],
            billingErrors: [],
            paymentsMethod: [],
            invoices: [],
            modal: false,
            fails: false,
            success: false,
            address: {},
            no_payment_method: true,
            loading: false,
            account_id: null,
            subscription_loader: false,
            cancelSubscriptionText: 'Cancel My Subscription',
            remainingDays: 0,
            percentDay: 0,
            countries: BILLING_COUNTRIES,
            editPaymentMethod: null,
            renderServiceCards: true,
            // has_qst_tax: false,
            // has_gst_tax: false,
            // gst_tax_num: null,
            // qst_tax_num: null,
            // is_taxable: false,
            nickname: ''
        }
    },
    methods: {
        currentPlan() {
            api.accounts.getCurrentPlan(0, this.service_id)
                .then(resp => {
                    this.plan = resp.data

                    let today = moment();
                    let nextBilling = moment(this.plan.next_billing_date, 'YYYY-MM-DD HH:mm:ss');
                    let remainingDays = nextBilling.diff(today, 'days') // Remaining days
                    this.remainingDays = remainingDays;


                    if (remainingDays < 0) {
                    }

                    this.percentDay = ((30 - remainingDays) * 100) / 30;

                });

        },
        initPaymentMethod() {
            api.billing.getPaymentMethods()
                .then(resp => {
                    let data = resp.data;
                    if (data.success && data.data) {
                        this.paymentsMethod = data.data;
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 401) {
                            this.$store.dispatch('Auth/resetUser', false);
                            this.$router.go(this.$router.currentRoute)
                        } else if (error.response.status == 500) {

                            this.$bvToast.toast('something went wrong!', {
                                title: 'Error',
                                variant: 'danger'
                            });
                        }

                        this.$bvToast.toast('something went wrong!', {
                            title: 'Error',
                            variant: 'danger'
                        })
                    }
                })
                .finally(() => {
                    sofbox.closeLoader();
                });
        },
        openModal({paymentMethod, address}) {

            this.editPaymentMethod = paymentMethod;
            this.address = address;
            this.nickname = address?.nickname;

            if (!this.editPaymentMethod) {
                this.address = {};
            }

            setTimeout(() => {
                this.$root.$emit("bv::show::modal", "payment-method");
                // this.initcard();
            }, 500)


        },
        iniInvoices() {
            api.billing.getInvoices().then((response) => {
                let data = response.data;
                if (data.success) {
                    this.invoices = data.data;
                }
            });
        },
        cancel() {
            api.subscriptions.cancelSubscription(this.user.account_id, {})
                .then(result => {
                    const title = 'You have successfully cancelled your subscription';
                    const message = 'You can subscribe at any point by returning to your billing dashboard';
                    this.$swal(title, message, 'success');
                    this.currentPlan();
                })
                .catch(function (error) {
                    window.that.$bvToast.toast('Something went wrong!', { message: 'Something went wrong!', title: 'Error', variant: 'danger' });
                })
                .finally(() => {
                    this.subscription_loader = false;
                });
        },
        resubscribe() {
            let plan = this.plan;
            api.subscriptions.reSubscription(this.user.account_id, { plan })
                .then(result => {
                    const title = result.success ? 'Good!' : 'Ooops!';
                    const message = result.message;
                    const type = result.success ? 'success' : 'info';
                    this.$swal(title, message, type);
                    this.currentPlan();
                })
                .catch(function (error) {
                    window.that.$bvToast.toast('Something went wrong!', { message: 'Something went wrong!', title: 'Error', variant: 'danger' });
                })
                .finally(() => {
                    this.subscription_loader = false;
                });
        },
        date_diff(date) {
            var now = moment(new Date()); //todays date
            var end = moment(date); // another date
            var duration = moment.duration(end.diff(now));
            var days = duration.asDays();
            return days;
        },
        isResubscribe(plan) {
            let date = plan.subscription_end_date ? plan.subscription_end_date : plan.next_billing_date;
            if (date == null) return false;
            const check = this.date_diff(date) > 0 ? true : false;
            console.log(this.date_diff(date));
            return check;
        },
        serviceIsActive(serviceID) {

            if (serviceID == LiveOpensServiceID) {
                return this.liveOpenersSubscribed
            }
            if (serviceID == LiveLeadsServiceID) {
                return this.liveLeadsSubscribed
            }
        },
        accountAlreadyUsedService(serviceID) {
            return typeof this.lastSubscriptions[serviceID] !== 'undefined';
        },
    },
}
</script>
<style>
.fa-infinity {
    font-size: 25px !important;
}

thead {
    text-align: center;
}
</style>
