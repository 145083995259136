<template>

    <b-container fluid>
        <b-row class="adminCharts">
            <b-col md="12" v-if="showChart">
                <iq-card v-show="liveLeadAutomation">
                    <template v-slot:headerTitle>
                        <h4>{{ liveLeadAutomation.name }} Automation Records</h4>
                    </template>
                    <template v-slot:body>
                        <b-row class="justify-content-center my-4">
                            <b-form class="my-auto" inline>
                                <b-form-group label="" class="" label-cols-sm="2" label-for="from">
                                    <b-form-input class="px-1" type="date" v-model="options.dates.from"
                                        :max="options.dates.to" :value="options.dates.from"
                                        @input="graph(liveLeadAutomation)"></b-form-input>
                                </b-form-group>
                                <label class="my-auto px-2">To</label>
                                <b-form-group label="" class="" label-cols-sm="2" label-for="to">
                                    <b-form-input class="px-1" type="date" v-model="options.dates.to"
                                        :min="options.dates.from" :max="options.dates.max" :value="options.dates.to"
                                        @input="graph(liveLeadAutomation)"></b-form-input>
                                </b-form-group>
                            </b-form>
                        </b-row>
                        <b-row class="d-flex justify-content-center text-center">
                            <b-button-toolbar v-if="statsData" v-for="(item, index) in statsData.indicators"
                                :key="index" aria-label="">
                                <b-button-group size="sm" class="mr-0">
                                    <b-button @click="toggleChartSerie($event, item, index)"
                                        :style="{ 'background-color': recordsChart.bodyData.colors[index], 'border-color': recordsChart.bodyData.colors[index] }"
                                        :class="isHide(item.title) ? 'indicator-disabled' : ''">{{ item.title
                                        }}</b-button>

                                </b-button-group>
                                <b-input-group size="sm" :append="formatDecimal(item.indicator)" class="mr-3">
                                </b-input-group>
                            </b-button-toolbar>
                        </b-row>
                        <b-col v-if="chartLoading" md="12" class="text-center">
                            <b-spinner small type="grow"></b-spinner>
                        </b-col>
                        <ApexChart v-else-if="!chartLoading && showChart" :element="'slug'"
                            :chartOption="recordsChart.bodyData" :columnsHide="recordsChart.columnsHide" />
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>

</template>

<script>
import ApexChart from "@/components/sofbox/charts/ApexChart.vue"
import { helper } from '@/helpers'

export default {
    name: 'RecordsChart',
    props: {
        automation: {
            type: Object,
            default: null,
        },
        params: {
            type: Object,
            default: null,
        },
        data: {
            type: Object,
            default: null,
        },
        chart: {
            type: Object,
            default: null,
        },
        chartIsLoading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ApexChart,
    },
    data() {
        return {
            showChart: false,
            liveLeadAutomation: null,
            options: {},
            // options: { label: 'event_id', period: PERIOD_DAY, dates: {} },
            statsData: null,
            recordsChart: {},
            chartLoading: false,
        }
    },
    mounted() {
        console.log('mounted');
        this.initComponent();
    },
    beforeUpdate() {
        console.log('beforeUpdate');
        this.initComponent();
    },
    updated() {
        console.log('updated');
        this.initComponent();
    },
    activated() {
        console.log('activated');
    },
    methods: {
        initComponent() {
            // if(this.show) {
            //     this.showChart = this.show;
            // }
            if(this.automation) {
                this.liveLeadAutomation = this.automation;
            }
            if(this.params) {
                this.options = this.params;
            }
            if(this.data) {
                this.statsData = this.data;
            }
            if(this.chart) {
                this.recordsChart = this.chart;
            }
            if(this.chartIsLoading) {
                this.chartLoading = this.chartIsLoading;
            }

            helper.delay(2000).then(() => {
                this.chartLoading = false;
            });
        },
        graph(item) {

            this.chartLoading = true
            let options = {
                "automation_id": item.id,
                "from": this.options.dates.from,
                "to": this.options.dates.to,
            }
            api.geAutomations.automation_chart_records(item.id, this.options.dates.from, this.options.dates.to)
                .then(response => {

                    if (response.success) {
                        this.statsData = this.getChartDataFormatted(response);
                        this.recordsChart.bodyData.series = this.statsData.series;
                        this.recordsChart.bodyData.labels = this.statsData.labels;

                        this.chartData = response.data.data

                        this.showChart = false;
                        this.$nextTick(() => {
                            this.showChart = true;
                        });
                    }

                })
                .catch(err => {
                    console.log(err)
                    this.$bvToast.toast(err.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    })
                })
                .finally(() => {
                    helper.delay(2000).then(() => {
                        this.chartLoading = false;
                    });

                    this.showChart = true
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $(".adminCharts").offset().top
                    }, 2000);

                })

        },
        toggleChartSerie($event, item) {
            $event.preventDefault();
            this.detailsRedirect(this.options.dates.from, this.options.dates.to, item.title);
        },
        detailsRedirect(dateFrom = null, dateTo = null, serie = null) {
            return this.redirectToRecordsTable(dateFrom, dateTo, serie)
        },
        redirectToRecordsTable(dateFrom = null, dateTo = null, serie = null) {
            let message = `You will be redirect to LiveLeads Records page`;
            if (dateFrom && dateTo) {
                message += ` from ${dateFrom} to ${dateTo}`;
            } else if (!dateTo && dateFrom) {
                message += ` ${dateFrom}`;
            } else if (!dateFrom && dateTo) {
                message += ` ${dateTo}`;
            }

            return this.$swal({
                title: 'Are you sure?',
                text: message,
                type: 'info',
                confirmButtonText: 'Yes',
                showCancelButton: true,
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    let params = {};
                    let query = {};

                    if (typeof this.liveLeadAutomation !== 'undefined') {
                        params.id = this.liveLeadAutomation.source_id
                    }

                    if (this.showChart) {
                        query.from = this.options.dates.from;
                        query.to = this.options.dates.to;
                    }

                    if (dateFrom) {
                        query.from = dateFrom
                    }
                    if (dateTo) {
                        query.to = dateTo
                    }
                    if (serie) {
                        query.serie = serie
                    }

                    this.$router.push({ name: 'liveleads.records', params, query })
                }
            })
        },
        formatDecimal(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        },
        isHide(name) {
            return _.includes(this.recordsChart?.columnsHide, name)
        },
        setShowChart(value = false) {
            if(typeof value == 'boolean') {
                this.showChart = value;
            }
        },
        getShowChart() {
            return this.showChart;
        }
    }
}

</script>