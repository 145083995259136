<template>
  <b-container fluid>

    <!-- Table title -->
    <b-row class="mb-2">
      <b-col lg="4" class="my-1">
        <h3 class="card-title d-flex" style="align-items: center"><i class="ri-history-line mr-2" style="color:rgb(85,170,221)"></i>Integration Logs</h3>
      </b-col>
    </b-row>

    <!-- Filters -->
    <div class="border rounded shadow-sm p-4 mb-2" >
      <div class="d-flex justify-content-between">
      <h5 @click="showFilters = !showFilters" style="cursor: pointer;"> <svg width="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M21 6H19M21 12H16M21 18H16M7 20V13.5612C7 13.3532 7 13.2492 6.97958 13.1497C6.96147 13.0615 6.93151 12.9761 6.89052 12.8958C6.84431 12.8054 6.77934 12.7242 6.64939 12.5617L3.35061 8.43826C3.22066 8.27583 3.15569 8.19461 3.10948 8.10417C3.06849 8.02393 3.03853 7.93852 3.02042 7.85026C3 7.75078 3 7.64677 3 7.43875V5.6C3 5.03995 3 4.75992 3.10899 4.54601C3.20487 4.35785 3.35785 4.20487 3.54601 4.10899C3.75992 4 4.03995 4 4.6 4H13.4C13.9601 4 14.2401 4 14.454 4.10899C14.6422 4.20487 14.7951 4.35785 14.891 4.54601C15 4.75992 15 5.03995 15 5.6V7.43875C15 7.64677 15 7.75078 14.9796 7.85026C14.9615 7.93852 14.9315 8.02393 14.8905 8.10417C14.8443 8.19461 14.7793 8.27583 14.6494 8.43826L11.3506 12.5617C11.2207 12.7242 11.1557 12.8054 11.1095 12.8958C11.0685 12.9761 11.0385 13.0615 11.0204 13.1497C11 13.2492 11 13.3532 11 13.5612V17L7 20Z" stroke="#55aadd" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg> Filters</h5>

        <i @click="showFilters=true" style="cursor: pointer;margin: 0px !important;margin-top: -18px !important;font-size: 24px;" v-if="!showFilters" class="ri-arrow-down-s-line"></i>
        <i @click="showFilters=false" style="cursor: pointer;margin: 0px !important;margin-top: -18px !important;font-size: 24px;" v-else class="ri-arrow-up-s-line"></i>
      </div>
      <div v-if="showFilters">

        <hr>
        <div class="d-flex" style="align-items: flex-end;gap: 17px;">

          <div style="width:20%">
            <label for="">Integration name</label>
            <b-form-input
                id="filter-input"
                v-model="filter.search"
                type="search"
                placeholder="Integration name"></b-form-input>
          </div>



          <div style="width:30%">
            <label for="">Actions</label>
            <div>
              <multiselect :options="actions" v-model="filter.action" :multiple="true"></multiselect>
            </div>
          </div>
          <div style="width:30%">
            <div class="row">
              <div class="col">
                <b-form-group label="From" label-for="Date from"  class="m-0">
                  <b-form-input id="from" type="date" v-model="filter.date.from" class="m-0"></b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group label="To" label-for="date to"  class="m-0">
                  <b-form-input id="to" type="date" v-model="filter.date.to"  class="m-0"></b-form-input>
                </b-form-group>
              </div>
            </div>

          </div>

          <div style="width:20%">
            <label for="">Account</label>
            <multiselect :options="accounts" label="name" track-by="id"  v-model="filter.account" :multiple="false"></multiselect>

          </div>


        </div>

        <hr>
        <div class="text-right">
          <button class="btn btn-light btn-sm mr-1" @click="resetFilters">Reset Filter</button>
          <button class="btn btn-primary btn-sm" @click="applyFilters" :disabled="loading.table">
            <svg v-if="!loading.table" width="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 10.5A3.502 3.502 0 0 0 18.355 8H21a1 1 0 1 0 0-2h-2.645a3.502 3.502 0 0 0-6.71 0H3a1 1 0 0 0 0 2h8.645A3.502 3.502 0 0 0 15 10.5zM3 16a1 1 0 1 0 0 2h2.145a3.502 3.502 0 0 0 6.71 0H21a1 1 0 1 0 0-2h-9.145a3.502 3.502 0 0 0-6.71 0H3z" fill="#ffffff"></path></g></svg>
            <b-spinner v-else label="Loading..." style="width: 14px;height: 14px;"></b-spinner>
            Apply filter</button>
        </div>

      </div>
    </div>

    <b-form-input
        id="filter-input"
        v-model="search"
        type="search"
        placeholder="Search"></b-form-input>


    <!-- Main table element -->
    <b-table class="ig-text-wrap" ref="integration-logs-datatable" :id="id" borderless
             :responsive="$store.getters['Auth/isAdmin']" :busy="loading.table" :items="items" :fields="fields"
             :current-page="currentPage" :per-page="perPage" :filter="search" :filter-included-fields="filterOn"
             :sort-by.sync="sortBy" :from="from" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" stacked="md" show-empty
             small @sort-changed="sortingChanged" >

      <template #table-busy>
        <div class="text-center d-inline-block my-2">
                    <span class='fa-stack fa-lg'>
                        <i class='fa fa-spinner fa-spin fa-stack-2x fa-fw'></i>
                    </span>&emsp;Processing ...
        </div>
      </template>

      <template #empty="scope">
        <p class="mb-1">No matching records found</p>
      </template>

      <!-- Cell Integration name -->
      <template #cell(name)="row">
        <div class="d-flex" style="align-items: center;">

          <div class="text-left">

            <h5 class="text-left">
              {{ row.value }}
            </h5>

            <small class="text-left my-2 text-muted">{{ row.item?.account }}</small>

          </div>
        </div>

      </template>

      <!-- Cell status -->
      <template #cell(action)="row">
                <span v-if="row.value == 'create'" class="badge cursor-pointer iq-badge-secondary iq-bg-success" style="color:#069e18 !important">
                    {{ 'Create' }}
                </span>

        <span v-if="row.value == 'update'" class="badge cursor-pointer iq-badge-secondary iq-bg-warning" style="    color: #cd8f04 !important;background: #fff4ae !important;">
                    {{ 'Update' }}
                </span>
        <span v-if="row.value == 'delete'" class="badge cursor-pointer iq-badge-secondary iq-bg-danger">
                    {{ 'Delete' }}
                </span>

        <span v-if="row.value == 'schedule'" class="badge cursor-pointer iq-badge-secondary iq-bg-info" style="color: #1a93c1 !important;background-color: #e8f7ff !important;">
                    {{ 'Schedule' }}
                </span>
      </template>


      <!-- Cell status -->
      <template #cell(columns)="row" style="width: 650px">
        <div class="d-flex" style=" flex-wrap: wrap; width: 650px">

          <div v-if="!hideShowMoreButton[row.item.id]" >
               <span v-for="(value,column) in limitedValues(row.value)" :key="row.item.id +'-' + value "  class="border rounded m-1 p-1 text-muted bg-white shadow-sm" >

                  <small class="mx-1" v-for="(subValue,subColumn) in value" > {{ subValue}} </small>
                </span>
          </div>

          <div v-else >
                <span v-for="(value,column) in row.value" :key="row"  class="border rounded m-1 p-1 text-muted bg-white shadow-sm" >
                  <small><b>{{ column }}</b> {{ value}}</small>
                </span>
          </div>

          <button v-if="(!hideShowMoreButton[row.item.id]) && Object.keys(row.value).length>3"
                  @click="hideShowMoreButton[row.item.id] = true;$refs['integration-logs-datatable'].refresh()"
                  class="btn btn-sm btn-light"
          >show more</button>

        </div>
      </template>

      <!-- Cell status -->
      <template #cell(completed_by)="row">
                <span>
                   {{ row.value }}
                </span>
      </template>

      <!-- Cell platform -->
      <template #cell(created_at)="row">
        <span>{{ row.value }}</span>
      </template>

    </b-table>

    <!-- Data table pagination -->
    <b-row class="justify-content-between mt-3">
      <b-col md="9" class="my-1">
        <div class="dataTables_info">
          <b-row class="dataTables_info justify-content-between" id="lists_info" role="status"
                 aria-live="polite">
            <b-col md="10" class="">
              Showing {{ (currentPage-1) * perPage }}
              to {{ (currentPage-1) * perPage  + perPage }} of {{ totalRows }} entries
            </b-col>
            <b-col md="2" class="">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm">
              </b-form-select>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col md="3" class="my-1">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                      class="my-0"></b-pagination>
      </b-col>
    </b-row>


  </b-container>
</template>

<script>
  import Spinner1 from '@/components/loaders/spinner1'
  import store from '@/store/index'
  import api from '@/api/RestClient'
  import SwitchCell from '@/components/inboxgeek/tables/SwitchCell.vue'
  import {
    mapGetters
  } from "vuex";
  import moment from 'moment'
  import Multiselect from "vue-multiselect"

  export default {
    name: 'IntegrationLogsDataTable',
    components: {
      Spinner1,
      SwitchCell,
      Multiselect
    },
    data() {
      return {
        id: 'integrations-logs-datatable',
        hideShowMoreButton:[],
        loading: {
          update: false,
          table:false
        },
        items: [],
        search:null,
        fields: [
          {
            key: 'name',
            label: 'Integration',
            sortable: true,
            sortDirection: 'desc',
            class: 'text-left'
          },
          {
            key: 'action',
            label: 'Action',
            sortable: true,
            class: 'text-center'
          },
          {
            key: 'columns',
            label: 'Columns',
            sortable: false,
            sortDirection: 'desc',
            class: 'text-centere',

          },
          {
            key: 'completed_by',
            label: 'Performed By',
            sortable: true,
            sortDirection: 'desc',
            class: 'text-centere',
          },
          {
            key: 'created_at',
            label: 'Created at',
            sortable: true,
            class: 'text-center',
          },
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 25,
        pageOptions: [25, 50, 100, {
          value: 100,
          text: "Show a lot"
        }],
        from: null,
        to: null,
        sortBy: 'active',
        sortDesc: true,
        sortDirection: 'asc',
        filter: {
          search:null,
          date:{
            from:null,
            to:null
          },
          account:null,
          filterByAction:false,
          action:[]
        },
        showFilters:false,
        filterOn: ["name", "tag_name"],
        accounts: [],
        selected: [],
        actions:[
            'create',
            'update',
            'delete',
            'Schedule deactivate',
            'Schedule activate',
        ]
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
            .filter(f => f.sortable)
            .map(f => {
              return {
                text: f.label,
                value: f.key
              }
            })
      },
      ...mapGetters('Auth', ['user', 'isAdmin']),


    },
    mounted() {
      if(this.isAdmin){
        this.fetchData();
        this.fetchAccounts();
      }
    },
    methods: {
      limitedValues(columns) {
        // Show all if showAll is true, otherwise show only the first three
        return Object.entries(columns).slice(0, 3);
      },
      applyFilters(){
        this.fetchData();
      },
      resetFilters(){
        this.filter =  {
          search:null,
              date:{
            from:null,
                to:null
          },
          account:null,
              filterByAction:false,
              action:[]
        };

      },
      fetchData() {

        this.loading.table = true;

        return api.integrations.logs(this.filter)
            .then((response) => {

              this.items = response.data;
              this.loading.table = false;
              this.totalRows = this.items.length

            })
            .catch((err) => {return []});

      },
      sortingChanged(data) {
        this.currentPage = 1
      },
      fetchAccounts(){
        api.accounts.getAccountsNames().then(response=>{
          this.accounts = response.data;
        })
      }
    },
  }
</script>

<style scoped>
.table tbody tr:nth-of-type(odd) {
  background-color: rgb(155 188 196 / 11%) ;
}

.scheduled .custom-control-label::before{
  background-color: #fac483;
  color: #9d3a11;
  border-color: #d66027;
}

.scheduled .custom-control-label::after  {
  background-color: #fff7eb;
}

td.lists>div,
td.integration>div {
  width: 16em;
}

td.tag>div,
td.account>div {
  width: 14em;
}

.ig-text-wrap {
  word-wrap: break-word;
}

ul.dropdown-menu.show {
  display: inline-block !important;
}

.dropdown-toggle::after {
  display: none !important;
}

li a.dropdown-item,
a[target] {
  padding: 10px 5px;
  text-decoration: none;
}

.dots-animate {
  animation: shadow-pulse-dots 1s infinite;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.dropdown.b-dropdown {
  float: right !important;
}

.table th, .table td {
  padding:1rem !important;
  vertical-align: middle !important;
}
.table-danger, .table-danger > th, .table-danger > td {
  background-color: #fce9eb !important;
}
</style>
