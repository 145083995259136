<template>

    <b-container fluid>

        <div v-for="(ticket, j) in data" :key="`ticket_content_${ticket.id}`" class="email-app-details"
            :id="`ticket_content_${ticket.id}`">
            <iq-card>
                <template v-slot:body class="p-0">
                    <div class="support-ticket-content">
                        <div class="iq-email-to-list p-3">
                            <div class="d-flex justify-content-between">
                                <ul>
                                    <li class="mr-3">
                                        <a href="javascript: void(0);" :ref="`hide-ticket-details-${ticket.id}`"
                                            @click.prevent="hideTicketDetails($event, ticket)">
                                            <h4 class="m-0">
                                                <i class="ri-arrow-left-line"></i>
                                            </h4>
                                        </a>
                                    </li>

                                    <li v-if="isAdmin" data-toggle="tooltip" data-placement="top" title="Bookmark"
                                        data-original-title="Bookmark">
                                        <a href="#" @click="markTicket($event, ticket)"><i
                                                :class="{ 'ri-bookmark-fill': ticket.important, 'ri-bookmark-line': !ticket.important }"></i></a>
                                    </li>

                                    <li v-if="![ticketsStatus.TICKET_CLOSE, ticketsStatus.TICKET_DELETE].includes(ticket.status)"
                                        data-toggle="tooltip" v-b-tooltip.hover top data-placement="top" title="Close"
                                        data-original-title="Close">
                                        <a href="#" @click="closeTicket($event, ticket)"><i
                                                class="ri-close-circle-fill"></i></a>
                                    </li>
                                    <li v-if="ticket.status != ticketsStatus.TICKET_DELETE" data-toggle="tooltip"
                                        v-b-tooltip.hover top data-placement="top" title="Delete"
                                        data-original-title="Delete">
                                        <a href="#" @click="deleteTicket($event, ticket)"><i
                                                class="ri-delete-bin-line"></i></a>
                                    </li>
                                </ul>
                                <div v-if="ticket && ticket.subject" class="ticket-subject">
                                    {{ ticket.subject }}
                                    <span class="badge badge-primary">{{ ticket.type }}</span>
                                </div>
                                <div class="iq-email-search d-flex">
                                    <ul v-if="activeGroupTicket && activeGroupTicket.data">
                                        <li class="mr-3">
                                            <a class="font-size-14" href="#">
                                                {{ (1 * activeGroupTicket.data.current_page)
                                                    * (1 + j) }}
                                                of {{ activeGroupTicket.data.total }}
                                            </a>
                                        </li>
                                        <li data-toggle="tooltip" data-placement="top" title=""
                                            data-original-title="Previous">
                                            <a href="#" @click="prevTicket($event, ticket)">
                                                <i class="ri-arrow-left-s-line"></i>
                                            </a>
                                        </li>
                                        <li data-toggle="tooltip" data-placement="top" title=""
                                            data-original-title="Next">
                                            <a href="#" @click="nextTicket($event, ticket)">
                                                <i class="ri-arrow-right-s-line"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <hr class="mt-0">

                        <!-- Ticket messages -->
                        <TicketMessages :ticket="ticket"></TicketMessages>
                        <!-- End Ticket messages -->


                    </div>
                </template>

                <template v-slot:footer>
                    <div class="pull-right">
                        <button v-if="isAdmin" class="btn btn-info btn-lg mb-3 font-size-10 p-3 mx-1"
                            @click="showTicketCommentModal($event, ticket)">
                            <i class="ri-chat-1-line mr-2"></i>{{ ticket.comment ? 'Update Comment' : 'Add Comment' }}
                        </button>

                        <button class="btn btn-primary btn-lg mb-3 font-size-10 p-3 mx-1"
                            v-b-modal.addSupportTicketMessageModal @click="$emit('ibg:show:message:ticket', ticket)">
                            <i class="ri-send-plane-line mr-2"></i>Reply to Ticket
                        </button>
                    </div>
                </template>

            </iq-card>
        </div>

    </b-container>

</template>
<script>
import {SUPPORT_TICKET_STATUS} from "@/constantes";
import TicketMessages from './TicketMessages';
import { mapGetters } from 'vuex'

export default {
    name: 'TicketContent',
    components: {
        TicketMessages
    },
    props: {
        data: {
            type: Array,
            default: [],
        },
        activeTicket: {
            type: Object,
            default: null,
        },
        activeGroupTicket: {
            type: Object,
            default: null,
        }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            user: 'Auth/user',
        })
    },
    data() {
        return {
            ticketsStatus: SUPPORT_TICKET_STATUS,
        }
    },
    methods: {
        hideTicketDetails($event, ticket) {

            const detailDiv = document.getElementById(`ticket_content_${ticket.id}`);


            if (detailDiv) {
                detailDiv.classList.remove('show');
            }
            this.$router.replace({ 'query': null });
        },
        markTicket($event, ticket) {
            this.$root.$emit('ibg:mark:ticket', {$event, ticket});
        },
        closeTicket($event, ticket) {
            this.$root.$emit('ibg:close:ticket', {$event, ticket});
        },
        deleteTicket($event, ticket) {
            this.$root.$emit('ibg:delete:ticket', {$event, ticket});
        },
        prevTicket($event, ticket) {
            this.$root.$emit('ibg:previous:ticket', {$event, ticket});
        },
        nextTicket($event, ticket) {
            this.$root.$emit('ibg:next:ticket', {$event, ticket});
        },
        showTicketCommentModal($event, ticket) {
            this.$root.$emit('ibg:show:comment:ticket', {$event, ticket});
        },
    }
}

</script>