<template>
  <div style="margin-bottom: -54px;padding: 0px 15px;">

    <div class="text-right" style="padding: 0px 15px;">
      <b-button v-b-tooltip.hover title="Filter" variant="primary"
                @click="showFilter=!showFilter"> <i class="fa fa-filter mx-0"></i>
      </b-button>
    </div>

    <div class="alert-secondary" v-if="showFilter" style="padding: 15px;border-radius: 5px;margin-bottom: 69px;margin-top: 5px">


    <b-form  @submit="onFilter" >
      <b-row class="mt-2">
        <b-col md="12" lg="12">
          <h4>Filters</h4>
          <b-row>
            <b-col :md="3">
              <b-form-group label="Create at date from:" label-for="input-3">
                <b-form-input :max="filter.date_to" v-model="filter.date_from" type="date" class="mb-2 mr-sm-2 mb-sm-0" placeholder="Created date from"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col :md="3">
              <b-form-group label="Created at date to:" label-for="input-3">
                <b-form-input :min="filter.date_from" v-model="filter.date_to" type="date" class="mb-2 mr-sm-2 mb-sm-0" placeholder="Created date to"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="3" class="text-left">
              <b-form-group id="label" label="Status:" label-for="input-3">
                <multiselect tag-placeholder="Manage status" placeholder="Choose status" label="name" track-by="code" v-model="filter.status" deselect-label="Can't remove this value" :allow-empty="true" :options="statusOptions" :multiple="false" :taggable="false">
                </multiselect>
              </b-form-group>
            </b-col>
            <b-col md="2" class="text-left" v-if="isAdmin">
              <b-form-group id="label" label="Account:" label-for="input-3">
                <multiselect  placeholder="Choose account" label="name" track-by="id" v-model="filter.account" deselect-label="Can't remove this value" :allow-empty="true" :options="accounts" :multiple="false" :taggable="false" :loading="isLoading">
                </multiselect>
              </b-form-group>
            </b-col>
            <b-col md="1" class="text-center">
              <label id="label-btn" class="sr-only" for="inline-form-input-name">Name</label>
              <b-button type="submit"  variant="primary" style="margin-top: 35px;height: 42px; width: 100%">
                <i class="fa fa-paper-plane m-0" ></i>
              </b-button>
            </b-col>

          </b-row>
        </b-col>
      </b-row>
    </b-form>

    </div>
  </div>


</template>
<script>
import api from '@/api/RestClient'
import {
  sofbox
} from '@/config/pluginInit'
import Multiselect from 'vue-multiselect'
import store from '@/store/index'
import { helper } from '@/helpers';
import Columns from "../../LiveReports/children/Columns"
import { mapGetters } from 'vuex'
import {SERVICES} from "@/constantes";

export default {
  name: 'InvoicesDataTableFilter',
  props: {
    onFilterTable: {
      type: Boolean,
      default: false
    },
    integrationFilterColumns: {
      type: Object,
      default() {
        return {
          options: [],
          selected: [],
          defaults: []
        };
      }
    },
    account: {
      type: Object,
      default: null,
    },
    platforms_array: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Multiselect,
    Columns
  },
  mounted() {
    sofbox.index()
    this.finalFilters = null;
    if (this.isAdmin) {
      this.fetchAccounts();
      this.cols.platform = 4;
      this.cols.create_to = 4;
      this.cols.create_from = 4;
      this.cols.daily_cap = 2;
      this.cols.status = 3;
      this.fetchPlans();
    }
  },
  data() {
    return {
      finalFilters : null,
      showFilter:false,
      onFilterDisable: false,
      isLoading: true,
      filter: {
        status: null,
        date_to: null,
        date_from: null,
        account: null,
      },
      accounts: [],
      cols: {
        platform: 3,
        create_to: 2,
        create_from: 2,
        daily_cap: 2,
        status: 2
      },
      plans: [],
      showOrHideColumns: {
        selected: [{ code: 'tag_name ', name: 'tag' }],
        options: [{ code: 'tag_name ', name: 'tag' }],
        defaults: [
          "selected",
          "name",
          "tag_name",
          "platform",
          "lists",
          "created_at",
          "active",
          "actions"
        ]
      }
    }
  },
  methods: {
    platformName(name) {
      return helper.platformFormatName(name);
    },
    onFilter(e) {
      e.preventDefault();
      this.onFilterDisable = true
      this.showFilter = false;
      this.finalFilters =  {...this.filter};
      this.$emit('onFilter',this.finalFilters);
    },
    finishFilter() {
      this.onFilterDisable = false
    },
    fetchPlans() {
      api.plans.getActive().then((response) => {
        response.data.forEach((plan) => {
          this.plans.push({
            name: plan.name,
            code: plan.id
          });
        });
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.isLoading = false;
      })
    },
    fetchAccounts(){
      this.isLoading = true;
      api.accounts.getAccountsNames().then(response =>{
        this.isLoading = false;
        this.accounts = response.data;
      }).catch(error=> {
        this.isLoading = false;
      })
    },
    onSelect(col) {
      this.$emit('onSelectedColumns', col);
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'Auth/isAdmin',
      isAccountManager: 'Auth/isAccountManager',
      platforms_co: 'platformConfigs'
    }),
    statusOptions() {
      return  [
        {
          code: -2,
          name: 'Canceled'
        },
        {
          code: -1,
          name: 'Failed'
        },
        {
          code: 0,
          name: 'Initial'
        },
        {
          code: 1,
          name: 'Paid'
        },
        {
          code: 2,
          name: 'Partial Refund'
        },
        {
          code: 3,
          name: 'Refund'
        }
      ];

    }
  }
}
</script>

<style>
.form-border {
  border: 5px solid;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 10px;
}
</style>
