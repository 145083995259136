<template>
  
    <!-- Sidebar  -->
    <div class="iq-sidebar" style="background-color: #1e2944;">
      <div class="iq-sidebar-logo d-flex justify-content-between" style="background-color: #1e2944;">
        <router-link :to="homeURL">
          <img :src="logo" class="img-fluid" alt="logo">
        </router-link>
        <div class="iq-menu-bt align-self-center" style="background: #1bb6ff26;">
          <div class="wrapper-menu">
            <div class="line-menu half start"></div>
            <div class="line-menu"></div>
            <div class="line-menu half end"></div>
          </div>
        </div>
      </div>
      <div id="sidebar-scrollbar" >
        <nav class="iq-sidebar-menu" :class="horizontal ? 'd-xl-none' : ''">
          <List :items="items" :open="true" :horizontal="horizontal" />
        </nav>
  
        <!-- Menu Help -->
        <Help />
  
      </div>
    </div>
    <!-- TOP Nav Bar -->
  </template>
  
  <script>

  // Use Sofbox component
  import List from '@/components/sofbox/menus/ListStyle1'
  import Help from '@/components/inboxgeek/sidebars/HelpMenu.vue'
  
  export default {
    name: 'AppSideBar',
    props: {
      homeURL: { type: Object, default: () => ({ name: 'dashboard1.home' }) },
      items: { type: Array },
      logo: { type: String, default: require('@/assets/images/logo.png') },
      horizontal: { type: Boolean }
    },
    components: {
      List,
      Help
    },
    data() {
      return {
      }
    }
  }
  </script>  