<template>

    <b-container fluid>
        <div class="chat-content scroller">
            <div v-for="(message, k) in ticket.support_ticket_messages"
                :key="`ticket_content_messages_${ticket.id}${message.id}`" class="chat"
                :class="{ 'chat-left': message.reply }">
                <div class="chat-detail">
                    <div class="chat-message">

                        <h5 class="mt-0"></h5>

                        <div class="iq-inbox-subject-info">
                            <div class="iq-subject-info">
                                <div class="iq-subject-status text-left">
                                    <h6 v-if="ticket.user && !message.reply" class="mb-0 text-light">
                                        {{ ticket.user.name }}
                                        <a :mailto="ticket.user.email">{{
                                            ticket.user.email }}</a>
                                    </h6>
                                </div>
                            </div>
                            <div class="iq-inbox-body mt-5" v-html="message.message"></div>
                            <hr class="mb-1">
                            <span class="float-right align-self-center small">
                                {{ getTicketMessageDate(message.created_at, 'MMM DD, YYYY, HH:mm') }}
                            </span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </b-container>

</template>
<script>
import moment from 'moment'

export default {
    name: 'TicketMessage',
    components: {},
    props: {
        ticket: {
            type: Object,
            default: null
        }
    },
    data() {
        return {}
    },
    methods: {
        getTicketMessageDate(date, format = 'YYYY-MM-DD') {
            
            const myDate = moment(date);

            const formattedDate = myDate.format(format);

            return formattedDate;

        },
    }
}

</script>