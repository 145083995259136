<template>

    <b-modal :id="id" :ref="id" size="lg" title="Activity timeline" :hide-footer=true @show="initModal">
        <template #modal-header>
            <h4 class="text-primary">Activity timeline</h4>

            <div>
                <span class="text-success cursor-pointer-ek mr-3" @click="reloadLogs">
                    Reload <i class="fa fa-refresh" v-show="!isReload"></i> 
                    <b-spinner small label="Small Spinner" type="grow" v-show="isReload"></b-spinner> 
                </span>
                <b-button size="sm" variant="outline-default" @click="close">
                    <i class="fa fa-close"></i>
                </b-button>
            </div>

        </template>

        <TimeLine :integrationLogs="integrationLogs" :integration_id="integrationId" @onReload="reloadLogs"></TimeLine>
    </b-modal>

</template>
<script>

    import TimeLine from "../children/timeLine.vue";
    import api from '@/api/RestClient'

    export default {
        name: 'ModalTimeLine',
        props: {
            id: {
                type: String,
                default: 'modal-timeline'
            },
            integrationId: {
                type: Number,
                default: null
            }
        },
        components: {
            TimeLine
        },
        data() {
            return {
                integrationLogs: [],
                isReload: false,

            }
        },
        methods: {
            initModal() {
                this.integrationLogs = [];
                this.reloadLogs();
            },
            reloadLogs() {
                if(!this.integrationId) return;

                this.isReload = true;
                const id = this.integrationId;

                api.integrations.get(id)
                    .then((response) => {
                        this.integrationLogs = response.data.integration_logs;
                    }).catch((error) => {
                        console.log(error)
                    }).finally(() => { 
                        this.isReload = false;
                    })
            },
            close() {
                this.$root.$emit('bv::hide::modal', this.id)
            }
        }
    }

</script>