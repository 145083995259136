<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">Sources</h4>
                    </template>

                    <template v-slot:headerAction class="mt-3">

                        <b-form v-if="is_manager" class="mx-3">
                            <AccountsMultiselect @onSelect="onSelectAccount" />
                        </b-form>

                      <b-button variant="light" class="mr-2" :disabled="btnAddIsDisabled" v-b-modal.SuppressionListsModal>
                        <i class="ri-file-forbid-line fa-lg mx-auto"></i> Suppression lists</b-button>

                      <b-button variant="primary" :disabled="btnAddIsDisabled" @click="showModalAddSource">Add New</b-button>


                    </template>

                    <template v-slot:body class="px-3 mx-0">
                        <b-row class="bg-warning px-0 text-center font-weight-bold small">
                            <b-col lg="12">{{ cookiePolicySourceText }} <a class="text-danger" href="https://help.inboxgeek.com/liveleads-compliance/" target="_blank">Learn more!</a></b-col>
                        </b-row>

                        <!-- Custom Filters row -->
                        <b-row>
                            <b-col lg="12">
                                <div class="container-fluid">
                                    <b-row class="d-flex justify-content-end p-3">
                                        <b-button class="mb-2" v-b-tooltip.hover title="Filter" variant="primary"
                                            @click="isFilter = !isFilter"> <i class="fa fa-filter mr-0"></i> </b-button>
                                        <Transition name="fade">
                                            <b-col lg="12" md="12" v-show="isFilter" class="alert-secondary">

                                                <SourcesFilters ref="dt-filters" @onFilter="onFilter"></SourcesFilters>
                                            </b-col>
                                        </Transition>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>
                        <!-- End Custom Filters row -->

                        <SourcesTable ref="dt-sources" v-bind="{accountId: organization, filters}" @hideChart="showChart = false" @onClick="handleBtn" @sortChanged="sortChanged"></SourcesTable>
                        
                    </template>
                </iq-card>
            </b-col>
        </b-row>

        <!-- Chart -->
        <b-row v-if="!ge_source_id" ref="records-chart" class="adminCharts">
            <b-col md="12" v-if="showChart">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4>{{ liveLeadSource.name }} records</h4>
                    </template>
                    <template v-slot:body>

                        <b-row v-if="showDates" class="justify-content-center my-4">

                            <b-form class="my-auto" inline>

                                <b-form-group label="" class="" label-cols-sm="2" label-for="from">
                                    <b-form-input class="px-1" type="date" v-model="options.dates.from"
                                        :max="options.dates.to" :value="options.dates.from"
                                        @input="graph(liveLeadSource)"></b-form-input>
                                </b-form-group>

                                <label class="my-auto px-2">To</label>

                                <b-form-group label="" class="" label-cols-sm="2" label-for="to">
                                    <b-form-input class="px-1" type="date" v-model="options.dates.to"
                                        :min="options.dates.from" :max="options.dates.max" :value="options.dates.to"
                                        @input="graph(liveLeadSource)"></b-form-input>
                                </b-form-group>
                            </b-form>

                        </b-row>

                        <b-row class="d-flex justify-content-center text-center">
                            <b-button-toolbar v-if="statsData && !item.disabled"
                                v-for="(item, index) in statsData.indicators" :key="index" aria-label="">
                                <b-button-group size="sm" class="mr-0">
                                    <b-button @click="toggleChartSerie($event, item, index)"
                                        :style="{ 'background-color': recordsChart.bodyData.colors[index], 'border-color': recordsChart.bodyData.colors[index] }"
                                        :class="isHide(item.title) ? 'indicator-disabled' : ''">{{ item.title }}</b-button>

                                </b-button-group>
                                <b-input-group size="sm" :append="formatDecimal(item.indicator)" class="mr-3">
                                </b-input-group>
                            </b-button-toolbar>
                        </b-row>

                        <b-col v-if="chartLoading" md="12" class="text-center">
                            <b-spinner small type="grow"></b-spinner>
                        </b-col>

                        <IbgApexChart v-else-if="!chartLoading && showChart" 
                            :element="'slug'"
                            :chartOption="recordsChart.bodyData" 
                            :columnsHide="recordsChart.columnsHide" />
                    </template>
                </iq-card>
            </b-col>
        </b-row>

        <!-- Modal Add GE Sources -->
        <GeModalAdd :id="addModal" :organization="organization" @add="finishAdded" @showIntegrationModal="createIntegration"
            :ref="addModal"  @showAutomationModal="showAutomationModal"/>

        <!-- Modal Edit LiveLeads Source -->
        <SourceModalSingleStepEdit v-if="liveLeadSource" :id="editModal" :ref="editModal" :source="liveLeadSource" :organization="liveLeadSource.account_id"
            @add="finishAdded" @showIntegrationModal="createIntegrationForEdit" />

        <!-- Modal GE Source - Script -->
        <GeScriptModal :id="'modal-script'" v-bind="{ geSource: liveLeadSource, errors: {}, title: 'Copy your Script!' }" />

        <!-- Modals SendFlow -->
        <b-modal :id="sendFlowModal.id" size="md" title="Source Test" :ref="sendFlowModal.id" hide-footer>
            <TestFlow :source="sendFlowModal.item.id" col=12></TestFlow>
        </b-modal>

        <!-- Modals SendFlow -->
        <b-modal id="time_logs" size="lg" title="Activity timeline" ref="time_logs" hide-footer>
            <SourceLogs :sourceLogs="logs" :name="sourceName"></SourceLogs>
        </b-modal>

        <!-- Modals SendFlow -->
        <b-modal id="modal-create-integration" ref="modal-create-integration" title="Integration LiveOpens" size="lg"
            centered hide-footer class="bg-secondary py-0">
            <Integration :formProp="form" @closeIntegrationModal="closeIntegrationModal"></Integration>
        </b-modal>

        <!-- Modal Add Automation -->
        <ModalAddGeAutomation
            :ref="addModalAutomation"
            :id="addModalAutomation"
            v-bind="{organization, source: sourceForAutomation}"
            @add="finishAdded"></ModalAddGeAutomation>

      <SuppressionListsModal :account="organization"></SuppressionListsModal>

      <ModalApplySuppressionList :source="selected_source"></ModalApplySuppressionList>

    </b-container>
</template>

<script>
import { sofbox } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import store from '@/store/index'
import api from '@/api/RestClient'
import GeModalAdd from "./modals/GeModalAdd.vue";
import SourceModalEdit from "./modals/SourceModalEdit.vue";
import GeScriptModal from "./modals/GeScriptModal.vue";
import PartialName from './modals/partials/Name.vue'
import PartialFinish from './modals/partials/Finish.vue'
import PartialIntegration from './modals/partials/Integration.vue'
import AccountsMultiselect from "@/views/children/AccountsMultiselect.vue";
import SourcesFilters from "./children/SourcesFilters.vue";
import SourceLogs from "./children/timeLine.vue";
import IbgApexChart from "@/components/charts/IbgApexChart.vue"
import Integration from './children/IntegrationLiveLead.vue'
import { excelParser } from "@/helpers/excel-parser";
import moment from 'moment-timezone'
import { helper } from '@/helpers'
import _ from 'lodash'
import TestFlow from '@/components/inboxgeek/GeSendflow'
import SourceModalSingleStepEdit from "./modals/SourceModalSingleStepEdit";
import ModalAddGeAutomation from "./modals/ModalAddGeAutomation";
import {SERVICES, APP_TIMEZONE} from "@/constantes";
import ModalApplySuppressionList from "@/components/inboxgeek/modals/source/ModalApplySuppressionList.vue"
import SuppressionListsModal from "@/components/inboxgeek/modals/source/SuppressionListsModal.vue";
import SourcesTable from "./children/SourcesTable"

const PERIOD_DAY = 0;
const PERIOD_WEEK = 1;
const PERIOD_MONTH = 2;
const PERIOD_YEAR = 3;
const SERVICE_ID = 2;

export default {
    name: "SourceList",
    components: {
        GeModalAdd,
        SourceModalEdit,
        GeScriptModal,
        PartialName,
        PartialFinish,
        PartialIntegration,
        AccountsMultiselect,
        IbgApexChart,
        SourcesFilters,
        TestFlow,
        SourceLogs,
        Integration,
        SourceModalSingleStepEdit,
        ModalAddGeAutomation,
        ModalApplySuppressionList,
        SuppressionListsModal,
        SourcesTable
    },
    data() {
        return {
            admin: {
                account: { code: null, name: '' }
            },
            loading: true,
            addModal: 'modal-add',
            editModal: 'modal-edit',
            addModalAutomation: 'modal-add-automation',
            organization: null,
            btnAddIsDisabled: !store.getters['Auth/isAdmin'] ? false : true,
            isFilter: false,
            liveLeadSource: {},
            ge_source_id: null,
            yaxis: 5,
            periodCode: 0,
            showDates: true,
            loadAdminStats: false,
            chartLoading: false,
            showChart: false,
            options: { label: 'event_id', period: PERIOD_DAY, dates: {} },
            statsData: null,
            chartData: [],
            recordsChart: {
                title: '***',
                type: 'line-area',
                dot: 1,
                bodyData: {
                    chart: {
                        height: 350,
                        type: 'area',
                        stacked: false,
                        events: {
                            click: (event, chartContext, config) => {
                                let date = config.globals.categoryLabels[config.dataPointIndex];
                                if (date) {
                                    this.detailsRedirect(date)
                                }
                            },
                            legendClick: (chartContext, seriesIndex, config) => {
                                this.clearHideColumns(chartContext, seriesIndex)
                            }
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: [3, 3],
                        curve: 'smooth'
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '50%'
                        }
                    },
                    colors: ['#00ca00', '#0084ff', '#FEB019', '#5A2A27', '#FD6A6A', '#662E9B', '#D7263D', '#C5D86D', '#4CAF50'],
                    series: [],
                    fill: {
                        opacity: [0.5, 0.5],
                        gradient: {
                            inverseColors: false,
                            shade: 'light',
                            type: 'vertical',
                            opacityFrom: 0.85,
                            opacityTo: 0.55,
                            stops: [0, 100, 100, 100]
                        }
                    },
                    labels: [],
                    markers: {
                        size: 0
                    },
                    xaxis: {
                        type: 'category',
                        interval: 4,
                        tickAmount: 10
                    },
                    yaxis: {
                        min: 0,
                        max: () => this.yaxis,
                        tickAmount: 9
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            formatter: function (y) {
                                if (typeof y !== 'undefined') {
                                    return y.toFixed(0)
                                }
                                return y
                            }
                        },
                        x: {},
                    },
                    legend: {
                        labels: {
                            useSeriesColors: true
                        },
                        markers: {
                            customHTML: [
                                function () {
                                    return ''
                                },
                                function () {
                                    return ''
                                },
                                function () {
                                    return ''
                                }
                            ]
                        }
                    }
                },
                columnsHide: [],
            },
            tabsPeriod: [
                { code: PERIOD_DAY, name: 'Days', format: 'YYYY-MM-DD', offset: 30 },
                { code: PERIOD_WEEK, name: 'Weeks', format: 'YYYY-MM-DD', offset: 10 },
                { code: PERIOD_MONTH, name: 'Months', format: 'YYYY-MM', offset: 6 },
                { code: PERIOD_YEAR, name: 'Years', format: 'YYYY', offset: 1 },
            ],

            filters: {},
            sendFlowModal: {
                id: 'send-flow-modal',
                title: '',
                item: {}
            },
            logs: [],
            sourceName: '',
            form: {},
            is_manager: false,
            cookiePolicySourceText: "Did you know? InboxGeek supplies everything you need to be compliant with the US data compliancy laws.",
            sourceForAutomation: null,
          selected_source:null
        }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            user: 'Auth/user',
            UserSubscriptions: 'Auth/UserSubscriptions'
        }),
    },
    mounted() {
        sofbox.index();
        this.initIntervalDates();
        this.isManager();
    },
    methods: {
        isManager() {
            this.is_manager = helper.hasRoles(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_ACCOUNTS_MANAGER']);
        },
        onSelectAccount(accountSelected) {
            this.chartLoading = false;
            this.showChart = false;

            if (this.is_manager) {
                if (accountSelected) {
                    this.btnAddIsDisabled = false
                    this.admin.account = accountSelected
                    this.organization = this.admin.account.code

                  api.subscriptions.getOptions({
                    service_id : SERVICES.LIVE_LEADS.id,
                    account_id :  this.organization
                  }).then((response)=>{
                    this.$store.dispatch('Auth/updateCurrentAccountOptions', { service_id :SERVICES.LIVE_LEADS.id , options :  response.data });
                  });

                } else {
                    delete this.admin.account
                    this.btnAddIsDisabled = true
                    accountSelected = null
                    this.organization = null
                }
            }
            this.$refs['dt-sources'].forceRenderTable();
        },
        finishAdded($event) {
            this.showChart = false;

            this.updateItem($event);
        },
        handleBtn(data) {
            this.showChart = false;

            if (!data.event || !data.row) {
                return
            }

            // Check Inactive Account
            if(this.user && this.user.account && !this.user.account.status) {
                helper.alertAccountInactive(this.$swal);
                return;
            }

            let action = data.event.title.toLowerCase();
            this.liveLeadSource = data.row;

            if (action == 'detail') {

                this.detailsRedirect()

            } else if (action == 'copy' && (typeof data.row.id !== "undefined")) {

                this.$bvModal.show('modal-script')

            } else if (action == 'chart' && (typeof data.row.id !== "undefined")) {

                this.getRecordsByGeSource(data.row, data.ref)

            } else if (action == 'delete' && (typeof data.row.id !== "undefined")) {
                this.removeItem(data.row)
            } else if (action == 'test' && (typeof data.row.id !== "undefined")) {

                // !!! TODO
                // Create script to test flow with platform parameter
                this.sendFlowModal.item = data.row;
                this.$root.$emit('bv::show::modal', this.sendFlowModal.id);

                // this.testFlow(data.row);

            } else if (action == 'logs') {
                this.logs = data.row.logs;
                this.sourceName = data.row.name;
                this.$refs['time_logs'].show();
            } else if (action == 'edit' && (typeof data.row.id !== "undefined")) {
                let showPreAlert = null;
                let checkShowAlert = null;
    
                checkShowAlert  = !this.isClient ? {state: null} : helper.showAlertOutOfEventOrEmptyBalance(SERVICE_ID);

                if (checkShowAlert.state && checkShowAlert.alert) {
                    showPreAlert = true;
                }

                if(showPreAlert && checkShowAlert?.state && checkShowAlert?.alert) {

                    return (this.$swal(checkShowAlert.alert))
                        .then((result) => {
                            if (data.ref && typeof data.ref.setState == 'function') {
                                data.ref.setState(false);
                            }
                            this.$root.$emit('bv::show::modal', this.editModal);
                        })
                        
                } else {
                    helper.delay(1000).then(() => {
                        this.$root.$emit('bv::show::modal', this.editModal);
                    });
                }

            }else if(action === 'applysuppressionlist'){

              this.selected_source = data.row;
              this.$root.$emit('bv::show::modal','applySuppressionList');
            }

            helper.delay(2000).then(() => {
                if (data.ref && typeof data.ref.setState == 'function') {
                    data.ref.setState(false);
                }
            });

        },
        removeItem(item) {

            if (!item.id) {
                return
            }

            this.$swal({
                title: 'Are you sure?',
                html: `<p>Once you delete this integration, email records will no longer be received into your account.</p>
                        <p>However, you will still have access to previously received records.</p>`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    let swalObj = {
                        icon: 'success',
                        confirmButtonText: 'OK',
                        showCloseButton: true,
                    }

                    api.geSources.delete(item.id)
                        .then(result => {
                            swalObj.title = 'Success!';
                            // swalObj.text = result.message;
                            swalObj.text = `LiveLeads Integration ${item.name} Deleted Successfully.`;
                        })
                        .catch(function (error) {
                            swalObj.icon = 'warning';
                            swalObj.title = 'Warning!';
                            swalObj.text = 'Something went wrong!';

                            this.$bvToast.toast(error.response.data.message, {
                                title: 'Error',
                                variant: 'danger',
                            })
                        })
                        .finally(() => {
                            this.$swal(swalObj)
                            this.$root.$emit('bv::refresh::table', this.$refs['dt-sources'].$refs['sources-table'].id)
                        });
                }
            })
        },
        testFlow(item) {
            let alert = {
                title: 'Test Flow',
                text: 'Error occurred, Please try later!'
            }

            const comp = this
            this.$swal.fire({
                title: 'Testing Flow',
                input: 'text',
                inputAttributes: {
                    placeholder: 'Email',
                    autocapitalize: 'off',
                },
                showCancelButton: true,
                confirmButtonText: 'Send Test Flow',
                showLoaderOnConfirm: true,
                preConfirm: (email) => {
                    return api.geSources.sendTestFlow(item.platform.code, email)
                        .then(response => {
                            alert.text = response.message
                        })
                        .catch(error => {
                            this.$bvToast.toast(error.response.data.message, {
                                title: 'Error',
                                variant: 'danger',
                            })
                        })
                },
                allowOutsideClick: () => !comp.$swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    comp.$swal.fire(alert)
                }
            })
        },
        getRecordsByGeSource(GeSource, ref = null) {
            if (!GeSource.id || !GeSource.name) {
                return
            }
            this.showChart = false;
            this.liveLeadSource = GeSource;

            this.chartLoading = true;

            helper.delay(1000).then(() => {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(".adminCharts").offset().top
                }, 1500)
                this.graph(GeSource);
                if (ref && typeof ref.setState == 'function') {
                    ref.setState(false);
                }
            });

        },
        exportData(data, fileName = null, type = null) {
            excelParser().exportDataFromJSON(data, fileName, type);
        },
        sortChanged(ctx) {
            this.showChart = false
            this.chartLoading = false
        },
        initIntervalDates() {
            let date = moment().tz(APP_TIMEZONE);
            let minDate = date.clone();

            const period = this.options.period;
            let currentPeriod = _.find(this.tabsPeriod, function (o) { return period == o.code; });

            minDate.subtract(currentPeriod.offset, currentPeriod.name.toLowerCase())

            this.options.dates.to = date.format('YYYY-MM-DD');
            this.options.dates.from = minDate.format('YYYY-MM-DD');
            this.options.dates.max = date.format('YYYY-MM-DD');
        },
        graph(item) {

            this.chartLoading = true

            let options = {
                "code": item.code,
                "from": this.options.dates.from,
                "to": this.options.dates.to,
            }

            api.stats.sourceRecordsCharts(options)
                .then(response => {

                    if (response.success) {
                        this.statsData = this.getChartDataFormatted(response);
                        this.recordsChart.bodyData.series = this.statsData.series;
                        this.recordsChart.bodyData.labels = this.statsData.labels;

                        this.chartData = response.data.data

                        this.showChart = false;
                        this.$nextTick(() => {
                            this.showChart = true;
                        });
                    }

                })
                .catch(err => {
                    this.$bvToast.toast(err.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    })
                })
                .finally(() => {
                    helper.delay(2000).then(() => {
                        this.chartLoading = false;
                    });

                })

        },
        setYAxis() {
            const max = this.getMaxSerie(this.recordsChart.bodyData.series);

            let addMarge = max ? (max + (max * 10) / 100) : 10;

            this.yaxis = max + Math.ceil(addMarge); // set Chart bodyData.yaxis.max dynamically

            this.recordsChart.bodyData.yaxis = {
                min: 0,
                max: addMarge,
                tickAmount: 5,
            }
        },
        arrayMax(arr) {
            let len = arr.length,
                max = -Infinity;
            while (len--) {
                if (Number(arr[len]) > max) {
                    max = Number(arr[len]);
                }
            }
            return max;
        },
        getMaxSerie(series = []) {
            if (!series) return 0;
            let elts = [];
            series.forEach(serie => {
                elts.push(this.arrayMax(serie.data))
            });
            return this.arrayMax(elts);
        },
        getChartDataFormatted(response, format = 'YYYY-MM-DD', comparator = 'day') {

            let chartData = {
                indicators: [],
                series: [],
                labels: [],
            }

            if (typeof response.data === "undefined") {
                return chartData
            }

            const that = this
            const data = response.data;

            let startDate = moment(data.period.from, format);
            let endDate = moment(data.period.to, format);

            chartData.labels = this.getLabelsFromRangeDates(startDate, endDate, format, `${comparator}s`);

            data.items.forEach(item => {
                let graphItems = _.filter(data.data, function (o) {

                    let label = null;
                    if (typeof that.options.label !== "undefined") {
                        label = (o.hasOwnProperty(that.options.label))
                            ? that.options.label : null
                    }

                    return item.id == o[label];
                });

                let indicatorPlan = {
                    title: item.name,
                    indicator: `${this.getSumItemsByAttr(graphItems)}`
                }
                if ((typeof item.disabled !== 'undefined' && item.disabled)) {
                    let alreadyHide = _.find(that.recordsChart.columnsHide, function (o) { return indicatorPlan.title == o; });

                    if (!alreadyHide) {
                        this.recordsChart.columnsHide.push(indicatorPlan.title)
                    }
                }
                chartData.indicators.push(indicatorPlan)

                let seriePlan = {
                    name: item.name,
                    type: `area`,
                    data: this.getDataChartByItemsAndLabels(chartData.labels, graphItems, comparator)
                }
                chartData.series.push(seriePlan)
            });

            // Update X tooltip
            this.recordsChart.bodyData.tooltip.x = {
                formatter: function (x, series, labels = chartData.labels) {
                    return labels[series.dataPointIndex]
                }
            }

            if (comparator == 'week') {
                chartData.labels = this.getLabelsFromRangeDates(startDate, endDate, '[Week ]WW-YYYY', `${comparator}s`);
            }
            return chartData
        },
        getLabelsFromRangeDates(startDate, endDate, format = 'YYYY-MM-DD', interval = 'days') {

            let now = startDate.clone(), dates = [];

            while (now.isSameOrBefore(endDate)) {
                dates.push(now.format(format));
                now.add(1, interval);
            }

            return dates;
        },
        getDataChartByItemsAndLabels(labels, items = [], comparator = 'day') {
            let data = [];
            labels.forEach(label => {
                let graphItems = _.filter(items, function (o) { return moment(o.date).isSame(label, comparator); });
                data.push(this.getSumItemsByAttr(graphItems))
            });
            return data;
        },
        getSumItemsByAttr(items, attr = 'total') {
            const sum = items.reduce((accumulator, object) => {
                if (!Object.hasOwn(object, attr) || object.event_id == 'all_time') {
                    return accumulator + 0;
                }
                return accumulator + parseInt(object[attr]);
            }, 0);
            return sum.toFixed(0);
        },
        loadGeSource(sourceID) {
            api.geSources.get(sourceID)
                .then(response => {
                    if (response.success) {
                        this.liveLeadSource = response.data
                    }
                })
                .catch(error => {
                    this.ge_source_id = null
                    this.liveLeadSource = {}
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    })
                    this.$router.replace({ name: 'ge.sources' })
                })
        },
        detailsRedirect(dateFrom = null, dateTo = null, serie = null) {
            return helper.redirectToLiveLeadsRecords(this, dateFrom, dateTo, serie)
        },
        resetChartData() {
            this.recordsChart.bodyData.series = [];
            this.recordsChart.bodyData.labels = [];
        },
        onFilter(data) {

            this.filters = this.filterTable(data);

            this.$refs['dt-sources'].renderDT = false;
            this.$nextTick(() => {
                this.$refs['dt-sources'].renderDT = true;
            });

            helper.delay(2000).then(() => {
                if (typeof this.$refs['dt-filters'].finishFilter === 'function') {
                    this.$refs['dt-filters'].finishFilter()
                }
            })
        },
        filterTable(params) {
            let filters = {
                active: params.status?.code ?? '',
                clean: params.clean?.code ?? '',
                platform: params.platform ? _.map(params.platform, 'name') : [],
                date_to: params.date_to ?? '',
                date_from: params.date_from ?? ''
            };

            return filters;
        },
        formatDecimal(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        },
        createIntegration(event) {
            this.form = event;
            this.$refs[this.addModal].hideModal();
            this.$refs['modal-create-integration'].show()
        },
        closeIntegrationModal() {
            this.$refs['modal-create-integration'].hide();
        },
        createIntegrationForEdit(event) {
            this.form = event;
            this.$refs[this.editModal].hideModal();
            this.$refs['modal-create-integration'].show()
        },
        toggleChartSerie($event, item) {
            $event.preventDefault();
            this.detailsRedirect(this.options.dates.from, this.options.dates.to, item.title);
        },
        clearHideColumns(chartContext, index) {
            let currIndicator = this.statsData.indicators[index];

            const that = this;
            if (_.includes(this.recordsChart.columnsHide, currIndicator.title)) {
                this.recordsChart.columnsHide = _.remove(this.recordsChart.columnsHide, function (popItem) {
                    return popItem !== currIndicator.title;
                });
            } else {
                let alreadyHide = _.find(that.recordsChart.columnsHide, function (o) { return currIndicator.title == o; });

                if (!alreadyHide) {
                    this.recordsChart.columnsHide.push(currIndicator.title)
                }
            }
        },
        isHide(name) {
            return _.includes(this.recordsChart.columnsHide, name)
        },
        showAutomationModal($event) {
            this.sourceForAutomation = $event;
            this.$refs[this.addModal].hideModal();

            helper.delay(500).then(() => {
                this.$root.$emit('bv::show::modal', this.addModalAutomation);
            });

        },
        showModalAddSource(e) {
            e.preventDefault();

            // Check Inactive Account
            if(this.user && this.user.account && !this.user.account.status) {
                helper.alertAccountInactive(this.$swal);
                return;
            }

            let checkShowAlert  = !this.isClient ? {state: null} : helper.showAlertOutOfEventOrEmptyBalance(SERVICE_ID);

            if (checkShowAlert.state && checkShowAlert.alert) {
                this.$swal(checkShowAlert.alert);
                return;
            } else {
                this.$root.$emit('bv::show::modal', this.addModal)
            }
        },
        showAlertRemaining() {
            let subscription = this.UserSubscriptions[2];

            let date = subscription ? subscription.ends_at : '--';

            let alertMessage = `It\'s appears you are out of records until [${date}]. If you\'d like to activate your overconsumption feature to continue sending events until your next billing cycle, please visit your dashboard`;

            this.$swal({
                title: 'Uh, oh...',
                text: alertMessage,
                type: 'info',
                confirmButtonText: 'Got it!',
                showCloseButton: true,
            })
        },
        updateItem(resultData) {

            const DT = this.$refs['dt-sources'].$refs['sources-table'].$refs[this.$refs['dt-sources'].$refs['sources-table'].id];
            const index = DT.$data.localItems.findIndex((item) => item.id === resultData.id);
            
            console.log( DT.$data, index )

            if (index !== -1) {

                const itemUpdated = { ...this.liveLeadSource, ...resultData }
                this.$set(DT.$data.localItems, index, itemUpdated);
                
            } else {
                
                this.$root.$emit('bv::refresh::table', this.$refs['dt-sources'].$refs['sources-table'].id)
                
            }
        },
    },
    watch: {
        'periodCode': {
            handler(newValue, oldValue) {
                this.options.period = newValue;

                this.resetChartData();
                this.initIntervalDates();
                if (this.liveLeadSource) {
                    this.graph(this.liveLeadSource)
                }
            },
            deep: true
        },
        'statsData': {
            handler(newValue, oldValue) {
                this.setYAxis();
            }
        },
    },
};
</script>
<style>
.indicator-disabled {
    opacity: 0.65;
}
.iq-card-body {
    padding: 15px;
    padding-top: 6px !important;
}
</style>
