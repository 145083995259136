<template>

    <b-container fluid>
        <DataTable v-if="renderDT" ref="automations-table" :url="'/ge-automations'"
            v-bind="{ columns: columns, customCells: dtCells, organization: accountId, filters: filters, selectable: true, rowSelect: [] }"
            @onClick="handleBtn" 
            @onSwitch="handleSwitch" 
            @sortChanged="handleSortChanged" 
            @bulkAction="handleBulk" 
            @onSelectItems="handleSelectItems"></DataTable>

             <!-- @onSwitch="handleSwitchAttr" @onClick="handleBtn" @sortChanged="sortChanged" -->
            <!-- @onSelectItems="handleSelectItems" @bulkAction="bulk" @contextChanged="showChart = false" -->
    </b-container>
    
</template>
<script>
import DataTable from '@/components/inboxgeek/tables/DataTable'
import DtCheckboxItem from './DtCheckboxItem';
import DtAutomationPlatform from './DtAutomationPlatform.vue';
import AutomationReceptionLists from './AutomationReceptionLists.vue';
import AutomationTotalContacts from './AutomationTotalContacts.vue';
import IBGSwitchCell from '@/components/inboxgeek/tables/IBGSwitchCell.vue'
import LLAutomationActions from '@/views/GetEmails/children/LLAutomationActions.vue'

import { mapGetters } from 'vuex'
import _ from 'lodash'
import { helper } from '@/helpers'

import api from '@/api/RestClient';

export default {
    name: 'AutomationsTable',
    components: {
        DataTable
    },
    props: {
        search: {
            type: String,
            default: null
        },
        accountId: {
            type: Number,
            default: null
        },
        filters: {
            type: Object,
            default: {}
        },
    },
    data() {
        return {
            renderDT: true,
            dtCells: [
                {
                    key: 'select',
                    component: DtCheckboxItem,
                    event: 'onSelectItem',
                },
                {
                    key: 'platform',
                    component: DtAutomationPlatform,
                    event: null,
                },
                {
                    key: 'lists',
                    component: AutomationReceptionLists,
                    event: null,
                },
                {
                    key: 'total_contacts',
                    component: AutomationTotalContacts,
                    event: null,
                },
                {
                    key: 'status',
                    component: IBGSwitchCell,
                    event: 'onSwitch',
                    props: {
                        attribute: 'status',
                    }
                },
                {
                    key: 'actions',
                    component: LLAutomationActions,
                    event: 'onClick',
                }
            ],
        }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
        }),
        columns() {
            let columns = [
                { label: '', key: 'select', class: 'text-center', sortable: false },
                { label: 'Name', key: 'name', class: 'text-left', sortable: true, sortDirection: 'desc' },
                { label: 'Source', key: 'source.name', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { label: 'Platform', key: 'platform', class: 'text-center', sortable: false, sortDirection: 'desc' },
                { label: 'Reception List', key: 'lists', class: 'text-center', sortable: false, sortDirection: 'desc' },
                { label: 'Organization', key: 'account.name', class: 'text-center', sortable: false, sortDirection: 'desc' },
                { label: '#records', key: 'total_contacts', class: 'text-center', sortable: false, sortDirection: 'desc' },
                {
                    label: 'Created at', key: 'created_at', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let dt = new Date(value);
                        return helper.formatDate(dt)
                    }
                },
                { label: 'Status', key: 'status', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { label: 'Actions', key: 'actions', class: 'text-center', sortable: false, sortDirection: 'desc' },
            ];

            if (!this.isAdmin) {
                // Remove Organization
                _.remove(columns, {
                    key: 'account.name'
                });
            }

            
            return columns;
        }
    },
    mounted() {
        
    },
    methods: {
        forceRenderTable() {
            this.renderDT = false;

            this.$nextTick(() => {
                this.filters.service = this.service;
                this.renderDT = true;
            });
        },
        handleSwitchVisible(data) {

            let itemCode = data.row.id
            let form = {}
            form[data.key] = data.input.checked ? 1 : 0

            let toast = { message: 'Something went wrong!', title: 'Error', variant: 'danger' }

            return api.plans.put(itemCode, form)
                .then(result => {
                    data.input.checked = result.data[data.key];
                    toast = { message: result?.message, title: 'Success', variant: 'success' }
                })
                .catch(err => {
                    this.$bvToast.toast(err.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    });
                })
                .finally(() => {
                    this.$root.$bvToast.toast(toast.message, toast);
                });
        },
        handleBtn({row, event}) {
            if(!row) return;

            this.$emit('onClick', { row, event });
        },
        handleSwitch(data) {

            let showPreAlert = null;
            let checkShowAlert = null;

            if (!data.input || !data.row || !data.key) {
                return
            }

            checkShowAlert = !this.isClient ? { state: null } : helper.showAlertOutOfEventOrEmptyBalance(SERVICE_ID);
            if (checkShowAlert.state && checkShowAlert.alert && data.input.checked) {
                showPreAlert = true;
            }

            const updateRowState = (data) => {
                let itemCode = data.row.id
                let form = {}
                form[data.key] = data.input.checked ? 1 : 0

                let toast = { message: 'Something went wrong!', title: 'Error', variant: 'danger' }

                return api.geAutomations.update_status(itemCode, form)
                    .then(result => {
                        toast = { message: result?.message, title: 'Success', variant: 'success' }
                    })
                    .catch((error) => {
                        data.input.checked = !data.input.checked;
                        let errors = [];
                        toast.message = error.response.data.message;

                    })
                    .finally(() => {
                        this.$root.$bvToast.toast(toast.message, toast);
                    });
            }


            if (showPreAlert && checkShowAlert?.state && checkShowAlert?.alert) {

                return (this.$swal(checkShowAlert.alert))
                    .then((result) => {
                        updateRowState(data)
                    })

            } else {

                updateRowState(data)

            }


        },
        handleSortChanged(data) {
            this.$emit('sortChanged', data);
        },
        handleBulk(data) {
            let showAlert = null;
            let checkShowAlert = null;

            // Check Inactive Account
            if(this.user && this.user.account && !this.user.account.status) {
                helper.alertAccountInactive(this.$swal);
                return;
            }

            if (typeof data.action == 'undefined' || typeof data.items == 'undefined' || (data.items && !data.items.length)) {
                return
            }

            const that = this.$refs['automations-table'];

            let bulkData = { active: -1 }

            let alertObject = {
                title: 'Are you sure?',
                text: `This action will permenantly delete ` + (this.$refs['automations-table'].itemsSelected.length ? ` ${(this.$refs['automations-table'].itemsSelected.length)} Automation${(this.$refs['automations-table'].itemsSelected.length > 1 ? 's' : '')}` : ''),
                type: 'danger',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                confirmButtonColor: '#e64141',
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !this.$swal.isLoading()
            }

            switch (data.action) {
                case 'turn-on':

                    checkShowAlert  = !this.isClient ? {state: null} : helper.showAlertOutOfEventOrEmptyBalance(SERVICE_ID);

                    if (checkShowAlert.state && checkShowAlert.alert) {
                        showAlert = true;
                    }
                    
                    alertObject.type = 'success'
                    alertObject.text = `If your ` + `${(this.$refs['automations-table'].itemsSelected.length ?? '')}` + ` Automation${(this.$refs['automations-table'].itemsSelected.length > 1 ? 's' : 'Automation')}` + ` shares contacts with any other synced list on the InboxGeek platform, events will begin sending as soon as today.`
                    alertObject.confirmButtonText = 'Turn on'
                    alertObject.confirmButtonColor = '#00ca00'
                    bulkData = { active: 1 }
                    break

                case 'turn-off':
                    alertObject.type = 'question'
                    alertObject.text = `If an Automation is turned off for longer than 1:00a EST the following day, the contacts synced to that list will need 24 hours to re-sync once the Automation is turned on again.`
                    alertObject.confirmButtonText = 'Turn off'
                    alertObject.confirmButtonColor = '#0084ff'
                    bulkData = { active: 0 }
                    break

                case 'delete':
                    bulkData = null
                    break

                default:
                    break
            }


            let swalObj = {
                type: 'success',
                confirmButtonText: 'OK',
                showCloseButton: true,
            }

            alertObject.preConfirm = () => {

                that.$swal.update({
                    title: 'In Progress', text: 'Please wait, action in progress ...', showConfirmButton: false, allowOutsideClick: false, showCloseButton: false,
                })

                if (data.action == 'delete') {

                    return api.geAutomations.bulkDelete(data.items)
                        .then(result => {
                            swalObj.title = 'Delete!';
                            swalObj.text = result.message;
                            this.indeterminate = false
                        })
                        .catch(function (error) {
                            swalObj.type = 'warning';
                            swalObj.title = 'Warning!';
                            swalObj.text = error.response.data.message;

                    }).finally(() => {
                        this.$bvToast.toast(swalObj.text, {
                            title: swalObj.title,
                            variant: swalObj.type,
                        })
                        })
                }

                return api.geAutomations.bulk(data.items, bulkData)
                    .then(result => {
                        swalObj.title = 'Success!';
                        swalObj.text = result.message;
                        this.indeterminate = false
                    })
                    .catch(function (error) {
                        swalObj.type = 'warning';
                        swalObj.title = 'Warning!';
                        swalObj.text = error.response.data.message;
                }).finally(() => {
                    this.$bvToast.toast(swalObj.text, {
                        title: swalObj.title,
                        variant: swalObj.type,
                    })
                    })

            };

            const bulkConfirmAction = (obj) => {

                that.$swal(alertObject)
                    .then((result) => {
                        this.$refs['automations-table'].itemInOperate = false;

                        if (result.isConfirmed) {
                            this.$refs['automations-table'].selected = [];
                            this.$refs['automations-table'].selectAll = false;
                            that.$swal(swalObj)
                            this.$refs['automations-table'].$root.$emit('bv::refresh::table', this.$refs['automations-table'].id);
                        }
                    })

            }

            if(showAlert && checkShowAlert?.state && checkShowAlert?.alert) {

                return (this.$swal(checkShowAlert.alert))
                    .then((result) => {
                        bulkConfirmAction(alertObject)
                    })
            } else {
                bulkConfirmAction(alertObject)
            }
                

        },
        handleSelectItems(data) {
            this.$emit('onSelectItems', data);
        },
    },
}

</script>