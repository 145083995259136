<template>

    <b-container fluid>
        <InvoiceDataTable v-if="renderDT" :id="id" ref="invoices-table" :url="'/invoices'"
            v-bind="{ columns: columns, customCells: dtCells, sort: 'created_at', filter_on: ['description'] , invoiceFilters:filter }"
            @onClick="handleAction"
            />
    </b-container>


</template>
<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import { helper } from '@/helpers'

import InvoiceDataTable from '@/components/inboxgeek/tables/InvoiceDataTable'
import DtInvoiceRefundCell from './table/DtInvoiceRefundCell';
import DtInvoiceStatusCell from './table/DtInvoiceStatusCell';
import DtActionsCell from './table/DtActionsCell';

export default {
    name: 'PlansTable',
    components: {
      InvoiceDataTable,
    },
    props: {
        id: {
            type: String,
            default: 'invoices-table'
        },
      filter:{
          default : null
      }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isAccounting: 'Auth/isAccounting',
            isManager: 'Auth/isManager',
            isClient: 'Auth/isClient',
        }),
        columns() {
            let columns = [
                {
                    key: 'id',
                    label: '#ID',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center'
                },
                {
                    key: 'description',
                    label: 'Description',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        return item.description ? item.description : '--';
                    }
                },
                {
                    key: 'amount',
                    label: 'Amount',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        return helper.moneyDefaultFormat(item.amount);
                    }
                },
                {
                    key: 'total_refund',
                    label: 'Total Refund',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                },
                {
                    key: 'date',
                    label: 'Date',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center'
                },
                {
                    key: 'due_date',
                    label: 'Due date',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center'
                },
                {
                    key: 'account',
                    label: 'Organization',
                    sortable: false,
                    sortDirection: 'desc',
                    class: 'text-center'
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: false,
                    class: 'text-center'
                },
                {
                    key: 'actions',
                    label: 'Action',
                    sortable: false,
                    class: 'text-center'
                },

            ];

            columns = _.map(columns, (item) => {
                if (this.isAdmin || this.isAccounting) {
                    return item;
                } else {
                    if (!["actions"].includes(item.key)) return item;
                }

            })

            return columns
        }
    },
    data() {
        return {
            renderDT: true,
            dtCells: [
                {
                    key: 'total_refund',
                    component: DtInvoiceRefundCell,
                    event: null,
                },
                {
                    key: 'status',
                    component: DtInvoiceStatusCell,
                    event: null,
                },
                {
                    key: 'actions',
                    component: DtActionsCell,
                    event: 'onClick',
                },
            ],
        }
    },
    methods: {
        handleAction({item, event}) {
            if(!item || !event) return;

            if(event == 'histories') {
                this.$emit('onHistories', item);
            } else if (event == 'refund') {
                this.$emit('onRefund', item);
            } else if (event == 'update') {
                this.$emit('onEdit', item);
            }else if (event == 'markAsPaid'){
              this.$emit('markAsPaid',item);
            }
        },
        forceRenderTable() {
            this.renderDT = false;
            this.$nextTick(() => {
                this.renderDT = true;
            });
        },
    }
}
</script>
