<template>
    <b-container>
        <iq-card class="">
            <template v-slot:headerTitle>
                <b-row class="mt-4">
                    <b-col md="6">
                        <b-form-group label="From" label-for="from">
                            <b-form-input type="date" v-model="from" :max="to" :value="from" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="To" label-for="to">
                            <b-form-input type="date" v-model="to" :min="from" :max="max" :value="to"
                                readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </template>
        </iq-card>


        <b-table ref="dt-management-reports" :items="items" :fields="fields" :current-page="paginate.page"
            :per-page="paginate.perPage">
            <template v-slot:cell(actions)="{ item }">
                <b-btn @click="toggleDetails(item)">
                    <b-spinner small label="Small Spinner" v-if="item == currentDetail" type="grow"></b-spinner>
                    Show Details
                </b-btn>
            </template>
            <template v-slot:row-details="{ item: { details: { message, row } } }">
                <iq-card>
                    <template v-slot:headerTitle>
                    </template>

                    <template v-slot:body>

                        <div class="text-left p-4 bg-light rounded position position-relative">
                            <span class="text-left" v-html="message"></span>
                            <i class="ri-file-copy-2-line fa-lg px-2 btn btn-light position position-absolute"
                                style="top: 0; right: 10px" @click.prevent="copyMessage(message)"></i>

                            <button class="btn iq-bg-info float-right mx-1" @click.prevent="downloadGraphImg(row)">
                                <i class="ri-folder-chart-line text-primary"></i> Download Graph
                            </button>
                            <button class="btn btn-light bg-white float-right mx-1" @click.prevent="showGraph(row)">
                                <i class="ri-line-chart-fill text-primary"></i> Show Graph
                            </button>
                        </div>

                        <b-row v-if="showChart || loadAdminStats" class="d-flex justify-content-center">
                            <b-col md="12" class="charts" v-if="!isAdmin && showChart">
                                <Ibgmorris :item="charts">
                                </Ibgmorris>
                            </b-col>
                            <b-col md="12" class="adminCharts" v-if="isAdmin && loadAdminStats">
                                <ApexCharts :params="params" :series="adminEventsChart.series"
                                    :categories="adminEventsChart.categories" :columnsHide="apexColumnsHide"
                                    :entity="entity">
                                </ApexCharts>
                            </b-col>
                        </b-row>


                    </template>
                </iq-card>
            </template>
        </b-table>

        <DtPaginate v-show="renderTablePaginate" :from="paginate.from" :to="paginate.to" :totalRows="items.length"
            :currentPage="paginate.page" :perPage="paginate.perPage" :pageOptions="paginate.pageOptions"
            @handlePerPage="updatePerPage" @handlePage="updatePage"></DtPaginate>
    </b-container>
</template>
<script>
import Spinner1 from '@/components/loaders/spinner1'
import store from '@/store/index'
import api from '@/api/RestClient'
import { helper } from '@/helpers';
import {
    mapGetters
} from "vuex";
import moment from 'moment'
import Ibgmorris from '../../../components/inboxgeek/IbgMorris'
import ApexCharts from "../../LiveReports/children/ApexCharts.vue"
import DtPaginate from '@/components/inboxgeek/tables/DtPaginate.vue'

export default {
    name: 'TableReport',
    props: ['rows', 'columns', 'from', 'to', 'max'],
    components: {
        Spinner1,
        Ibgmorris,
        ApexCharts,
        DtPaginate
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
        })
    },
    data() {
        return {
            isLoading: true,
            currentDetail: null,
            items: [],
            fields: ['id', 'name', 'amount_of_contacts', 'daily_limit', {
                key: 'actions',
                label: ''
            }],
            entity: null,
            charts: {},
            loadAdminStats: false,
            apexChartTitle: '***',
            adminEventsChart: {
                series: [],
                categories: []
            },
            showChart: false,
            apexColumnsHide: ['Others', 'Contact Clean', 'Time Limit Reached', 'Daily Limit Reached', 'Contact Already Tagged', 'Events Already Sent', 'Insufficient Balance'],
            dataCalculated: {},
            renderTablePaginate: false,
            paginate: {
                page: 1,
                from: 1,
                to: null,
                perPage: 5,
                pageOptions: [5, 10, 20, 50, 100, {
                    value: 250,
                    text: "Show a lot"
                }],
            }
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.renderTablePaginate = false;

            // Set items and fields
            if (Array.isArray(this.rows) && this.rows.length) {
                this.items = this.rows
                    .map(row => {
                        const lists = row.lists;
                        const contacts = lists.length > 0 ? this.sumInArrayByKey(row.lists, 'total_contacts') : 0

                        return {
                            id: row.id,
                            name: row.name,
                            amount_of_contacts: helper.formatDecimal(contacts),
                            balance: row.account.balances.length ? helper.formatDecimal(row.account.balances[0].balance) : 'None',
                            daily_limit: row.limit != -1 ? row.limit : '--',
                            graph: (row) => {
                                return '--';
                            },
                        }
                    })
            }

            // Set paginate data
            this.setPagination();
            if (this.items.length > this.paginate.perPage) {
                this.renderTablePaginate = true;
            }

            helper.delay(2000).then(() => {
                this.isLoading = false;
            })
        },
        sumInArrayByKey(items = [], key = 'events') {
            let sum = 0;
            items.forEach(item => {
                sum += item[key]
            });
            return sum;
        },
        resetVisibleChart() {
            // Reset Charts
            this.showChart = false;
            this.loadAdminStats = false;
            this.showChart = false;
        },
        toggleDetails(item) {
            this.currentDetail = item;

            this.resetVisibleChart();

            this.items.forEach((row, i) => {
                if (row._showDetails) row._showDetails = false
            });

            if (item._showDetails) { // if details are open, close them
                item._showDetails = false
            } else if (item.details) { // if details already exists, show the details
                this.$set(item, '_showDetails', true)
            } else {
                const that = this;

                let row = _.find(that.rows, function (o) { return item.id == o.id; });
                if (!row) {
                    return {}
                }
                this.adminGraph(row);

                helper.delay(2000).then(() => {
                    item = this.loadChartRow(row, item);
                    this.$set(item, '_showDetails', true)
                })
            }

            helper.delay(2000).then(() => {
                let cardHeader = this.$el.querySelector('.adminCharts div.iq-card > div.iq-card-header.d-flex.justify-content-between')
                cardHeader.classList.remove('justify-content-between');
                cardHeader.classList.add('justify-content-center');
                
                this.currentDetail = null;
            });

        },
        showGraph(row, download = false) {

            // TODO: Fetch item Graph 
            if (this.isAdmin) {
                this.adminGraph(row, download);
            }
            else {
                this.graph(row);
            }
        },
        downloadGraphImg(row) {
            this.showGraph(row, true);
        },
        loadChartRow(row, item) {
            // Data to set via graph data

            let eventsPerDay = row.limit != -1 ? row.limit : 'unlimited';
            let events =  this.sumInArrayByKey(row.events, 'events_sent');
            let total_contacts = this.sumInArrayByKey(row.lists, 'total_contacts');

            let startTime = moment(this.from); //todays date
            let endTime = moment(this.to);
            let duration = moment.duration(endTime.diff(startTime));
            let daysNumber = duration.asDays() + 1;
            let average = events ? events / daysNumber : 0;

            let dt = new Date(row.created_at);
            let date = helper.formatDate(dt);

            item.details = {
                message: `<b>${date}</b> - <b>${row.name}</b> - <b>${(row.lists.length)}</b> Integration <b>${total_contacts}</b> contacts set to send
                        <b>${eventsPerDay}</b> events/day. Since ${startTime.format('DD-MM-YYYY')} to ${endTime.format('DD-MM-YYYY')} has sent <b>${events.toFixed(2)}</b> events with a daily average of <b>${average.toFixed(2)}</b><br> Graph
                        indicates steady trends.`,
                row: row
            }

            return item;
        },
        copyMessage(message = '') {
            if (message) {
                navigator.clipboard.writeText(message)
                this.$bvToast.toast('Detail Copied', {
                    title: 'Copy Details Text',
                    variant: 'info'
                })
            }
        },
        graph(item) {
            this.entity = item;
            this.showChart = false;
            let options = {
                "integration_id": item.id,
                "from": this.from,
                "to": this.to,
            }
            api.stats.getOpenersStats(options)
                .then(response => {
                    const data = response.data;
                    let responseData = data.chart;
                    const ONE_DAY = 1;
                    let charts = {
                        title: item.tag_name + ' (Events Sent ' + options.from + ' to ' + options.to + ')',
                        type: 'line',
                        colors: ['#36A2EB'],
                        xKeys: 'period',
                        yKeys: ['a'],
                        labels: ['Events'],
                    }

                    let stat = false;
                    let dates = data.dates;
                    if (dates.length == ONE_DAY) {
                        let _dates = dates;
                        this.xLabels = "hour";
                        stat = true;
                        dates = [];
                        for (let i = 0; i < 24; i++) {
                            let date = _dates[0];
                            dates.push(this.getDateAndTime(date, i));

                        }
                    } else {
                        this.xLabels = "day";
                    }
                    charts.bodyData = this.chartData(dates, responseData, stat);

                    // this.$root.$emit('chartDone', item);
                    item.editable = false;

                    setTimeout(() => {
                        charts.xLabels = this.xLabels;
                        this.charts = charts;
                        this.showChart = true;
                    }, 1000);

                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    this.progress = false;
                })

        },
        adminGraph(item, isDownloadable = false) {
            this.entity = item;
            this.showChart = false;
            this.loadAdminStats = false;
            this.apexChartTitle = item.name;
            this.params = {
                title: item.name,
                from: this.from,
                to: this.to,
                integration_id: item.id,
                account_id: item.account_id
            }

            this.dataCalculated = {
                eventsPerDay: 0,
                eventsSent: 0,
                average: 0
            }

            api.stats.getOpenersStatsForAdminByDates(this.params)
                .then(response => {
                    let keys = response.data.keys;
                    let labels = response.data.labels;
                    let statistics = response.data.chart;
                    let dates = _.map(statistics, function (o) {
                        return o.date
                    });
                    this.adminEventsChart.categories = dates;
                    this.adminEventsChart.series = this.adminChartEventBuildSeries(keys, labels, statistics);

                    let a = moment(this.from);
                    let b = moment(this.to);
                    let daysInterval = b.diff(a, 'days') + 1;


                    let eventsSent = 0;
                    this.adminEventsChart.series.forEach(serie => {
                        serie.data.forEach(nbEvents => {
                            if (serie.name == "Events Sent") eventsSent += nbEvents
                        });
                    });

                    this.dataCalculated.eventsPerDay = eventsSent / daysInterval;
                    this.dataCalculated.eventsSent = eventsSent;

                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.loadAdminStats = true;

                    if (isDownloadable) {
                        setTimeout(() => {
                            let btnSVG = document.querySelector('[title="Download SVG"]');
                            if (btnSVG) btnSVG.click();
                        }, 1000);
                    }
                })
        },
        chartData(dates, responseData, ONE_DAY) {
            var bodies = [];
            let responseDatas = responseData;
            for (let index = 0; index < dates.length; index++) {
                var date = {
                    a: 0,
                    period: dates[index]
                }
                if (responseDatas.length > 0) {
                    responseDatas.forEach(data => {
                        if (ONE_DAY) {
                            if (data.hour == index) {
                                date.period = dates[index]
                                date.a = data.events
                            }
                        } else {
                            if (data.period == dates[index]) {
                                date.period = data.period
                                date.a = data.a
                            }
                        }
                    });

                }

                bodies.push(date)
            }

            return bodies;
        },
        adminChartEventBuildSeries(keys = [], labels = [], statistics = []) {

            let series = [];
            let serie = {};
            let data = [];

            for (let index = 0; index < labels.length; index++) {
                serie.name = labels[index];
                if (statistics.length > 0) {
                    for (let i = 0; i < statistics.length; i++) {
                        data.push(statistics[i][keys[index]]);

                    }
                }
                serie.data = data;
                series.push(serie);
                serie = {};
                data = [];
            }

            return series;
        },
        updatePage(data) {
            this.paginate.page = data
            this.setPagination();
            this.$root.$emit('bv::refresh::table', 'dt-management-reports')
        },
        updatePerPage(data) {
            this.paginate.perPage = data;
            this.setPagination();
            this.$root.$emit('bv::refresh::table', 'dt-management-reports')
        },
        setPagination() {
            this.paginate.from = ((this.paginate.page - 1) * this.paginate.perPage) + 1;
            this.paginate.to = (1 * this.paginate.page * this.paginate.perPage);
        }
    }
}
</script>
<style>
i.position-to-right {
    position: absolute;
    top: 0;
    right: 10px;
}
</style>