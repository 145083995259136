import { PLATFORMS_CODE, COLUMNS_GRID } from "@/constantes";
import api from '@/api/RestClient';

export default function platform(axios) {
    return {
        labels: {
        },
        maps: {
            listsMap: (obj) => {
                let item = {}
                item.code = obj.id
                item.name = obj.name
                item.type = obj['type']
                return item
            },
        },
        customShowAnotherCols(COMP) {
            COMP.showCols.tags = false;
            COMP.showCols.event = true;
            if(typeof COMP.showCols.apiKey !== 'undefined')  COMP.showCols.apiKey = true;
            if(typeof COMP.showCols.accountId!== 'undefined')  COMP.showCols.accountId = true;
            COMP.apiTokenLabel = 'API Key:*';

            if(typeof COMP.$refs["step-platform"] !== 'undefined') {
                COMP.$refs["step-platform"].accountLabel = 'Your APIv2 address';
                COMP.$refs["step-platform"].accountPlaceholder = 'APIv2 address';
            }
            
            if(typeof COMP.accountLabel !== 'undefined') COMP.accountLabel = 'Your APIv2 address';
            if(typeof COMP.accountPlaceholder !== 'undefined') COMP.accountPlaceholder = 'APIv2 address';
            
            COMP.apiTokenPlaceholder = 'API key';
            if(typeof COMP.cols.apiKeyCol !== 'undefined')  COMP.cols.apiKeyCol = COLUMNS_GRID.COL12;
        },

    }
}