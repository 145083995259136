<template>

    <b-container fluid>

        <b-row>

            <b-col md="12">

                <label class="mr-2">Trigger link : *</label>

                <b-form-input v-model="triggerLink" name="tag" placeholder="Trigger link" @input="updateValue">
                </b-form-input>
            </b-col>

        </b-row>

      <b-alert :show="error.show" variant="danger">{{ error.message }}</b-alert>

    </b-container>

</template>
<script>

import LimitPerDayInput from "@/components/inboxgeek/fields/integration/LimitPerDayInput";

export default {
    name: 'WebhookLoIntegrationStep',
    components: {
        LimitPerDayInput,
    },
    props: {
        form: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
          triggerLink: null,
          error:{
            show:false,
            message:''
          }
        }
    },
    methods: {
        handleSelectTag() {
            this.$emit('tag', this.integration)
        },
        updateValue() {
          this.error.show = false;
          this.error.message = '';
          if(this.validateLink()){
            this.$emit('tag', {tag: {code:null, name: this.triggerLink}, type: 'Trigger link'})
          }else{
            this.error.show = true;
            this.error.message = 'provided link is invalid';
          }

            // this.$emit('customEvent', {code: null, name: this.eventName});
        },
      validateLink() {
        const urlPattern = new RegExp(
            '^(https?:\\/\\/)?' + // Protocol
            '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // Domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
            '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // Port and path
            '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // Query string
            '(\\#[-a-zA-Z\\d_]*)?$',
            'i'
        );

        return urlPattern.test(this.triggerLink);
      },
        handleUpdateLimitPerDay(data) {
            this.$emit('limitPerDay', data)
        },
    }
}

</script>