<template>

    <b-container fluid>
        
        <DataTable v-if="renderDT"

            ref="dt-liveopens-reports" 
            :url="'/liveopens-report'" 
            v-bind="{ 
                columns: columns, customCells: dtCells, filters: filters, sort: 'account_name',
                theadTop: [
                    { colspan: 1, variant: null, srOnly: true, title: 'Name' },
                    { colspan: 1, variant: null, srOnly: false, title: 'Generated Events' },
                    { colspan: 2, variant: 'light', srOnly: false, title: 'Sent' },
                    { colspan: 2, variant: 'light', srOnly: false, title: 'Balance' },
                    { colspan: 2, variant: 'light', srOnly: false, title: 'Already Sent' },
                    { colspan: 2, variant: 'light', srOnly: false, title: 'Already Tagged' },
                    { colspan: 2, variant: 'light', srOnly: false, title: 'Daily Limit' },
                    { colspan: 2, variant: 'light', srOnly: false, title: 'Time Limit' },
                    { colspan: 2, variant: 'light', srOnly: false, title: 'Others' },
                ]
                }"></DataTable>

    </b-container>

</template>
<script>
import DataTable from '@/components/inboxgeek/tables/DataTable.vue'
import { helper } from '@/helpers'

export default {
    name: 'LiveOpensEventsReportTable',
    components: {
        DataTable
    },
    props: {
        filters: {
            type: Object,
            default: {},
        }
    },
    computed: {
        columns() {
            let columns = [
                { label: 'Account Name', key: 'account_name', class: 'text-left', sortable: true, sortDirection: 'desc' },
                { label: 'Events', key: 'received_events', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { label: 'Event Sent', key: 'events_sent', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { 
                    label: '%', key: 'events_sent_percent', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getPercentDecimal(value)
                    }
                },
                { label: 'Insufficient Balance', key: 'insufficient_balance', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { 
                    label: '%', key: 'insufficient_balance_percent', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getPercentDecimal(value)
                    }
                },
                { label: 'Already Sent', key: 'already_sent', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { 
                    label: '%', key: 'already_sent_percent', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getPercentDecimal(value)
                    }
                },
                { label: 'Already Tagged', key: 'contact_already_tagged', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { 
                    label: '%', key: 'contact_already_tagged_percent', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getPercentDecimal(value)
                    }
                },
                { label: 'Daily Limit', key: 'daily_limit', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { 
                    label: '%', key: 'daily_limit_percent', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getPercentDecimal(value)
                    }
                },
                { label: 'Time Limit', key: 'time_limit', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { 
                    label: '%', key: 'time_limit_percent', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getPercentDecimal(value)
                    }
                },
                { label: 'Others', key: 'others', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { 
                    label: '%', key: 'others_percent', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getPercentDecimal(value)
                    }
                },

            ];

            return columns;
        }
    },
    data() {
        return {
            renderDT: true,
            dtCells: [
            ],
        }
    },
    methods: {
        forceRenderTable() {
            this.renderDT = false;

            this.$nextTick(() => {
                this.renderDT = true;
            });
        },
        getPercentDecimal(value) {
            const decimal = value && !(value % 1) ? 0 : 3;
            return value ? parseFloat(value).toFixed(decimal) + '%' : 0;
        }
    }
}

</script>