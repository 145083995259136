import Qs from 'qs';

export default function (axios) {
  return {
    /**
     * API route depracate - Can Use getConsumptionService
     * @param {*} service_id
     * @returns
     */
    getConsumptionDataService: (service_id = 1) => {
      return axios.get(`accounts/consumption_details/` + service_id, {
        paramsSerializer: function (params) {
          return Qs.stringify(params, { arrayFormat: 'brackets' })
        }
      }).then(response => response.data);
    },
    getOpenersStats: (options) => {
      return axios.get(`/stats/charts/openers`, {
        params: {
          'from': options.from,
          'to': options.to,
          'integration_id': options.integration_id
        }
      }).then(response => response.data);
    },
    getEventsTotalStats: (options) => {
      return axios.get(`/stats/events`, {
        params: {
          'from': options.from,
          'to': options.to,
          'service': options.service
        },
        paramsSerializer: function (params) {
          return Qs.stringify(params, { arrayFormat: 'brackets' })
        }
      }).then(response => response.data);
    },
    getEventsTotal: (params = {}) => {
      return axios.get(`/stats/events`, { params })
        .then(response => response.data);
    },
    reports: (params = {}) => {
      return axios.get(`/stats/openers-events`, { params })
        .then(response => response.data);
    },
    revenues: (options) => {
      return axios.get(`/stats/revenues`, {
        params: {
          'from': options.from,
          'to': options.to,
          'service': options.service,
          'total_events': options.total_events
        },
        paramsSerializer: function (params) {
          return Qs.stringify(params, { arrayFormat: 'brackets' })
        }
      }).then(response => response.data);
    },
    revenueCharts: (options) => {
      return axios.get(`/stats/charts/revenues`, {
        params: {
          'from': options.from,
          'to': options.to
        },
        paramsSerializer: function (params) {
          return Qs.stringify(params, { arrayFormat: 'brackets' })
        }
      }).then(response => response.data);
    },
    getOpenersStatsForAccountByDates: (params = {}) => {
      return axios.get(`/stats/account-openers-events`, { params })
        .then(response => response.data);
    },
    getOpenersStatsForAdminByDates: (params = {}) => {
      return axios.get(`/stats/admin-check-events`, { params })
        .then(response => response.data);
    },

    accountReports: (params = {}) => {
      return axios.get(`/stats/openers-events-account`, { params })
        .then(response => response.data);
    },
    download: (params = {}) => {
      return axios.get(`/stats/live-report/download`, { params })
        .then(response => response.data);
    },
    sendIntegrationReport: (params = {}) => {
      return axios.get(`/stats/live-report/mail`, { params })
        .then(response => response.data);
    },
    sourceRecordsCharts: (options = {}) => {
      return axios.get(`/stats/charts/ge_sources`, {
        params: {
          'code': options.code,
          'from': options.from,
          'to': options.to
        },
        paramsSerializer: function (params) {
          return Qs.stringify(params, { arrayFormat: 'brackets' })
        }
      }).then(response => response.data);
    },
    sourceLoAutomationStatsCharts: (options = {}) => {
      return axios.get(`/stats/charts/lo_automation_stats`, {
        params: {
          'lo_automation': options.lo_automation,
          'from': options.from,
          'to': options.to
        },
        paramsSerializer: function (params) {
          return Qs.stringify(params, { arrayFormat: 'brackets' })
        }
      }).then(response => response.data);
    },
  }
}
