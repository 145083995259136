<template>

    <b-modal id="update-platform-details" ref="updatePlatformsModal" hide-footer
        title="Update your platform details" @hidden="hideModalEdit" @show="initModal">
        
        <b-form @submit="onUpdate" class="p-4 pb-0">

            <b-form-group id="input-platform" label-for="m-platform" label="Platform" description="">
                <Multiselect :options="platformsSorted" :customLabel="() => { }" v-model="form.name" :searchable="true"
                    :disabled="true">

                    <template slot="option" slot-scope="props">

                        {{ platformName(props.option) }}

                    </template>

                    <template slot="singleLabel" slot-scope="props">

                        {{ platformName(props.option) }}
                    </template>

                </Multiselect>


            </b-form-group>

            <b-form-group id="input-nickname" label-for="m-nickname" label="Nickname" description="">
                <b-form-input id="m-nickname" v-model="form.nickname" placeholder="Nickname"></b-form-input>
            </b-form-group>

            <b-form-group id="input-account" label-for="m-account" label="Account" description=""
                v-if="form.account && form.account != ''">
                <b-form-input id="m-account" v-model="form.account" placeholder="" :disabled="true"></b-form-input>
            </b-form-group>

            <b-form-group id="input-api-key" label-for="m-api-key" label="Api Key" description="">
                <b-input-group>
                    <b-form-input id="m-api-key" v-model="form.key" placeholder="Api key" :disabled="!showApiKey || disableApiKey"></b-form-input>
                    <template #append>
                        <b-input-group-text class="cursor-pointer p-0 m-0">
                            <b-button variant="light" class="h-100" @click.prevent="toggleShowApiKey">
                                <i v-if="showApiKey" class="ri-eye-line"></i>
                                <i v-else class="ri-eye-off-line"></i>
                            </b-button>
                        </b-input-group-text>
                    </template>
                </b-input-group>
            </b-form-group>

            <hr>

            <b-row class="text-right px-3 mb-0 pb-0" style="justify-content:end;">

                <b-button v-if="isOAuthPlatform" id="oauthReconnect" name="oauthReconnect" variant="info"
                    ref="oauthReconnect" :disabled="!isAllowedReconnection" class="auth2 action-button float-right mr-2"
                    :data-action="oauth.state" @click="handleOauthReconnection($event)">
                    <b-spinner small type="grow" v-if="oauth.inProgress"></b-spinner>
                    Reconnect {{ platform.name }} Platform
                </b-button>

                <b-button variant="primary" type="submit" class="text-right">
                    Update <b-spinner small type="grow" v-show="isLoading"></b-spinner>
                </b-button>

            </b-row>

        </b-form>
    </b-modal>

</template>
<script>

import api from '@/api/RestClient';
import { helper } from '@/helpers';
import { PLATFORMS_ITEMS } from "@/constantes";
import { mapGetters } from 'vuex'

import Multiselect from 'vue-multiselect'

export default {
    name: 'EditPlatformModal',
    props: {
        form: {
            type: Object,
            default: {
                id: null,
                key: null,
                name: null,
                account: null,
                nickname: null,
            }
        },
        accountId: {
            type: Number,
            default: null
        },
        platform: {
            type: Object,
            default: null
        },
        platformApiKey: {
            type: String,
            default: ''
        },
        oauth: {
            type: Object,
            default: {state: '', inProgress: false}
        },
    },
    components: {
        Multiselect
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            isAccountManager: 'Auth/isAccountManager',
        }),
        platformsSorted() {
            return _.sortBy(PLATFORMS_ITEMS, 'name').map((item) => {
                return item.name;
            });
        },
        isOAuthPlatform() {
            let state = this.platform 
                && this.platform.name != null 
                && this.platform.name.trim() != '' 
                && ['aweber', 'hubspot', 'keap', 'mailchimp'].includes(this.platform.name.toLowerCase());
            return state;
        },
        isAllowedReconnection(){
            let state = this.isOAuthPlatform && !this.oauth.inProgress;

            return state;
        }
    },
    data() {
        return {
            showApiKey: false,
            disableApiKey: false,
            isLoading: false,
            disableApiKey: false,
            hiddenKey: null,
        }
    },
    methods: {
        platformName(name) {
            return helper.platformFormatName(name);
        },
        initModal() {
            this.disableApiKey = this.platform && this.isJSON(this.platform.key);
        },
        hideModalEdit() {
            this.$emit('hideModalEdit');
        },
        onUpdate(e) {

            e.preventDefault();

            let form = this.form;

            if (!form.account) {
                delete form.account;
            }

            if (!this.showApiKey || this.disableApiKey || form.key.includes('***')) {
                delete form.key;
            }

            if (form.name == 'Inbox Suite') { form.name = 'Inboxsuite'; }

            api.platforms.update(this.form.id, form)
                .then((response) => {

                    if (response.success) {

                        this.$swal({
                            title: 'Platform Updated',
                            text: response.message,
                            icon: 'success',
                            type: 'success',
                            confirmButtonText: 'Ok',
                            showCloseButton: true,
                        }).then((result) => {
                            this.$refs['updatePlatformsModal'].hide();
                        })

                    }

                })
                .catch((error) => {
                    this.$swal('Invalid Credentials', error.response.data.message, 'error')
                })

        },
        toggleShowApiKey() {
            this.showApiKey = !this.showApiKey

            if(this.form.key.includes('***')) {
                this.hiddenKey = this.form.key
            }

            if(this.showApiKey) {
                this.form.key = this.platformApiKey;
            } else {
                this.form.key = this.hiddenKey ?? this.platform.key;
            }
        },
        handleOauthReconnection() {

            if (this.isOAuthPlatform) {
                this.oauth.inProgress = true;

                let oauthServiceApi = null;
                let platform_id = this.platform.id;
                let platformName = this.platform.name.toLowerCase();
                var swalObj = {
                    text: 'You will be redirected to the authentication page. Click "Ok" to continue',
                    type: 'info',
                    showCancelButton: true,
                    confirmButtonText: 'Ok',
                    cancelButtonText: 'Cancel!',
                    showCloseButton: true,
                    showLoaderOnConfirm: true
                };

                this.oauth.state = '';

                localStorage.removeItem('oauth.refresh');
                localStorage.removeItem('oauth.params');
                localStorage.removeItem('oauth.install');

                swalObj.title = `InboxGeek ${platformName} Integration`

                oauthServiceApi = api.default
                    .get(`/platforms/${platformName}/oauth/authorize${(platform_id ? '?platform_id=' + platform_id : '')}`)
                    .then(response => response.data);



                oauthServiceApi.then((result) => {


                    this.auth_url = result.url;
                    this.auth_params = result.params;

                    localStorage.setItem('oauth.install', this.auth_params);

                })
                    .catch((error) => {

                        this.$swal({
                            title: swalObj.title,
                            text: 'An error has occurred. Please try again',
                            type: 'warning',
                            confirmButtonText: 'Ok'
                        })

                        this.oauth.inProgress = false;
                    })
                    .finally(() => {

                        this.$swal(swalObj).then((result) => {

                            if (result.value) {
                                this.showWindowOauth(result)
                            } else {
                                this.oauth.inProgress = false;
                            }

                        });
                    })
            }

        },
        isJSON(text) {
            if (typeof text !== "string") {
                return false;
            }
            try {
                JSON.parse(text);
                return true;
            } catch (error) {
                return false;
            }
        },
        showWindowOauth(result) {

            if (this.isOAuthPlatform) {
                if (!result.value) {

                    this.$bvToast.toast('Your Integration process has been cancel', {
                        title: 'Warning',
                        variant: 'warning'
                    });

                    this.oauth.inProgress = false;

                }

                var oauthRefresh, _params = null;

                var w = 800,
                    h = 600;

                // Fixes dual-screen position                             Most browsers      Firefox
                const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
                const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

                const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

                const systemZoom = width / window.screen.availWidth;
                const left = (width - w) / 2 / systemZoom + dualScreenLeft
                const top = (height - h) / 2 / systemZoom + dualScreenTop

                let platform_id = this.platform.id;
                let platformName = this.platform.name.toLowerCase();
                let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${w / systemZoom},height=${h / systemZoom},left=${left},top=${top}`;

                var _oauthWindow = window.open(this.auth_url, 'oauth', params);

                var intervalID = setInterval(() => {

                    oauthRefresh = localStorage.getItem('oauth.refresh');
                    _params = localStorage.getItem('oauth.params') ? JSON.parse(localStorage.getItem('oauth.params')) : {};
                    _params.account = this.account


                    if (oauthRefresh) {

                        api.default.post(`/platforms/${platformName}/oauth/access_token`, _params)
                            .then((result) => {

                                // TODO Update Visual Item ApiKey  
                                if (result.data && typeof result.data.token != 'undefined') {

                                    this.platform.key = JSON.stringify(result.data.token);
                                    this.currPlatformApiKey = this.isJSON(this.platform.key) ? this.getPlatformApiAccess(this.platform.key) : response.data.key

                                    this.$swal({
                                        title: `InboxGeek ${this.platform.name} Integration`,
                                        text: 'The platform has been successfully connected',
                                        type: 'success',
                                        confirmButtonText: 'Ok'
                                    })
                                }

                                this.oauth.inProgress = false;
                            })
                            .catch((error) => {
                                this.$swal({
                                    title: `InboxGeek ${this.platform.name} Integration`,
                                    text: 'An error has occurred. Please try again',
                                    type: 'warning',
                                    confirmButtonText: 'Ok'
                                })

                                this.oauth.inProgress = false;
                            });

                        clearInterval(intervalID)
                    }

                    else if (_oauthWindow.closed && !oauthRefresh) {
                        this.$bvToast.toast('Please authenticate to complete your integration', {
                            title: 'Warning', variant: 'warning'
                        });

                        this.oauth.inProgress = false;

                        clearInterval(intervalID)
                    }

                    if (_oauthWindow.closed || !_oauthWindow) {
                        this.oauth.btnAuthorize = false
                    }
                }, 2000);
            }


        },
        getPlatformApiAccess(ApiKey) {
            let key = JSON.parse(ApiKey);

            return typeof key.accessToken != 'undefined' ? key.accessToken : key.access_token;
        },
    }
}

</script>