<template>

    <b-container fluid>

        <DataTable v-if="renderDT" ref="logs-table" :url="'/logs'" v-bind="{ columns: columns, customCells: dtCells, filter_on: filterOn }"/>

    </b-container>

</template>

<script>
import DataTable from '@/components/inboxgeek/tables/DataTable'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
    name: 'LogsTable',
    components: {
        DataTable
    },
    props: {
        filterOn: {
            type: Array,
            default: ["description", "user", "method", "organization"]
        }
    },
    data() {
        return {
            renderDT: true,
            dtCells: [],
        }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
        }),
        columns() {
            let columns = [
                {
                    key: 'id',
                    label: '#ID',
                    sortable: false,
                    sortDirection: 'desc',
                    class: 'text-center'
                },
                {
                    key: 'description',
                    label: 'Activities',
                    sortable: false,
                    sortDirection: 'desc',
                    class: 'text-left description'
                },
                {
                    key: 'act',
                    label: 'Action',
                    sortable: false,
                    thStyle: {
                        width: "20em"
                    },
                    class: 'text-center'
                },
                {
                    key: 'user_name',
                    label: 'User',
                    sortable: false,
                    sortDirection: 'desc',
                    class: 'text-center'
                },
                {
                    key: 'account',
                    label: 'Organization',
                    sortable: false,
                    sortDirection: 'desc',
                    class: 'text-center'
                },
                {
                    key: 'created_at',
                    label: 'Dates',
                    sortable: true,
                    class: 'text-center'
                }

            ];
            
            return columns;
        }
    },
    mounted() {
    },
    methods: {
        forceRenderTable() {
            this.renderDT = false;

            this.$nextTick(() => {
                this.renderDT = true;
            });
        },
    },
}

</script>