<template>
<b-container fluid>

    <!-- Table title -->
    <b-row class="mb-3">
        <b-col lg="6" class="my-1">
            <h4 class="card-title">Invoices</h4>
        </b-col>
        <b-col v-show="isAdmin" lg="6" class="my-1">
            <b-button v-b-modal.modal-new-account variant="primary" @click="new_invoice" class="text-right mr-1 pull-right">New invoice <i class="fa fa-plus ml-2"></i></b-button>
        </b-col>
    </b-row>

  <invoices-data-table-filter @onFilter="filterInvoices"></invoices-data-table-filter>

    <!-- Invoices Table -->
    <InvoicesTable
        ref="dt-invoices"
       :filter="filter" @onHistories="histories"
        @onRefund="refund"
        @onEdit="update"
    @markAsPaid="markAsPaid"
    ></InvoicesTable>


    <b-modal id="modal-prevent-closing" ref="modal" title="Update invoice status" :hide-footer=true>
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Name" label-for="name-input" invalid-feedback="Name is required">
                <b-form-select v-model="selected" :options="options" class="mb-3" value-field="item" text-field="name" disabled-field="notEnabled"></b-form-select>
            </b-form-group>
            <b-button type="submit" variant="primary" :disabled="onSubmit">
                <b-spinner small label="Small Spinner" type="grow" v-show="onSubmit"></b-spinner> Submit
            </b-button>
        </form>
    </b-modal>

</b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/api/RestClient'
import { INVOICE_STATES } from "../../../constantes";
import InvoicesTable from "./InvoicesTable"
import InvoicesDataTableFilter from "@/views/invoices/children/InvoicesDataTableFilter";

export default {
    name: 'DtInvoice',
    components: {
      InvoicesDataTableFilter,
        InvoicesTable
    },
    created() {
    },
    data() {
        return {
            id: 'dt-invoices',
          filter:null,
            dates: null,
            selected: null,
            invoice_id: null,
            options: INVOICE_STATES,
            onSubmit: false,
            refundTitle: '***'
        }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isAccounting: 'Auth/isAccounting',
            isManager: 'Auth/isManager',
            isClient: 'Auth/isClient',
        })
    },
    mounted() {
    },
    methods: {
      filterInvoices(filter){
        this.filter = null;
        this.filter = filter;
      },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
        update(item) {
            this.selected = item.state;
            this.invoice_id = item.id;
            this.$emit('onEditInvoice', item);
        },
        handleSubmit() {
            this.onSubmit = true;
            const data = {
                status: this.selected
            }
            const id = this.invoice_id;
            api.invoices.update(id, data).then((response) => {
                this.$bvToast.toast(response.message, {
                    title: 'Success',
                    variant: 'success'
                });
                this.$refs['dt-invoices'].forceRenderTable();
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                this.onSubmit = false;
            })
        },
        onHoverTable() {
            $('[data-toggle="tooltip"]').tooltip({
                html: true
            })
        },
        refund(item) {
            this.$emit('onRefund', item);
        },
      markAsPaid(item){
          this.$emit('markAsPaid',item);
      },
        histories(item) {
            this.$emit('onShowInvoiceHistories', item);
        },
        new_invoice() {
            this.$emit('onAddInvoice');
        },
        toast(title = "success", variant = "success", message = "Invoice added") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        },
    },
    watch: {}
}
</script>

<style>
td.lists>div,
td.integration>div {
    width: 10em;
}

td.tag>div,
td.account>div {
    width: 8em;
}

.cursor-pointer-ek {
    cursor: pointer;
}

.pull-right {
    float: right;
}

.dropdown-menu.show {
    display: inherit;
}

ul[role=menu] {
    transform: translate3d(-136px, 37px, 0px);
}

.dropdown-menu {
    padding: 0.5rem 0.5rem;
}
.tooltip-inner {
    max-width: 500px !important;
}
</style>
