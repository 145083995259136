<template>
    <b-container fluid>
        <span v-if="row.item.service" 
            class="badge cursor-pointer badge-primary badge-pill iq-bg-primary">
            {{ row.item.service.name || '' }}
        </span>
    </b-container>
</template>
<script>

export default {
    name: 'PlanServiceNameCell',
    props: ['row'],
}
</script>