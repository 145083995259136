<template>

    <b-row>
        <b-form-group label-for="list" class="col-md-12">

            <ListStepBlastable v-if="platform" 
                :lists="lists"
                :oAccountId="platform?.account_id" 
                :platform="platform" 
                :platform_id="platform?.id" 
                @listSelected="listSelected" 
                @segmentSelected="segmentSelected"></ListStepBlastable>
           
            <small v-if="errors?.list" class="text-danger">A list should be selected</small>
        </b-form-group>
    </b-row>

</template>
<script>

import LimitPerDayInput from "@/components/inboxgeek/fields/integration/LimitPerDayInput";
import RecurrenceInput from "@/components/inboxgeek/fields/integration/RecurrenceInput";

import IntegrationListMultiselect from "@/components/inboxgeek/fields/list/IntegrationListMultiselect";

import ListStepBlastable from '@/components/inboxgeek/modals/IntgrationModal/ListStepBlastable.vue'


export default {
    name: 'BlastableLoListStep',
    components: {
        LimitPerDayInput,
        RecurrenceInput,
        IntegrationListMultiselect,
        ListStepBlastable
    },
    props: {
        lists: {
            type: [Array, Object],
            default: () => []
        },
        form: {
            type: Object,
            default: () => {}
        },
        errors: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            listLabel: 'List: *',
            platform: null
        }
    },
    mounted() {
        if(this.form.platform && this.form.platform?.platform) {
            this.platform = this.form.platform.platform
            this.platform.code = this.platform.id
        }

    },
    methods: {
        handleSelectList(itemSelected) {
            this.$emit('select', this.list)
        },

        // TODO test and valid following methods
        listSelected(list) {

            if (list) {
                this.list = [{
                    'code': list.code,
                    'name': list.name,
                    'type': list.type ?? 'list'
                }];

                this.$emit('select', this.list)

            } else {
                this.list = null;
            }
        },
        segmentSelected(segments, list) {

            if (list) {
                this.isLoading = true;

                this.list = segments.map((segment) => {

                    let list_or_segment_id = segment.code;

                    return {
                        'code': list_or_segment_id,
                        'name': segment.name,
                        'type': 'segment'
                    };
                })

                this.$emit('select', this.list)

            } else {
                this.list = null;
            }

        },
    },
    computed: {

    }
}

</script>