<template>

    <div class="iq-card">
        <div class="iq-card-header d-flex justify-content-between">
            <div class="iq-header-title">
                <h4 class="card-title">Payment Methods</h4>
            </div>
        </div>
        <div class="iq-card-body">
            <div class="acc-edit">
                <div id="table">
                    <span class="table-add float-right mb-3 mr-2">
                        <button @click="editPaymentMethod = null; $emit('openModal', { paymentMethod: editPaymentMethod, address: address})" type="button" class="btn mb-3 btn-success">
                            <i class="fa fa-plus"></i>Add New
                        </button>
                    </span>
                    <table class="table table-responsive-md text-center billing-card">
                        <thead>
                            <tr>
                                <th>Card</th>
                                <th>Expires</th>
                                <th>Name on card</th>
                                <th>Card Nickname</th>
                                <th>Billing Address</th>
                                <th>Default</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(method, index) in paymentsMethod">
                                <td>{{ method.brand }}</td>
                                <td>{{ method.exp_month }}/{{ method.exp_year }}</td>
                                <td>{{ method.name }}</td>
                                <td>{{ method.card_nickname }}</td>
                                <td>{{ method.billing_details.line1 }}</td>
                                <td>
                                    <div
                                        class="custom-control custom-switch custom-switch-text custom-control-inline default">
                                        <div class="custom-switch-inner">
                                            <input :id="`checkbox-${index}`" type="checkbox"
                                                class="custom-control-input" v-model="method.default"
                                                v-bind:key="method.id" :disabled="method.default"
                                                @change="changeDefault($event, method.id)">
                                            <label :for="`checkbox-${index}`" class="custom-control-label"
                                                data-on-label="Yes" data-off-label="No">
                                            </label>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span class="table-remove">
                                        <button v-if="!method.default" @click="deletePaymentMethod(method.id, index)"
                                            type="button" class="btn btn-danger btn-sm mb-3 mx-1">
                                            <i class="ri-delete-bin-2-fill mx-auto delete-payment-method"></i>
                                        </button>
                                    </span>

                                    <span class="table-update">
                                        <button @click="updatePaymentMethod(method)" type="button"
                                            class="btn btn-info btn-sm mb-3 mx-1">
                                            <i class="ri-edit-box-line mx-auto update-payment-method"></i>
                                        </button>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>


</template>
<script>
import { sofbox } from '@/config/pluginInit';
import api from '@/api/RestClient';
import { loadStripe } from '@stripe/stripe-js';
import { mapGetters } from "vuex";
import { BILLING_COUNTRIES } from '@/utils/countries';

export default {
    name: 'PaymentMethods',
    props: {},
    components: {
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            client: 'Auth/isClient',
            user: 'Auth/user',
        })
    },
    data() {
        return {
            editPaymentMethod: null,
            billingErrors: [],
            fails: false,
            success: false,
            paymentsMethod: [],
            address: {},
            has_qst_tax: false,
            has_gst_tax: false,
            gst_tax_num: null,
            qst_tax_num: null,
            is_taxable: false,
            nickname: '',
            countries: BILLING_COUNTRIES,

        }
    },
    mounted() {
        this.initPaymentMethod();
        // this.iniInvoices();
    },
    methods: {
        openModal() {
            if (!this.editPaymentMethod) {
                this.address = {};
            }
            // this.modal = true;
            this.$root.$emit('bv::show::modal', 'payment-method')
            this.initcard();
        },
        async initcard() {
            this.stripe = await loadStripe(process.env.VUE_APP_STRIPESECRET);
            const elements = this.stripe.elements()
            this.cardElement = elements.create('card')
            this.cardElement.mount('#card');
            // this.cardElement.mount('#card-second');
        },
        initPaymentMethod() {
            api.billing.getPaymentMethods()
                .then(resp => {
                    let data = resp.data;
                    if (data.success && data.data) {
                        this.paymentsMethod = data.data;
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 401) {
                            this.$store.dispatch('Auth/resetUser', false);
                            this.$router.go(this.$router.currentRoute)
                        } else if (error.response.status == 500) {

                            this.$bvToast.toast('something went wrong!', {
                                title: 'Error',
                                variant: 'danger'
                            });
                        }

                        this.$bvToast.toast('something went wrong!', {
                            title: 'Error',
                            variant: 'danger'
                        })
                    }
                })
                .finally(() => {
                    // TODO #REPLACE IF CHANGE TEMPLATE
                    sofbox.closeLoader();
                });
        },
        async updatePaymentMethod(method) {
            // Show Modal Update
            if (typeof method.billing_details != 'undefined') {

                let countryCode = typeof method.billing_details.country != 'undefined' ? method.billing_details.country : null;
                
                this.address = {
                    first_name: method.billing_details.first_name,
                    last_name: method.billing_details.last_name,
                    address: method.billing_details.line1 ?? this.user.address,
                    city: method.billing_details.city,
                    postal_code: method.billing_details.postal_code,
                    state: method.billing_details.state,
                    nickname:  method.card_nickname
                }
                // this.nickname = method.card_nickname;
                let name = method.name.split(' ');
                this.address.first_name = name[0];
                this.address.last_name = name[1];
                this.editPaymentMethod = method;
                

                const account = this.user.account;

                const qst_tax_num = account?.qst_tax_num
                const gst_tax_num = account?.gst_tax_num

                if (qst_tax_num) {
                    this.qst_tax_num = qst_tax_num;
                    this.has_qst_tax = true;
                    this.is_taxable = true;
                }

                if (gst_tax_num) {
                    this.gst_tax_num = gst_tax_num;
                    this.has_gst_tax = true;
                    this.is_taxable = true;
                }

                let country = this.selectBillingCountry(countryCode);
                if(country) this.address.country = country;

                this.$emit('openModal', { paymentMethod: this.editPaymentMethod, address: this.address});
                // this.openModal();
            }
        },
        changeDefault(element, id) {
            if (element.target.checked) {
                api.billing.changeDefaultMethod({
                    id: id,
                }).then((response) => {
                    let data = response.data;
                    if (data.success) {
                        this.paymentsMethod = data.data;
                    }

                    $.each(this.paymentsMethod, (index, pm) => {
                        pm.default = false;
                        if (pm.id == id)
                            pm.default = true;
                    });
                });
            }
            
        },
        selectBillingCountry(countryCode = null) {
            let paymentMethod = this.editPaymentMethod;
            let country = null;

            if(!countryCode && paymentMethod) {
                if (typeof paymentMethod.card_country != 'undefined' && paymentMethod.card_country != '') countryCode = paymentMethod.card_country;
                else if( typeof paymentMethod.billing_details.country != 'undefined' && paymentMethod.billing_details.country != '') countryCode = paymentMethod.billing_details.country;
            }

            if (countryCode) {
                country = _.find(this.countries, (o) => { return o.code == countryCode })
            }

            return country;
        },
        deletePaymentMethod(id, index) {
            this.$bvModal.msgBoxConfirm('Are you sure you want to delete this payment method.', {
                title: 'Please Confirm',
                size: 'sm',
                bodyClass: 'delete-payment-method-confirmation',
                okVariant: 'danger',
                cancelVariant: 'dark',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2 footer-modal-deleter-payment-method',
                hideHeaderClose: false,
                centered: true
            })
                .then(confirmation => {
                    if (confirmation)
                        api.billing.deletePaymentMethod(id).then(resp => {
                            let data = resp.data;
                            if (data.success) {
                                this.paymentsMethod.splice(index, 1)
                                this.$bvToast.toast(data.message, {
                                    title: 'Success',
                                    variant: 'success'
                                })
                            }
                        });
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 401) {
                            this.$store.dispatch('Auth/resetUser', false);
                            this.$router.go(this.$router.currentRoute)
                        } else if (error.response.status == 500) { }

                        this.$bvToast.toast('something went wrong!', {
                            title: 'Error',
                            variant: 'danger'
                        })
                    }
                });

        },
    }
}

</script>