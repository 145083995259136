<template>
    <fieldset>
        <div class="form-card text-left">
            <b-row>
                <b-col cols="7">
                    <h3 class="mb-4 d-none">Make Integration:</h3>
                </b-col>
                <b-col cols="5">
                    <h2 class="steps">Step 3 - 4</h2>
                </b-col>
            </b-row>
            <b-row>

                <b-form-group class="col-md-12" label="Name: *" label-for="integration-name">
                    <b-form-input v-model="form.integrationName" id="integration-name" name="integration-name"
                        placeholder="Enter Integration Name">
                    </b-form-input>
                    <small id="alertName" class="text-danger d-none"></small>
                </b-form-group>

                <!-- START | Custom Event trigger - ExpertSender -->
                <b-form-group class="col-md-6" label="Custom Event ID:" label-for="name" v-show="showCols.event">
                    <b-form-input v-model="form.CustomEventId" id="tag" name="tag" placeholder="Enter Name">
                    </b-form-input>
                    <small id="alertEventID" class="text-danger d-none"></small>
                </b-form-group>

                <b-form-group class="col-md-6" label="Custom Event Name: *" label-for="name" v-show="showCols.event">
                    <b-form-input v-model="form.CustomEventName" id="tag" name="tag" placeholder="Enter Name">
                    </b-form-input>
                    <small id="alertEventName" class="text-danger d-none"></small>
                </b-form-group>
                <!-- END | Custom Event trigger - ExpertSender -->

                <!-- SendinBlue Platform -->
                <b-form-group class="col-md-12" label="Custom Track Event: *" label-for="name" v-show="showCols.sendin_blue_event">
                    <b-form-input v-model="form.sendin_blue_event" id="tag" name="tag" placeholder="Custom Track Event">
                    </b-form-input>
                    <small id="alertEventName" class="text-danger d-none"></small>
                </b-form-group>

                <!-- Inbox Platform -->
                <b-form-group v-if="form.platform && form.platform.name.toLowerCase() == 'inbox'" class="col-md-12" label="Tag: *" label-for="name" v-show="showCols.customTag">
                    <b-form-input v-model="form.customTag" name="tag" placeholder="Tag Tracked">
                    </b-form-input>
                    <small id="alertTag" class="text-danger d-none"></small>
                </b-form-group>

                <!-- START | Custom Event trigger - Webhook or InboxSuite -->
                <b-form-group v-else-if="form.platform && ['webhook', 'inboxsuite'].includes(form.platform.name.toLowerCase())" class="col-md-12" :label="tagLabelProp" label-for="trigger_link">
                    <b-form-input v-if="tagSelected && showCols.event_link" v-model="tagSelected.name" id="trigger_link" name="trigger_link"
                        :placeholder="tagPlaceholderProp">
                    </b-form-input>
                    <small id="alertTag" class="text-danger d-none"></small>
                </b-form-group>
                <!-- END | Custom Event trigger - Webhook or InboxSuite -->

                <b-form-group v-else-if="form.platform && form.platform.name.toLowerCase() =='ongage'" class="col-md-6 mb-3"  :label="tagLabel" label-for="tag" v-show="showCols.tags">
                    <div>
                        <multiselect v-model="tagSelected" :loading="isLoading" :tag-placeholder="tagPlaceholder"
                            :allow-empty="false" deselect-label="Can't remove this value"
                            :placeholder="selectTagPlaceholder" label="name" track-by="code" return="code" :options="tags"
                            :multiple="false" :close-on-select="true" :taggable="true" @tag="handleAddTag" :disabled="isDisabledTag" @select="handleTag">

                            <template slot="singleLabel" slot-scope="props">
                                <div class="option__desc">

                                    <span class="option__title">

                                        <span v-if="props.option.type">
                                            <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                                                style="color:#5ad"></i>
                                            <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>
                                        </span>
                                        <span v-else>
                                            <i class="ri-list-check color-primary" style="color:#5ad"></i>
                                        </span>

                                        <span style="font-wight:bold;"> {{ props.option.name }} </span>

                                        <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>

                                    </span>

                                </div>
                            </template>

                            <template slot="option" slot-scope="props">
                                <div class="option__desc">

                                    <span class="option__title">

                                        <span v-if="props.option.type">

                                            <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                                                style="color:#5ad"></i>
                                            <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                                        </span>

                                        <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                                        <span style="font-wight:bold;"> {{ props.option.name }} </span>

                                        <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>


                                    </span>

                                </div>

                            </template>

                        </multiselect>
                        <small id="alertTag" class="text-danger d-none"></small>
                    </div>
                </b-form-group>

                <b-form-group v-else-if="form.platform && form.platform.name.toLowerCase() =='omnisend'" label="Event *" label-for="event" class="col-md-12 mb-3" v-show="showCols.tags">
                    <div>
                        <multiselect v-model="tagSelected" :loading="isLoading" tag-placeholder="Create event"
                            :allow-empty="false" deselect-label="Can't remove this value"
                            placeholder="Select event" label="name" track-by="code" return="code" :options="tags"
                            :multiple="false" :close-on-select="true" :taggable="true" @tag="handleAddTag" :disabled="isDisabledTag" @select="handleTag">

                            <template slot="singleLabel" slot-scope="props">
                                <div class="option__desc">

                                    <span class="option__title">

                                        <span v-if="props.option.type">
                                            <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                                                style="color:#5ad"></i>
                                            <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>
                                        </span>
                                        <span v-else>
                                            <i class="ri-list-check color-primary" style="color:#5ad"></i>
                                        </span>

                                        <span style="font-wight:bold;"> {{ props.option.name }} </span>

                                        <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>

                                    </span>

                                </div>
                            </template>

                            <template slot="option" slot-scope="props">
                                <div class="option__desc">

                                    <span class="option__title">

                                        <span v-if="props.option.type">

                                            <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                                                style="color:#5ad"></i>
                                            <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                                        </span>

                                        <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                                        <span style="font-wight:bold;"> {{ props.option.name }} </span>

                                        <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>


                                    </span>

                                </div>

                            </template>

                        </multiselect>
                        <small id="alertTag" class="text-danger d-none"></small>
                    </div>
                </b-form-group>


                <b-form-group v-else class="col-md-12 mb-3" :label="tagLabel" label-for="tag" v-show="showCols.tags">
                    <div>
                        <multiselect v-model="tagSelected" :loading="isLoading" :tag-placeholder="tagPlaceholder"
                            :allow-empty="false" deselect-label="Can't remove this value"
                            :placeholder="selectTagPlaceholder" label="name" track-by="code" return="code" :options="tags"
                            :multiple="false" :close-on-select="true" :taggable="true" @tag="handleAddTag" :disabled="isDisabledTag" @select="handleTag">

                            <template slot="singleLabel" slot-scope="props">
                                <div class="option__desc">

                                    <span class="option__title">

                                        <span v-if="props.option.type">
                                            <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                                                style="color:#5ad"></i>
                                            <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>
                                        </span>
                                        <span v-else>
                                            <i class="ri-list-check color-primary" style="color:#5ad"></i>
                                        </span>

                                        <span style="font-wight:bold;"> {{ props.option.name }} </span>

                                        <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>

                                    </span>

                                </div>
                            </template>

                            <template slot="option" slot-scope="props">
                                <div class="option__desc">

                                    <span class="option__title">

                                        <span v-if="props.option.type">

                                            <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                                                style="color:#5ad"></i>
                                            <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                                        </span>

                                        <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                                        <span style="font-wight:bold;"> {{ props.option.name }} </span>

                                        <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>


                                    </span>

                                </div>

                            </template>

                        </multiselect>
                        <small id="alertTag" class="text-danger d-none"></small>
                    </div>
                </b-form-group>

                
                <b-form-group class="col-md-6" v-if="showCols.fields" label="Custom Field Trigger Value: *" label-for="field" >
                    <b-form-input id="field" v-model="form.field" name="field" placeholder="Custom Field Trigger Value">
                    </b-form-input>
                    <small id="alertFieldID" class="text-danger d-none"></small>
                </b-form-group>


                <div class="spinnerAddTag" v-show="spinnerAddTag">
                    <b-spinner small label="Small Spinner" type="grow"></b-spinner>
                </div>
                <b-form-group class="col-md-12">
                    <b-row>
                        <b-col  class="mb-3">
                            <div
                                class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                                <div class="custom-switch-inner">
                                    <label class="mr-2">Limit per day</label>
                                    <input type="checkbox" class="custom-control-input bg-primary"
                                        v-model="form.ableLimitPerDay" id="limit-per-day"
                                        @input="onSwitchLimitPerDay($event.target)">
                                    <label class="custom-control-label" for="limit-per-day" data-on-label="On"
                                        data-off-label="Off">
                                    </label>
                                </div>
                            </div>
                            <b-form-input v-model="form.limit" type="number" id="limit" name="limit"
                                placeholder="limit per day" :disabled="!form.ableLimitPerDay"></b-form-input>
                        </b-col>
                        <b-col v-if="hasOption('time_limit')">
                            <b-row>
                                <b-col md="12">
                                    <div
                                        class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                                        <div class="custom-switch-inner">
                                            <label class="mr-2">Time limit</label>
                                            <input type="checkbox" class="custom-control-input bg-primary"
                                                v-model="form.ableLimitTime" id="limit-limit"
                                                @input="onSwitchTimeLimit($event.target)">
                                            <label class="custom-control-label" for="limit-limit" data-on-label="On"
                                                data-off-label="Off">
                                            </label>
                                        </div>
                                    </div>
                                </b-col>
                                <b-col md="6" class="mb-3">
                                    <b-form-input v-model="form.time_limit_from" id="time_limit_from" :type="timeType"
                                        value="13:45" placeholder="time from"
                                        :disabled="!form.ableLimitTime"></b-form-input>
                                    <small v-show="timeFromAlert" class="text-danger">This field is required in its
                                        correct
                                        time
                                        format</small>
                                </b-col>
                                <b-col md="6" class="mb-3">
                                    <b-form-input v-model="form.time_limit_to" id="time_limit_to" :type="timeType"
                                        value="13:45" placeholder="time to" :disabled="!form.ableLimitTime"></b-form-input>
                                    <small v-show="timeToAlert" class="text-danger">This field is required in its
                                        correct time
                                        format</small>
                                </b-col>

                            </b-row>
                        </b-col>


                        <b-col v-if="showReccurence">
                            <div
                                class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                                <div class="custom-switch-inner">
                                    <label class="mr-2">Repeat Unique Events</label>
                                    <input type="checkbox" class="custom-control-input bg-primary"
                                        v-model="integration.recurrence" id="customSwitch-23"
                                        @input="onSwitchRepeatEvent($event.target)">
                                    <label class="custom-control-label" for="customSwitch-23" data-on-label="On"
                                        data-off-label="Off">
                                    </label>
                                </div>
                            </div>
                            <b-form-input v-model="form.recurrence" type="number" id="recurrence" name="recurrence"
                                placeholder="Recurrence Events"
                                :disabled="!integration.recurrence"></b-form-input>
                        </b-col>


                      <b-col>
                        <div
                            class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                          <div class="custom-switch-inner">
                            <label class="mr-2">Schedule</label>

                            <input type="checkbox" class="custom-control-input bg-primary"
                                   v-model="schedule.active" id="integration_schedule"
                                   @input="onTogleScheduleOption($event.target)">
                            <label class="custom-control-label" for="integration_schedule" data-on-label="On"
                                   data-off-label="Off">
                            </label>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col">
                            <b-form-input
                                v-model="schedule.value.date"
                                type="date"
                                id="schedule_date"
                                name="date"
                                placeholder="date"
                                :disabled="!schedule.active"></b-form-input>
                          </div> <div class="col">
                          <b-form-input
                              v-model="schedule.value.time"
                              type="time"
                              id="schedule_date"
                              name="date"
                              placeholder="date"
                              :disabled="!schedule.active"></b-form-input>
                          </div>
                        </div>


                      </b-col>
                    </b-row>
                </b-form-group>
            </b-row>
        </div>

        <b-button name="next" variant="primary" class="next action-button float-right" value="Next"
            @click="handleNextStep($event)">
            <b-spinner small type="grow" v-show="nextSpinner"></b-spinner>
            Next
        </b-button>
        <b-button ref="previous" name="previous" variant="dark" class="previous action-button-previous float-right mr-3"
            value="Previous" @click="handlePreviousStep($event)">Previous</b-button>
    </fieldset>
</template>
<script>
import api from '@/api/RestClient';
import { PLATFORMS_CODE,SERVICES } from "@/constantes";
import { mapState,mapGetters } from 'vuex';
import TestFlow from '@/components/inboxgeek/sendflow'

import Multiselect from 'vue-multiselect'
import _ from 'lodash';

export default {
    name: 'StepIntegration',
    components: {
        Multiselect,
        TestFlow
    },
    props: {
        tagLabelProp: {
            default: () => 'Tag: *',
        },
        tagPlaceholderProp: {
            default: () => 'Create tag',
        },
        selectTagPlaceholderProp: {
            default: () => 'Select tag',
        },
        organizationId:{
            default: () => null,
        },
        formProp: {
            type: [Object],
            default: () => null,
        },
        tagProp: {
            type: [Array],
            default: () => null,
        },
        tagsProp: {
            type: [Array],
            default: () => null,
        },
        showColsProp: {
            type: [Object],
            default: () => null,
        },
    },
    data() {
        return {
          schedule:{
            active:false,
            value: {
              date:null,
              time:null
            }
          },
            email: null,
            expert_send_test: false,
            is_disabled_btn_next: false,
            step: 2,
            isLoading: true,
            timeType: 'text',

            showReccurence: false,
            isDisabledTag: false,
            spinnerAddTag: false,
            timeFromAlert: false,
            timeToAlert: false,
            field:null,
            form: {},
            integration: {},
            tagSelected:null,
            tags: [],
            selectedtag: {},

            tagLabel: null,
            tagPlaceholder: null,
            selectTagPlaceholder: null,
           

            showCols: {},
            selectedTagName: null,
            selectedTag_name: null,
             
            nextSpinner: false,
            prevSpinner: false,
        }
    },
    methods: {

        initStep() {
            
            if (typeof this.formProp !== "undefined") this.form = this.formProp
            if (typeof this.tagProp !== "undefined") this.tagSelected = this.tagProp
            if (typeof this.tagsProp !== "undefined") this.tags = this.tagsProp
            if (typeof this.showColsProp !== "undefined") this.showCols = this.showColsProp

            if (typeof this.tagLabelProp !== "undefined") this.tagLabel = this.tagLabelProp
            if (typeof this.tagPlaceholderProp !== "undefined") this.tagPlaceholder = this.tagPlaceholderProp
            if (typeof this.selectTagPlaceholderProp !== "undefined") this.selectTagPlaceholder = this.selectTagPlaceholderProp
            this.form.field = null;
            this.fetchOptions();
        },
        updateRender() {
            if (this.form.platform && this.form.platform.code) {
                // For [Klaviyo - Maropost - Active Campaign - Webhook]
                if ([PLATFORMS_CODE.KLAVIYO, PLATFORMS_CODE.MAROPOST, PLATFORMS_CODE.ACTIVE_CAMPAIGN, PLATFORMS_CODE.WEBHOOK].includes(this.form.platform.code)) {
                    this.showReccurence = true
                }
            }
        },
        cleanForm() {
            this.nextSpinner = false
            this.tagSelected = null
            this.tags = []
            this.form.field = null;

            this.form.integrationName = null
            this.form.ableLimitTime = null
            this.form.ableLimitPerDay = null
            this.integration.recurrence = null
            this.cleanTimeLimit()
        },
        cleanTimeLimit() {
            this.timeType = "text"
            this.form.time_limit_from = null
            this.form.time_limit_to = null
        },
        handleNextStep($event) {
            this.$emit('onNext', { btn: $event.target, step: this.step, compStep: this })
        },
        handlePreviousStep($event) {
            this.$emit('onPrevious', { btn: $event.target, step: this.step, compStep: this })
        },
        handleSelectTagOption(itemSelected) {
            this.$emit('onTagSelectedOption', itemSelected)
        },
        handleAddTag(newTag) {
            this.isLoading = true;
            this.selectedTagName = newTag;
            this.$emit('onAddTag', newTag)
        },
        setTags(items) {
            if (Array.isArray(items)) this.tags = items
        },

        onSwitchLimitPerDay($event) {
            if (!$event.checked) {
                this.form.limitPerDay = null
                this.form.limit = null
            }
            else this.form.limit = 0
        },
        onSwitchTimeLimit($event) {
            if (!$event.checked) {
                this.cleanTimeLimit()
            } else this.timeType = "time"
        },
        onSwitchRepeatEvent($event) {
            if (!$event.checked) {
                this.form.recurrence = null
                this.integration.recurrence = null
            } else {
                this.form.recurrence = 0
                this.integration.recurrence = true
            }
        },
        setLimitTime(integration, form) {
            var timeLimit = JSON.parse(integration.time_limit)
            if (helper.validateTime(timeLimit.time_limit_to) && helper.validateTime(timeLimit.time_limit_from)) {
                form.timelimitchecked = true
                form.time_to = timeLimit.time_limit_to;
                form.time_from = timeLimit.time_limit_from;
            }
        },
        handleTag(data) {
            this.isLoading = true;
            this.$emit('onCheckTag', data);
        },
        finishCheckTag(resp, form) {
            if (resp && form.platform && form.platform.name) {
                this.alertTag(form.platform.name);
            }
            this.isLoading = false;
        },
        alertTag(name = 'hubspot') {
            this.$swal({
                title: `This ${this.getIntegrationReceptionLabel(name)} is already used`,
                text: 'Do you still want to use it?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'No, change it!',
                cancelButtonText: 'Yes, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    this.tagSelected = null;
                }
            })
        },
        getIntegrationReceptionLabel(platform) {
            let name = platform?.toLowerCase();
            switch (name) {

                case 'hubspot':
                case 'sendgrid':
                case 'campaigner':
                    name = 'list';
                    break;
                case 'brevo':
                case 'kalaviyo':
                case 'expertsender':
                    name = 'event';
                    break;
                default:
                    name = 'tag'
                    break;
            }

            return name;
        },
        fetchOptions(){
    
       

            if(this.userSubscriptions[SERVICES.LIVE_OPENS.id]){

                this.options = JSON.parse(this.userSubscriptions[SERVICES.LIVE_OPENS.id].options);
            }

           
          
        },
        hasOption(option_name){

            if (this.options ) {
                return this.options[option_name] == 1;
            }

            return false;
        },
      onTogleScheduleOption(event){
        this.form.schedule = this.schedule;
      }
    },
    watch: {
        'selectedTag_name': {
            handler(newVal, oldVal) {
                this.isLoading = true;
                let name = this.form.platform.name;
                let id = this.form.platform.p_id;
                let tolowerPlatformName = name.toLowerCase()
                if (['omnisend'].includes(tolowerPlatformName)) {
                    let url = `platforms/${tolowerPlatformName}/tags?platform=27&entities=tags&platform_id=${id}`
                    api.platforms.tags(url).then(((result) => {
                        let events = []
                        if (result.success) {
                            events = _.map(result.data, function (event) {
                                return {
                                    code: event.id,
                                    name: event.name
                                }
                            })
                            this.tags = events;
                            let event = _.find(events, { 'name': this.selectedTagName });
                            this.tagSelected = { code: event.code, name: this.selectedTagName };
                        }

                    })).catch((error) => {

                    }).finally(() => {
                        this.isLoading = false;
                    })
                }
            }   
        },
        'tags': {
            handler(newVal, oldVal) {
                if (this.selectedTagName) {
                    this.selectedTag_name = this.selectedTagName;
                }
                
            }
        },
        'form.platform.name': {
            handler(newVal, oldVal) {
                 if (this.form.platform.name.toLowerCase() == 'expertsender') {
                    this.is_disabled_btn_next = true;
                }
                console.log(this.form.platform.name.toLowerCase());

            }
        }
    },
    computed : {
        ...mapState(['subscription']),

        ...mapGetters({
            isClient: 'Auth/isClient',
            liveOpenersSubscribed: 'Auth/liveOpenersSubscribed',
            liveLeadsSubscribed: 'Auth/liveLeadsSubscribed',
            userSubscriptions : 'Auth/UserSubscriptions'
        }),

        selected_tag(){
            if(this.field != null) {
                return { 'name' : this.field ,'id' : this.selectedtag.id } ;
            }
            return this.selectedtag;
        }
    },
    mounted() {
        this.initStep()

    },
    updated() {
        this.updateRender()
    },
}
</script>
