<template>

    <div class="iq-card">
        <div class="iq-card-body">
            <span class="table-add float-right mb-3 mr-2">
                <p>&nbsp;</p>
            </span>
            <SimpleInvoiceTable title="Invoices" :headers="invoiceColumns" :account="accountId">
            </SimpleInvoiceTable>
        </div>
    </div>

</template>
<script>
import SimpleInvoiceTable from './IbgSimpleInvoiceTable'

export default {
    name: 'AccountInvoices',
    props: ['accountId'],
    components: {
        SimpleInvoiceTable
    },
    data() {
        return {
            invoiceColumns: [
                {
                    key: 'date',
                    label: 'Date',
                    tdClass: 'text-center'
                },
                {
                    key: 'amount',
                    label: 'Amount',
                    tdClass: 'text-center'
                },
                {
                    key: 'status',
                    label: 'Status',
                    tdClass: 'text-center'
                },
                {
                    key: 'description',
                    label: 'Description',
                    tdClass: ''
                },
                {
                    key: 'action',
                    label: 'Action',
                    tdClass: 'text-center'
                },
            ],
        }
    },
    methods: {}
}

</script>