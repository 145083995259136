<template>
    <div class="mt-2" v-if="items.length > 0">
        <h5>Resend Records List</h5>

        <b-table :items="items" :busy="isBusy" :fields="fields" class="mt-3" :current-page="currentPage" :per-page="perPage" show-empty outlined>
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                </div>
            </template>
            <template #cell(actions)="row">
                <span v-if="row.item.status != IN_STOPPING" size="sm" v-b-tooltip.hover title="Stop Resend" class="text-danger" @click="trash(row.item)">
                    <i class="fa fa-pause-circle cursor-pointer"></i>
                </span>
                <b-spinner v-if="row.item.status == IN_STOPPING" small label="Small Spinner" type="grow" class="text-success"></b-spinner>
                <span size="sm" v-b-tooltip.hover title="View details" class="text-success ml-2" @click="resendDetail(row.item)">
                    <i class="fa fa-list cursor-pointer"></i>
                </span>
                <span size="sm"  v-if="row.item.status != IN_DOWNLOADING" v-b-tooltip.hover title="Download report" class="text-danger ml-2" @click="downloadReport(row.item)">
                    <i class="fa fa-download cursor-pointer"></i>
                </span>
                <b-spinner v-else-if="row.item.status == IN_DOWNLOADING" small label="Small Spinner" type="grow" class="text-danger ml-2"></b-spinner>
            </template>
            <!-- A custom formatted data column cell -->
            <template #cell(status)="data">
                <span data-v-7f8d19ce="" class="badge" :class="statusClass(data.item.status)">{{ statusName(data.item.status) }}</span>
            </template>
            <template #cell(method)="data">
                <span data-v-7f8d19ce="" :class="methodClass(data.item.method)">{{methodName(data.item.method)}}</span>
            </template>
        </b-table>
        <b-row>
            <b-col offset-md="6" offset-lg="6" sm="7" md="6" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0"></b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import api from '@/api/RestClient'
export default {
    name: 'ResendAutomationList',
    props: {
        is_loading: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            default: () => []
        }
    },
    mounted() { this.totalRows = this.items.length},
    data() {
        return {
            isBusy: false,
            show_table: false,
            IN_STOPPING: 11,
            IN_DOWNLOADING: 21,
            fields: ['id', 'from', 'to', 'method', 'status', 'actions'],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    methods: {
        toggleBusy() {
            this.isBusy = !this.isBusy
        },
        edit(item) {
            this.$emit("onEdit", item)
        },
        trash(item) {
            item.status = this.IN_STOPPING;
            this.$emit("onTrash", item)
        },
        resendDetail(item) {
            this.$emit("onResendStats", item)
        },
        downloadReport(item) {
            let status = item.status
            item.status = this.IN_DOWNLOADING;

            api.geAutomations.automation_resend_stats_csv(item.automation_id, item.id).then((response) => {

                var fileURL = window.URL.createObjectURL(new Blob([response]));
                var fileLink = document.createElement('a');
                var fileName = 'resent_automation_'+ Date.now() + '.csv';

                fileLink.href = fileURL;
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);

                let message = 'Report generated successfully'
                this.$swal('Success!', message, 'success')

                fileLink.click();

            }).catch((error) => {
                console.log(error);
                let message = error.response.data.message ?? 'Something want wrong try later'
                this.$swal('Error!!', message, 'error')
            }).finally(() => {
                this.isBusy = false;
                item.status = status;
            });
        },
        statusClass(status) { 
            let class_name = 'badge-primary';
            switch (status) {
                case 11:
                    class_name = 'badge-default';
                    break;
                case -2:
                    class_name = 'badge-warning';
                    break;
                case -1:
                    class_name = 'badge-danger';
                    break;
                case 0:
                    class_name = 'badge-dark';
                    break;
                case 1:
                    class_name = 'badge-success';
                    break;
            
                default:
                    break;
            }
            return class_name;
        },
        statusName(status) {
            let name = '--';
            switch (status) {
                case 11:
                    name = '...';
                    break;
                case -2:
                    name = 'Stop';
                    break;
                case -1:
                    name = 'Remove';
                    break;
                case 0:
                    name = 'Initial';
                    break;
                case 1:
                    name = 'Finished';
                    break;

                default:
                    break;
            }
            return name;
        },
        methodName(method) {
            if(method == -1) return 'Resend All';
            else if (method == 1) return 'Resend Only unsent records'
            else return '--'
        },
        methodClass(method) {
            if (method == -1) return 'badge badge-success';
            else if (method == 1) return 'badge badge-danger'
            else return 'badge badge-dark'
        },
        updateStatus(item, status) {
            item.status = status;
            console.log('successs');
        }
    },
    watch: {
        'is_loading': {
            handler(newValue, oldValue) {
                console.log(newValue);
                this.isBusy = this.is_loading
            }
        },
        'items': {
            handler(newValue, oldValue) {
                this.totalRows = newValue.length
                setTimeout(() => { this.isBusy = false }, 1000);
                const resends = JSON.parse(JSON.stringify(newValue));
                this.show_table = resends.length > 0;
            }
        }
    }
}
</script>