import { PLATFORMS_CODE, COLUMNS_GRID } from "@/constantes";
import api from '@/api/RestClient';

export default function platform(axios) {
    return {
        labels: {
        },
        customShowAnotherCols(COMP) {
            COMP.showCols.tags = false;
            COMP.showCols.event = false;
            if(typeof COMP.showCols.apiKey !== 'undefined')  COMP.showCols.apiKey = false;
            COMP.showCols.event_link = true;
            if(typeof COMP.showCols.accountId!== 'undefined')  COMP.showCols.accountId = false;
            COMP.apiTokenLabel = '';
            COMP.form.tagSelected = '';
            COMP.form.platform.key = '';
            COMP.apiTokenPlaceholder = '';
            if(typeof COMP.cols.apiKeyCol !== 'undefined')  COMP.cols.apiKeyCol = COLUMNS_GRID.COL12;

        }
    }
}