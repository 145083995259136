<template>
    <span>

        <span v-if="!lists.length" class="badge badge-light mx-2">Not Defined</span>
        <span v-for="(list, i) in lists" :key="i" class="mx-1">
            <span class="badge badge-primary mx-2"> {{ filterLessThan(listNameFormatted(list)) }}</span>
        </span>

    </span>
</template>

<script>
import { helper } from '@/helpers'
import { mapGetters } from 'vuex'

export default {
    name: 'AutomationReceptionLists',
    props: ['row'],
    components: {
    },
    data() {
        return {
            lists: []
        }
    },
    mounted() {
        this.loadLists()
    },
    methods: {
        loadLists() {
            let lists = this.row.item.lists;

            lists.forEach((list) => {
                this.lists.push(list);
            });
        },
        listNameFormatted(list) {
            return `${list.name} - ${list.list_id}`;
        },
        filterLessThan(value, max = 20) {
            return helper.showMaxCharacters(value, max)
        }
    },
    computed: {
        ...mapGetters({
            user: 'Auth/user',
            isOwnerAccount: 'Auth/isOwnerAccount',
        })
    }
}

</script>
