<template>

    <div class="d-flex justify-content-center">

        <button @click="showLogs(row.item, row.index, $event.target)" type="button" class="btn iq-bg-info btn-sm mr-1"
            data-toggle="tooltip" data-placement="top" title="List TimeLine" data-name="Inbox Geek"
            data-original-title="List TimeLine">
            <i v-if="row.item.state != 601" class="fa fa-history m-0"></i>
            <b-spinner small label="Small Spinner" v-if="row.item.state == 601" type="grow"></b-spinner>
        </button>

        <button v-if="['webhook', 'inboxsuite'].includes(row.item.platform.toLowerCase())"
            v-b-modal.update-contacts-in-uploaded-list-modal @click="updateUploadContacts(row.item, 'add')" type="button"
            class="btn iq-bg-secondary mr-1 btn-sm" data-toggle="tooltip" data-placement="top"
            title="Import new contacts" href="#">
            <i v-if="row.item.state != 301" class="ri-contacts-book-upload-fill m-0"></i>
            <b-spinner small label="Small Spinner" v-if="row.item.state == 301" type="grow"></b-spinner>
        </button>

        <button v-if="['webhook', 'inboxsuite'].includes(row.item.platform.toLowerCase())"
            v-b-modal.update-contacts-in-uploaded-list-modal @click="updateUploadContacts(row.item, 'delete')"
            type="button" class="btn iq-bg-danger mr-1 btn-sm" data-toggle="tooltip" data-placement="top"
            title="Delete contacts" href="#">
            <i v-if="row.item.state != 301" class="ri-file-reduce-fill m-0"></i>
            <b-spinner small label="Small Spinner" v-if="row.item.state == 301" type="grow"></b-spinner>
        </button>

        <button @click="timeLogs(row.item, row.index)" type="button" class="btn iq-bg-success mr-1 btn-sm"
            data-toggle="tooltip" data-placement="top" title="Time Line" data-original-title="Edit" href="#">
            <i v-if="row.item.state != 201" class="fa fa-history m-0"></i>
            <b-spinner small label="Small Spinner" v-if="row.item.state == 201" type="grow"></b-spinner>
        </button>

        <button v-if="canRefreshList(row.item)" @click="refreshContacts(row.item, row.index, $event.target)"
            type="button" class="btn iq-bg-success mr-1 btn-sm" data-toggle="tooltip" data-placement="top"
            title="Refresh contacts" data-original-title="Edit" href="#" :disabled="!isRefreshable(row.item.state)">
            <i v-if="row.item.state != 101" class="ion-refresh m-0"></i>
            <b-spinner small label="Small Spinner" v-if="row.item.state == 101" type="grow"></b-spinner>
        </button>

        <button @click="removeItem(row.item, row.index, $event.target)" type="button" class="btn iq-bg-danger btn-sm"
            data-toggle="tooltip" data-placement="top" title="Delete" data-name="Inbox Geek"
            data-original-title="delete Inbox Geek">
            <i class="ri-delete-bin-line i-delete m-0 delete_integration_icone"></i>
            <div class="spinner-grow spinner-grow-sm delete_integration_spiner d-none" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </button>
    </div>

</template>
<script>

import { mapGetters } from 'vuex'
import api from '@/api/RestClient';

export default {
    name: 'DtActionsCell',
    props: ['row'],
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isAccounting: 'Auth/isAccounting',
            isManager: 'Auth/isManager',
            isClient: 'Auth/isClient',
        })
    },
    methods: {
        remove(item) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    api.invoices.delete(item.id).then(response => {
                        let varian = response.success ? 'success' : 'danger';
                        this.toast('response.message', varian, response.message);
                        if (response.success) this.$root.$emit('bv::refresh::table', this.id);
                    }).catch(err => {
                        this.$bvToast.toast(err.response.data.message, {
                            title: 'Error',
                            variant: 'danger',
                        });
                    })
                } else {
                    this.$swal('Cancelled', 'Your file is still intact', 'info')
                }
            })
        },

        canRefreshList(item) {
            try {
                if (!item.platform || item.platform.trim() == '') return true;

                let platform = item.platform.toLowerCase();
                return !['webhook', 'inboxsuite'].includes(platform);
            } catch (e) {
                console.error(e);
                return null;
            }

        },
        refreshContacts(item, index, button) {
            item.state = 101;
            var listID = item?.id
            var listData = { state: 5 }
            this.updateItem(listID, listData, item);
        },
        updateItem(id, data, item = null) {
            var toast = { message: 'Something went wrong!', title: 'Error', variant: 'danger' }

            return api.trackedLists.put(id, data)
                .then(result => {
                    toast = { message: result?.message, title: 'Success', variant: 'success' }
                })
                .catch((error) => {
                    this.$root.$emit('bv::refresh::table', this.id)
                })
                .finally(() => {
                    if (item) {
                        item.state = 'state' in data ? data.state : item.state;
                    }
                    this.$root.$bvToast.toast(toast.message, toast);
                });
        },
        isRefreshable(state) {
            return (state === 1 || state === 2 || state === -3);
        },
        timeLogs(item, index) {
            this.$emit('onClick', {item, event: 'time-logs'});
        },
        showLogs(item, index) {
            this.$emit('onClick', {item, event: 'show-logs'});
        },
        updateUploadContacts(item, action) {
            this.$emit('onClick', {item, event: 'update-list', action});
        },
        removeItem(item, index, button) {
            this.$emit('onClick', {item, event: 'remove'});
        },
        handleBtn({row, event, ref}) {
            if(!row) return;

            this.$emit('onClick', { row, event, ref });
        },
    }
}

</script>