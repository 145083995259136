<template>
    <!-- <b-container fluid> -->
    <fieldset class="text-right">
        <div class="form-card text-left">

            <b-row>
                <b-col cols="7">
                    <h3 class="mb-4">Platform Information:</h3>
                </b-col>
                <b-col cols="5">
                    <h2 class="steps">Step 1 - 4</h2>
                </b-col>
            </b-row>

            <div v-if="!show_new_platform_inputs" class="my-2" >
                <ExistPlatforms
                @toggleNextBtn="toggleNextBtn"
                :account="organizationProp"
                @addNewPlatform="switchNewPlatformInputs"
                @platformSelected="selectExistingPlatform"
                service="lo_service"

                ></ExistPlatforms></div>
            <div v-else>
            <b-row>

                <b-form-group class="col-md-12" label="Platform: *" label-for="Platform">

                    <div>
                        <multiselect v-model="form.platform" tag-placeholder="Empty" :allow-empty="false"
                            deselect-label="Can't remove this value" placeholder="Select platform" label="name"
                            track-by="code" :options="platforms" :multiple="false" :taggable="true"
                            @input="showAnotherCols">
                            <template slot="singleLabel" slot-scope="props">
                                <img class="option__image img-responsive mx-2" :src="getImageURL(props.option)"
                                    alt="Platform image" :width="logoWidth">
                                <span class="option__desc">
                                    <span class="option__title">{{ platformName(props.option.name) }}</span>
                                </span>
                            </template>

                            <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                    <img class="option__image img-responsive mx-2" :src="getImageURL(props.option)"
                                        alt="Platform label image" :width="logoWidth">
                                    <span class="option__title">{{ platformName(props.option.name) }}</span>
                                </div>
                            </template>
                        </multiselect>
                    </div>
                    <small id="alertPlatform" class="text-danger d-none"></small>
                </b-form-group>
            </b-row>

            <b-row>
                <b-col :md="cols.accoundIdCol" v-show="showCols.accountId">
                    <b-form-group :label="accountLabel" label-for="account_id">
                        <b-form-input v-show="accountIsVisible" id="platform" type="text" v-model="form.accountid"
                            :placeholder="accountPlaceholder" :disabled="false"
                            @change="handleOnChangeAccountID($event)" />

                        <div ref="auth2AccountsGroup" class="d-none">
                            <multiselect v-show="auth2.addNew" v-model="form.oAccountID" tag-placeholder="Empty"
                                deselect-label="Can't remove this value" :placeholder="accountPlaceholder" label="name"
                                :custom-label="({ name, code }) => `${name} - [${code}]`" track-by="code"
                                :options="auth2.listAccounts" :multiple="false" :disabled="false" :taggable="true">
                            </multiselect>
                            <multiselect v-show="!auth2.addNew" v-model="auth2.platform" tag-placeholder="Empty"
                                deselect-label="Can't remove this value" :placeholder="accountPlaceholder" label="name"
                                :custom-label="({ name, code }) => `${name} - [${code}]`" track-by="code"
                                :options="auth2.platforms" :multiple="false" :disabled="false" :taggable="true"
                                @select="selectPlatformConfig($event)">
                            </multiselect>
                        </div>

                        <small id="alertAccountId" class="text-danger d-none"></small>
                    </b-form-group>
                </b-col>

                <b-form-group v-show="showCols.groupCol" class="col-md-12" label="Group name: " label-for="group">
                    <b-form-input id="group" name="group" placeholder="Group name" :disabled="true"></b-form-input>
                </b-form-group>
                <b-col :md="cols.apiKeyCol" v-show="showCols.apiKey">
                    <b-form-group :label="apiTokenLabel" label-for="api_key">
                        <b-form-input required type="text" id="validationDefault01" v-model="form.apikey"
                            :placeholder="apiTokenPlaceholder" />
                        <small id="alertKey" class="text-danger d-none"></small>
                    </b-form-group>
                </b-col>

                <b-col v-if="custom.isVisible" md="6" class="mb-3">
                    <b-row>
                        <div class="col">
                            <div
                                class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                                <div class="custom-switch-inner">
                                    <label class="mr-2">Sending domain</label>
                                    <input type="checkbox" class="custom-control-input bg-primary"
                                        v-model="custom.checkbox" id="blastableSwitch" @click="cleanError()">
                                    <label class="custom-control-label" for="blastableSwitch" data-on-label="On"
                                        data-off-label="Off">
                                    </label>
                                </div>
                            </div>
                            <b-form-input v-show="custom.checkbox" v-model="custom.link" type="text" id="blastable-link"
                                name="blastable-link" placeholder="example.com" :disabled="!custom.checkbox">
                            </b-form-input>
                            <small ref="alertCustomApiLink" id="alertCustomApiLink" class="text-danger d-none"></small>
                        </div>
                    </b-row>
                </b-col>
            </b-row>
            </div>
        </div>

        <button class="btn mx-2 btn-secondary" v-if="show_new_platform_inputs" @click="show_new_platform_inputs = false">Choose an existing platform <i class="ri-arrow-go-back-line"></i></button>
        <b-button v-show="showNextBtn && !showCols.auth2Btn" name="next" variant="primary" ref="nextStep1" id="step1" class="next action-button float-right"
            :disabled="disabledNextBtnStep1" @click="handleNextStep($event)">
            <b-spinner small type="grow" v-show="nextSpinner"></b-spinner>
            Next
        </b-button>

        <b-button name="auth2Btn" variant="primary" ref="auth2Btn" id="auth2Btn" class="auth2 action-button float-right"
            v-show="showCols.auth2Btn" :data-action="auth2.state" :disabled="auth2.btnAuthorize"
            @click="handleOauth($event)">
            <b-spinner small type="grow" v-show="auth2.btnAuthorize"></b-spinner>
            {{ auth2.btnLabel }}
        </b-button>
    </fieldset>
    <!-- </b-container> -->
</template>
<script>
import Multiselect from 'vue-multiselect'
import ExistPlatforms from '@/components/inboxgeek/ExistPlatforms'
import { PLATFORMS_CODE, COLUMNS_GRID, PLATFORMS_ITEMS } from "@/constantes";
import _ from 'lodash';
import { helper } from '@/helpers';
export default {
    name: 'StepPlatform',
    components: {
        Multiselect,
        ExistPlatforms
    },
    props: {
        formProp: {
            type: [Object],
            default: () => null,
        },
        // platformsProp: {
        //     type: [Array],
        //     default: () => null,
        // },
        colsProp: {
            type: [Object],
            default: () => null,
        },
        showColsProp: {
            type: [Object],
            default: () => null,
        },
        customProp: {
            type: [Object],
            default: () => null,
        },
        organizationProp: {
            type: [Object],
            default: () => null,
        },

        auth2Prop: {
            type: [Object],
            default: () => null,
        },
    },
    data() {
        return {
            search:null,
            show_new_platform_inputs:false,
            logoWidth: 28,
            step: 0,
            accountLabel: 'Account ID:',
            accountPlaceholder: 'Account ID',
            apiTokenLabel: 'API key/Token: *',
            apiTokenPlaceholder: 'API key',


            accountIsVisible: false,
            disabledNextBtnStep1: false,
            nextSpinner: false,
            showNextBtn:true,
            form: {},
            platforms: [],
            showCols: {},
            cols: {},
            custom: {},
            organization: {},

            auth2: this.resetOauth(),
        }
    },
    methods: {
        initStep() {
            if (typeof this.formProp !== "undefined") this.form = this.formProp
            // if (typeof this.platformsProp !== "undefined") this.platforms = this.platformsProp
            if (typeof this.showColsProp !== "undefined") this.showCols = this.showColsProp
            if (typeof this.colsProp !== "undefined") this.cols = this.colsProp
            if (typeof this.customProp !== "undefined") this.custom = this.customProp
            if (typeof this.organizationProp !== "undefined") this.organization = this.organizationProp
            if (typeof this.auth2Prop !== "undefined") this.auth2 = this.auth2Prop
        },
        cleanForm() {
            this.form.platform = null
            this.form.accountid = null
            this.form.oAccountID = null
            this.form.apikey = null
            this.auth2.listAccounts = []
            this.auth2.addNew = true
            this.custom = { isVisible: false, checkbox: false, link: '' }
        },
        resetOauth() {
            return {
                addNew: true, btnAuthorize: false, inProgress: false, state: '', btnLabel: '', listAccounts: [], platforms: [], platform: null,
            }
        },
        handleNextStep($event) {

            this.auth2.platform = this.form.platform

            this.$emit('onNext', { btn: $event.target, step: this.step, compStep: this })
        },
        handleOauth($event) {
            this.$emit('onOauth', { btn: $event.target, step: this.step, compStep: this })
        },
        handleOnChangeAccountID(value) {
            if (this.form?.platform?.code == PLATFORMS_CODE.ACTIVE_CAMPAIGN) {
                const breakpoint = /https:\/\/|\.api\-us1\.com/
                const result = value.split(breakpoint);
                if (typeof result[1] !== "undefined" && result[1] !== "") this.form.accountid = result[1]
            }
        },
        showAnotherCols(platformSelected) {
            this.$emit('onPlatformSelected', platformSelected)
        },
        selectPlatformConfig($event) {
            this.$emit('onOauthSelectPlatform', $event.code)
        },
        cleanError() {
            this.$refs.alertCustomApiLink.classList.add('d-none')
            this.custom.link = '';
        },
        isValidURL(str) {
            var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
            return !!pattern.test(str);
        },
        platformName(name) {
            return helper.platformFormatName(name);
        },
        getImageURL(option) {
            try {
                return require(`@/assets/images/platforms/${option.img}`)
            } catch (e) {
                return require(`@/assets/images/platforms/default.png`)
            }
        },
        selectExistingPlatform(platform){
            this.cleanForm();

            this.form.platform = this.platforms.filter((platform_form)=>{
                return (platform_form.name == platform.name);
            })[0];


            this.showAnotherCols(this.form.platform);

            this.form.p_id          = platform.id;

            this.form.account       = platform.account;

            console.log('p id',this.form.p_id);

            this.form.accountid     = platform.account;
            this.form.apikey        = platform.key;
            this.form.oAccountID    = {code: platform.account, name: platform.name}


            this.$emit('onPlatformSelected', platform)
        },
        switchNewPlatformInputs(){
            this.form.p_id = null;
            this.form.platform = null;
            this.form.accountid = null;
            this.form.oAccountID = null;
            this.form.apikey = null;
            this.show_new_platform_inputs=true;

        },
        toggleNextBtn(state){
            this.showNextBtn = state;
        }
    },
    computed: {
        platformsSorted() {
            return _.sortBy(PLATFORMS_ITEMS, ['name', 'code']);
        }
    },
    watch: {
    },
    created() {
        this.platforms = this.platformsSorted
    },
    mounted() {
        this.initStep()
    },
}
</script>

<style>

.btn-soft-primary {
    text-align: center;
  background-color: rgb(255 208 221 / 34%) !important;
  padding: 1rem;
  border: unset;
  cursor: pointer;
  color: #f6386b;
}
.btn-soft-primary:hover {
    background-color: #f6386b !important;
    color:white;
}
</style>
