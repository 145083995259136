<template>
    <fieldset>
        <div class="form-card text-left">
            <b-row>
                <b-col cols="7">
                    <h3 class="mb-4 d-none">Finish:</h3>
                </b-col>
                <b-col cols="5">
                    <h2 class="steps">Step 4 - 4</h2>
                </b-col>
            </b-row><br />
            <b-button v-b-modal.modal-new-integration variant="success" @click="sendFlow = !sendFlow">
            Integration Test</b-button>
            <br /><br />

            <h2 v-if="!sendFlow" class="text-success text-center"><strong>SUCCESS !</strong></h2>
            <br />
            <b-row v-if="!sendFlow" class="justify-content-center">
                <b-col cols="3">
                    <b-img :src="require('@/assets/images/page-img/img-success.png')" class="fit-image" />
                </b-col>
            </b-row><br /><br />
            <b-row class="justify-content-center" v-if="!sendFlow">
                <b-col cols="7" class="text-center">
                    <h5 class="purple-text text-center">
                        Integration Successfully added
                    </h5>
                </b-col>
            </b-row>
            <b-row class="justify-content-center" v-if="sendFlow">
                <testFlow :integrationId="integrationId" col=12></testFlow>
            </b-row>
        </div>
        <b-button ref="previous" name="previous" variant="dark" class="previous action-button-previous float-right mr-3"
            value="Previous" @click="handleCloseModal($event)">Close</b-button>
    </fieldset>
</template>
<script>
import TestFlow from '@/components/inboxgeek/sendflow'
import _ from 'lodash';
export default {
    name: 'StepFinish',
    components: {
        TestFlow,
    },
    props: {
        listProp: {
            type: [Array],
            default: () => null,
        },
        listsProp: {
            type: [Array],
            default: () => null,
        },
    },
    data() {
        return {
            step: 3,
            sendFlow: false,
            integrationId: null,

            prevSpinner: false,
        }
    },
    methods: {
        initStep() {
            if (typeof this.listProp !== "undefined") this.list = this.listProp
            if (typeof this.listsProp !== "undefined") this.lists = this.listsProp
        },
        handleCloseModal($event) {
            this.$emit('onHideModal', { })
            // this.$emit('onPrevious', { btn: $event.target, step: this.step, compStep: this })
        },
        handleSelectListOption(itemSelected) {
            this.$emit('onSelectedListOption', this.list)
        },

        setIntegrationID(integrationID) {
            this.integrationId = integrationID
        }
    },
    watch: {
    },
    mounted() {
        this.initStep()
    },
}
</script>