<template>

    <b-container fluid>

        <b-row>
            <b-form-group class="col-md-12" label="Tag: *" label-for="integration-tag">
                <b-form-input v-model="tag" name="integration-tag" placeholder="Tag Tracked" @input="updateValue">
                </b-form-input>
            </b-form-group>
        </b-row>


    </b-container>

</template>
<script>

import LimitPerDayInput from "@/components/inboxgeek/fields/integration/LimitPerDayInput";

import Multiselect from 'vue-multiselect'

import api from '@/api/RestClient';

export default {
    name: 'InboxLoIntegrationStep',
    components: {
        Multiselect,
        LimitPerDayInput,
    },
    props: {
        tags: {
            type: Array,
            default: () => []
        },
        form: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            isLoading: false,
            tag: null,
            isDisabled: false,
        }
    },
    methods: {
        // handleUpdateTag(data) {
        //     this.$emit('tag', {tag: data, type: 'tag'})
        // },
        handleTagAdded(tag) {
            this.$emit('tagAdded', tag)
        },
        handleUpdateLimitPerDay(data) {
            this.$emit('limitPerDay', data)
        },
        handleUpdateRecurrence(data) {
            this.$emit('recurrence', data)
        },
        addTag(tag) {
            
            this.isLoading = true;

            let _params = {};
            let platform = this.form.platform.platform.id;
            let platformName = this.form.platform.platform.name.toLowerCase();
            // let listId = this.form.list.list[0].code;
            let listId = (typeof this.form.list.list !== 'undefined' && this.form.list.list.length) ? this.form.list.list[0].code : null;

            _params.platform = platform;
            _params.platform_id = platform;
            _params.entities = 'tags';
            _params.tag = tag;
            _params.listId = listId;
            _params.platform_name = platformName;

            var title = 'Ooops ...';
            var message = 'An Something went wrong, please try later';
            var success = 'error';
            var tagLabel = 'Tag';

            api.platforms.addTag(_params)
                .then((response) => {
                    var results = 'data' in response.data ? response.data : response;
                    if (response.success) {

                        let tagData = {}

                        tagData = this.getTagAdded(results)

                        this.tag = tagData;
                        this.$emit('tagAdded', tagData);

                        title = 'Success!';
                        message = tagLabel + ' ' + this.tag.name + ' has created successfully';
                        success = 'success';
                        
                        this.updateValue();
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        message = error.response.data.errors
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        message = error.message;
                    }
                })
                .finally(() => {
                    this.$swal(title, message, success)
                    this.isLoading = false;
                })

        },
        updateValue(data) {
            console.log('this.tag:data', this.tag, data);
            this.$emit('tag', {tag: {code: data, name: data}})
        },
        getTagAdded(result) {
            result = 'data' in result ? result.data : result;
            return { code: result.tag.id, name: result.tag.name }
        },
    },
    computed: {
        tagsFormatted() {
            let tags = this.tags.map((tag) => {
              return {
                code: tag.id,
                name: tag.name,
                type: tag.type ?? 'tag'
              }
            });

            return tags;
        }
    }
}

</script>
