<template>
    <span>

        <span>
           {{ contacts }}
        </span>

    </span>
</template>

<script>
import { helper } from '@/helpers'
import { mapGetters } from 'vuex'

export default {
    name: 'AutomationReceptionLists',
    props: ['row'],
    components: {
    },
    data() {
        return {
            contacts: 0
        }
    },
    mounted() {
        this.getTotalContacts()
    },
    methods: {
        getTotalContacts() {
            let records = this.row.item.records;
            this.contacts = this.sumInArrayByKey(records);
        },
        sumInArrayByKey(items = [], key = 'sent') {
            let sum = 0;
            items.forEach(item => {
                sum += item[key]
            });
            return sum;
        },
    },
    computed: {
        ...mapGetters({
            user: 'Auth/user',
            isOwnerAccount: 'Auth/isOwnerAccount',
        })
    }
}

</script>
