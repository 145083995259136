<template>
    <span>
        {{ row.item.name }}

        <span v-if="row.item.id == this.user.account.user_id" class="badge badge-primary mx-1">
            Owner
        </span>
    </span>
</template>

<script>
import { helper } from '@/helpers'
import { mapGetters } from 'vuex'

export default {
    name: 'DtUserName',
    props: ['row'],
    components: {
    },
    data() {
        return {
            roles: []
        }
    },
    mounted() {
        this.loadRowRoles()
    },
    methods: {
        loadRowRoles() {
            let roles = this.row.item.role.split(',');

            if (this.row.item.id == this.user.account.user_id) {
                this.roles.push('Owner');
            }
            roles.forEach((role) => {
                if (role && (role.trim() !== '')) {
                    this.roles.push(role);
                }
            });
        }
    },
    computed: {
        ...mapGetters({
            user: 'Auth/user',
            isOwnerAccount: 'Auth/isOwnerAccount',
        }),
    }
}

</script>
