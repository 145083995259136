<template>

    <b-container fluid>

        <b-row>

            <b-col md="6">
                <b-form-group label="Custom field:" label-for="event-id">
                    <b-form-input v-model="eventId" name="event-id" placeholder="Custom field" @input="updateValue">
                    </b-form-input>
                    <small id="alertEventID" class="text-danger d-none"></small>
                </b-form-group>
            </b-col>

            <b-col md="6">
                <b-form-group label="Custom Field Trigger Value: *" label-for="event-name">
                    <b-form-input v-model="eventName" name="event-name" placeholder="Custom Field Trigger Value" @input="updateValue">
                    </b-form-input>
                    <small id="alertEventName" class="text-danger d-none"></small>
                </b-form-group>
            </b-col>

        </b-row>

    </b-container>

</template>
<script>

import LimitPerDayInput from "@/components/inboxgeek/fields/integration/LimitPerDayInput";
import api from '@/api/RestClient';

export default {
    name: 'OngageLoIntegrationStep',
    components: {
        LimitPerDayInput
    },
    data() {
        return {
            eventId: null,
            eventName: null

        }
    },
    mounted() {
        this.getFields();
    },
    methods: {
        handleUpdateCustomEvent(data) {
            this.$emit('customEvent', data)
        },
        handleUpdateLimitPerDay(data) {
            this.$emit('limitPerDay', data)
        },
        updateValue() {
            let event = {code: this.eventId, name: this.eventName};
            this.$emit('tag', {tag: event, type: 'custom_event'})
        },
        getFields() {
            let list = this.form.list.list;
            this.isLoading = true;
            let platform = this.form.platform.platform;
            let params = {
                entities: 'fields',
                platform_id: platform.id,
                listId: list[0].code,
                platform_name: platform.name.toLowerCase(),
            };
            api.platforms.fetchServices(params).then((response) => {
                console.log('fetchServices', response.data)
                // let events = response.data
                // this.events = events.map((tag) => {
                //     return {
                //         'code': tag.id,
                //         'name': tag.name,
                //         'type': 'event',
                //     }
                // });
            }).catch((error) => {
                let message = error.response.data.message
                this.$swal('Oops!!', message, 'error');
            }).finally(() => {
                this.isLoading = false;
            })
        },
    }
}

</script>