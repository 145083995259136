<template>
    <div>
        <h1 class="mb-0">Sign in</h1>
        <p>Enter your email address and password to access admin panel.</p>
        <b-alert :show="alert" variant=" " dismissible fade class="text-white bg-danger">
            <div class="iq-alert-text">{{ message }}</div>
        </b-alert>
        <form @submit.prevent="login" class="mt-4 add-form">
            <div class="form-group">
                <label for="email">Email address</label>
                <input require type="email" class="form-control mb-0" id="email" name="email" v-model="email"
                    aria-describedby="emailHelp" placeholder="Enter email">
            </div>

            <div class="form-group">
                <label for="password">Password</label>
                <!-- <a href="#" class="float-right">Forgot password?</a> -->
                &nbsp;
                <router-link :to="{ name: 'auth1.password-reset' }">Forgot password?</router-link>
                <input require type="password" class="form-control mb-0" id="password" name="password" v-model="password"
                    placeholder="Password" autocomplete>
            </div>
            <div class="d-inline-block w-100">
                <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                    <input v-model="remember" type="checkbox" class="custom-control-input" id="customCheck1">
                    <label class="custom-control-label" for="customCheck1" v-show="true">Remember Me</label>
                </div>
                <button type="submit" class="btn btn-primary float-right">
                    <span class="spinner-grow spinner-grow-sm" v-show="spinner" role="status" aria-hidden="true"></span>
                    <span class="sr-only">Loading...</span>
                    <span> Sign in</span>
                </button>
            </div>
            <div class="sign-info">
                <span class="d-inline-block line-height-2">Don't have an account? <router-link
                        :to="{ name: 'auth1.sign-up1' }">Sign up</router-link></span>
                <ul class="iq-social-media" v-show="false">
                    <li><a href="#"><i class="ri-facebook-box-line"></i></a></li>
                    <li><a href="#"><i class="ri-twitter-line"></i></a></li>
                    <li><a href="#"><i class="ri-instagram-line"></i></a></li>
                </ul>
            </div>
        </form>
    </div>
</template>

<script>
import api from '@/api/RestClient'
import {
    mapGetters
} from 'vuex'
import { helper } from '@/helpers'
import { SERVICES } from "@/constantes";

export default {
    name: 'SignIn1',
    data() {
        return {
            email: '',
            password: '',
            remember: false,
            loading: true,
            alert: false,
            message: '',
            spinner: false
        }
    },
    computed: {
        ...mapGetters({
            isLogin: 'Auth/loggedIn',
            currentUser: 'Auth/user',
            currentToken: 'Auth/token'
        }),
    },
    created() {
        if (!this.isLogin) {
            this.$store.dispatch('Auth/resetUser', false);
        }
    },
    methods: {
        login: function () {
            this.spinner = true;
            localStorage.clear();

            api.auth.login(this.email, this.password, this.remember).then(data => {
                helper.loginSuccessCallback(data);
            })
                .catch(err => {
                    this.message = this.formatErrorResponse(err);
                    this.alert = true;
                })
                .finally(() => {
                    this.spinner = false;
                    setTimeout(() => {
                        this.alert = false;
                    }, 3000);
                })
        },
        formatErrorResponse(errors) {

            if (errors.response) {
                errors = errors.response.data.message
            } else if (errors.request) {
                errors = errors.request
            } else {
                errors = errors.message;
            }

            return errors
        },
    }
}
</script>
