<template>
    <b-container fluid>
        <div class="card pt-4 mb-4 pl-4 pr-4">
            <div v-show="!isLoad" class="header text-center mb-4">
                <h2>Choose Your Plan</h2>
                <h3>{{ service }}</h3>
                <p v-show="false">If you need more info about our pricing, please check <a href="#">Pricing Guidelines</a>
                </p>
            </div>
            <b-row align-v="center" class="justify-content-md-center change-plan">

                <loader :isLoad="isLoad"></loader>

                <b-col class=" mt-2" md="3" v-if="showTrialCard && trialPlan" v-show="!isLoad">

                    <component is="FreePlanCard" v-bind="{id, plan, trialPlan, spinner}"></component>

                </b-col>
                <b-col class="price-tag mt-2" md="3" v-show="!isLoad" v-if="!item.is_trial" v-for="(item, index) in plans" :key="index">

                    <component is="PaidPlanCard" v-bind="{id, plan, item}" @startChangeSubscription="handleStartChangeSubscription" @successChangeSubscription="handleSuccessChangeSubscription"></component>

                </b-col>

            </b-row>

            <b-row v-show="!isLoad">
                <b-col lg="6" offset-lg="3" class="mb-5">
                    <h4>All subscriptions include:</h4>
                    <ul v-if="serviceId == 1" class="list-unstyled line-height-4 style mb-0">
                        <li><i class="fa fa-check"></i> Free Integration Onboarding</li>
                        <li><i class="fa fa-check"></i> Premier Management Support</li>
                        <li><i class="fa fa-check"></i> Full access to Email Expertise & Auditing</li>
                        <li><i class="fa fa-check"></i> Exclusive Access to Upcoming Beta Features</li>
                    </ul>
                    <ul v-else-if="serviceId == 2" class="list-unstyled line-height-4 style mb-0">
                        <li><i class="fa fa-check"></i> Automated Lead Validation, Cleaning & Delivery</li>
                        <li><i class="fa fa-check"></i> Customizable Lead Suppression Solutions</li>
                        <li><i class="fa fa-check"></i> Premier Management Support & Onboarding</li>
                        <li><i class="fa fa-check"></i> Full Access to Email Expertise & Auditing</li>
                        <li><i class="fa fa-check"></i> Exclusive Access to Upcoming Beta Features</li>
                    </ul>

                </b-col>
            </b-row>
        </div>

        <div class="row justify-content-center">
            <div class="col-sm-8 text-center">
                <p>Need more than 50,000 {{ eventNameService }}s a month? Schedule a call for details <a
                        href="https://calendly.com/inboxgeek/enterprise">InboxGeek Enterprise Call - Team InboxGeek</a>
                    or email <a href="mailto:support@inboxgeek.com">support@inboxgeek.com</a></p>
            </div>
        </div>

        <SuccessPayment v-if="modal" v-bind="{success, loading}" @close="close"></SuccessPayment>

    </b-container>
</template>

<style scoped>
.change-plan {
    min-height: 40vh;
}

.plan-changed h4,
.plan-changed h5 {
    text-align: center;
    margin-top: 5px;
}
</style>

<script>
import {
    sofbox
} from '../../config/pluginInit'
import api from '@/api/RestClient'
import Loader from '@/components/inboxgeek/Loader'
import _ from 'lodash'
import {
    mapGetters
} from "vuex";
import { SERVICES, SERVICES_BY_ID } from "@/constantes";
import moment from 'moment'
import { helper } from '@/helpers'
import FreePlanCard from './children/FreePlanCard';
import PaidPlanCard from './children/PaidPlanCard';
import SuccessPayment from './modals/SuccessPayment';

const ON_TWELVE_MONTH = 12;
const ON_ONE_MONTH = 0;
export default {
    data() {
        return {
            id: null,
            plans: [],
            plan: 0,
            modal: false,
            loading: false,
            spinner: false,
            success: false,
            planPeriod: ON_ONE_MONTH,
            isLoad: true,
            billedPeriod: 'monthly',
            service: SERVICES_BY_ID[this.$route.params.service].name,
            serviceId: this.$route.params.service,
            trialPlan: null,
            agrementLink: "https://inboxgeek.com/services-agreement/",
            subscriptionUsed: true
        }
    },
    name: 'BillingPLans',
    components: {
        Loader,
        FreePlanCard,
        PaidPlanCard,
        SuccessPayment
    },
    computed: {
        ...mapGetters('Auth', ['user', 'isAdmin', 'isClient', 'UserSubscriptions', 'lastSubscriptions', 'livePlans']),
        eventNameService() {
            let name = null;
            let serviceID = this.$route.params.service;

            if (serviceID == SERVICES.LIVE_OPENS.id) { name = 'event'; }
            else if (serviceID == SERVICES.LIVE_LEADS.id) { name = 'record'; }
            return helper.capitalizeFirstLetter(name);
        },
        showTrialCard() {
            let serviceId = this.$route.params.service;
            let serviceNotUsed = !this.subscriptionUsed || (this.UserSubscriptions[serviceId] == 'undefined' || !this.UserSubscriptions[serviceId]);

            return this.isClient && !this.isLoad && this.trialPlan && serviceNotUsed;
        }
    },
    beforeCreate() {
        sofbox.openLoader();
    },
    mounted() {
        sofbox.index()
        this.getPlans({ service: this.$route.params.service });
    },
    methods: {
        getPlans(param = {}) {
            this.isLoad = true;
            api.plans.getActive(param).then(resp => {
                if (resp.success) {
                    this.plans = resp.data;
                    this.trialPlan = _.find(resp.data, (o) => { return o.is_trial == 1 });
                }
            })
                .catch(error => {
                    if (error.response) {
                        this.$bvToast.toast('something went wrong!', {
                            title: 'Error',
                            variant: 'danger'
                        });
                    }
                })
                .finally(() => {
                    this.isLoad = false;
                    this.fetchSubscriptionAlreadyUsed(this.$route.params.service)

                });
        },
        handleStartChangeSubscription() {
            this.loading = true;
            this.modal = true;
            this.success = false;
            this.spinner = true;
        },
        handleSuccessChangeSubscription({plan}) {
            const serviceID = this.$route.params.service;

            if(serviceID == SERVICES.LIVE_LEADS.id) {
                this.id = plan;
            }

            this.success = true;
            this.loading = false;
        },
        addMonths(numOfMonths, date = new Date()) {
            date.setMonth(date.getMonth() + numOfMonths);

            return date;
        },
        close(e) {
            e.stopPropagation();
            this.modal = false;
        },
        fetchSubscriptionAlreadyUsed(serviceId) {

            const accountId = this.user && this.user.account_id ? this.user.account_id : null;

            if(this.isClient && accountId) {
                api.subscriptions.accountAlreadyUseService(accountId, serviceId)
                .then((response) => {

                    if (response.success) {
                        this.subscriptionUsed = response.data ?? false;
                    }

                })
            }

        },
    },
}
</script>
<style>
.display-3 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.discount {
    display: flex;
    justify-content: inherit;
    margin-bottom: 0cm;
}

a.discountBtn {
    border: 1px solid;
    margin: 0px 2px;
}

.border_plan {
    border: 10px solid #dee2e6 !important;
}

.border_plan:hover {
    margin-top: -30px;
    transition: margin-top .3s linear;
}

.style {
    margin: 0 7em;
    font-weight: 600;
}

@media (max-width: 700px) {
    .style {
        margin: 0 0;
        font-weight: 600;
    }

    #loading-center {
        position: inherit !important;
    }
}
</style>
