<template>
    <b-container fluid class="mt-4 mt-md-0">
        
        <!-- CLIENT -->
        <ClientDashboard v-if="isClient"></ClientDashboard>


        <!-- ADMIN -->
        <AdminDashboard v-if="isAdmin || isAdminOrIsManager"></AdminDashboard>

    </b-container>
</template>

<script>

import { sofbox } from '@/config/pluginInit'
import { mapGetters,mapState,mapMutations } from 'vuex'
import AdminDashboard from './children/AdminDashboard.vue'
import ClientDashboard from './children/ClientDashboard.vue'
import { helper } from '@/helpers'

export default {
    name: 'Dashboard',
    props: {
        logo: { type: String, default: require('../../assets/images/logo_dark.png') },
    },
    components: {
        AdminDashboard,
        ClientDashboard
    },
    computed: {
        ...mapState(['subscription']),
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            isAccountManager: 'Auth/isAccountManager',
        }),
        isAdminOrIsManager() {
            let state = helper.hasRoles(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_ACCOUNTS_MANAGER']);
            console.log(state, this.isAccountManager || this.isAdmin)
            return helper.hasRoles(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_ACCOUNTS_MANAGER']);
        },
    },
    mounted() {
        sofbox.index();
    },
    data: () => ({
    }),
    methods: {
        ...mapMutations(['updateSubscriptionOptions']),
    }
}
</script>
<style>
div.remaining-alert h2.swal2-title {
    background-color: #f6386b !important;
    padding: 0.5rem !important;
    color: white;
}
.an-img-two {
    width: 700px;
    position: absolute;
    right: -35%;
    top: 0;
    z-index: -1;
}
</style>
