<template>

    <b-row>
        <b-form-group :label="listLabel" label-for="list" class="col-md-12">

            <multiselect v-model="list" tag-placeholder="Empty" :placeholder="listPlaceholder" label="name"
                track-by="code" :options="listsFormatted" :multiple="true" return="code" :allow-empty="true" :taggable="true"
                 @select="handleSelectList" @input="handleSelectList"
                @remove="handleSelectList">

                <template slot="option" slot-scope="props">
                    <div class="option__desc">

                        <span class="option__title">

                            <span v-if="props.option.type">

                                <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                                    style="color:#5ad"></i>
                                <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                            </span>

                            <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                            <span style="font-wight:bold;"> {{ props.option.name }} </span>

                            <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>


                        </span>

                    </div>

                </template>

            </multiselect>

            
            <small v-if="errors?.list" class="text-danger">A list should be selected</small>
        </b-form-group>
    </b-row>

</template>
<script>

import LimitPerDayInput from "@/components/inboxgeek/fields/integration/LimitPerDayInput";
import RecurrenceInput from "@/components/inboxgeek/fields/integration/RecurrenceInput";


import Multiselect from 'vue-multiselect'


export default {
    name: 'emailoctopusLoListStep',
    components: {
        LimitPerDayInput,
        RecurrenceInput,
        Multiselect
    },
    props: {
        lists: {
            type: Array,
            default: () => []
        },
        form: {
            type: Object,
            default: () => {}
        },
        errors: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            isLoading: false,
            list: null,
            listLabel: 'List: *',
            listPlaceholder: 'Select Lists',
        }
    },
    methods: {
        handleSelectList(itemSelected) {
            this.$emit('select', this.list)
        },
    },
    computed: {
        listsFormatted() {
            let lists = this.lists.map((list) => {
              return {
                code: list.id,
                name: list.name,
                type: list.type ?? 'list'
              }
            });

            return lists;
        }
    }
}

</script>
