<template>

    <span data-toggle="tooltip" 
        data-placement="top" 
        :data-original-title="getStateText(row.item.state)"
        :title="getStateText(row.item.state)" class="">
        <i aria-hidden="true" class="fa" :class="getStateIcon(row.item.state)"></i>
    </span>

</template>
<script>

import { helper } from '@/helpers';

export default {
    name: 'DtListStatusCell',
    props: ['row'],
    methods: {
        getStateText(state) {
            var stateEn = '';
            switch (state) {
                case -1: case -2:
                    stateEn = 'Inactive/deleted';
                    break;
                case -3:
                    stateEn = 'Disconnected';
                    break;
                case 1: case 2:
                    stateEn = 'Synced';
                    break;
                default:
                    stateEn = 'In Progress';
                    break;
            }
            return stateEn;
        },
        getStateIcon(state) {
            var stateEn = '';
            switch (state) {
                case -1: case -2:
                    stateEn = 'fa-exclamation-triangle text-danger';
                    break;
                case -3:
                    stateEn = 'fa-ban text-danger';
                    break;
                case 1: case 2:
                    stateEn = 'fa-check text-success';
                    break;
                default:
                    stateEn = 'fa-spinner text-info';
                    break;
            }
            return stateEn;
        },
    }
}

</script>