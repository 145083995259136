<template>
  <div class="row">
            <div class="col-lg-12">
              <h3>
                Hello there,
                <span class="text-primary">
                  {{ filterCapitalize((profile ? profile.first_name : ''), true) }}
                </span>
              </h3>
            </div>
  </div>
</template>

<script>


export default {
  name: 'WelcomeWidget',
  mounted() {
   
  },
  props: ['service_id'],
  data() {
    return {
      profile : 'Test'
    }
  },
  methods: {
    filterCapitalize(value, all = null) {
      return helper.customCapitalize(value, all)
    },
  }
}

</script>