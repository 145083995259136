<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card class="">
                    <template v-slot:headerTitle>
                        <b-row class="mt-4">
                            <b-col md="4">
                                <b-form-group label="From" label-for="from">
                                    <b-form-input id="from" type="date" v-model="dates.from" :max="dates.to"
                                        :value="dates.from"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="To" label-for="to">
                                    <b-form-input id="to" type="date" v-model="dates.to" :min="dates.from" :max="dates.max"
                                        :value="dates.to"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="To" label-for="submit">
                                    <b-button class="action" variant="primary" :disabled="submitload" @click="onSubmit">
                                        <b-spinner small type="grow" v-if="submitload"></b-spinner>
                                        Submit
                                    </b-button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
                <iq-card>
                    <template v-slot:headerAction class="mt-3">
                        <b-form v-if="is_manager" class="mx-3">
                            <AccountsMultiselect @onSelect="onSelectAccount" />
                        </b-form>
                    </template>

                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12">

                                <LiveOpensEventsReportTable ref="liveopens-reports-table" v-bind="{filters}"></LiveOpensEventsReportTable>
                                
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        
    </b-container>
</template>

<script>
import { sofbox } from "@/config/pluginInit";
import AccountsMultiselect from "@/views/children/AccountsMultiselect.vue";
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { helper } from '@/helpers'
import LiveOpensEventsReportTable from "./tables/LiveOpensEventsReportTable";

export default {
    name: "LiveOpensReport",
    components: {
        AccountsMultiselect,
        LiveOpensEventsReportTable
    },
    mounted() {
        sofbox.index();
        this.initDates();
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            user: 'Auth/user',
        })
    },
    methods: {
        onSelectAccount(accountSelected) {
            let account = -1;

            this.showChart = false;
            this.loadAdminStats = false;

            this.btnAddIsDisabled = false
            if (this.is_manager) {
                if (accountSelected) {
                    account = accountSelected.code;
                    this.$refs['dt-liveopens-reports'].setAccount(account)
                } else {
                    this.$refs['dt-liveopens-reports'].setAccount(account)
                }
            }
        },
        onSubmit() {
            this.submitload = true;

            let dates = {
                from: this.dates.from,
                to: this.dates.to
            }

            this.filters.from = this.dates.from;
            this.filters.to = this.dates.to;
            this.submitload = false;

            this.$refs['liveopens-reports-table'].forceRenderTable()

        },
        initDates() {

            var date = helper.dateToYMD(new Date());
            var minDate = helper.dateToYMD(helper.AddOrSubractDays(new Date(), 6, false));

            this.dates.to = date;
            this.dates.from = minDate;
            this.dates.max = date;
        },
        onFilter(form) {
            this.$refs['dt-liveopens-reports'].filterTable(form);
        },
    },
    watch: {},
    data() {
        return {
            dates: {
                to: null,
                from: null,
            },
            filters: {
                from: null,
                to: null,
            },
            isFilter: false,
            submitload: false,
            is_manager: false,
        };
    },
};
</script>

<style scope>
#myTabContent-5 {
    padding-bottom: 2em !important;
    /* margin: 0 5px; */
}

.actionClass {
    width: 18%;
}

.timeClass,
.createdatClass,
.accountClass {
    width: 10%;
}

textarea.form-control {
    line-height: 25px;
}

.cursor-default {
    cursor: default;
}

.table th,
.table td {
    padding: 0.55rem;
    vertical-align: top;
}

.action {
    width: 100%;
    height: 43.5px;
}

label[for=submit] {
    visibility: hidden;
}
</style>
