<template>

    <b-container fluid>
        <DataTable v-if="renderDT" ref="plans-table" :url="'/plans'"
            v-bind="{ columns: columns, customCells: dtCells, filters: filters}"
            @onSwitch="handleSwitchVisible" 
            @onClick="handleBtn"
            />
    </b-container>

</template>

<script>
import DataTable from '@/components/inboxgeek/tables/DataTable'
import IBGSwitchCell from '@/components/inboxgeek/tables/IBGSwitchCell'
import PlanStatusCell from './Table/PlanStatusCell'
import PlanServiceNameCell from './Table/PlanServiceNameCell'
import PlanParentNameCell from './Table/PlanParentNameCell'
import PlanActionsCell from './Table/PlanActionsCell'
import {SERVICES} from "@/constantes";
import { mapGetters } from 'vuex'
import _ from 'lodash'

import api from '@/api/RestClient';

export default {
    name: 'PlansTable',
    components: {
        DataTable
    },
    props: {
        search: {
            type: String,
            default: null
        },
        service: {
            type: Number,
            default: SERVICES.LIVE_OPENS
        }
    },
    data() {
        return {
            renderDT: true,
            filters: {
                search: null,
                service: this.service
            },
            dtCells: [
                {
                    key: 'service',
                    component: PlanServiceNameCell,
                    event: null,
                },
                {
                    key: 'parent_plan',
                    component: PlanParentNameCell,
                    event: null,
                },
                {
                    key: 'status',
                    component: PlanStatusCell,
                    event: null,
                },
                {
                    key: 'is_visible',
                    component: IBGSwitchCell,
                    event: 'onSwitch',
					props: {
						attribute: 'is_visible',
					}
                },
                {
                    key: 'actions',
                    component: PlanActionsCell,
                    event: 'onClick',
                }
            ],
        }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
        }),
        columns() {
            let columns = [
                {
                    key: 'id',
                    label: '#',
                    sortable: true,
                    sortDirection: 'desc'
                },
                {
                    key: 'service',
                    label: 'Service',
                    sortable: false,
                    class: 'text-center'
                },
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'parent_plan',
                    label: 'Parent Plan',
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: 'interval',
                    label: 'Interval',
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: 'balance',
                    label: 'Balance',
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: 'price',
                    label: 'Price',
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: 'unit_price',
                    label: 'Price/Unit',
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: 'is_visible',
                    label: 'Visible',
                    sortable: true,
                    class: 'text-center'
                },
                {
                key: 'actions',
                label: 'Actions',
                class: 'text-center'
            }
               
            ];
            
            if (!this.isAdmin) {
                _.remove(columns, { key: 'actions' });
            }

            return columns;
        }
    },
    mounted() {
        this.$root.$on('plans:table:filter', (filter) => {
            console.log(filter)
            // this.filters.service = 1;
        })
    },
    methods: {
        forceRenderTable() {
            this.renderDT = false;

            this.$nextTick(() => {
                this.filters.service = this.service;
                this.renderDT = true;
            });
        },
        handleSwitchVisible(data) {

            let itemCode = data.row.id
            let form = {}
            form[data.key] = data.input.checked ? 1 : 0

            let toast = { message: 'Something went wrong!', title: 'Error', variant: 'danger' }

            return api.plans.put(itemCode, form)
                .then(result => {
                    data.input.checked = result.data[data.key];
                    toast = { message: result?.message, title: 'Success', variant: 'success' }
                })
                .catch(err => {
                    this.$bvToast.toast(err.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    });
                })
                .finally(() => {
                    this.$root.$bvToast.toast(toast.message, toast);
                });
        },
        handleBtn({row, event}) {
            if(!row) return;

            this.$emit('onClick', { row, event });
        },
    },
}

</script>