<template>
    <div class="charts">

        <!-- Chart -->
        <b-row ref="records-chart">
            <b-col md="12" v-if="showChart">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4>Records</h4>
                    </template>
                    <template v-slot:body>

                        <b-row class="justify-content-center my-4">

                            <b-form class="my-auto" inline>

                                <b-form-group label="" class="" label-cols-sm="2" label-for="from">
                                    <b-form-input class="px-1" type="date" v-model="options.dates.from"
                                        :max="options.dates.to" :value="options.dates.from" @input="graph()"></b-form-input>
                                </b-form-group>

                                <label class="my-auto px-2">To</label>

                                <b-form-group label="" class="" label-cols-sm="2" label-for="to">
                                    <b-form-input class="px-1" type="date" v-model="options.dates.to"
                                        :min="options.dates.from" :max="options.dates.max" :value="options.dates.to"
                                        @input="graph()"></b-form-input>
                                </b-form-group>
                            </b-form>

                        </b-row>

                        <b-row class="d-flex justify-content-center">
                            <b-button-toolbar v-if="statsData && !item.disabled"
                                v-for="(item, index) in statsData.indicators" :key="index" aria-label="">
                                <b-button-group size="sm" class="mr-0">
                                    <b-button @click="toggleChartSerie($event, item, index)"
                                        :style="{ 'background-color': chart.bodyData.colors[index], 'border-color': chart.bodyData.colors[index] }"
                                        :class="isHide(item.title) ? 'indicator-disabled' : ''">{{ item.title }}</b-button>

                                </b-button-group>
                                <b-input-group size="sm" :append="formatDecimal(item.indicator)" class="mr-3">
                                </b-input-group>
                            </b-button-toolbar>

                        </b-row>

                        <b-col v-if="chartLoading" md="12" class="text-center">
                            <b-spinner small type="grow"></b-spinner>
                        </b-col>
                        
                        <IbgApexChart v-else-if="!chartLoading && showChart" 
                            ref="chart" 
                            :element="'slug'"
                            :chartOption="chart.bodyData" :columnsHide="chart.columnsHide" />

                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import api from '@/api/RestClient'
import {
    helper
} from '@/helpers'
import IbgApexChart from "@/components/charts/IbgApexChart.vue"
import moment from 'moment'
import _ from 'lodash'

const PERIOD_DAY = 0;
const PERIOD_WEEK = 1;
const PERIOD_MONTH = 2;
const PERIOD_YEAR = 3;


export default {
    name: 'RecordsChart',
    components: {
        IbgApexChart
    },
    data() {
        return {
            options: { label: 'event_id', period: PERIOD_DAY, dates: {} },
            tabsPeriod: [
                { code: PERIOD_DAY, name: 'Days', format: 'YYYY-MM-DD', offset: 30 },
                { code: PERIOD_WEEK, name: 'Weeks', format: 'YYYY-MM-DD', offset: 10 },
                { code: PERIOD_MONTH, name: 'Months', format: 'YYYY-MM', offset: 6 },
                { code: PERIOD_YEAR, name: 'Years', format: 'YYYY', offset: 1 },
            ],
            showChart: false,
            chartLoading: false,
            statsData: null,
            chartData: [],
            yaxis: 5,
            chart: {
                title: '***',
                type: 'area',
                dot: 1,
                bodyData: {
                    chart: {
                        height: 350,
                        type: 'line',
                        stacked: false,
                        events: {
                            click: (event, chartContext, config) => {
                                let date = config.globals.categoryLabels[config.dataPointIndex];
                                if (date) {
                                    this.detailsRedirect(date)
                                }
                            },
                            legendClick: (chartContext, seriesIndex, config) => {
                                this.clearHideColumns(chartContext, seriesIndex)
                            }
                        },
                    },
                    stroke: {
                        width: [3, 3],
                        curve: 'smooth'
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '50%'
                        }
                    },
                    colors: ['#00ca00', '#0084ff', '#FEB019', '#5A2A27', '#FD6A6A', '#662E9B', '#D7263D', '#C5D86D', '#4CAF50'],
                    series: [],
                    fill: {
                        opacity: [0.5, 0.5],
                        gradient: {
                            inverseColors: false,
                            shade: 'light',
                            type: 'vertical',
                            opacityFrom: 0.85,
                            opacityTo: 0.55,
                            stops: [0, 100, 100, 100]
                        }
                    },
                    labels: [],
                    markers: {
                        size: 0
                    },
                    xaxis: {
                        type: 'category',
                        interval: 4,
                        tickAmount: 10
                    },
                    yaxis: {
                        min: 0,
                        max: () => this.yaxis,
                        tickAmount: 9
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            formatter: function (y) {
                                if (typeof y !== 'undefined') {
                                    return y.toFixed(0)
                                }
                                return y
                            }
                        },
                        x: {},
                    },
                    legend: {
                        labels: {
                            useSeriesColors: true
                        },
                    }
                },
                columnsHide: [],
            },
            totalRecords: 0,
        }
    },
    mounted() {
        this.initIntervalDates();
        this.graph()
    },
    methods: {
        graph(item = null) {

            this.chartLoading = true

            let options = {
                "code": item ? item.code : null,
                "from": this.options.dates.from,
                "to": this.options.dates.to,
            }

            api.stats.sourceRecordsCharts(options)
                .then(response => {

                    if (response.success) {

                        this.totalRecords = response.data.total;
                        this.statsData = this.getChartDataFormatted(response);
                        this.chart.bodyData.series = this.statsData.series;
                        this.chart.bodyData.labels = this.statsData.labels;

                        let seriesData = [];
                        if (this.statsData.series.length) {

                            _.forEach(this.statsData.series, function (item) {

                                _.forEach(item.data, function (val) {
                                    seriesData.push(val)
                                });

                            });
                        }

                        // Fix fetch chart data empty
                        if (!seriesData.length) {

                            let emptySerie = { type: 'area', name: this.options.label, data: [] }

                            _.forEach(this.statsData.labels, function (label) {
                                emptySerie.data.push("0")
                            });

                            this.statsData.series.push(emptySerie)
                            this.chart.bodyData.series = this.statsData.series;
                        }

                        this.chartData = response.data.data

                        this.showChart = false;
                        this.$nextTick(() => {
                            this.showChart = true;
                        });
                    }

                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    helper.delay(2000).then(() => {
                        this.chartLoading = false;
                    });

                })

        },
        initIntervalDates() {
            let date = moment();
            let minDate = date.clone();

            const period = this.options.period;
            let currentPeriod = _.find(this.tabsPeriod, function (o) { return period == o.code; });

            minDate.subtract(currentPeriod.offset, currentPeriod.name.toLowerCase())

            this.options.dates.to = date.format('YYYY-MM-DD');
            this.options.dates.from = minDate.format('YYYY-MM-DD');
            this.options.dates.max = date.format('YYYY-MM-DD');
        },
        getChartDataFormatted(response, format = 'YYYY-MM-DD', comparator = 'day') {

            let chartData = {
                indicators: [],
                series: [],
                labels: [],
            }

            if (typeof response.data === "undefined") {
                return chartData
            }

            const that = this
            const data = response.data;

            let startDate = moment(data.period.from, format);
            let endDate = moment(data.period.to, format);

            chartData.labels = this.getLabelsFromRangeDates(startDate, endDate, format, `${comparator}s`);

            data.items.forEach(item => {
                let graphItems = _.filter(data.data, function (o) {

                    let label = null;
                    if (typeof that.options.label !== "undefined") {
                        label = (o.hasOwnProperty(that.options.label))
                            ? that.options.label : null
                    }

                    return item.id == o[label];
                });

                let indicatorPlan = {
                    title: item.name,
                    indicator: `${this.getSumItemsByAttr(graphItems)}`
                }
                chartData.indicators.push(indicatorPlan)

                let seriePlan = {
                    name: item.name,
                    type: `area`,
                    data: this.getDataChartByItemsAndLabels(chartData.labels, graphItems, comparator)
                }
                chartData.series.push(seriePlan)
            });

            // Update X tooltip
            this.chart.bodyData.tooltip.x = {
                formatter: function (x, series, labels = chartData.labels) {
                    return labels[series.dataPointIndex]
                }
            }

            if (comparator == 'week') {
                chartData.labels = this.getLabelsFromRangeDates(startDate, endDate, '[Week ]WW-YYYY', `${comparator}s`);
            }
            return chartData
        },
        getLabelsFromRangeDates(startDate, endDate, format = 'YYYY-MM-DD', interval = 'days') {

            let now = startDate.clone(), dates = [];

            while (now.isSameOrBefore(endDate)) {
                dates.push(now.format(format));
                now.add(1, interval);
            }

            return dates;
        },
        getDataChartByItemsAndLabels(labels, items = [], comparator = 'day') {
            let data = [];
            labels.forEach(label => {
                let graphItems = _.filter(items, function (o) { return moment(o.date).isSame(label, comparator); });
                data.push(this.getSumItemsByAttr(graphItems))
            });
            return data;
        },
        getSumItemsByAttr(items, attr = 'total') {
            const sum = items.reduce((accumulator, object) => {
                if (!Object.hasOwn(object, attr)) {
                    return accumulator + 0;
                }
                return accumulator + parseInt(object[attr]);
            }, 0);
            return sum.toFixed(0);
        },
        detailsRedirect(dateFrom = null, dateTo = null, serie = null) {
            return helper.redirectToLiveLeadsRecords(this, dateFrom, dateTo, serie)
        },
        setYAxis() {
            const minMax = this.getMaxSerie(this.chart.bodyData.series);

            let addMarge = minMax.max ? ((minMax.max * 10) / 100) : 10;

            this.yaxis = minMax.max + Math.ceil(addMarge); // set Chart bodyData.yaxis.max dynamically

            this.chart.bodyData.yaxis = {
                min: 0,
                max: this.getMaxSerie(this.chart.bodyData.series),
                tickAmount: 5,
            }
        },
        arrayMax(arr) {
            let len = arr.length,
                max = -Infinity;
            while (len--) {
                if (Number(arr[len]) > max) {
                    max = Number(arr[len]);
                }
            }
            return max;
        },
        getMaxSerie(series = []) {
            if (!series) return 0;
            let elts = [];
            series.forEach(serie => {
                elts.push(this.arrayMax(serie.data))
            });
            return this.arrayMax(elts);
        },
        formatDecimal(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        },
        toggleChartSerie($event, item) {
            $event.preventDefault();
            this.detailsRedirect(this.options.dates.from, this.options.dates.to, item.title);
        },
        clearHideColumns(chartContext, index) {
            let currIndicator = this.statsData.indicators[index];
            const that = this;

            if (_.includes(this.chart.columnsHide, currIndicator.title)) {
                this.chart.columnsHide = _.remove(this.chart.columnsHide, function (popItem) {
                    return popItem !== currIndicator.title;
                });
            } else {
                let alreadyHide = _.find(that.chart.columnsHide, function (o) { return currIndicator.title == o; });

                if (!alreadyHide) {
                    this.chart.columnsHide.push(currIndicator.title)
                }
            }
        },
        isHide(name) {
            return _.includes(this.chart.columnsHide, name)
        }
    },
    watch: {
        'statsData': {
            handler(newValue, oldValue) {
                this.setYAxis();
            }
        },
    },
}
</script>
<style>
.indicator-disabled {
    opacity: 0.65;
}
</style>