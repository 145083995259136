<template>
    <b-row>
        <div class="form-card text-left col-md-12">

            <UploadFileFiled :list="list" :platform="platform" @uploadedList="uploadedList"></UploadFileFiled>

        </div>
    </b-row>
</template>

<script>
import Multiselect from 'vue-multiselect'
import UploadFileFiled from "@/components/inboxgeek/fileds/UploadFileFiled";

import api from "@/api";

export default {
    name: 'WebhookLoListStep',
    components: {
        Multiselect,
        UploadFileFiled
    },
    props: {
        form: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            list: null,
            lists: [],
            platform: null,
            isLoading: false
        }
    },
    mounted() {
        if(this.form.platform.platform) {
            this.platform =  this.form.platform.platform
        }
    },
    methods: {
        handleSelectList(itemSelected) {
            this.$emit('select', this.list)
        },
        uploadedList(lists) {
            if (lists[0]) {

                if (lists[0].code) {
                    this.list = lists.map(list => { return { 'code': list.code, 'name': list.name }; });
                    this.$emit('select', this.list);
                    console.log('we are here!!',this.list);
                }

            }

            //
        },
    }
}
</script>
