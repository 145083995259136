<template>

    <b-modal id="modal-list-timeline" ref="modal-list-timeLine" size="lg" title="List Activity timeline" @show="initModal" @hidden="resetModal"
        :hide-footer=true>
        <template #modal-header>
            <!-- Emulate built in modal header close button action -->
            <h4 class="text-primary">
                List Activity timeline
            </h4>

            <!-- <div>
                <span class="text-success cursor-pointer-ek mr-3" @click="reload">
                    Reload <i class="fa fa-refresh" v-show="!reload_logs"></i> 
                    <b-spinner small label="Small Spinner" type="grow" v-show="reload_logs"></b-spinner> 
                </span>
                <b-button size="sm" variant="outline-default" @click="close"><i class="fa fa-close"></i></b-button>
            </div> -->

        </template>

        <!-- <timeLine :integrationLogs="integrationLogs" :integration_id="integration_id" @onReload="reloadLogs"></timeLine> -->

        <b-card>

            <p v-if="isLoading" class="text-center">
                <b-spinner small label="Small Spinner" type="grow"></b-spinner>
            </p>
            <ul v-else class="iq-timeline">
                <li v-if="!timeLineLogs.length">No data found</li>
                <li v-else v-for="(log, i) in timeLineLogs" :key="i">
                    <div class="timeline-dots" :class="timeLineBorderClass(log.action)"></div>
                    <h6 class="float-left mb-1">List {{ log.action }}</h6>
                    <small class="float-right mt-1">{{ getLogDate(log, 'MMM DD YYYY HH:MM') }}, by {{ log.completed_by_user ?? log.completed_by }}</small>
                    <div class="d-inline-block w-100">
                        {{ logFormatted(log) }}
                    </div>
                </li>
            </ul>
        </b-card>
    </b-modal>

</template>

<script>
import api from "@/api";
import moment from 'moment'

export default {
    name: "TrackedListTimeLine",
    props: ['list'],
    components: {
    },
    data() {
        return {
            isLoading: false,
            timeLineLogs: [],
          state_message : {
            "-3" : { "general" : '"Disconnected"' , 'state' :'Error' },
            "-2" : { "general" :  '"Inactive/deleted"', 'state' :'Deleted' },
            "-1" : { "general" :  '"Inactive/deleted"', 'state' :'To Delete' },
            "0" :  { "general" : '"In Progress"', 'state' :'Start Fresh' },
            "1" :  { "general" : '"Synced"', 'state' :'Downloaded' },
            "2" :  { "general" : '"Synced"', 'state' :'Uploaded' },
            "3" :  { "general" : '"In Progress"', 'state' :'Download In Progress' },
            "30" :{ "general" : '"Disconnected"', 'state' :'Download Later'},
            "4" : { "general" :'"Synced"', 'state' :'Upload In Progress' },
            "40" : { "general" :'"Synced"', 'state' :'Upload Later' },
            "5" : { "general" :'"In Progress"', 'state' :'Manual Refresh' },
          }
        }
    },
    methods: {
        initModal() {

            setTimeout(() => {

                this.isLoading = true;
                this.timeLineLogs = [];

                api.trackedLists.logs(this.list.id)
                    .then((response) => {
                        this.timeLineLogs = response.data
                    })
                    .catch((err) => { })
                    .finally(() => {
                        this.isLoading = false;
                    })

            }, 400)
            
        },
        resetModal() {

            this.isLoading = false;
            this.timeLineLogs = [];

        },
        getLogDate(log, format = 'YYYY-MM-DD') {
            
            const logDate = moment(log.created_at);

            const formattedDate = logDate.format(format);

            return formattedDate;

        },
        logFormatted(log) {
            let text = [];
            if(log.type!='error'){
                let columns = JSON.parse(log.columns);
                Object.keys(columns).forEach((key, index) => {

                    if (!['updated_at'].includes(key)) {

                        if ([0, 1].includes(columns[key]) && log.action == 'updated') {

                          if(key == 'state') {
                            text.push(`The state has been changed to ${this.state_message[columns[key]]?.general ?? '-'}`)
                          }else{
                            if (columns[key] == 0) {
                              text.push(`The ${key} has been disabled`)
                            } else if (columns[key] == 1) {
                              text.push(`The ${key} has been enabled`)
                            }
                          }


                        } else {

                            text.push(`The ${key} field has been ${log.action} ${log.action == 'created' ? 'with' : 'to'} ${columns[key]}`)

                        }
                    }
                });

            } else if (log.type == 'error') {
                text.push(log.errors)
            }
            
            return text.join(', ');
        },
        columnsLogFormatted(log) {
            let logs = JSON.parse(log.columns);

            let text = ``;

            for (const [key, value] of logs.entries(column)) {
                console.log(`key=${e}  value=${column[e]}`)

                text += `The ${e} has been ${log.action} 
                    ${(log.action == 'created') ? ' with value: ' : ''}  
                    ${(log.action == 'updated') ? 'to' : ''} 
                    ${column[e]}, `;
            }


            return text;
        },
        timeLineBorderClass(logAction) {
            let borderClass = '';

            if(logAction == 'created') {
                borderClass = 'border-success';
            } else if (logAction == 'updated') {
                borderClass = 'border-primary';
            } else if (logAction == 'deleted') {
                borderClass = 'border-danger';
            } else if (logAction == 'restore') {
                borderClass = 'border-warning';
            } else if (logAction == 'error') {
                borderClass = 'border-danger';
            }

            return borderClass;
        }
    }
}
</script>