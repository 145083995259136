<template>
    <b-container fluid>

        <!-- Table title -->
        <b-row class="mb-3 d-flex justify-content-between">
            <b-col lg="4" class="my-1">
                <h4 class="card-title">Account Management</h4>
            </b-col>
        </b-row>

        <!-- User Interface controls -->
        <b-row class="mb-3 d-flex justify-content-between">

            <b-col lg="4" class="my-1">
                <b-input-group size="md">
                    <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Search"></b-form-input>
                </b-input-group>
            </b-col>

            <Transition>
                <div v-if="selected.length" class="col-md-8 col-sm-12 mb-2 xs-mx-auto my-auto">
                    <b-alert :show="true" variant="secondary" class="py-1 my-0 px-2">
                        <div class="iq-alert-icon">
                            <i class="ri-information-line"></i>
                        </div>
                        <div class="iq-alert-text">
                            <span>
                                {{ (selected.length == to && selected.length > 1) ? 'All' : '' }} <b>{{ selected.length
                                }}</b>
                                Statistic{{ selected.length > 1 ? 's' : '' }}
                                {{ selected.length < totalRows ? 'on this page' : 'recorded' }} are selected. </span>

                                    <b-button v-if="selected.length < totalRows" variant="outline-info"
                                        @click="selectAllItems">
                                        Select all <b>{{ totalRows }}</b> Statistics
                                    </b-button>
                                    <b-button v-else-if="selected.length == totalRows" variant="light"
                                        @click="clearSelection">
                                        Clear Selection
                                    </b-button>

                                    <b-button variant="primary" class="float-right" @click.prevent="displayReportSelected()"
                                        :disabled="!selected.length">
                                        <i class="ri-file-download-fill fa-lg"></i>
                                        Display Report
                                    </b-button>
                        </div>
                    </b-alert>

                </div>
            </Transition>
        </b-row>

        <AccountManagementTable ref="account-management-table" v-bind="{accountId: account != -1 ? account : null, filters: filterData, dates :dateRange }" @getChart="getChart" @onFinishedFilter="$emit('onFinishedFilter')" @onDisplayReport="handleDisplayReport" @itemsSelected="updateItemsSelected"></AccountManagementTable>
    </b-container>
</template>

<script>
import Spinner1 from '@/components/loaders/spinner1'
import store from '@/store/index'
import api from '@/api/RestClient'
import { helper } from '@/helpers';
import {
    mapGetters
} from "vuex";
import moment from 'moment'
import { excelParser } from "@/helpers/excel-parser";
import AccountManagementTable from '../tables/AccountManagementTable';

export default {
    name: 'DtAccountManagement',
   props:['dateRange'],
    components: {
        Spinner1,
        AccountManagementTable
    },
    data() {
        return {
            tableId: 'dt-live-reports',
            items: [],
            from: null,
            to: null,
            dates: null,
            $search: '',
            filter: null,
            account: -1,
            
            reportDays: null,
            filterData: null,
            selected: [],
            totalRows: null,
            selectAll: false,
            indeterminate: false,
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    }
                })
        },
        ...mapGetters('Auth', ['user']),
        ...mapGetters('Auth', ['isAdmin'])
    },
    mounted() {
        this.$root.$on('chartDone', (item) => {
            item.state = -101; // -1 for chart retrieved
        });
        if (!this.isAdmin) this.account = this.user.account_id;
    },
    methods: {
        setAccount(account) {
            this.account = account
            this.currentPage = 1
            this.$root.$emit('bv::refresh::table', this.$refs['account-management-table'].tableId)
        },
        setDates(dates) {
            this.dates = dates
            this.currentPage = 1
            this.$root.$emit('bv::refresh::table', this.$refs['account-management-table'].tableId)
        },
        getChart({item, index, target}) {
            item.state = 101; // spinner true load data graph wip
            this.$root.$emit('showChart', item);
        },
        handleDisplayReport({items, data}) {
            this.$emit('onDisplayReport', { items, data });
        },
        filterTable(params) {
            this.filterData = JSON.stringify({
                status: params.status?.code ?? '',
                daily_cap: params.daily_cap ?? '',
                date_to: params.date_to ?? '',
                date_from: params.date_from ?? '',
                account_type: params.account_type?.code ?? '',
                plans: params.plans ? _.map(params.plans, 'code') : [],
                platform: params.platform ? _.map(params.platform, 'name') : [],
            });
            this.$root.$emit('bv::refresh::table', this.$refs['account-management-table'].tableId)
        },
        selectAllItems($event) {

            this.$refs['account-management-table'].selectAllItems($event);
        },
        clearSelection($event) {
            this.$refs['account-management-table'].clearSelection($event);
        },
        displayReportSelected() {
            this.$refs['account-management-table'].displayReportSelected();
        },
        exportData(data, fileName = null, type = null) {
            excelParser().exportDataFromJSON(data, fileName, type);
        },
        async downloadSVG(chartId) {

            const chartInstance = window.Apex._chartInstances.find(
                (chart) => chart.id === chartId
            );
            const base64 = await chartInstance.chart.dataURI();
            const downloadLink = document.createElement("a");
            downloadLink.href = base64.imgURI;
            downloadLink.download = "image.png";

            // Add the anchor element to the document
            document.body.appendChild(downloadLink);

            // Simulate a click event to initiate the download
            downloadLink.click();

            // Remove the anchor element from the document
            document.body.removeChild(downloadLink);
        },
        updateItemsSelected({selected, total}) {
            this.selected = selected;
            this.totalRows = total;
        }
    },
    watch: {}
}
</script>

<style>
td.lists>div,
td.integration>div {
    width: 16em;
}

td.tag>div,
td.account>div {
    width: 14em;
}

.ig-text-wrap {
    word-wrap: break-word;
}
</style>
