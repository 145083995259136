<template>

    <span v-b-tooltip v-b-tooltip.hover :title="row.value.length > 20 ? row.value : ''" class="badge badge-light badge-pill">
        {{ filterLessThan(row.value) }}
    </span>

</template>
<script>

import { helper } from '@/helpers';

export default {
    name: 'DtListIdCell',
    props: ['row'],
    methods: {
        filterLessThan(value, max = 20) {
            return helper.showMaxCharacters(value, max)
        }
    }
}

</script>