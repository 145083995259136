<template>
<b-container fluid>
    <a @click='notifcationBar()' href="#" variant="primary iq-waves-effect" size="lg"><i class="fas fa-sliders-h fa-2 notification-list-side-toggle-button"></i></a>
    <b-row>
        
        <b-col lg="12">
            <iq-card body-class="chat-page p-0">
                
                <template v-slot:body>
                    <div class="chat-data-block">
                        <b-row>
                            <b-col lg="12" :class="loader_class">
                                <loader :isLoad="isLoad"></loader>
                            </b-col>
                            <b-col v-show="!isLoad" lg="3" class="chat-data-left scroller notification-list-side">
                                <div class="chat-search pt-3 pl-3">
                                    <div class="d-flex align-items-center">
                                        <div class="chat-profile mr-3">
                                            <!-- <img :src="require('../../../assets/images/user/1.jpg')" alt="chat-user" class="avatar-60 "> -->
                                        </div>
                                        <div class="chat-caption">
                                            <h5 class="mb-0">Notifications list</h5>
                                            <!-- <p class="m-0">Web Designer</p> -->
                                        </div>
                                        <button @click='notifcationBar(true)' type="submit" class="close-btn-res p-3"><i class="ri-close-fill"></i></button>
                                        <span class="filter d-none">
                                            <a href="#" @click='filter()'>news</a>&nbsp;
                                            <a href="#" @click='filter("notread")'>notread</a>
                                        </span>
                                    </div>
                                    <div id="user-detail-popup" class="scroller">
                                        <div class="user-profile">
                                            <button type="submit" class="close-popup p-3"><i class="ri-close-fill"></i></button>
                                            <div class="user text-center mb-4">
                                                <a class="avatar m-0">
                                                    <!-- <img :src="require('../../../assets/images/user/1.jpg')" alt="avatar"> -->
                                                </a>
                                                <div class="user-name mt-4">
                                                    <h4>Nik Jordan</h4>
                                                </div>
                                                <div class="user-desc">
                                                    <p>Web Designer</p>
                                                </div>
                                            </div>
                                            <hr>
                                            <div class="user-detail text-left mt-4 pl-4 pr-4">
                                                <h5 class="mt-4 mb-4">About</h5>
                                                <p>It is long established fact that a reader will be distracted bt the reddable.</p>
                                                <h5 class="mt-3 mb-3">Status</h5>
                                                <ul class="user-status p-0">
                                                    <li class="mb-1"><i class="ri-checkbox-blank-circle-fill text-success pr-1"></i><span>Online</span></li>
                                                    <li class="mb-1"><i class="ri-checkbox-blank-circle-fill text-warning pr-1"></i><span>Away</span></li>
                                                    <li class="mb-1"><i class="ri-checkbox-blank-circle-fill text-danger pr-1"></i><span>Do Not Disturb</span></li>
                                                    <li class="mb-1"><i class="ri-checkbox-blank-circle-fill text-light pr-1"></i><span>Offline</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="chat-sidebar-channel scroller pl-3" id='notification_container' @scroll="handleScroll">
                                    <tab-nav :pills="true" :vertical="true" class="iq-chat-ui" id="chat-list-data" :class="notification_opacity">
                                        <h5></h5>
                                        <template v-for="(item,index) in notifications">
                                            <li>
                                                <a @click.prevent='getItem(item)' :href="$router.resolve({ name: 'notifications', params: { id: item.id }}).href">
                                                    <template>
                                                        <div class="d-flex- align-items-center" :class="{'font-weight-bold': !item.seen}">
                                                            <div class="chat-sidebar-name">
                                                                <h6 class="mb-0" :class="{'seen': item.seen}">{{ item.title }}</h6>
                                                                <span v-if="item.message.length > msgMaxLength" v-html="item.message.substring(0, msgMaxLength) + '...'"></span>
                                                                <span v-else v-html="item.message"></span>
                                                            </div>
                                                            <div class="chat-meta float-right text-center mr-1">
                                                                <span class="text-nowrap px-1"><i class="ri-calendar-line"></i></span> 
                                                                <span class="text-nowrap">{{ dateFormat(item.event_time, 'YYYY-MM-DD') }}</span> 

                                                                <span class="text-nowrap px-1"><i class="ri-time-line"></i></span> 
                                                                <span class="text-nowrap">{{ dateFormat(item.event_time, 'HH:mm:ss') }}</span>
                                                            </div>
                                                        </div> 
                                                    </template>
                                                </a>
                                            </li>
                                        </template>
                                    </tab-nav>
                                    <div class="text-center">
                                        <b-spinner style="width: 2rem; height: 2rem;" label="Large Spinner" type="grow" v-if="loadOtherNotification"></b-spinner>
                                    </div>
                                </div>
                            </b-col>
                            <div v-show="!isLoad" class="col-lg-9 chat-data p-0 chat-data-right">
                                <tab-content id="v-pills-tabContent">
                                    <tab-content-item :active="true" id="v-pills-default" aria-labelled-by="v-pills-default">
                                        <template>
                                            <ToggleButton :mini-toggle="true" :close-button="false" toggleShow="#chat-user-detail-popup" mediaClass="chat-user-profile">
                                                <template v-slot:media>
                                                </template>
                                                <template v-slot:body>
                                                </template>
                                            </ToggleButton>
                                            <div class="chat-start empty-box" v-if='!notification'>
                                                <span class="iq-start-icon text-primary"><i class="ri-message-3-line"/></span>
                                            </div>
                                            <div class="chat-start" v-if='notification'>
                                                <b-container>
                                                    <b-row>
                                                        <b-col>
                                                            <div>
                                                                <h4 class="my-2">{{ notification.title }}</h4>
                                                                <p v-html="notification.message"></p>
                                                                
                                                            </div>
                                                            <div v-if="notification.link" class="mt-4 notification-redirect">
                                                                <b-button :href="notification.link" variant="primary iq-waves-effect" size="lg">
                                                                    <span v-if='notification.type == "balance-alert"'>Update Your Plan!</span>
                                                                    <span v-if='notification.type == "integration-alert"'>Update The Integration!</span>
                                                                    <span v-if='notification.type == "checkout_session"'>Link checkout</span>
                                                                </b-button>
                                                            </div>
                                                        </b-col>
                                                    </b-row>
                                                </b-container>
                                            </div>
                                        </template>
                                    </tab-content-item>
                                    <tab-content-item id="chatbox" aria-labelled-by="v-pills-default">
                                        <template>
                                            <div class="chat-head">
                                                <header class="d-flex justify-content-between align-items-center bg-white pt-3 pr-3 pb-3">
                                                    <div class="chat-header-icons d-flex">
                                                        <a class="iq-bg-primary iq-waves-effect mr-1 chat-icon-phone"><i class="ri-phone-line mr-0" /></a>
                                                        <a class="iq-bg-primary iq-waves-effect mr-1 chat-icon-video"><i class="ri-vidicon-line  mr-0" /></a>
                                                        <a class="iq-bg-primary iq-waves-effect mr-1 chat-icon-delete"><i class="ri-delete-bin-line  mr-0" /></a>
                                                        <b-dropdown id="dropdownMenuButton2" right variant="none iq-bg-primary iq-waves-effect remove-toggle">
                                                            <template v-slot:button-content>
                                                                <i class="ri-more-2-line mr-0" />
                                                            </template>
                                                            <b-dropdown-item href="#"><i class="fa fa-thumb-tack mr-0" aria-hidden="true"></i> Pin to top</b-dropdown-item>
                                                            <b-dropdown-item href="#"><i class="fa fa-trash-o mr-0" aria-hidden="true"></i> Delete chat</b-dropdown-item>
                                                            <b-dropdown-item href="#"><i class="fa fa-ban mr-0" aria-hidden="true"></i> Block</b-dropdown-item>
                                                        </b-dropdown>
                                                    </div>
                                                </header>
                                            </div>
                                            <div class="chat-content scroller">
                                                <template v-for="(item,index) in chat">
                                                    <div class="chat" :key="index" v-if="!item.me">
                                                        <div class="chat-user">
                                                            <a class="avatar m-0">
                                                                <img :src="checkUser(item.userId, 'image')" alt="avatar" class="avatar-35 " />
                                                            </a>
                                                            <span class="chat-time mt-1">{{ item.time }}</span>
                                                        </div>
                                                        <div class="chat-detail">
                                                            <div class="chat-message">
                                                                <p>{{ item.text }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="chat chat-left" :key="index" v-else>
                                                        <div class="chat-user">
                                                            <a class="avatar m-0">
                                                                <img :src="checkUser(item.userId,'image')" alt="avatar" class="avatar-35 " />
                                                            </a>
                                                            <span class="chat-time mt-1">{{ item.time }}</span>
                                                        </div>
                                                        <div class="chat-detail">
                                                            <div class="chat-message">
                                                                <p>{{ item.text }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                            <div class="chat-footer p-3 bg-white">
                                                <form class="d-flex align-items-center" action="javascript:void(0);">
                                                    <div class="chat-attagement d-flex">
                                                        <a href="javascript:void(0)"><i class="fa fa-smile-o pr-3" aria-hidden="true"></i></a>
                                                        <a href="javascript:void(0)"><i class="fa fa-paperclip pr-3" aria-hidden="true"></i></a>
                                                    </div>
                                                    <input type="text" class="form-control mr-3" placeholder="Type your message">
                                                    <button type="submit" class="btn btn-primary d-flex align-items-center p-2"><i class="fas fa-paper-plane" aria-hidden="true"></i><span class="d-none d-lg-block ml-1">Send</span></button>
                                                </form>
                                            </div>
                                        </template>
                                    </tab-content-item>
                                </tab-content>
                            </div>
                        </b-row>
                    </div>
                </template>
            </iq-card>
        </b-col>
    </b-row>
</b-container>
</template>

<script>
import {
    sofbox
} from '../../config/pluginInit'
import ToggleButton from '@/components/inboxgeek/chat/ToggleButton'
import api from '@/api/RestClient'
import Loader from '@/components/inboxgeek/Loader'
import moment from 'moment'

const LIMIT = 10;
const OFFSET = 0;
export default {
    name: 'Notification',
    components: {
        ToggleButton,
        Loader,
    },
    mounted() {
        sofbox.index()
        sofbox.ripple()
        let id = this.$route.params.id;
        this.getNotifications();
    },
    computed: {
    },
    data() {
        return {
            id: '',
            search: '',
            user: [],
            chat: [],
            notifications: [],
            notification: null,
            publicChannel: [{
                title: 'lorem',
                message: 'exemple'
            },],
            isLoad: true,
            loader_class: 'loader_bloc',
            notification_opacity: 'notification_opacity_normal',
            loadOtherNotification: false,
            limit: LIMIT,
            offset: OFFSET,
            msgMaxLength: 40,
            lastPage: 1,
            nextPageUrl: null
        }
    },
    methods: {
        getNotifications(params = {}) {
            api.notificationEvents.list(params)
                .then(result => {
                    let response = result;
                    if (response.success) {

                        let notifications = response.data.data;
                        // this.notifications = notifications;

                        notifications.forEach(notification => {
                            let _notification = _.find(this.notifications, (o) => { return o.id == notification.id })
                            if(!_notification) {
                                this.notifications.push(notification);
                            }
                        });

                        this.lastPage = response.data.current_page;
                        this.nextPageUrl = response.data.next_page_url;
                    }
                }).finally(data => {
                    this.isLoad = false;
                    this.loader_class = 'loader_bloc_margin_null';
                    this.loadOtherNotification = false;
                    this.notification_opacity = 'notification_opacity_normal';
                });
        },
        changeSeendStatus(id) {
            api.notificationEvents.put(id, {seen: 1})
                .then(response => {
                    if (response.success) {
                        const index = this.notifications.findIndex((item) => item.id === response.data.id);

                        if (index !== -1) {
                            const itemUpdated = { ...this.notifications[index], ...response.data }
                            this.$set(this.notifications, index, itemUpdated);
                        }
                        
                        this.$root.$emit('refresh:notificationEvents')
                    }
                })
        },
        getItem(item) {
            this.notification = item;
            if (!this.$store.getters['Auth/isAdmin']) {
                this.changeSeendStatus(item.id);
            }
        },
        notifcationBar(close = false) {
            let side = $(".notification-list-side");
            if (close)
                side.removeClass('show');
            else
                side.toggleClass('show');
        },
        handleScroll(event) {
            event.preventDefault();
            const offsetHeight = document.getElementById("notification_container").offsetHeight;
            const scrollTop = document.getElementById("notification_container").scrollTop;
            const heightContainer = document.getElementById("notification_container").scrollHeight;

            if ((this.nextPageUrl) && ((offsetHeight + scrollTop) + 1 >= heightContainer)) {
                this.offset = this.offset + LIMIT;
                this.loadOtherNotification = true;
                this.notification_opacity = 'notification_opacity';
                let params = {page: this.lastPage+1};
                this.getNotifications(params);
            }
            
        },
        dateFormat(date, format = 'YYYY-MM-DD HH:mm:ss') {
            const d = new Date(date);
            return moment(d).format(format);
        }
    },
}
</script>

<style>
.remove-toggle::after {
    content: unset;
}

.iq-chat-ui li a {
    border-bottom: 1px solid #f1f2f1 !important;
}

.chat-data {
    background: #e6f2ff;
}

.chat-start {
    background: #fff;
    height: auto;
    border-radius: 2px !important;
    margin: 10px !important;
}

.notification-title {
    /*font-weight: 500;*/
    margin-top: 10px;
}

.notification-redirect {
    text-align: center;
    padding: 3%;
}

.seen {
    font-weight: 500;
}

.filter {
    position: absolute;
    right: 25px;
    font-weight: 300;
}

.empty-box {
    background: none;
    height: calc(100vh - 10rem);
}

.notification-list-side-toggle-button {
    display: none;
}

@media (max-width: 991px) {
    .chat-search {
        margin-top: 50px;
    }

    button.close-btn-res {
        top: 0px
    }

    .d-flex.align-items-center .sidebar-toggle {
        display: none !important;
    }

    .notification-list-side-toggle-button {
        display: block;
    }
}
.loader_bloc {
    display: block;
    margin: 20% auto;
}

.loader_bloc_margin_null {
    display: block;
    margin: 0% auto;
}

.notification_opacity {
    opacity: 0.2;
}

.notification_opacity_normal {
    opacity: 1;
}
</style>
