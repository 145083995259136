<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card class="">
                    <template v-slot:headerTitle>
                        <b-row class="mt-4">
                            <b-col md="4">
                                <b-form-group label="From" label-for="from">
                                    <b-form-input id="from" type="date" v-model="dates.from" :max="dates.to"
                                        :value="dates.from"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="To" label-for="to">
                                    <b-form-input id="to" type="date" v-model="dates.to" :min="dates.from" :max="dates.max"
                                        :value="dates.to"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="To" label-for="submit">
                                    <b-button class="action" variant="primary" :disabled="submitload" @click="onSubmit">
                                        <b-spinner small type="grow" v-if="submitload"></b-spinner>
                                        Submit
                                    </b-button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
                <iq-card>
                    <template v-slot:headerAction class="mt-3">
                        <b-form v-if="is_manager" class="mx-3">
                            <AccountsMultiselect @onSelect="onSelectAccount" />
                        </b-form>
                    </template>

                    <template v-slot:body>
                        <b-row style="justify-content: end;">
                            <b-col lg="12">
                                <div class="container-fluid">
                                    <b-row style="justify-content: end; padding:10px 0px;">
                                        <b-col lg="11" md="11" v-show="isFilter" class="alert-secondary">
                                            <FiltersAccountManagement ref="account-management-filters" @onFilter="onFilter" :platforms_array="platforms_co"></FiltersAccountManagement>
                                        </b-col>
                                        <b-col lg="1" class="mt-2">
                                            <b-button class="float-right" v-b-tooltip.hover title="Filter" variant="primary"
                                                @click="toggleInputsFilter"> <i class="fa fa-filter mx-0"></i> </b-button>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12">
                                <DtAccountManagement ref="dt-reports" :dateRange="dates" @onFinishedFilter="finishedFilter"
                                    @onDisplayReport="onDisplayReport" />
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-row class="charts" v-if="!is_manager && showChart">
            <Ibgmorris :item="charts" ref="ibgmorris">
            </Ibgmorris>
        </b-row>
        <b-row class="adminCharts" v-if="is_manager && loadAdminStats">
            <ApexCharts :params="params" :series="adminEventsChart.series" :categories="adminEventsChart.categories"
                :columnsHide="apexColumnsHide" :entity="entity" ref="ibgapexcharts"></ApexCharts>
        </b-row>

        <!-- Start Add Setting Modal-->
        <b-modal :id="'reports-modal'" :ref="'reports-modal'" size="xl" title="Account Management Reports" centered
            hide-footer class="bg-secondary py-0">
            <TableReport v-bind="{ rows: rows, columns: columns, from: dates.from, to: dates.to, max: dates.max }" />
        </b-modal>
        <!-- END Add Setting Modal-->

    </b-container>
</template>

<script>
import {
    sofbox
} from "@/config/pluginInit";

import AccountsMultiselect from "@/views/children/AccountsMultiselect.vue";
import DtAccountManagement from "./children/DtAccountManagement.vue";

import Spinner1 from '@/components/loaders/spinner1'
import TestFlow from '@/components/inboxgeek/sendflow'
import _ from 'lodash'
import TableReport from './children/TableReport.vue';

import {
    mapGetters
} from 'vuex'
import api from '@/api/RestClient'
import Ibgmorris from '@/components/inboxgeek/IbgMorris'
import ApexCharts from "../LiveReports/children/ApexCharts.vue"

import {
    helper
} from '@/helpers'
import store from '@/store/index'

import FiltersAccountManagement from "./children/FiltersAccountManagement.vue";

export default {
    name: "UiDataTable",
    components: {
        AccountsMultiselect,
        DtAccountManagement,
        Spinner1,
        TestFlow,
        Ibgmorris,
        ApexCharts,
        FiltersAccountManagement,
        TableReport
    },
    mounted() {
        sofbox.index();
        this.initDates();
        this.isManager();
        this.$root.$on('showChart', (item) => {
            this.item = item;
            if (this.is_manager) this.adminGraph(item);
            else this.graph(item);
        })
        this.$root.$on('updatedTable', (item) => {
            this.submitload = false;
        })
        this.$root.$on('onFetchData', (item) => {
            this.showChart = false;
            this.loadAdminStats = false
        })
    },
    methods: {
        isManager() {
            this.is_manager = helper.hasRoles(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_ACCOUNTS_MANAGER']);
        },
        onSelectAccount(accountSelected) {
            let account = -1;

            this.showChart = false;
            this.loadAdminStats = false;

            this.btnAddIsDisabled = false
            if (this.is_manager) {
                if (accountSelected) {
                    account = accountSelected.code;
                    this.$refs['dt-reports'].setAccount(account)
                } else {
                    this.$refs['dt-reports'].setAccount(account)
                }
            }
        },
        onSubmit() {
            this.submitload = true;

            this.showChart = false;
            this.loadAdminStats = false;
            let date = {
                from: this.dates.from,
                to: this.dates.to
            }
            this.$refs['dt-reports'].setDates(date)
        },
        graph(item) {
            this.entity = item;
            this.showChart = false;

            console.log('scrolling');

            let options = {
                "integration_id": item.id,
                "from": this.dates.from,
                "to": this.dates.to,
            }
            api.stats.getOpenersStats(options)
                .then(response => {
                    const data = response.data;
                    var responseData = data.chart;
                    const ONE_DAY = 1;
                    var chars = {
                        title: item.tag_name + ' (Events Sent ' + options['from'] + ' to ' + options['to'] + ')',
                        type: 'line',
                        colors: ['#36A2EB'],
                        xKeys: 'period',
                        yKeys: ['a'],
                        labels: ['Events'],
                    }
                    //this.$data.charts = {};
                    let stat = false;
                    let dates = data.dates;
                    if (dates.length == ONE_DAY) {
                        let _dates = dates;
                        this.xLabels = "hour";
                        stat = true;
                        dates = [];
                        for (let i = 0; i < 24; i++) {
                            let date = _dates[0];
                            dates.push(this.getDateAndTime(date, i));

                        }
                    } else {
                        this.xLabels = "day";
                    }
                    chars.bodyData = this.chartData(dates, responseData, stat);
                    this.$root.$emit('chartDone', item);

                    this.showChart = true;
                    chars.xLabels = this.xLabels;
                    this.charts = chars;

                    console.log('scroll');
                    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" }); 
                    
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    this.progress = false;
                })

        },
        adminChartEventBuildSeries(keys = [], labels = [], statistics = []) {

            let series = [];
            let serie = {};
            let data = [];

            for (let index = 0; index < labels.length; index++) {
                serie.name = labels[index];
                if (statistics.length > 0) {
                    for (let i = 0; i < statistics.length; i++) {
                        data.push(statistics[i][keys[index]]);

                    }
                }
                serie.data = data;
                series.push(serie);
                serie = {};
                data = [];
            }

            return series;
        },
        adminGraph(item) {
            this.entity = item;
            this.showChart = false;
            this.loadAdminStats = false;
            this.apexChartTitle = item.name;
            this.params = {
                title: item.name,
                from: this.dates.from,
                to: this.dates.to,
                integration_id: item.id,
                account_id: item.account_id
            }
            api.stats.getOpenersStatsForAdminByDates(this.params)
                .then(response => {
                    let keys = response.data.keys;
                    let labels = response.data.labels;
                    let statistics = response.data.chart;
                    let dates = _.map(statistics, function (o) {
                        return o.date
                    });
                    this.adminEventsChart.categories = dates;
                    this.adminEventsChart.series = this.adminChartEventBuildSeries(keys, labels, statistics);
                
                

                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.loadAdminStats = true;
                    this.$root.$emit('chartDone', this.item);

                    
                    setTimeout(function(){
                       
                      window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" }); 

                    }, 600);

                })
        },
        chartData(dates, responseData, ONE_DAY) {
            var bodies = [];
            //let responseDatas = ONE_DAY ? responseData : responseData.data;
            let responseDatas = responseData;
            for (let index = 0; index < dates.length; index++) {
                var date = {
                    a: 0,
                    period: dates[index]
                }
                if (responseDatas.length > 0) {
                    responseDatas.forEach(data => {
                        if (ONE_DAY) {
                            if (data.hour == index) {
                                date.period = dates[index]
                                date.a = data.events
                            }
                        } else {
                            if (data.period == dates[index]) {
                                date.period = data.period
                                date.a = data.a
                            }
                        }
                    });

                }

                bodies.push(date)
            }

            return bodies;
        },
        getDateAndTime(date, i) {
            return date + ' ' + (i.toString().length > 1 ? i.toString() : '0' + i.toString()) + ':00:00';
        },
        initDates() {

            var date = helper.dateToYMD(new Date());
            var minDate = helper.dateToYMD(helper.AddOrSubractDays(new Date(), 6, false));

            this.dates.to = date;
            this.dates.from = minDate;
            this.dates.max = date;
        },
        finishedFilter() {
            this.$refs['account-management-filters'].finishFilter();
        },
        onFilter(form) {
            this.$refs['dt-reports'].filterTable(form);
        },
        toggleInputsFilter(event) {
            const parentElement = event.target.parentElement;
            if (this.isFilter) $(parentElement).parents('.row').removeClass('alert-secondary');
            else $(parentElement).parents('.row').addClass('alert-secondary');
            this.isFilter = !this.isFilter
        },
        onDisplayReport(data) {
            if (Array.isArray(data.items) && data.items.length) {
                this.rows = data.items
            }
            this.$root.$emit('bv::show::modal', 'reports-modal');
        },
        async downloadSVG(data) {
            console.log(data);

            console.log(this.$refs);

            if (this.is_manager) {
                console.log(this.$refs['ibgapexcharts'].body())
            } else {
                console.log(this.$refs['ibgmorris']);
            }
        },
        hasRoles(roles = []) {
            return !_.isEmpty(_.filter(this.user.roles, function (r) {
                return roles.includes(r.slug);
            }))
        }
    },
    watch: {
        "organizations": {
            handler(newVal, oldVal) { },
            deep: true
        },
    },
    computed: {
        ...mapGetters({
            admin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            user: 'Auth/user',
            platforms_co: 'platformConfigs',
            client: 'Auth/isClient',
        })
    },
    data() {
        return {
            isFilter: false,
            isAdmin: false,
            columns: [],
            rows: [],
            dates: {
                to: null,
                from: null,
            },
            submitload: false,
            charts: {},
            loadAdminStats: false,
            apexChartTitle: '***',
            item: {},
            adminEventsChart: {
                series: [],
                categories: []
            },
            showChart: false,
            entity: {},
            apexColumnsHide: ['Others', 'Contact Clean', 'Time Limit Reached', 'Daily Limit Reached', 'Contact Already Tagged', 'Events Already Sent', 'Insufficient Balance'],
            is_manager: false
        };
    },
};
</script>

<style>
#myTabContent-5 {
    padding-bottom: 2em !important;
    /* margin: 0 5px; */
}

.actionClass {
    width: 18%;
}

.timeClass,
.createdatClass,
.accountClass {
    width: 10%;
}

textarea.form-control {
    line-height: 25px;
}

.cursor-default {
    cursor: default;
}

.table th,
.table td {
    padding: 0.55rem;
    vertical-align: top;
}

.action {
    width: 100%;
    height: 43.5px;
}

label[for=submit] {
    visibility: hidden;
}
</style>
