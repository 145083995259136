<template>

    <div class="text-center">

      <button v-if="isAdmin && row.item.state === -1" type="button" class="btn iq-bg-success mr-1 btn-sm" v-b-tooltip.hover data-toggle="tooltip"
              title="Mark as paid" @click="markAsPaid(row.item)">
        <i class="fa fa-money-bill m-0"></i>
      </button>

        <button type="button" class="btn iq-bg-primary mr-1 btn-sm" v-b-tooltip.hover data-toggle="tooltip"
            title="Histories" @click="histories(row.item)">
            <i class="fa fa-history m-0"></i>
        </button>
        <button v-if="isAdmin" :disabled="[-1, -3].includes(row.item.state)" type="button"
            class="btn iq-bg-danger mr-1 btn-sm" v-b-tooltip.hover data-toggle="tooltip" title="Refund"
            @click="refund(row.item)">
            <i class="fa fa-undo m-0"></i>
            <b-spinner small label="Small Spinner" type="grow" v-show="row.item.state == -201"></b-spinner>
        </button>
        <button v-if="isAdmin" :disabled="row.item.state == 1" type="button" class="btn iq-bg-success mr-1 btn-sm"
            v-b-tooltip.hover data-toggle="tooltip" title="Edit" @click="update(row.item)">
            <i class="ion-edit m-0"></i>
        </button>
        <button type="button" class="btn iq-bg-primary mr-1 btn-sm" v-b-tooltip.hover data-toggle="tooltip"
            title="Show">
            <router-link :to="{ name: 'invoices.show', params: { id: row.item.id } }"><i
                    class="fa fa-eye m-0"></i></router-link>
        </button>
        <button v-if="row.item.status !== -1 && isAdmin" type="button" class="btn iq-bg-danger mr-1 px-2 btn-sm"
            v-b-tooltip.hover data-toggle="tooltip" title="Delete" @click="remove(row.item)">
            <i class="fa fa-trash mx-0" v-show="true"></i>
        </button>

    </div>

</template>
<script>

import { mapGetters } from 'vuex'
import api from '@/api/RestClient';

export default {
    name: 'DtActionsCell',
    props: ['row'],
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isAccounting: 'Auth/isAccounting',
            isManager: 'Auth/isManager',
            isClient: 'Auth/isClient',
        })
    },
    methods: {
      markAsPaid(item){
        this.$emit('onClick', {item, event: 'markAsPaid'});
      },
        histories(item) {
            this.$emit('onClick', {item, event: 'histories'});
        },
        refund(item) {
            this.$emit('onClick', {item, event: 'refund'});
        },
        update(item) {
            this.$emit('onClick', {item, event: 'update'});
        },
        remove(item) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    api.invoices.delete(item.id).then(response => {
                        let varian = response.success ? 'success' : 'danger';
                        this.toast('response.message', varian, response.message);
                        if (response.success) this.$root.$emit('bv::refresh::table', this.id);
                    }).catch(err => {
                        this.$bvToast.toast(err.response.data.message, {
                            title: 'Error',
                            variant: 'danger',
                        });
                    })
                } else {
                    this.$swal('Cancelled', 'Your file is still intact', 'info')
                }
            })
        },
        handleBtn({row, event, ref}) {
            if(!row) return;

            this.$emit('onClick', { row, event, ref });
        },
        toast(title = "success", variant = "success", message = "Invoice added") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        },
    }
}

</script>