<style>
.organisation-name {
    color: red;
}
</style>
<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12">
                <iq-card body-class="p-0">
                    <template v-slot:body>
                        <div class="iq-edit-list">
                            <tab-nav :pills="true" class="iq-edit-profile d-flex">
                                <tab-nav-items class="col-md-6 p-0" :active="true" href="#personal-information" title="Personal Information" />
                                <tab-nav-items class="col-md-6 p-0" :active="false" href="#chang-pwd" title="Change Password" />
                            </tab-nav>
                        </div>
                    </template>
                </iq-card>
            </b-col>
            <b-col lg="12">
                <div class="iq-edit-list-data">
                    <tab-content id="pills-tabContent-2">
                        
                        <tab-content-item :active="true" id="personal-information">

                            <PersonalInformationCard></PersonalInformationCard>
                            
                        </tab-content-item>
                        
                        <tab-content-item :active="false" id="chang-pwd">

                            <ChangePasswordCard></ChangePasswordCard>
                            
                        </tab-content-item>

                    </tab-content>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { sofbox } from '@/config/pluginInit'
import PersonalInformationCard from './children/PersonalInformationCard';
import ChangePasswordCard from './children/ChangePasswordCard';

export default {
    name: 'ProfileEdit',
    components: {
        PersonalInformationCard,
        ChangePasswordCard
    },
    beforeCreate() {
        sofbox.openLoader();
    },
    mounted() {
        sofbox.index()
    },
    updated() {
    },

    data() {
        return {}
    },
    methods: {
    },
    computed: {}
}
</script>
