export default function (axios) {
  return {
    fetch: () => {
      return axios.get(`/ge-automations`)
        .then(response => response.data);
    },
    get: (automationId) => {
      return axios.get(`/ge-automations/${automationId}`)
        .then(response => response.data);
    },
    post: (data) => {
      return axios.post(`/ge-automations`, data)
        .then(response => response.data)
    },
    put: (automationId, data) => {
      return axios.put(`/ge-automations/${automationId}`, data)
        .then(response => response.data)
    },
    delete: (automationId) => {
      return axios.delete(`/ge-automations/${automationId}`)
        .then(response => response.data);
    },
    update_status: (automationId, data) => {
      return axios.put(`/ge-automations/${automationId}/status`, data)
        .then(response => response.data)
    },
    test_flow: (automationId, email) => {
      return axios.get(`/ge-automations/${automationId}/send-test-flow?email=${email}`)
        .then(response => response.data);
    },
    automation_chart_records: (automationId, from, to) => {
      return axios.get(`ge-automations/${automationId}/records/chart?from=${from}&to=${to}`)
        .then(response => response.data);
    },
    restore: (id) => {
      return axios.post(`/ge-automations/${id}/restore`, {})
        .then(response => response.data);
    },
    bulk: (items, data) => {
      return axios.put(`/ge-automations/bulk`, {items, data})
        .then(response => response.data)
    },
    bulkDelete: (items) => {
      return axios.put(`/ge-automations/bulk/delete`, {items})
        .then(response => response.data)
    },
    resend_records: (id, data) => {
      return axios.post(`/ge-automations/${id}/resends`, data)
        .then(response => response.data)
    },
    stop_resend: (id) => {
      return axios.put(`/ge-automations/resends/${id}/stop`)
        .then(response => response.data)
    },
    fetch_resend_records: (automation_id) => {
      return axios.get(`/ge-automations/${automation_id}/resends`)
        .then(response => response.data)
    },
    fetch_automation_resend_stats: (automation_id, resend_id) => {
      return axios.get(`/ge-automations/${automation_id}/resends/${resend_id}`)
        .then(response => response.data)
    },
    automation_resend_stats_csv: (automation_id, resend_id) => {
      return axios.get(`/ge-automations/${automation_id}/resends/${resend_id}/download`, { responseType: 'blob' })
        .then(response => response.data);
    },
    
     
  }
}
