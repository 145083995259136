<template>
    <b-container fluid>

        <!-- Table title -->
        <b-row class="mb-3">
            <b-col lg="4" class="my-1">
                <h4 class="card-title">Activities List</h4>
            </b-col>
        </b-row>

        <!-- Main table element -->
        <LogsTable :id="id" ref="table" v-bind="{filterOn}"></LogsTable>

    </b-container>
</template>

<script>
import LogsTable from "./LogsTable"
import Spinner1 from '@/components/loaders/spinner1'

export default {
    name: 'DtLists',
    components: {
        Spinner1,
        LogsTable
    },
    data() {
        return {
            id: 'dt-live-reports',
            filterOn: ["description", "user", "method", "organization"],
        }
    },
    computed: {
    },
    mounted() {
    },
    methods: {
    },
    watch: {}
}
</script>

<style>
td.description>div {
    width: 35em;
    display: block;
}
</style>
