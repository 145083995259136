<template>

    <iq-card>
        <template v-slot:headerTitle>
            <h4 class="card-title">Change Password</h4>
        </template>
        <template v-slot:body>
            <div class="view pb-4" style="text-align: justify;">
                <b-form @submit.prevent="resetPassword">
                    <b-form-group>
                        <label for="cpass">Current Password:</label>
                        <b-form-input type="password" v-model="password.current_password" id="cpass" required>
                        </b-form-input>
                        <div class="text-danger" id="password">{{ errors.current_password }}</div>
                    </b-form-group>
                    <b-form-group>
                        <label for="npass">New Password:</label>
                        <b-form-input type="password" v-model="password.new_password" id="npass"
                            required></b-form-input>
                        <div class="text-danger" id="new_password">{{ errors.new_password }}</div>
                    </b-form-group>
                    <b-form-group>
                        <label for="vpass">Verify Password:</label>
                        <b-form-input type="password" v-model="password.verify_password" id="vpass" required>
                        </b-form-input>
                        <div class="text-danger" id="verify_password">{{ errors.verify_password }}</div>
                    </b-form-group>
                    <b-button type="submit" variant="primary" class="float-right">Submit <b-spinner small type="grow"
                            v-show="pass_spinner"></b-spinner>
                    </b-button>
                </b-form>
            </div>


        </template>
    </iq-card>

</template>
<script>

import api from '@/api/RestClient'
import { helper } from '@/helpers'
import { mapGetters } from 'vuex'

    export default {
        name: 'ChangePasswordCard',
        props: {},
        components: {},
        computed: {
            ...mapGetters({
                isAdmin: 'Auth/isAdmin',
                isClient: 'Auth/isClient',
                user: 'Auth/user',
            })
        },
        data() {
            return {
                profileId: null,
                errors: [],
                pass_spinner: false,
                password: {
                    current_password: '',
                    new_password: '',
                    verify_password: '',
                },
            }
        },
        mounted() {
            this.loadProfileData();
        },
        methods: {
            loadProfileData() {
                // this.account = '--';
                if (!this.isAdmin) {
                    this.profileId = this.user.profile_id;
                }
            },
            resetPassword() {
                this.checkPassWordForm();
                if (helper.isEmpty(this.errors)) {
                    this.pass_spinner = true;
                    const data = this.password;
                    api.profiles.reset_password(this.profileId, data).then(response => {
                        if (response.success) {
                            // update user in store
                            this.toast('Success!', 'success', response.message);
                        } else {
                            response.data.map((error) => {
                                this.toast('oops ..', 'danger', error);
                            });
                        }
                    }).catch(err => {
                        this.toast('Oh!', 'danger', 'Something went wrong')
                    })
                        .finally(() => {
                            this.pass_spinner = false;
                        })
                }
            },
            checkPassWordForm() {
                this.resetErrors();
                if (!this.password.current_password) {
                    this.errors.current_password = 'Current password required.';
                }
                if (!this.password.new_password) {
                    this.errors.new_password = 'New password required.';
                }
                if (!this.password.verify_password) {
                    this.errors.verify_password = 'Repeat password required.';
                }
                if (helper.checkStringLegth(this.password.new_password, 6)) {
                    this.errors.new_password = 'Password must be at least 6 characters';
                }
                if (!helper.equal(this.password.new_password, this.password.verify_password)) {
                    this.errors.verify_password = 'Password did not matched';
                }
            },
            resetErrors() {
                this.errors = {}
            },
            toast(title = "success", variant = "success", message = "Account chargerd") {
                this.$bvToast.toast(message, {
                    title: title,
                    variant: variant
                })
            }
        }
    }

</script>