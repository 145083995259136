<template>

    <span>
        {{ moneyDefaultFormat(row.item.total_refund) }} 
        <i class="fa fa-info text-info cursor-pointer-ek" 
        v-if="row.item.refunds.length > 0" 
        v-b-tooltip.html data-toggle="tooltip" 
        :title="refund_note(row.item.refunds)"></i>
    </span>

</template>
<script>

export default {
    name: 'DtInvoiceRefundCell',
    props: ['row'],
    methods: {
        moneyDefaultFormat(amount) {
            return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        refund_note(refunds) {
            if (refunds.length <= 0) return '';
            let ul = '<ol>'
            refunds.forEach(refund => {
                let date = this.formatDate(refund.created_at);
                let note = refund.note + `($${refund.total}, ${date})`
                ul += `<li>${note}</li>`
            });

            ul += '</ol>'

            return ul;
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
    }
}

</script>