<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">Users List</h4>
                    </template>

                    <template v-slot:headerAction>
                        <b-button v-b-modal.modal-add-user variant="primary" @click="add_new_account = true"
                            class="text-right mr-1">Add User</b-button>
                    </template>

                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12">
                                <DtUsers ref="dt-users" @handleEdit="handleEdit" />
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>

        <!-- Start Add User Modal-->
        <b-modal :id="addModal.id" :ref="addModal.id" :title="addModal.title" size="lg" centered hide-footer @hide="">
            <AddUser col=12 @modalHide="closeModal(addModal)"></AddUser>
        </b-modal>
        <!-- END Add User Modal-->

        <!-- Start Edit User Modal-->
        <b-modal :id="editModal.id" :ref="editModal.id" :title="editModal.title" size="lg" centered hide-footer
            @hide="">
            <EditUser col=12 :item="editModal.item" @modalHide="closeModal(editModal)"></EditUser>
        </b-modal>
        <!-- END Edit User Modal-->
    </b-container>
</template>

    
<script>
import { sofbox } from "@/config/pluginInit";
import DtUsers from "./children/DtUsers.vue";
import Spinner1 from '@/components/loaders/spinner1'
import _ from 'lodash'

import { mapGetters } from 'vuex'
import api from '@/api/RestClient'
import { helper } from '@/helpers'
import store from '@/store/index'
import AddUser from './modals/AddUser'
import EditUser from './children/EditUser'

export default {
    name: "UiDataTable",
    components: {
        DtUsers,
        AddUser,
        EditUser,
        Spinner1,
    },
    created() {
        let _url = window.location.href.replace('127.0.0.1', 'localhost')
        if (window.location.host.split(":")[0] == '127.0.0.1') window.location.href = _url
        this.isAdmin = this.admin;
    },
    mounted() {
        sofbox.index();
    },
    data() {
        return {
            isAdmin: false,
            columns: [],
            rows: [],
            dates: {
                to: null,
                from: null,
            },
            accountId: 3,
            submitload: false,
            charts: {},
            addModal: {
                id: 'modal-add-user',
                title: 'Add User',
                item: {}
            },
            editModal: {
                id: 'modal-edit-user',
                title: 'Edit User',
                item: {}
            },
        };
    },
    computed: {
        ...mapGetters({
            admin: 'Auth/isAdmin',
            isAccounting: 'Auth/isAccounting',
            isManager: 'Auth/isManager',
            isClient: 'Auth/isClient',
        })
    },
    methods: {
        closeModalAdd(toastObj) {
            this.$bvToast.toast(toastObj.message, { ..._.omit(toastObj, ['message']) })
            this.$root.$emit('bv::hide::modal', this.addModal.id)
            this.$root.$emit('bv::refresh::table', this.$refs['dt-users'].id)
        },
        closeModal(item) {
            if (item.message) {
                this.$bvToast.toast(item.message, { ..._.omit(item, ['message']) })
            }
            if (item.id) {
                this.$root.$emit('bv::hide::modal', item.id)
            }
            this.$root.$emit('bv::refresh::table', this.$refs['dt-users'].id)
        },
        handleEdit(item) {
            if (!item) {
                return
            }
            this.editModal.title = `Edit integration ${item?.name}`
            this.editModal.item = item
            this.$root.$emit('bv::show::modal', this.editModal.id)
        }
    },
    watch: {},
};
</script>

    
<style>
#myTabContent-5 {
    padding-bottom: 2em !important;
    /* margin: 0 5px; */
}

.actionClass {
    width: 18%;
}

.timeClass,
.createdatClass,
.accountClass {
    width: 10%;
}

textarea.form-control {
    line-height: 25px;
}

.cursor-default {
    cursor: default;
}

.table th,
.table td {
    padding: 0.55rem;
    vertical-align: top;
}

.action {
    width: 100%;
    height: 43.5px;
}
</style>
