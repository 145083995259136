<template>

    <b-container fluid>
        <DataTable v-if="renderDT" :id="id" ref="lists-table" :url="'/lists'"
            v-bind="{ columns: columns, customCells: dtCells, filter_on: ['list_name'], filters }" 
            @onClick="handleAction"
            />
    </b-container>

</template>
<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';

import DataTable from '@/components/inboxgeek/tables/DataTable';
import DtListActionsCell from './table/DtListActionsCell';
import DtListIdCell from './table/DtListIdCell';
import DtListPlatformCell from './table/DtListPlatformCell';
import DtListStatusCell from './table/DtListStatusCell';
import DtListSwitchRefreshCell from './table/DtListSwitchRefreshCell';

export default {
    name: 'ListsTable',
    components: {
        DataTable,
    },
    props: {
        id: {
            type: String,
            default: 'lists-table'
        },
        filters: {
            type: Object,
            default: null
        },
        account : {
          default : null
        }

    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isAccounting: 'Auth/isAccounting',
            isManager: 'Auth/isManager',
            isClient: 'Auth/isClient',
        }),
        columns() {
            let columns = [
                { key: 'list_name', label: 'List name', sortable: true, sortDirection: 'desc', class: 'text-left' },
                { key: 'list_id', label: 'List Id', sortable: true, sortDirection: 'desc', class: 'text-center' },
                { key: 'platform', label: 'Platform', sortable: true, sortDirection: 'desc', class: 'text-left' },
                { key: 'total_contacts', label: 'Contacts', sortable: true, sortDirection: 'desc', class: 'text-center' },
                { key: 'account', label: 'Account', sortable: true, sortDirection: 'desc', class: 'text-center' },
                { key: 'state', label: 'Status', sortable: true, sortDirection: 'desc', class: 'text-center' },
                { key: 'refresh', label: 'Auto Refresh', sortable: true, sortDirection: 'desc', class: 'text-center' },
                // { key: 'month_opens', label: 'Month Opens', sortable: false, class: 'text-center' },
                { key: 'actions', label: 'Actions', sortable: false, class: 'text-center' },
            ];

            columns = _.map(columns, (item) => {
                if (this.isAdmin || this.isAccounting) {
                    return item;
                } else {
                    if (!["account", "month_opens"].includes(item.key)) return item;
                }
            })

            return columns
        }
    },
    data() {
        return {
            renderDT: true,
            dtCells: [
                {
                    key: 'list_id',
                    component: DtListIdCell,
                    event: null,
                },
                {
                    key: 'platform',
                    component: DtListPlatformCell,
                    event: null,
                },
                {
                    key: 'state',
                    component: DtListStatusCell,
                    event: null,
                },
                {
					key: 'refresh',
					component: DtListSwitchRefreshCell,
                    event: null,
				},
                {
                    key: 'actions',
                    component: DtListActionsCell,
                    event: 'onClick',
                },
            ],
        }
    },
    methods: {
        handleAction({item, event, action = null}) {
            if(!item || !event) return;

            if(event == 'show-logs') {
                this.$emit('show-logs', item);
            } else if (event == 'time-logs') {
                this.$emit('time-logs', item);
            } else if (event == 'remove') {
                this.$emit('remove', item);
            } else if (event == 'update-list') {
                this.$emit('update-list', {list: item, action});
            }
        },
        forceRenderTable() {
            this.renderDT = false;

            this.$nextTick(() => {
                this.renderDT = true;
            });
        },
    }
}
</script>