<template>

    <b-container fluid>

        <b-row>
            <b-form-group class="col-md-12" label="Tag: *" label-for="integration-tag">
                <multiselect v-model="tag" :loading="isLoading" tag-placeholder="Create Tag" :allow-empty="true"
                    deselect-label="Can't remove this value" placeholder="Select Tag" label="name" track-by="code"
                    return="code" :options="_tags" :multiple="false" :close-on-select="true" :taggable="true"
                    @tag="addTag" @select="updateValue" :disabled="isDisabled">

                    <template slot="singleLabel" slot-scope="props">
                        <div class="option__desc">

                            <span class="option__title">

                                <span v-if="props.option.type">
                                    <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                                        style="color:#5ad"></i>
                                    <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>
                                </span>
                                <span v-else>
                                    <i class="ri-list-check color-primary" style="color:#5ad"></i>
                                </span>

                                <span style="font-wight:bold;">
                                    {{ props.option.name }}
                                </span>

                                <span style="font-size:11px; color:#888">
                                    {{ props.option.code }}
                                </span>

                            </span>

                        </div>
                    </template>

                    <template slot="option" slot-scope="props">
                        <div class="option__desc">

                            <span class="option__title">

                                <span v-if="props.option.type">

                                    <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                                        style="color:#5ad"></i>
                                    <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                                </span>

                                <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                                <span style="font-wight:bold;"> {{ props.option.name }} </span>

                                <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>


                            </span>

                        </div>

                    </template>

                </multiselect>
            </b-form-group>
        </b-row>



    </b-container>

</template>
<script>

import LimitPerDayInput from "@/components/inboxgeek/fields/integration/LimitPerDayInput";
import RecurrenceInput from "@/components/inboxgeek/fields/integration/RecurrenceInput";

import Multiselect from 'vue-multiselect'

import api from '@/api/RestClient';

export default {
    name: 'EmailOctopusLoIntegrationStep',
    components: {
        Multiselect,
        LimitPerDayInput,
        RecurrenceInput
    },
    props: {
        tags: {
            type: Array,
            default: () => []
        },
        form: {
            type: Object,
            default: () => {}
        }
    },
    mounted() {
        this.getTags();
    },
    data() {
        return {
            isLoading: false,
            tag: null,
            isDisabled: false,
            _tags: []
        }
    },
    methods: {
        // handleUpdateTag(data) {
        //     this.$emit('tag', {tag: data, type: 'tag'})
        // },
        handleTagAdded(tag) {
            this.$emit('tagAdded', tag)
        },
        handleUpdateLimitPerDay(data) {
            this.$emit('limitPerDay', data)
        },
        handleUpdateRecurrence(data) {
            this.$emit('recurrence', data)
        },
        addTag(tag) {
            
            this.isLoading = true;

            let _params = {};
            let platform = this.form.platform.platform.id;
            let platformName = this.form.platform.platform.name.toLowerCase();
            // let listId = this.form.list.list[0].code;
            let listId = (typeof this.form.list.list !== 'undefined' && this.form.list.list.length) ? this.form.list.list[0].code : null;

            _params.platform = platform;
            _params.platform_id = platform;
            _params.entities = 'tags';
            _params.tag = tag;
            _params.list_id = listId;
            _params.platform_name = platformName;

            var title = 'Ooops ...';
            var message = 'An Something went wrong, please try later';
            var success = 'error';
            var tagLabel = 'Tag';

            api.platforms.addTag(_params)
                .then((response) => {
                    var results = 'data' in response.data ? response.data : response;
                    if (response.success) {

                        let tagData = {}

                        tagData = this.getTagAdded(results)

                        this.tag = tagData;
                        this.$emit('tagAdded', tagData);

                        title = 'Success!';
                        message = tagLabel + ' ' + this.tag.name + ' has created successfully';
                        success = 'success';
                        
                        this.updateValue(this.tag);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        message = error.response.data.errors
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        message = error.message;
                    }
                })
                .finally(() => {
                    this.$swal(title, message, success)
                    this.isLoading = false;
                })

        },
        updateValue(data) {

            this.$emit('tag', {tag: data , type:'tag'})
        },
        getTagAdded(result) {
            result = 'data' in result ? result.data : result;
            return { code: result.list_id, name: result.list_id }
        },
        getTags() {
            this.isLoading = true;
            let list = this.form.list.list;
            
            let platform = this.form.platform.platform;
            let params = {
                entities: 'tags',
                platform_id: platform.id,
                platform_name: platform.name.toLowerCase(),
                list_ids: list && list.length ? list[0].code : null
            };
            api.platforms.fetchServices(params).then((response) => { 
                let tags = response.data
                this._tags = tags.map((tag) => {
                            return {
                                'code': tag.list_id,
                                'name': tag.tag,
                                'type': 'tag',
                            }
                        });
            }).catch((error) => {
                let message = error.response.data.message
                this.$swal('Oops!!', message, 'error');
            }).finally(() => { 
                this.isLoading = false;
            })
        },
    },
    computed: {
        tagsFormatted() {

            let tags = this._tags.map((tag) => {
              return {
                code: tag.code,
                name: tag.name,
                type: tag.type ?? 'tag'
              }
            });

            return tags;
        }
    }
}

</script>