<template>
<b-form @submit="onFilter">
    <b-row class="mt-2">
        <b-col md="12" lg="12">
            <h4>Filters</h4>
            <b-row>
                <b-col :md="cols.platform">
                    <b-form-group id="input-group-3" label="Choose platform:" label-for="input-3">
                        <multiselect :loading="isLoading" :disabled="isLoading" tag-placeholder="Manage platform" placeholder="Choose platform" label="name" track-by="code" v-model="form.platform" deselect-label="Can't remove this value" :allow-empty="true" :options="platforms" :multiple="true" :taggable="true">
                        </multiselect>
                    </b-form-group>
                </b-col>
                <b-col :md="cols.create_from">
                    <b-form-group id="input-group-3" label="Create at date from:" label-for="input-3">
                        <b-form-input :max="form.date_to" v-model="form.date_from" type="date" class="mb-2 mr-sm-2 mb-sm-0" placeholder="Created date from"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col :md="cols.create_to">
                    <b-form-group id="input-group-3" label="Created at date to:" label-for="input-3">
                        <b-form-input :min="form.date_from" v-model="form.date_to" type="date" class="mb-2 mr-sm-2 mb-sm-0" placeholder="Created date to"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col :md="cols.daily_cap">
                    <b-form-group id="input-group-3" label="Daily cap:" label-for="input-3">
                        <b-form-input type="number" v-model="form.daily_cap" id="inline-form-input-name" class="mb-2 mr-sm-2 mb-sm-0" placeholder="Daily cap"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col :md="cols.status" class="text-left">
                    <b-form-group id="label" label="Status:" label-for="input-3">
                        <multiselect tag-placeholder="Manage status" placeholder="Choose status" label="name" track-by="code" v-model="form.status" deselect-label="Can't remove this value" :allow-empty="true" :options="status" :multiple="false" :taggable="false">
                        </multiselect>
                    </b-form-group>
                </b-col>
                <b-col md="3" class="text-left" v-if="isAdmin">
                    <b-form-group id="label" label="Account type:" label-for="input-3">
                        <multiselect tag-placeholder="Manage status" placeholder="Choose status" label="name" track-by="code" v-model="form.account_type" deselect-label="Can't remove this value" :allow-empty="true" :options="account_types" :multiple="false" :taggable="false">
                        </multiselect>
                    </b-form-group>
                </b-col>
                <b-col md="3" class="text-left" v-if="isAdmin">
                    <b-form-group id="label" label="Subscription:" label-for="input-3">
                        <multiselect tag-placeholder="Manage Subscription" placeholder="Choose plan" label="name" track-by="code" v-model="form.plans" deselect-label="Can't remove this value" :allow-empty="true" :options="plans" :multiple="true" :taggable="false">
                        </multiselect>
                    </b-form-group>
                </b-col>
                <b-col md="1" class="text-right">
                    <label id="label-btn" class="sr-only" for="inline-form-input-name">Name</label>
                    <b-button type="submit" :disabled="onFilterDisable" variant="primary" style="height: 3.3em; margin-top: 2.3em">
                        <b-spinner small label="Small Spinner" type="grow" v-show="onFilterDisable"></b-spinner> <i class="fa fa-paper-plane m-0" v-show="!onFilterDisable"></i>
                    </b-button>
                </b-col>

            </b-row>
        </b-col>

    </b-row>
</b-form>
</template>

<script>
import api from '@/api/RestClient'
import {
    sofbox
} from '@/config/pluginInit'
import Multiselect from 'vue-multiselect'
import store from '@/store/index'
import {
    helper
} from '@/helpers';
export default {
    name: 'FiltersAccountManagement',
    props: {
        onFilterTable: {
            type: Boolean,
            default: false
        },
        platforms_array: {
            type: Array,
            default: () => []
        }
    },
    components: {
        Multiselect
    },
    mounted() {
        sofbox.index()
        this.fetchPlatforms();

        if(this.isAdmin){
            this.cols.platform = 4;
            this.cols.create_to = 4;
            this.cols.create_from = 4;
            this.cols.daily_cap = 2;
            this.cols.status = 3;
            this.fetchPlans();
        }
    },
    data() {
        return {
            onFilterDisable: false,
            isLoading: true,
            form: {
                status: false,
                daily_cap: null,
                platform: null,
                date_to: null,
                date_from: null,
                account_type: null,
                plans: [],
            },
            platforms: [],
            plans:[],
            status: [{
                    code: 1,
                    name: 'active'
                },
                {
                    code: 0,
                    name: 'inactive'
                }

            ],
            account_types: [
                {
                    code: 3,
                    name: 'test'
                },
                {
                    code: 1,
                    name: 'client'
                },
                 {
                    code: 2,
                    name: 'internal'
                },

            ],
            isAdmin: store.getters['Auth/isAdmin'],
            cols: {
                platform: 3,
                create_to: 2,
                create_from: 2,
                daily_cap: 2,
                status: 2
            }
        }
    },
    methods: {
        onFilter(e) {
            e.preventDefault();
            this.onFilterDisable = true
            this.$emit('onFilter', this.form)
        },
        platformName(name) {
            return helper.platformFormatName(name);
        },
        fetchPlatforms() {
            var data = JSON.parse(JSON.stringify(this.platforms_array))
            data.forEach((plat) => {
                let platform = {};
                platform.name = this.platformName(plat.name);
                platform.code = plat.id;
                this.platforms.push(platform);
            });
            this.isLoading = false;
        },
        finishFilter() {
            this.onFilterDisable = false
        },
        fetchPlans() {
            api.plans.getActive().then((response) => {
                response.data.forEach((plan) => {
                    this.plans.push({
                        name: plan.name,
                        code: plan.id
                    });
                });
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.isLoading = false;
            })
        }
    },
}
</script>
<style>
    .form-border{
        border: 5px solid;
        border-radius: 5px;
        margin-bottom: 30px;
        padding: 10px;
    }
</style>
