<template>

    <b-row>
        <b-form-group label="List *" label-for="list" class="col-md-12">

            <multiselect
                v-model="list"
                tag-placeholder="Empty"
                placeholder="Select list"
                label="name"
                track-by="id"
                :options="lists"
                :multiple="true"
                return="id"
                :allow-empty="true"
                :taggable="true"
                @input="handleSelectList"
                :loading="isLoading" >

                <template slot="option" slot-scope="props">
                    <div class="option__desc">

                        <span class="option__title">

                            <span v-if="props.option.type">

                                <i v-if="props.option.type === 'segment'" class="ri-flashlight-line"
                                    style="color:#5ad"></i>
                                <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                            </span>

                            <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                            <span style="font-wight:bold;"> {{ props.option.name }} </span>

                            <span style="font-size:11px; color:#888"> {{ props.option.id }}</span>

                        </span>

                    </div>

                </template>

            </multiselect>

            <small v-if="errors?.list" class="text-danger">A list should be selected</small>
        </b-form-group>
    </b-row>

</template>
<script>
import Multiselect from 'vue-multiselect'

import api from "@/api";

export default {
    name: 'KlaviyoLoListStep',
    components: {
        Multiselect
    },
    props: ['form','lists'],
    data() {
        return {
            list: null,
            isLoading: false,
            errors:null
        }
    },
    methods: {
        handleSelectList(itemSelected) {
            this.$emit('select',itemSelected.map((list)=>{
                return {
                  type:list.type ?? 'list',
                  code:list.id,
                  name:list.name
                };
          }));
        },
        getLists() {
            this.isLoading = true;
            let platform = this.form.platform.platform;
            let params = {
                entities: 'lists',
                platform_id: platform.id,
                platform_name: platform.name.toLowerCase(),
            };
            api.platforms.fetchServices(params).then((response) => { 
                let lists = response.data
                this.lists = lists.map((list) => {
                            return {
                                'code': list.id,
                                'name': list.name,
                                'type': list.type,
                            }
                        });
            }).catch((error) => {
                let message = error.response.data.message;
                this.$swal('Error Lists', message, 'error');
            }).finally(() => { 
                this.isLoading = false;
            })
        },
    }
}

</script>