<template>

    <b-container fluid>
        <DataTable v-if="renderDT" ref="dt-subscriptions-summary" :url="'/subscriptions_summary'"
            v-bind="{
                columns: columns, 
                customCells: [], 
                filters: filters, 
                organization: accountId, 
                theadTop: theadTop
            }" />
    </b-container>

</template>
<script>

import _ from 'lodash'
import { helper } from '@/helpers'
import DataTable from '@/components/inboxgeek/tables/DataTable'
import { SERVICES } from "@/constantes";

var moment = require('moment');

export default {
    name: 'SubscriptionSummariesTable',
    components: {
        DataTable
    },
    props: {
        accountId: {
            type: Number,
            default: null
        },
        filters: {
            type: Object,
            default: {}
        }
    },
    computed: {
        columns() {
            let columns = [
                { label: 'Organization', key: 'account_name', class: 'text-left', sortable: false, sortDirection: 'desc' },
                {
                    label: 'Event Used', key: 'events_sent', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let loSubscriptionSummary = _.find(item.subscription_summaries, (o) => { return o.service_id == SERVICES.LIVE_OPENS.id });
                        let loServiceBalance = _.find(item.balances, (o) => { return o.service_id == SERVICES.LIVE_OPENS.id });

                        let plan = this.getItemPlan(item, SERVICES.LIVE_OPENS.id);

                        if (!loSubscriptionSummary) {

                            return loServiceBalance && plan ? loServiceBalance.consumption : '--';

                        }

                        return loSubscriptionSummary && plan ? loSubscriptionSummary.consumption : '--';
                    }
                },
                {
                    label: 'Event Balance Remaining', key: 'lo_balances', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let loSubscriptionSummary = _.find(item.subscription_summaries, (o) => { return o.service_id == SERVICES.LIVE_OPENS.id });
                        let loServiceBalance = _.find(item.balances, (o) => { return o.service_id == SERVICES.LIVE_OPENS.id });

                        let plan = this.getItemPlan(item, SERVICES.LIVE_OPENS.id);

                        if (!loSubscriptionSummary) {

                            return loServiceBalance && plan ? loServiceBalance.balance : '--';

                        }

                        return loSubscriptionSummary && plan ? loSubscriptionSummary.balance : '--';
                    }
                },
                {
                    label: 'Overconsumption Balance ', key: 'overconsumption_balance', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let loSubscriptionSummary = _.find(item.subscription_summaries, (o) => { return o.service_id == SERVICES.LIVE_OPENS.id });
                        let loServiceBalance = _.find(item.balances, (o) => { return o.service_id == SERVICES.LIVE_OPENS.id });

                        let plan = this.getItemPlan(item, SERVICES.LIVE_OPENS.id);

                        if (!loSubscriptionSummary) {

                            return loServiceBalance && plan ? (loServiceBalance.balance < 0 ? loServiceBalance.balance : '--') : '--';

                        }

                        return loSubscriptionSummary && plan ? (loSubscriptionSummary.balance < 0 ? loSubscriptionSummary.balance : '--') : '--';
                    }
                },
                {
                    label: 'LO Billing Date', key: 'lo_billing_date', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {

                        let subscriptionSummary = _.find(item.subscription_summaries, (o) => {
                            return o.service_id == SERVICES.LIVE_OPENS.id
                        });

                        let loServiceBalance = _.find(item.balances, (o) => { return o.service_id == SERVICES.LIVE_OPENS.id });

                        let plan = this.getItemPlan(item, SERVICES.LIVE_OPENS.id);

                        if (!subscriptionSummary) {

                            return loServiceBalance && plan ? helper.formatDate(new Date(loServiceBalance.ends_at)) : '--';

                        }

                        return subscriptionSummary && plan ? helper.formatDate(new Date(subscriptionSummary.billing_date)) : '--';
                    }
                },
                {
                    label: 'LiveOpens Plan', key: 'current_lo_sub', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {

                        let plan = this.getItemPlan(item, SERVICES.LIVE_OPENS.id);

                        return plan ? plan?.name : '--';
                    }
                },
                {
                    label: 'Records Captured', key: 'records', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let llSubscriptionSummary = _.find(item.subscription_summaries, (o) => { return o.service_id == SERVICES.LIVE_LEADS.id });
                        let llServiceBalance = _.find(item.balances, (o) => { return o.service_id == SERVICES.LIVE_LEADS.id });

                        let plan = this.getItemPlan(item, SERVICES.LIVE_LEADS.id);

                        if (!llSubscriptionSummary) {

                            return llServiceBalance && plan ? llServiceBalance.consumption : '--';

                        }

                        return llSubscriptionSummary && plan ? llSubscriptionSummary.consumption : '--';

                    }
                },
                {
                    label: 'Records Balance Remaining', key: 'll_balances', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let llSubscriptionSummary = _.find(item.subscription_summaries, (o) => { return o.service_id == SERVICES.LIVE_LEADS.id });
                        let llServiceBalance = _.find(item.balances, (o) => { return o.service_id == SERVICES.LIVE_LEADS.id });

                        let plan = this.getItemPlan(item, SERVICES.LIVE_LEADS.id);

                        if (!llSubscriptionSummary) {

                            return llServiceBalance && plan ? llServiceBalance.balance : '--';

                        }

                        return llSubscriptionSummary && plan ? llSubscriptionSummary.balance : '--';

                    }
                },
                {
                    label: 'LL Billing Date', key: 'll_billing_date', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {

                        let subscriptionSummary = _.find(item.subscription_summaries, (o) => {
                            return o.service_id == SERVICES.LIVE_LEADS.id
                        });

                        let llServiceBalance = _.find(item.balances, (o) => { return o.service_id == SERVICES.LIVE_LEADS.id });

                        let plan = this.getItemPlan(item, SERVICES.LIVE_LEADS.id);

                        if (!subscriptionSummary) {

                            return llServiceBalance && plan ? helper.formatDate(new Date(llServiceBalance.ends_at)) : '--';

                        }

                        return subscriptionSummary && plan ? helper.formatDate(new Date(subscriptionSummary.billing_date)) : '--';
                    }
                },
                {
                    label: 'LiveLeads Plan', key: 'current_ll_sub', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {

                        let plan = this.getItemPlan(item, SERVICES.LIVE_LEADS.id);

                        return plan ? plan?.name : '--';
                    }
                }
            ];

            return columns;
        },
    },
    data() {
        return {
            renderDT: true,
            theadTop: [
                { colspan: 1, variant: null, srOnly: true, title: 'Account' },
                { colspan: 5, variant: 'danger', srOnly: false, title: 'LiveOpens' },
                { colspan: 4, variant: 'primary', srOnly: false, title: 'LiveLeads' },
            ]
        }
    },
    methods: {
        forceRenderTable() {
            this.renderDT = false;
            this.$emit('submitLoad', false)

            this.$nextTick(() => {
                this.renderDT = true;
            });
        },
        getItemPlan(item, serviceId) {
            let subscriptionSummary = _.find(item.subscription_summaries, (o) => {
                return o.service_id == serviceId
            });
            let currentSubscription = _.find(item.subscriptions, (o) => {
                return o.service_id == serviceId
            });

            return subscriptionSummary && subscriptionSummary?.subscription && subscriptionSummary.subscription?.plan
                ? subscriptionSummary.subscription?.plan 
                : (currentSubscription && currentSubscription?.plan ? currentSubscription.plan : null);
        }
    }
}

</script>