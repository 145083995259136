<template>
<b-row>
    <b-form-group label="List *" label-for="list" class="col-md-12">

        <multiselect v-model="list"  tag-placeholder="Add this as new tag" placeholder="Select tag" label="name" track-by="code" :options="lists" :multiple="true" return="code" :allow-empty="true" :taggable="true" @select="handleSelectList" @input="handleSelectList" @remove="handleSelectList" :loading="isLoading" @tag="tagInput">

            <template slot="option" slot-scope="props">
                <div class="option__desc">

                    <span class="option__title">

                        <span v-if="props.option.type">

                            <i v-if="props.option.type == 'segment'" class="ri-flashlight-line" style="color:#5ad"></i>
                            <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                        </span>

                        <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                        <span style="font-wight:bold;"> {{ props.option.name }} </span>

                        <span style="font-size:.6875rem; color:#888"> {{ props.option.code }}</span>

                    </span>

                </div>

            </template>

        </multiselect>

        <p class="text-dark alert alert-warning my-2" style="padding: 16px;">
            Please type into the field the name of your InboxGeek tag that already exists in Omnisend, then press 'ENTER'
        </p>

        <small v-if="errors?.list" class="text-danger">A tag should be selected</small>
    </b-form-group>
</b-row>
</template>

<script>
import Multiselect from 'vue-multiselect'

import api from "@/api";

export default {
    name: 'omnisendLoListStep',
    components: {
        Multiselect
    },
    props: {
        form: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            list: null,
            lists: [],
            isLoading: false,
          errors : {
              list:false
          }
        }
    },
    mounted() {
        this.getLists();
    },
    methods: {
        handleSelectList(itemSelected) {
            this.$emit('select', this.list)
        },
        getLists() {
            this.isLoading = true;
            let platform = this.form.platform.platform;
            let params = {
                entities: 'lists',
                platform_id: platform.id,
                platform_name: platform.name.toLowerCase(),
            };
            api.platforms.fetchServices(params).then((response) => {
                let lists = response.data
                this.lists = lists.map((list) => {
                    return {
                        'code': list.id,
                        'name': list.name,
                        'type': 'list',
                    }
                });
            }).catch((error) => {
                let message = error.response.data.message;
                this.$swal('Error Lists', message, 'error');
            }).finally(() => {
                this.isLoading = false;
            })
        },
        tagInput(tag) {
             this.list = [];
             if (tag) {
                this.isLoading = true;
                let code = tag;
                let platform = this.form.platform.platform;
                let platform_id = platform.id
                api.integrations.checkOmniTag(code, platform_id).then(response => {
                    let total = response.data.length;
                    if (total == 0) {
                        let message = 'Tag ' + tag + ' doesn\'t exist'
                        this.$swal('Error tag', message, 'error')
                    } else {
                        this.list.push({
                            'code': tag,
                            'name': tag,
                            'type': 'list'
                        });
                        this.$emit('select', this.list)
                    }
                }).catch((error) => {
                    console.log(error);
                    let message = error;
                    let error_data = error.response.data.message;
                    if(error_data) message = error_data.message
                    this.$bvToast.toast(message, {
                        title: 'Error',
                        variant: 'danger'
                    });
                }).finally(() => {
                   this.isLoading = false;
                })
            }
        },
    }
}
</script>
