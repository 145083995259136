<template>
    <b-col class=" w-100 p-4 rounded" style="background: #c7e0f42b">
        <form action="#" @submit="submit" method="post" id="sendflow">

          <label for="validationDefaultUsername">Enter an email address below to test your integration.</label>
            <div class="row" id="email-inputs">
                <div class="col-12 p-2">

                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroupPrepend2">@</span>
                        </div>
                        <input id="testEmailFlow" type="email" class="form-control" v-model="email" placeholder="Email"
                            aria-describedby="inputGroupPrepend2" required>

                    </div>
                </div>

              <div class="col-12 p-2">
                <button class="btn btn-primary w-100" type="submit" style="padding: 10px" variant="primary" :disabled="send">
                  <svg
                      v-if="!send" viewBox="0 0 24 24" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"fill="#ffffff" width="25px"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g class="st0" id="grid_system"></g> <g id="_icons"> <path d="M5.7,10.8c-0.1,1,0.3,1.9,0.9,2.6l3.2,3.2c0.6,0.6,1.4,1,2.3,1c0.9,0,1.7-0.3,2.3-1l6.4-6.4c0.6-0.6,1-1.4,1-2.3 c0-0.9-0.3-1.7-1-2.3l-3.2-3.2c-0.7-0.7-1.6-1-2.6-0.9c-0.8,0.1-1.5,0.4-2,0.9L6.7,8.8C6.1,9.3,5.8,10.1,5.7,10.8 C5.7,10.8,5.7,10.8,5.7,10.8C5.7,10.8,5.7,10.8,5.7,10.8C5.7,10.8,5.7,10.8,5.7,10.8z M13.9,4.4l-0.5,4c-0.1,0.5-0.4,0.8-0.9,0.9 l-4,0.5L13.9,4.4z M12.8,11.2c1.4-0.2,2.5-1.2,2.6-2.6L16,3.7c0.1,0,0.1,0.1,0.2,0.1L19.4,7c0.5,0.5,0.5,1.3,0,1.8L13,15.2 c-0.5,0.5-1.3,0.5-1.8,0L8.1,12C8,11.9,8,11.8,7.9,11.8L12.8,11.2z"></path> <path d="M3,19c0.3,0,0.5-0.1,0.7-0.3l3-3c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-3,3c-0.4,0.4-0.4,1,0,1.4C2.5,18.9,2.7,19,3,19z"></path> <path d="M7.7,19.7l1-1c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-1,1c-0.4,0.4-0.4,1,0,1.4C6.5,19.9,6.7,20,7,20S7.5,19.9,7.7,19.7z"></path> </g> </g>
                  </svg>
                  <b-spinner small type="grow" v-if="send"></b-spinner> {{ sendText }}</button>
              </div>

            </div>

        </form>
    </b-col>
</template>
<script>
import api from '@/api/RestClient'
const BTN_TEXT = "Send Test Event"
export default {
    name: 'TestFlow',
    props: {
        integrationId: Number,
        col: String
    },
    mounted() {
        this.column = this.col;
    },
    data: () => ({
        column: 12,
        sendText: BTN_TEXT,
        send: false,
        email: ""
    }),
    methods: {
        submit(e) {
            e.preventDefault();
            this.send = true;
            this.sendText = 'Sending...';
            this.sendTestFlow(this.integrationId, this.email);
        },
        sendTestFlow(integrationId, email) {
            const that = this
            return api.integrations.sendTestFlow(integrationId, email)
                .then(result => {
                    that.sendText = BTN_TEXT;
                    let title = result.success ? 'Success!' : 'Oops ...';
                    let type = result.success ? 'success' : 'error';
                    that.$swal(title, 'Test event sent', type);
                })
                .catch(function (error) {
                    // handle error
                    if (error.response.data.errors) {
                        let title = 'Oops ...';
                        that.$swal(title, error.response.data.errors.message, "error");
                    }
                })
                .finally(() => {
                    that.send = false;
                    that.sendText = BTN_TEXT;
                });
        }
    }
}
</script>

<style>
.form-row {
    display: block !important;
}
</style>
