<template>
<b-row>
    <b-form-group label="List *" label-for="list" class="col-md-12">

        <multiselect v-model="list" tag-placeholder="Empty" placeholder="Select list" label="name" track-by="id" :options="lists" :multiple="true" return="id" :allow-empty="true" :taggable="true" @select="handleSelectList" @input="handleSelectList" @remove="handleSelectList" :loading="isLoading">

            <template slot="option" slot-scope="props">
                <div class="option__desc">

                    <span class="option__title">

                        <span v-if="props.option.type">

                            <i v-if="props.option.type == 'segment'" class="ri-flashlight-line" style="color:#5ad"></i>
                            <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                        </span>

                        <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                        <span style="font-wight:bold;"> {{ props.option.name }} </span>

                        <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>

                    </span>

                </div>

            </template>

        </multiselect>

        <small id="alertLists" class="text-danger"></small>
    </b-form-group>
</b-row>
</template>

<script>
import Multiselect from 'vue-multiselect'

import api from "@/api";

export default {
    name: 'activecampaignLoListStep',
    components: {
        Multiselect
    },
    props: {
        form: {
            type: Object,
            default: () => {}
        },
      lists:{
          default:[]
      }
    },
    data() {
        return {
            list: null,
            isLoading: false
        }
    },
    mounted() {

    },
    methods: {
        handleSelectList(itemSelected) {
            this.$emit('select', this.list.map((list)=>{
              return {
                code:list.id,
                name:list.name,
                type: list.type ?? 'list'
              };
            }))
        },
    }
}
</script>
