<template>

    <b-row>
        <b-form-group label-for="list" class="col-md-12">

            <ListStepMailchimp 
                :lists="listsFormatted" 
                :oAccountId="platform?.account_id" 
                :platform="platform" 
                @listSelected="listSelected" 
                @segmentSelected="segmentSelected"></ListStepMailchimp>
            
            <small v-if="errors?.list" class="text-danger">A list should be selected</small>
        </b-form-group>
    </b-row>

</template>
<script>


import Multiselect from 'vue-multiselect'
import ListStepMailchimp from '@/components/inboxgeek/modals/IntgrationModal/ListStepMailchimp.vue'


export default {
    name: 'MailchimpLoListStep',
    components: {
        Multiselect,
        ListStepMailchimp
    },
    props: {
        lists: {
            type: Array,
            default: () => []
        },
        form: {
            type: Object,
            default: () => {}
        },
        errors: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            isLoading: false,
            list: null,
            platform: null,
            listLabel: 'List: *',
            listPlaceholder: 'Select Lists',
        }
    },
    mounted() {
        if(this.form.platform && this.form.platform?.platform) {
            this.platform = this.form.platform.platform
            this.platform.code = this.platform.id
        }
    },
    methods: {
        handleSelectList(itemSelected) {
            this.$emit('select', this.list)
        },
        listSelected(list) {

            if (list) {
                this.list = [{
                    'code': list.code,
                    'name': list.name,
                    'type': list.type ?? 'list'
                }];

                this.$emit('select', this.list)

            } else {
                this.list = null;
            }
        },
        segmentSelected(segments, list) {

            if (list) {
                this.isLoading = true;

                this.list = segments.map((segment) => {

                    let list_or_segment_id = segment.code;

                    return {
                        'code':  list_or_segment_id + '-' + list.code,
                        'name': segment.name,
                        'type': 'segment'
                    };
                })

                this.$emit('select', this.list)

            } else {
                this.list = null;
            }

        },
    },
    computed: {
        listsFormatted() {
            let lists = this.lists.map((list) => {
              return {
                code: list.id,
                name: list.name,
                type: list.type ?? 'list'
              }
            });

            return lists;
        }
    }
}

</script>
