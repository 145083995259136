<template>

    <b-container fluid>
        <iq-card v-for="(item, index) in settings" :key="index" v-if="item.value !== 'emails'">
            <template v-slot:headerTitle>
                <h4 class="card-title">
                    <i :class="item.icon"></i> {{ item.name }}
                </h4>
            </template>
            <template v-slot:headerAction>
                <div>
                    <b-button v-b-modal variant="primary" @click="handleAdd($event, item.value)"
                        class="text-right my-auto mb-4" v-b-tooltip.hover title="Add Setting" :disabled="loading">
                        Add {{ item.name }}
                        <i class="fa fa-plus fa-lg mx-2"></i>
                    </b-button>
                </div>
            </template>
            <template v-slot:body>
                <b-row v-if="loading">

                    <iq-card class="col-md-12">
                        <template v-slot:body>
                            <Spinner1 />
                        </template>
                    </iq-card>

                </b-row>
                <div v-else>
                    <b-row v-if="!item.data.length">
                        <b-col md="12">
                            No settings found.
                        </b-col>
                    </b-row>
                    <b-row v-else-if="item.value !== 'emails'">

                        <b-col v-for="(param, j) in item.data" :key="j" v-if="!param.name.startsWith('support_chat')"
                            md="12" :class="{ 'd-flex my-1': item.value !== 'credentials' }">

                            <label :for="param.name">
                                {{ filterCapitalize(param.name) }}
                                <span class="badge cursor-pointer badge-secondary badge-pill"
                                    :class="{ 'iq-bg-success': param.status == 1, 'iq-bg-warning': param.status != 1 }">
                                    {{
                                        param.status
                                            ==
                                            1 ? 'Active' : 'Inactive' }} </span>
                            </label>



                            <div class="ml-auto">

                                <button v-show="!showField(param) && item.value !== 'credentials'"
                                    class="btn btn-sm mx-1 iq-bg-primary float-right" @click="focusField(param)">
                                    <i class="ri-pencil-line"></i>
                                    Edit
                                </button>


                                <span v-show="!showField(param)"
                                    :class="{ 'float-right my-1': item.value !== 'credentials' }"
                                    @click="focusField(param)">

                                    <span v-if="item.value !== 'credentials'" class="text-primary">{{ param.value
                                        }}</span>

                                </span>
                                <b-input-group v-if="editSetting && showField(param)" class="">

                                    <b-form-input :ref="`${param.type}-${param.id}`" v-model="editSetting.value"
                                        @focus="focusField(param)" @blur="blurField(param)">
                                    </b-form-input>

                                    <b-input-group-append>
                                        <button type="button" class="btn btn-primary fa-xl mr-0" @click="saveSetting">
                                            <i class="ri-save-2-fill"></i>
                                            Save
                                        </button>
                                    </b-input-group-append>
                                </b-input-group>

                            </div>
                        </b-col>

                    </b-row>
                    <b-row v-else-if="item.value === 'emails'">
                        <b-col v-for="(param, j) in item.data" :key="j" md="12" class="d-flex">
                            <label :for="param.name">
                                {{ filterCapitalize(param.name) }}
                                <span class="badge cursor-pointer badge-secondary badge-pill"
                                    :class="{ 'iq-bg-success': param.status == 1, 'iq-bg-warning': param.status != 1 }">
                                    {{
                                        param.status
                                            ==
                                            1 ? 'Active' : 'Inactive' }} </span>
                            </label>
                        </b-col>
                    </b-row>

                </div>

            </template>
        </iq-card>
    </b-container>

</template>
<script>
import { helper } from '@/helpers'
import api from '@/api/RestClient'

import Spinner1 from '@/components/loaders/spinner1'

export default {
    name: 'AppSettingCard',
    props: {
        settings: {
            type: Array,
            default: []
        },
        loading: {
            type: Boolean,
            default: true
        }
    },
    components: {
        Spinner1
    },
    data() {
        return {
            editSetting: null,
            onsubmit: false,
            errors: {},
        }
    },
    methods: {
        handleAdd(event, type = null) {
            this.$emit('showAddSettingModal', type)
        },
        blurField(param) {
            console.log('Blur')
        },
        showField(param) {
            return this.editSetting == param
        },
        focusField(param) {
            if (param.type !== 'credentials') {
                param.edit = true
                this.editSetting = param;
            }
        },
        saveSetting(e) {
            e.preventDefault()

            this.onsubmit = true;
            // this.checkForm();

            if (helper.isEmpty(this.errors)) {
                if (this.editSetting.id) {
                    this.updateItem()
                } else {
                    // this.addNewItem()
                }
            } else {
                // Can emit error
                this.onsubmit = false;
            }
        },
        updateItem() {
            let isValidForm = helper.isEmpty(this.errors)
            const id = this.editSetting.id
            let data = { id: this.editSetting.id, name: this.editSetting.name, value: this.editSetting.value }

            if (!isValidForm) {
                this.onsubmit = false;
                return;
            }

            api.settings.put(id, data)
                .then(data => {
                    if ('success' in data) {
                        this.$emit('handleSubmit', { ...data.data, new: false })
                    } else {
                        console.log('error in update settings');
                    }
                })
                .catch(errors => {
                    console.log('Catch error update settings');
                })
                .finally(() => {
                    this.onsubmit = false;
                    // param.edit = false
                    this.editSetting = null;
                })
        },
        filterCapitalize(value, all = null) {
            return helper.customCapitalize(value, all)
        },
    }
}

</script>