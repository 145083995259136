<template>

    <b-container fluid>
        <iq-card body-class="border_plan text-center iq-bg-info" :class="'bg-primary'">

            <template v-if="trialPlan" v-slot:body>
                <span class="font-size-16 text-uppercase" v-if="trialPlan && trialPlan.name !== ''">
                    {{ trialPlan.name }}
                </span>
                
                <h2 class="mb-4 display-3 font-weight-bolder" :class="trialPlan && trialPlan.id == id ? 'text-white' : ''">

                    {{ trialPlan.balance.toLocaleString() }}
                    
                    <small class="font-size-14 d-block mb-4" :class="trialPlan.id == id ? 'text-white' : 'text-muted'">
                        {{ eventNameService }}s
                    </small>
                    
                    <span class="font-size-12 d-block mb-1">
                        ${{ trialPlan.unit_price }}/{{ eventNameService }}
                    </span>
                    
                    <span class="font-size-12 d-block">Valid for one month</span>
                </h2>

                <b-button @click="startTrialVersion($route.params.service)" class="btn btn-primary mt-5"
                    alt="Subscribe now">
                    <span class="spinner-grow spinner-grow-sm" v-show="spinner && plan == trialPlan.id" role="status" aria-hidden="true"></span>
                    <span class="sr-only">Loading...</span>
                    Try for Free
                </b-button>
            </template>
        </iq-card>
    </b-container>

</template>
<script>
import moment from 'moment'
import { mapGetters } from "vuex";
import { SERVICES } from "@/constantes";
import { helper } from '@/helpers'
import api from '@/api/RestClient'

export default {
    name: 'FreePlanCard',
    components: {},
    props: {
        id: {
            type: Number,
            default: null
        },
        plan: {
            type: Number,
            default: null
        },
        trialPlan: {
            type: Object,
            default: null
        },
        spinner: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters('Auth', ['user', 'isAdmin', 'isClient', 'UserSubscriptions', 'lastSubscriptions', 'livePlans']),
        eventNameService() {
            let name = null;
            let serviceID = this.$route.params.service;

            if (serviceID == SERVICES.LIVE_OPENS.id) { name = 'event'; }
            else if (serviceID == SERVICES.LIVE_LEADS.id) { name = 'record'; }
            return helper.capitalizeFirstLetter(name);
        },
    },
    data() {
        return {
            agrementLink: "https://inboxgeek.com/services-agreement/",
        }
    },
    methods: {
        startTrialVersion(serviceID) {
            if (this.isClient) {
                let startTrialDate = moment();
                const endTrialDate = moment().add(30, 'days');

                let html = `<div style='text-align: left; font-size: 17px;'>
                        <p><b>New plan:</b> ${this.trialPlan.name}; 
                            starting ${startTrialDate.format('DD-MM-YYYY')} - ending ${endTrialDate.format('DD-MM-YYYY')}; 
                            $${this.trialPlan.price}
                        </p>
                        <div style='#'>
                            <input type="checkbox" id="services-agreement" name="services-agreement" value="1" disabled checked>
                            By clicking "Yes", you accept
                            <a target="_blank" href="${this.agrementLink}"><small>InboxGeek\'s Services Agreement</small></a>
                        </div>
                    </div>`

                return this.$swal({
                    title: 'Trial Plan Subscription Confirmation',
                    html: html,
                    type: 'info',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Activate Trial Now',
                    cancelButtonText: 'Cancel',
                    showCloseButton: true,
                    showLoaderOnConfirm: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.getTrial(serviceID)
                    }
                })
            }
        },
        getTrial(serviceID) {
            this.isLoad = true;

            return api.subscriptions.freeSubscription(this.user.account_id, serviceID)
                .then(response => {
                    if (response.success) {
                        let lastServicesPlans = this.lastSubscriptions;
                        let livePlans = this.livePlans;

                        this.UserSubscriptions[serviceID] = response.data;
                        
                        livePlans[serviceID] = response.data;
                        lastServicesPlans[serviceID] = response.data;

                        this.$store.dispatch('Auth/updateUserSubscriptions', this.UserSubscriptions);

                        this.$store.dispatch('Auth/updateLivePlans', livePlans);
                        this.$store.dispatch('Auth/updateLastSubscriptions', lastServicesPlans);

                        this.$swal.fire({
                            title: 'Activate Trial',
                            text: response.message,
                            icon: 'success'
                        }).then((confirm) => {
                            this.isLoad = false;
                            this.redirectToBilling();
                        })

                    }
                })
                .catch((error) => {
                    let message = error?.response?.data?.message ?? 'something went wrong!';
                    if (error.response.status == 500) {
                        message = 'something went wrong!'
                    }

                    this.isLoad = false;

                    this.$bvToast.toast(message, {
                        title: 'Error Activate Trial',
                        variant: 'danger'
                    });
                });
        },
        redirectToBilling() {
            setTimeout(() => {
                window.location.href = '/user/billing';
            }, 1500);
        },
    }
}

</script>