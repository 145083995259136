<template>
<b-container fluid>

    <b-col sm="12">
        <iq-card class="p-2">

            <template v-slot:headerTitle>
                <h4 class="card-title">Plans</h4>

            </template>
            <template v-slot:headerAction>
                <services @onSelect="filterTable"></services>
                <b-button v-if="isAdmin" :disabled="addIsDisable" v-b-modal.modal-add-edit-plan variant="primary" class="text-right_ mr-1" @click="modal.plan = null">Add </b-button>
            </template>
            <template v-slot:body>
                
                <!-- Plans Datatable -->
                <PlansTable ref="dt-plans" :service="modalPlanForm().service_id" @onClick="handleTableAction"></PlansTable>

            </template>

        </iq-card>

    </b-col>

    <!-- START CREATE/UPDATE PLAN MODAL -->
    <ModalAddEditPlan ref="add-plan-modal" :item="modal.plan" :service-id="service?.code" @hide="$refs['dt-plans'].forceRenderTable()"></ModalAddEditPlan>
    <!-- END CREATE/UPDATE PLAN MODAL -->

</b-container>
</template>

<script>
import {
    sofbox
} from '../../config/pluginInit'
import Spinner1 from '../../components/loaders/spinner1'
import services from './children/ServicesMultiselect'
import Multiselect from 'vue-multiselect'
import api from '@/api/RestClient';
import { mapGetters } from 'vuex'
import {SERVICES} from "@/constantes";
import PlansTable from "./children/PlansTable"
import ModalAddEditPlan from "./modals/ModalAddEditPlan"
import { helper } from '@/helpers'

export default {
    name: 'LogKw',
    components: {
        Spinner1,
        services,
        Multiselect,
        PlansTable,
        ModalAddEditPlan
    },
    async mounted() {
        sofbox.index()
        this.fetchOptionVariables();

        window.parent = this;
        this.fetchServices();
    },

    data() {
        return {
          existed_options:[],
          loading: {
                modal: false,
                table: false,
            },

            loader: false,
            headerTitle: 'Plan list',
            notOrderable: [],
            plan_id: null,
            add_new_plan: false,
            columnsTable: null,
            intervals: [
                "monthly"
            ],
            services: [],
            service: {
              code : 1,
              name : 'LiveOpens'
            },
            addIsDisable: true,

            // New data 
            modal: {
                plan: null
            }
        }

    },
    methods: {
        edit(plan) {

            this.modal.plan = plan;
            setTimeout(() => {
                this.$root.$emit('bv::show::modal', 'modal-add-edit-plan')
            }, 500)

        },
        destroy(plan) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                type: 'danger',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {

                if (result.value) {

                    api.plans.delete(plan.id).then(response => {
                        let varian = response.success ? 'success' : 'danger';
                        this.toast('Delete user', varian, response.message);
                        if (response.success) {
                            if(this.$refs['dt-plans'] && typeof this.$refs['dt-plans'].forceRenderTable == 'function') {
                                helper.delay(600).then(() => {
                                    this.$refs['dt-plans'].forceRenderTable();
                                })
                            }
                        }
                    }).catch(err => {
                        this.$bvToast.toast(err.response.data.message, {
                            title: 'Error',
                            variant: 'danger',
                        });
                    })
                }
            })
        },
        handleSwitchVisible(data) {

            let itemCode = data.row.id
            let form = {}
            form[data.key] = data.input.checked ? 1 : 0

            let toast = { message: 'Something went wrong!', title: 'Error', variant: 'danger' }

            return api.plans.put(itemCode, form)
                .then(result => {
                    data.input.checked = result.data[data.key];
                    toast = { message: result?.message, title: 'Success', variant: 'success' }
                })
                .catch(err => {
                    this.$bvToast.toast(err.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    });
                })
                .finally(() => {
                    this.$root.$bvToast.toast(toast.message, toast);
                });
        },
        setNotOrderableColumns() {
            let n = this.columns.length
            for (let index = 0; index < n; index++) {
                if (index != this.filter) this.notOrderable.push(index)
            }
        },
        toast(title = "success", variant = "success", message = "Organization updated successfully") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        },
        onServiceSelected() {
            
            this.modalPlanForm().service_id = this.service.code;
        },
        fetchServices() {
            var services = [];
            api.services.list()
                .then(response => {
                    response.data.forEach((serv) => {
                      console.log()
                        let service = {};
                        service.name = serv.name;
                        service.code = serv.id;
                        services.push(service);
                    });
                })
                .catch(err => {
                    this.$bvToast.toast(err.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    });
                })
                .finally(() => {
                    setTimeout(() => {
                        this.services = services;
                        let service = this.services.find(service => service.code === 1);
                        //console.log(service);
                        //this.service = service;
                        this.isLoading = false;
                    }, 1000)
                })
        },
        filterTable(data) {
            
            if (data) {
                this.filter = data.name;

                this.service = {
                    code: data.code,
                    name: data.name
                }


                this.modalPlanForm().service_id = data.code;

                this.addIsDisable = false
            } else {
                this.filter = '';
                this.addIsDisable = true
            }

            if(this.$refs['dt-plans'] && typeof this.$refs['dt-plans'].forceRenderTable == 'function') {
                helper.delay(600).then(() => {
                    this.$refs['dt-plans'].forceRenderTable();
                })
            }
            
        },
        fetchOptionVariables(){
            api.subscriptions.fetchOptionVariables().then((response)=>{

            this.existed_options = response.data;
            })
        },
        handleTableAction({row, event}) {

            if(!row || !event) return;

            if(event == 'edit') {
                this.edit(row.item);
            }

            if(event == 'destroy') {
                this.destroy(row.item);
            }
        },
        modalPlanForm() {
            return typeof this.$refs['add-plan-modal'] !== 'undefined' ? this.$refs['add-plan-modal'].getForm() : {service_id: SERVICES.LIVE_OPENS.id};
        }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
        }),
  },
}

</script>

<style scope>
    .dataTables_filter>label {
        width: 50% !important;
        text-align: left;
    }

    .dataTables_filter {
        text-align: right;
    }

    ul.pagination {
        justify-content: end;
        ;
    }
</style>
