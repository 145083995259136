import api from '@/api/RestClient';
import { COLUMNS_GRID } from "@/constantes";

export default function platform(axios) {
    return {
        labels: {
        },
        customShowAnotherCols(COMP) {
            if (typeof COMP.showCols.apiKey !== 'undefined') COMP.showCols.apiKey = true;
            if (typeof COMP.showCols.accountId !== 'undefined') COMP.showCols.accountId = true;
            if (typeof COMP.cols.apiKeyCol !== 'undefined') COMP.cols.apiKeyCol = COLUMNS_GRID.COL12;

            if (typeof COMP.$refs["step-platform"]?.accountLabel !== 'undefined') COMP.$refs["step-platform"].accountLabel = 'API Secret';
            if (typeof COMP.$refs["step-platform"]?.accountPlaceholder !== 'undefined') COMP.$refs["step-platform"].accountPlaceholder = 'API Secret';

            if (typeof COMP.accountLabel !== 'undefined') COMP.accountLabel = 'Api secret';
            if (typeof COMP.accountPlaceholder !== 'undefined') COMP.accountPlaceholder = 'Api secret';

        }
    }
}