<template>

    <b-container fluid>
        
        <DataTable v-if="renderDT" 
            ref="records-table" 
            :url="'/ge_records'" 
            v-bind="{
                columns: dtColumns,
                customCells: dtCells,
                organization: accountId,
                item: item,
                filters: filters
            }" />

    </b-container>

</template>
<script>
import { GE_RECORD_STATUS_STATES, GE_RECORD_CLEAN_STATES,SERVICES } from "@/constantes";
import RecordStateCell from '@/components/inboxgeek/tables/RecordStateCell';
import DataTable from '@/components/inboxgeek/tables/DataTable.vue'
import moment from 'moment'

export default {
    name: 'RecordsTable',
    components: {
        DataTable,
    },
    props: {
        accountId: {
            type: Number,
            default: null
        },
        item: {
            type: Number,
            default: null
        },
        filters: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            renderDT: true,
            dtColumns: [
                { label: 'Email', key: 'email', class: 'text-left', sortable: true, sortDirection: 'desc' },
                { label: 'Page Title', key: 'title', class: 'text-left', sortable: true, sortDirection: 'desc' },
                { label: 'Source name', key: 'source.name', class: 'text-left', sortable: true, sortDirection: 'desc' },
                { label: 'Website', key: 'source.website', class: 'text-left', sortable: true, sortDirection: 'desc' },
                { label: 'Organization', key: 'source.account.name', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { label: 'Status', key: 'status', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { label: 'Clean', key: 'clean', class: 'text-center', sortable: true, sortDirection: 'desc' },
               
                {
                    label: 'Date', key: 'date', class: 'text-right', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let dt = new Date(value);
                        // return helper.formatDate(dt)
                        if (value) {
                            return moment(value).format('YYYY-MM-DD HH:mm')
                        }
                        return '--'
                    }
                },
            ],
            dtCells: [
                {
                    key: 'status',
                    component: RecordStateCell,
                    event: null,
                    props: {
                        attribute: 'status',
                        states: GE_RECORD_STATUS_STATES,
                    }
                },
                {
                    key: 'clean',
                    component: RecordStateCell,
                    event: null,
                    props: {
                        attribute: 'clean',
                        states: GE_RECORD_CLEAN_STATES,
                    }
                }
            ],
        }
    },
    computed: {
        
    },
    methods: {
        forceRenderTable() {
            this.renderDT = false;
            this.$nextTick(() => {
                this.renderDT = true;
            });
        },
    }
}

</script>