<template>

    <b-container fluid>

        <b-row>

            <b-col md="6">
                <b-form-group label="Custom Event ID:" label-for="event-id">
                    <b-form-input v-model="eventId" name="event-id" placeholder="Enter Name" @input="updateValue">
                    </b-form-input>
                    <small id="alertEventID" class="text-danger d-none"></small>
                </b-form-group>
            </b-col>

            <b-col md="6">
                <b-form-group label="Custom Event Name: *" label-for="event-name">
                    <b-form-input v-model="eventName" name="event-name" placeholder="Enter Name" @input="updateValue">
                    </b-form-input>
                    <small id="alertEventName" class="text-danger d-none"></small>
                </b-form-group>
            </b-col>

        </b-row>

    </b-container>

</template>

<script>
import Multiselect from 'vue-multiselect'
import api from '@/api/RestClient';
import LimitPerDayInput from "@/components/inboxgeek/fields/integration/LimitPerDayInput";

export default {
    name: 'ExpertSenderLoIntegrationStep',
    components: {
        Multiselect,
        LimitPerDayInput
    },
    props: {
        form: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            integration: {
                name: null,
                tag: null,
                limit: null,
                recurrence: null,
                type: 'Metric'
            },
            eventId: null,
            eventName: null,
            events: [],
            isLoading: false,
            custom_event_id: null,
            is_disabled_limit: false,
            custom_event_name: null,
            is_disabled_recurrence: false
        }
    },
    mounted() {
        this.getEvents();
    },
    methods: {
        handleInputEvent() {
            this.integration.tag = {
                code: this.custom_event_id,
                name: this.custom_event_name,
            }
            this.handleSelectEvent();
        },
        handleSelectEvent() {
            this.$emit('tag', this.integration)
        },
        handleInputLimitPerDay() {
            this.$emit('limitPerDay', this.integration)
        },
        handleInputRecurrence() {
            this.$emit('handleRecurrence', this.integration)
        },
        handleInputName() {
            this.$emit('name', this.integration)
        },
        onSwitchLimitPerDay($event) {
            if (!$event.checked) {
                this.integration.limitPerDay = null
                this.integration.limit = null
            } else {
                this.integration.limit = 0
            }
            this.is_disabled_limit = !$event.checked
        },
        getEvents() {
           // ...
        },
        onSwitchRepeatEvent($event) {
            if (!$event.checked) {
                this.form.recurrence = null
                this.integration.recurrence = null
            } else {
                this.form.recurrence = 0
                this.integration.recurrence = true
            }
            this.is_disabled_recurrence = !$event.checked
        },
        postEvent(tag) {
            let list = this.form.list.list;
            this.isLoading = true;
            let platform = this.form.platform.platform;
            let params = {
                entities: 'tags',
                listId: list.code,
                tag: tag,
                platform_id: platform.id,
                platform_name: platform.name.toLowerCase(),
            };
            api.platforms.addTag(params).then((response) => {
                this.integration.tag = {
                    code: Math.random(),
                    name: tag,
                    type: 'metric'
                };
                let message = response.message
                this.$swal('Good', message, 'success');
                this.$emit('tag', this.integration)
            }).catch((error) => {
                let message = error.response.data.message
                this.$swal('Oops!!', message, 'error');
            }).finally(() => {
                this.isLoading = false;
            })
        },
        handleUpdateLimitPerDay(data) {
            this.$emit('limitPerDay', data)
        },
        updateValue() {
            let event = {code: this.eventId, name: this.eventName};
            this.$emit('tag', {tag: event, type: 'custom_event'})
        },

    }
}
</script>
