<template>
  <div>
    <b-modal
        id="activate-confirmation-disabled-integration-modal"
        ref="activate-confirmation-disabled-integration-modal"
        hide-footer
        title="Integration Disabled: Platform Error">
      <b-form @submit="onSubmit" class="px-2 pb-0">

       <p>This integration has been disabled due to a platform error. Please resolve the platform issue before reactivating it.</p>
        <hr>

        <b-row class="text-right px-3 mb-0 pb-0" style="justify-content:end;">

          <b-button
              variant="secondary"
              class="text-right mx-2" @click="$refs['activate-confirmation-disabled-integration-modal'].hide()"> cancel
          </b-button>

          <b-button
              variant="primary"
              type="submit"
              class="text-right"> Activate <b-spinner small type="grow" v-show="modal_loader"></b-spinner>
          </b-button>

        </b-row>

      </b-form>
    </b-modal>
  </div>
</template>


<script>

import Vue from 'vue'
import api from '@/api/RestClient'
import { mapGetters } from 'vuex'
import { helper } from '@/helpers'

const SERVICE_ID = 1;

export default {
  name: 'ActivateConfirmationDisabledIntegrationModal',
  mounted() {
    // this.GetConsumptionData();

    //  this.freeAccount = data.plan.name === 'Free';
  },
  props: ['integration'],

  data() {
    return {

      form: {
        limit: 0
      },
      is_unlimited: false,
      loading: false,
      modal_loader: false,
      plan: null,
      progress: false,
    }
  },
  methods: {

    onSubmit(e) {

      this.modal_loader = true;

      e.preventDefault();

      let showPreAlert = null;
      let checkShowAlert = null;
      let resultData = null;


      checkShowAlert  = !this.isClient ? {state: null} : helper.showAlertOutOfEventOrEmptyBalance(SERVICE_ID);

      if (checkShowAlert.state && checkShowAlert.alert) {
        showPreAlert = true;
      }

      const updateRowState = () => {
        let itemCode = this.integration?.id
        let form = { active: 1 }

        let toast = { message: 'Something went wrong!', title: 'Error', variant: 'danger' }

        if(!itemCode) return ;

        return api.integrations.put(itemCode, form)
            .then(result => {
              resultData = result.data
              toast = { message: result?.message, title: 'Success', variant: 'success' }
              this.modal_loader = false;
            })
            .finally(() => {
              this.$emit('IntegrationActivated',resultData,toast);

              this.$refs['activate-confirmation-disabled-integration-modal'].hide();
            });

      }


      if(showPreAlert && checkShowAlert?.state && checkShowAlert?.alert) {

        return (this.$swal(checkShowAlert.alert))
            .then((result) => {
              updateRowState()
            })

      } else {

        updateRowState()

      }

    }
  },
  computed: {
    ...mapGetters({
      isClient: 'Auth/isClient',
    }),
  }
}
</script>
