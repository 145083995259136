<template>
<b-container fluid>

    <fieldset>
        <div class="form-card text-left">
            <b-row>
                <!-- Tags -->
                <b-form-group class="col-md-12 mb-3" label="Tag: *" label-for="list">
                    <div>
                        <multiselect v-model="selectedTag" :loading="isLoading" tag-placeholder="Create tag" :allow-empty="false" deselect-label="Can't remove this value" placeholder="Select tag" label="tag_name" track-by="tag_id" return="tag_id" :options="tags" :multiple="false" :close-on-select="true" :taggable="true" @tag="postTag" @select="handleSelectTag">

                            <template slot="singleLabel" slot-scope="props">
                                <div class="option__desc">

                                    <span class="option__title">

                                        <span v-if="props.option.type">
                                            <i v-if="props.option.type == 'segment'" class="ri-flashlight-line" style="color:#5ad"></i>
                                            <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>
                                        </span>
                                        <span v-else>
                                            <i class="ri-list-check color-primary" style="color:#5ad"></i>
                                        </span>

                                        <span style="font-wight:bold;"> {{ props.option.tag_name }} </span>

                                        <span style="font-size:11px; color:#888"> {{ props.option.tag_id}}</span>

                                    </span>

                                </div>
                            </template>

                            <template slot="option" slot-scope="props">
                                <div class="option__desc">

                                    <span class="option__title">

                                        <span v-if="props.option.type">

                                            <i v-if="props.option.type == 'segment'" class="ri-flashlight-line" style="color:#5ad"></i>
                                            <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                                        </span>

                                        <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                                        <span style="font-wight:bold;"> {{ props.option.tag_name }} </span>

                                        <span style="font-size:11px; color:#888"> {{ props.option.tag_id }}</span>

                                    </span>

                                </div>

                            </template>

                        </multiselect>
                        <small id="alertTag" class="text-danger d-none"></small>
                    </div>
                </b-form-group>
            </b-row>

        </div>
    </fieldset>
</b-container>
</template>

<script>
import Multiselect from 'vue-multiselect'
import api from '@/api/RestClient';
import LimitPerDayInput from "@/components/inboxgeek/fields/integration/LimitPerDayInput";
import RecurrenceInput from "@/components/inboxgeek/fields/integration/RecurrenceInput";

export default {
    name: 'OntraportLoIntegrationStep',
    components: {
        Multiselect,
        LimitPerDayInput,
        RecurrenceInput
    },
    props: {
        form: {
          type: Object,
          default: () => {
          }
        },
      tags:{
        default: () => {
          return [];
        }
      }
    },
    data() {
        return {
            integration: {
                name: null,
                tag: null,
                limit: null,
                recurrence: null,
                type: 'Tag'
            },
            isLoading: false,
          selectedTag:null,
            is_disabled_limit: false,
        }
    },
    mounted() {
        this.getTags();
    },
    methods: {
      getTags() {

        let list        = this.form.list.list;
        this.isLoading  = true;
        let platform    = this.form.platform.platform;

        let params = {
          entities        : 'tags',
          platform_id     : platform.id,
          listId          : list[0].code,
          platform_name   : platform.name.toLowerCase(),
        };
        api.platforms.fetchServices(params).then((response) => {
          let events = response.data
          this.events = events.map((tag) => {
            return {
              'code': tag.tag_id,
              'name': tag.tag_name,
              'type': 'tag',
            }
          });
        }).catch((error) => {
          let message = error.response.data.message
          this.$swal('Oops!!', message, 'error');
        }).finally(() => {
          this.isLoading = false;
        })
      },
        handleSelectTag() {
          this.integration.tag = {
            name:this.selectedTag.tag_name,
            code:this.selectedTag.tag_id
          };

            this.$emit('tag', this.integration)
        },
        handleUpdateLimitPerDay(data) {
            this.$emit('limitPerDay', data)
        },
        handleUpdateRecurrence(data) {
            this.$emit('recurrence', data)
        },
        handleInputLimitPerDay() {
            this.$emit('limitPerDay', this.integration)
        },
        handleInputRecurrence() {
            this.$emit('handleRecurrence', this.integration)
        },
        handleInputName() {
            this.$emit('name', this.integration)
        },
        onSwitchLimitPerDay($event) {
            if (!$event.checked) {
                this.integration.limitPerDay = null
                this.integration.limit = null
            } else {
                this.integration.limit = 0
            }
            this.is_disabled_limit = !$event.checked
        },
        postTag(tag) {
            let list = this.form.list.list;
            this.isLoading = true;
            let platform = this.form.platform.platform;
            let params = {
                entities: 'tags',
                listId: list[0].code,
                tag: tag,
                platform_id: platform.id,
                platform_name: platform.name.toLowerCase(),
            };
            api.platforms.addTag(params).then((response) => {
                let data = response.data.data
                this.integration.tag = {
                    code: data.tag_id,
                    name: data.tag_name,

                };
                let message = response.message
                this.$swal('Good', message, 'success');
                this.$emit('tag',{tag:this.integration.tag,    type: 'tag'} );
                this.selectedTag =  {
                  tag_id: data.tag_id,
                  tag_name: data.tag_name,

                };
            }).catch((error) => {
                let message = error.response.data.message
                this.$swal('Oops!!', message, 'error');
            }).finally(() => {
                this.isLoading = false;
            })
        },
    }
}
</script>
