<template>
    <b-collapse tag="ul" :class="className" :visible="open" :id="idName" :accordion="accordianName" :ref="'_' + idName">
  
      <li v-for="(item, index) in items" :key="index"
        :class="item.is_heading ? 'iq-menu-title' : activeLink(item) && item.children ? 'active' : activeLink(item) ? 'active' : ''"
        :ref="'_' + item.name">
        <div v-if="isAdmin && item.is_heading_admin 
                    || isClient && item.is_heading_client 
                    || isAccounting && item.is_heading_accounting
                    || isAccountManager && item.is_heading_account_manager">
  
          <i v-if="item.is_heading" class="ri-subtract-line" />
          <span v-if="item.is_heading">{{ item.title }}</span>
  
          <router-link :target="item.attr_target ? item.attr_target : '_self'" :to="getItemLink(item)"
            v-if="!item.is_heading || (isAdmin || (!item.premium || accountAlreadyUsedService(item.premium.code)))"
            :class="`iq-waves-effect ibg-background ${activeLink(item) && item.children ? 'active' : activeLink(item) ? 'active' : ''}`"
            style="background-color: #1e2944;" v-b-toggle="item.name" :ref="item.name">
  
            <i v-if="item.is_icon_class" :class="item.icon" style="color:#008CFF;" />
  
            <template v-else v-html="item.icon">
            </template>
            <span style="color:white">{{ item.title }}</span>
  
            <i v-if="item.premium && (isClient && !accountAlreadyUsedService(item.premium.code))" :id="item.name + '-tooltip'"
              class="iq-arrow-right text-primary" :class="item.premium.icon" />
            <i v-else-if="item.children" class="ri-arrow-right-s-line iq-arrow-right" />
  
  
            <span class="">
              <b-tooltip v-if="item.premium && !isAdmin && !accountAlreadyUsedService(item.premium.code)"
                :target="item.name + '-tooltip'" placement="righttop">
                <router-link class="text-light" :to="{ name: item.premium.link, params: {service: item.premium.code} }">{{ item.premium.text }}</router-link>
              </b-tooltip>
            </span>
  
  
            <small v-html="item.append" :class="item.append_class" />
          </router-link>
  
  
          <List
            v-if="item.children && (isAdmin || (!item.premium || (item.premium && accountAlreadyUsedService(item.premium.code))))"
            :items="item.children"
            :open="item.link.name !== '' && activeLink(item) && item.children ? true : !!(item.link.name !== '' && activeLink(item))"
            :idName="item.name" :accordianName="`sidebar-accordion ${item.class_name}`"
            :className="`iq-submenu ${item.class_name}`" />
  
        </div>
  
      </li>
  
    </b-collapse>
  </template>
  <script>

  // Use Sofbox component
  import List from '@/components/sofbox/menus/ListStyle1'
  import { sofbox } from '@/config/pluginInit'
  import { mapGetters } from 'vuex'
  import api from '@/api/RestClient'
  import {SERVICES} from "@/constantes";
  
  export default {
    name: 'AppList',
    props: {
      items: Array,
      className: { type: String, default: 'iq-menu' },
      horizontal: Boolean,
      open: { type: Boolean, default: false },
      idName: { type: String, default: 'sidebar' },
      accordianName: { type: String, default: 'sidebar' }
    },
    components: {
      List
    },
    computed: {
      ...mapGetters({
        isAdmin: 'Auth/isAdmin',
        isClient: 'Auth/isClient',
        isAccounting: 'Auth/isAccounting',
        isAccountManager: 'Auth/isAccountManager',
        user: 'Auth/user',
        liveOpenersSubscribed: 'Auth/liveOpenersSubscribed',
        liveLeadsSubscribed: 'Auth/liveLeadsSubscribed',
        lastSubscriptions: 'Auth/lastSubscriptions',
        UserSubscriptions: 'Auth/UserSubscriptions'
      })
    },
    mounted() {
      if(this.isClient) {
        this.fetchSubscriptionAlreadyUsed(SERVICES.LIVE_OPENS.id);
        this.fetchSubscriptionAlreadyUsed(SERVICES.LIVE_LEADS.id);
      }
      
    },
    methods: {
      activeLink(item) {
        return sofbox.getActiveLink(item, this.$route.name)
      },
      serviceIsActive(serviceID) {
        if(serviceID == this.services.LIVE_OPENS.id) {
          return this.liveOpenersSubscribed
        }
        if(serviceID == this.services.LIVE_LEADS.id) {
          return this.liveLeadsSubscribed
        }
      },
      accountAlreadyUsedService(serviceID) {
        let serviceAlreadyUsed = (this.UserSubscriptions[serviceID] !== 'undefined' && this.UserSubscriptions[serviceID]) || (this.subscriptionUsed[serviceID] !== 'undefined' && this.subscriptionUsed[serviceID]);
        return this.isAccountManager || serviceAlreadyUsed;
      },
      getItemLink(item) {
        let link = item.link;
  
        if(item.premium && !this.isAdmin && !this.accountAlreadyUsedService(item.premium.code)) {
          return { name: item.premium.link, params: {service: item.premium.code} }
        }
        return link;
      },
      fetchSubscriptionAlreadyUsed(serviceId) {
        return api.subscriptions.accountAlreadyUseService(this.user.account_id, serviceId)
          .then((response) => {
            if(response.success && response.data) {
              this.subscriptionUsed[serviceId] = response.data;
              this.$forceUpdate();
            }
          })
      }
    },
    data() {
      return {
        services: SERVICES,
        subscriptionUsed: {},
      }
    }
  }
  </script>
  
  
  <style scoped>
  .ibg-background:hover {
    background: #283c6c !important;
  }
  
  a[target] {
    font-weight: normal
  }</style>  