<template>
    <div>
        <b-form @submit="onFilter">
            <b-row class="mt-2">
                <b-col md="12" lg="12">
                    <h4>Filters</h4>
                    <b-row class="px-2">

                        <b-col md="4">
                            <b-form-group label="Choose platform:" label-for="platform-name-filter">
                                <multiselect :loading="isLoading" :disabled="isLoading" tag-placeholder="Manage platform" placeholder="Choose platform" label="name" track-by="code" v-model="form.platform" deselect-label="Can't remove this value" :allow-empty="true" :options="platforms" :multiple="true" :taggable="true">
                                </multiselect>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group id="input-group-platform-account-filter" label="Platform account:" label-for="platform-account-filter">
                                <b-form-input v-model="form.platform_account" type="text" class="mb-2 mr-sm-2 mb-sm-0" placeholder="Platform Account"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group label="Daily cap:" label-for="daily-cap-filter">
                                <b-form-input type="number" v-model="form.daily_cap" id="daily-cap-filter" class="mb-2 mr-sm-2 mb-sm-0" placeholder="Daily cap"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="3" class="text-left">
                            <b-form-group id="label" label="Status:" label-for="status-filter" class="d-none">
                                <multiselect tag-placeholder="Manage status" placeholder="Choose status" label="name" track-by="code" v-model="form.status" deselect-label="Can't remove this value" :allow-empty="true" :options="status" :multiple="false" :taggable="false">
                                </multiselect>
                            </b-form-group>
                        </b-col>

                        <b-button class="ml-auto my-auto px-3 mb-2" type="submit" :disabled="onFilterDisable" variant="primary"
                            style="height: 3.3em; margin-top: 2.3em">
                            <b-spinner small label="Small Spinner" type="grow" v-show="onFilterDisable"></b-spinner> <i
                                class="fa fa-paper-plane m-0" v-show="!onFilterDisable"></i>
                        </b-button>

                    </b-row>
                </b-col>

            </b-row>
        </b-form>

        <b-row class="mb-3">
            <b-col lg="12">
                <Columns :cols="dtColumns.options" :selected="dtColumns.selected"  label="Displayed columns" @onSelect="onSelect"></Columns>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { sofbox } from '@/config/pluginInit'
import Columns from "../../LiveReports/children/Columns"
import { helper } from '@/helpers';
import Multiselect from 'vue-multiselect'
import api from '@/api/RestClient'

export default {
    name: 'LoAutomationStatsFilters',
    props: {
        onFilterTable: {
            type: Boolean,
            default: false
        },
        dtColumns: {
            type: Object,
            default() {
                return {
                    options: [],
                    selected: [],
                    defaults: []
                };
            }
        },
        platforms_array: {
            type: Array,
            default: () => []
        }
    },
    components: {
        Columns,
        Multiselect,
    },
    mounted() {
        sofbox.index();
        this.fetchPlatforms();

        console.log( 'Check', this.dtColumns )
    },
    data() {
        return {
            onFilterDisable: false,
            isLoading: true,
            form: {
                platform: null,
                platform_account: null,
                daily_cap: null,
                // status: false,
            },
            platforms: [],
            status: [],
        }
    },
    methods: {
        onFilter(e) {
            e.preventDefault();
            this.onFilterDisable = true
            this.$emit('onFilter', this.form)
        },
        finishFilter() {
            this.onFilterDisable = false
        },
        fetchPlatforms() {
            var data = JSON.parse(JSON.stringify(this.platforms_array))
            data.forEach((plat) => {
                let platform = {};
                platform.name = this.platformName(plat.name);
                platform.code = plat.id;
                this.platforms.push(platform);
            });
            this.isLoading = false;
        },
        platformName(name) {
            return helper.platformFormatName(name);
        },
        onSelect(col) {
            this.$emit('onSelectedColumns', col);
        }
    },
}
</script>
<style>
.form-border {
    border: 5px solid;
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 10px;
}
</style>
