<template>

    <b-container fluid>
        <DataTable v-if="renderDT" 
            :id="id" 
            ref="users-table" :url="'/users'" 
            v-bind="{ columns: dtColumns, customCells: dtCells, sort: 'name' }"
            @onClick="handleBtn" @onSwitch="handleSwitchAttr" />
    </b-container>

</template>

<script>
import DataTable from '@/components/inboxgeek/tables/DataTable'
import DtUserName from '@/views/User/children/DtUserName.vue';
import DtUserRoles from '@/views/User/children/DtUserRoles.vue';
import StatusCell from '@/components/inboxgeek/tables/StatusCell.vue'
import IBGSwitchCell from '@/components/inboxgeek/tables/IBGSwitchCell.vue'
import { helper } from '@/helpers'

import { mapGetters } from 'vuex'
import _ from 'lodash'

import api from '@/api/RestClient';

export default {
    name: 'UsersTable',
    components: {
        DataTable
    },
    props: {
        id: {
            type: String,
            default: 'users-table'
        },
        search: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            renderDT: true,
            ge_source: null,
            dtColumns: [
                { label: 'Name', key: 'name', class: 'text-left', sortable: true, sortDirection: 'desc' },
                { label: 'Email', key: 'email', class: 'text-left', sortable: true, sortDirection: 'desc' },
                {
                    label: 'Created at', key: 'created_at', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let dt = new Date(value);
                        return helper.formatDate(dt)
                    }
                },
                { label: 'Status', key: 'status', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { label: 'Actions', key: 'actions', class: 'text-center', sortable: false, sortDirection: 'desc' },
            ],
            dtCells: [
                {
                    key: 'name',
                    component: DtUserName,
                    event: null,
                },
                {
                    key: 'role',
                    component: DtUserRoles,
                    event: null,
                },
                {
                    key: 'status',
                    component: StatusCell,
                    event: null,
                },
                {
                    key: 'actions',
                    component: IBGSwitchCell,
                    event: 'onSwitch',
                    props: {
                        attribute: 'status',
                    }
                }
            ],
        }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
        }),
        columns() {
            let columns = [];

            return columns;
        }
    },
    mounted() {
        
    },
    methods: {
        forceRenderTable() {
            this.renderDT = false;

            this.$nextTick(() => {
                this.renderDT = true;
            });
        },
        handleBtn(data) {
            this.$emit('handleBtn', data);
        },
        handleSwitchAttr(data) {
            this.$emit('handleSwitchAttr', data)

            // if (data.row.id == this.user.account.user_id) {
            //     data.input.checked = !data.input.checked;
            //     return;
            // }

            // if (!data.input || !data.row || !data.key) {
            //     return
            // }

            // let itemCode = data.row.id
            // let form = { name: data.row.name, email: data.row.email, account: data.row.account }
            // form[data.key] = data.input.checked ? true : false


            // let toast = { message: 'Something went wrong!', title: 'Error', variant: 'danger' }

            // return api.users.put(itemCode, form)
            //     .then(result => {
            //         toast = { message: result?.message, title: 'Success', variant: 'success' }
            //     })
            //     .catch((error) => {
            //         data.input.checked = !data.input.checked;
            //         let errors = [];
            //         toast.message = '';

            //     })
            //     .finally(() => {
            //         this.$root.$bvToast.toast(toast.message, toast);
            //         this.$root.$emit('bv::refresh::table', this.$refs['users-table'].id)
            //     });
        },
        handleEdit(item) {
            this.$emit('handleEdit', item)
        },
    },
}

</script>