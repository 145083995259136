<template>
    <b-container fluid>
        <b-button variant=" iq-bg-info mr-1 mb-1" size="sm" @click="edit(row.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
        <b-button variant=" iq-bg-danger" size="sm" @click="destroy(row.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
    </b-container>
</template>
<script>

export default {
    name: 'PlanActionsCell',
    props: ['row'],
    methods: {
        edit() {
            const ROW = this.row;
            this.$emit('onClick', { row: ROW, event: 'edit' })
        },
        destroy() {
            const ROW = this.row;
            this.$emit('onClick', { row: ROW, event: 'destroy' })
        },
        onClick(event, row, ref) {

            // if (typeof this.$refs[ref] !== 'undefined') {
            //     this.$refs[ref].setState();
            // }

            this.$emit('onClick', { row, event, ref: this.$refs[ref] })
        },
    }
}
</script>