<template>
    <b-container fluid>

        <iq-card>

            <template v-slot:body>

                <div class="">
                    <div class="iq-email-to-list p-3">
                        <div class="d-flex justify-content-between">
                            <ul>
                                <li id="select-all">
                                    <b-form-checkbox v-model="allSelected" :indeterminate="indeterminate"
                                        @change="toggleAllSelected"></b-form-checkbox>
                                </li>
                                
                                <li data-toggle="tooltip" v-b-tooltip.hover top data-placement="top" title="Reload"
                                    data-original-title="Reload">
                                    <a href="#" @click.prevent="reloadTicketsList"><i class="ri-restart-line"></i></a>
                                </li>
                                <li v-if="activeGroupTicket && !['closed', 'trash'].includes(activeGroupTicket.label)"
                                    data-toggle="tooltip" v-b-tooltip.hover top data-placement="top" title="Close"
                                    data-original-title="Close">
                                    <a href="#" @click="bulkCloseTicket($event)"><i
                                            class="ri-close-circle-fill"></i></a>
                                </li>
                                <li v-if="activeGroupTicket && activeGroupTicket.label !== 'trash'"
                                    data-toggle="tooltip" v-b-tooltip.hover top data-placement="top" title="Delete"
                                    data-original-title="Delete">
                                    <a href="#" @click="bulkDeleteTicket($event)"><i class="ri-delete-bin-line"></i></a>
                                </li>
                            </ul>
                            <div class="iq-email-search d-flex">
                                <form class="mr-3 position-relative">
                                    <div class="form-group mb-0">
                                        <input type="text" v-model="search" class="form-control" id="search-input"
                                            aria-describedby="emailHelp" placeholder="Search">
                                        <a class="search-link" href="#"><i class="ri-search-line"></i></a>
                                    </div>
                                </form>
                                <ul v-if="activeGroupTicket && activeGroupTicket.data">
                                    <li class="mr-3">
                                        <a class="font-size-14" href="#" id="navbarDropdown" data-toggle="dropdown">
                                            {{ activeGroupTicket.data.from }}
                                            - {{ activeGroupTicket.data.to }}
                                            of {{ activeGroupTicket.data.total }}
                                        </a>
                                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <a class="dropdown-item" href="#">20 per page</a>
                                            <a class="dropdown-item" href="#">50 per page</a>
                                            <a class="dropdown-item" href="#">100 per page</a>
                                        </div>
                                    </li>
                                    <li data-toggle="tooltip" v-b-tooltip.hover top data-placement="top"
                                        title="Previous" data-original-title="Previous">
                                        <a href="#" :class="{'disabled': !activeGroupTicket.data.prev_page_url}"
                                            @click="prevPage">
                                            <i class="ri-arrow-left-s-line"></i>
                                        </a>
                                    </li>
                                    <li data-toggle="tooltip" v-b-tooltip.hover top data-placement="top" title="Next"
                                        data-original-title="Next">
                                        <a href="#" :class="{'disabled': !activeGroupTicket.data.next_page_url}"
                                            @click=nextPage>
                                            <i class="ri-arrow-right-s-line"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div id="ticket-box-content" class="iq-email-listbox">
                        <div class="tab-content">

                            <div v-if="isLoading" class="tab-pane fade show active">
                                <spinner1></spinner1>
                            </div>

                            <div v-for="(groupTicket, i) in groupTickets" :key="i"
                                class="tab-pane fade tab-pane-ticket-group" :id="`mail-${groupTicket.label}`"
                                role="tabpanel">
                                <ul v-if="groupTicket.data && !isLoading" class="iq-email-sender-list">

                                    <li v-for="(ticket, index) in groupTicket.data.data"
                                        :key="`ticket_${ticket.id}`"
                                        :class="{ 'iq-unread': lastMessageIsUnread(ticket) }">
                                        <div class="d-flex align-self-center">
                                            <div class="iq-email-sender-info">
                                                <div class="iq-checkbox-mail">
                                                    <div class="custom-control custom-checkbox">
                                                        
                                                        <input type="checkbox" class="custom-control-input"
                                                            :id="`select-ticket-${ticket.id}`"
                                                            :checked="isSelected(ticket)"
                                                            @input="toggleSelectTicket($event, ticket)">
                                                            
                                                        <label class="custom-control-label" :for="`select-ticket-${ticket.id}`"></label>

                                                    </div>
                                                </div>

                                                <!-- Is important for Admin -->
                                                <span v-if="isAdmin" class="iq-star-toggle cursor-pointer"
                                                    :class="{'ri-star-fill text-warning': ticket.important, 'ri-star-line text-dark': !ticket.important}"
                                                    @click="markTicket($event, ticket)">
                                                </span>

                                                
                                                <a href="javascript: void(0);" :id="`ticket_subject_detail${ticket.id}`"
                                                    class="iq-email-title" @click="showTicketDetails($event, ticket)">
                                                    <span v-if="ticket" class="d-flex flex-column">
                                                        <span class="h6 mt-2 mb-1">{{ ticket.user.name }}</span>
                                                        <span v-if="ticket && ticket.subject" class="small h6 mt-1">{{ ticket.subject }}</span>
                                                    </span>
                                                </a>
                                            </div>
                                            <div class="iq-email-content">
                                                <a href="javascript: void(0);" class="iq-email-subject bg-white"
                                                    :id="`ticket_content_detail${ticket.id}`"
                                                    v-html="getTicketLastMessage(ticket) ? getTicketLastMessage(ticket).message : '--'"
                                                    @click="showTicketDetails($event, ticket)"></a>
                                                <div class="iq-email-date">{{ getTicketDate(ticket.created_at, 'HH:mm')
                                                    }}</div>
                                            </div>
                                            <ul class="iq-social-media">
                                                <li v-if="isAdmin">
                                                    <a href="#" v-b-tooltip.hover top title="Comment"
                                                        @click="showTicketCommentModal($event, ticket)"><i
                                                            class="ri-file-list-2-line"></i></a>
                                                </li>

                                                <li v-if="[ticketsStatus.TICKET_CLOSE].includes(ticket.status) && isAdmin">
                                                    <a href="#" v-b-tooltip.hover top title="Open"
                                                        @click="openTicket($event, ticket)"><i
                                                            class="ri-mail-open-line"></i></a>
                                                </li>

                                                <li
                                                    v-if="![ticketsStatus.TICKET_CLOSE, ticketsStatus.TICKET_DELETE].includes(ticket.status)">
                                                    <a href="#" v-b-tooltip.hover top title="Close"
                                                        @click="closeTicket($event, ticket)"><i
                                                            class="ri-close-circle-fill"></i></a>
                                                </li>

                                                <li v-if="ticket.status != ticketsStatus.TICKET_DELETE">
                                                    <a href="#" v-b-tooltip.hover top title="Delete"
                                                        @click="deleteTicket($event, ticket)"><i
                                                            class="ri-delete-bin-2-line"></i></a>
                                                </li>

                                                <li v-if="ticket.deleted_at && isAdmin">
                                                    <a href="#" v-b-tooltip.hover top title="Restore"
                                                        @click="restoreTicket($event, ticket)"><i
                                                            class="ri-restart-line"></i></a>
                                                </li>

                                            </ul>
                                        </div>
                                    </li>

                                    <TicketContent :data="groupTicket.data.data" :activeTicket="activeTicket" :activeGroupTicket="activeGroupTicket"></TicketContent>


                                    <div v-if="(!groupTicket.data.data || !groupTicket.data.data.length) && !isLoading">
                                        <div class="alert alert-primary" role="alert">
                                            No Data Found
                                        </div>
                                    </div>


                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

            </template>

            <!-- Ticket Message Modal -->
            <CreateSupportTicketMessageModal v-bind="{ticket: activeTicket}"
                @createTicketMessage="addMessageToTicketList($event)"></CreateSupportTicketMessageModal>

            <!-- Comment  Modal -->
            <AddSupportTicketCommentModal v-bind="{ticket: commentModalTicket ?? activeTicket}"
                @hide="hideTicketCommentModal"></AddSupportTicketCommentModal>



        </iq-card>


    </b-container>
</template>

<script>
import Spinner1 from '@/components/loaders/spinner1'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import api from '@/api/RestClient'
import Pusher from 'pusher-js';
import moment from 'moment'
import CreateSupportTicketMessageModal from '@/components/inboxgeek/modals/Wizards/SupportTicket/CreateSupportTicketMessageModal.vue'
import AddSupportTicketCommentModal from '@/components/inboxgeek/modals/Wizards/SupportTicket/AddSupportTicketCommentModal.vue'
import {SUPPORT_TICKET_STATUS} from "@/constantes";
import TicketContent from './TicketContent';
import TicketMessages from './TicketMessages';

export default {
    name: "TicketBoxContent",
    props: ['groupTickets'],
    components: {
        Spinner1,
        CreateSupportTicketMessageModal,
        AddSupportTicketCommentModal,
        TicketContent,
        TicketMessages
    },
    mounted() {
        this.getTickets();

        this.$root.$on('ibg:active:ticket-group', (item) => {

            const items = document.querySelectorAll('.tab-pane-ticket-group');

            this.resetSearch();

            // Iterate over each item using forEach
            items.forEach( (item, index) => {
                item.classList.remove('active')
                item.classList.remove('show')
            });

            const activeItem = document.getElementById(`mail-${item.label}`);

            if(activeItem) {
                activeItem.classList.add('show')
                activeItem.classList.add('active')
                this.setActiveGroup(item)

                this.selected = []
            }
        })

        this.$root.$on('ibg:create-ticket-message', (item) => {
            this.activeTicket.support_ticket_messages.push(item)
        })

        this.$root.$on('ibg:create-ticket', (item) => {
            this.getTickets();
        })

        this.$root.$on('ibg:mark:ticket', ({$event, ticket}) => {
            this.markTicket($event, ticket)
        })

        this.$root.$on('ibg:close:ticket', ({$event, ticket}) => {
            this.closeTicket($event, ticket)
        })

        this.$root.$on('ibg:delete:ticket', ({$event, ticket}) => {
            this.deleteTicket($event, ticket)
        })

        this.$root.$on('ibg:previous:ticket', ({$event, ticket}) => {
            this.prevTicket($event, ticket)
        })

        this.$root.$on('ibg:next:ticket', ({$event, ticket}) => {
            this.nextTicket($event, ticket)
        })

        this.$root.$on('ibg:show:comment:ticket', ({$event, ticket}) => {
            this.showTicketCommentModal($event, ticket)
        })

        this.$root.$on('ibg:show:message:ticket', ({$event, ticket}) => {
            this.activeTicket = ticket;
        })
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            user: 'Auth/user',
        })
    },
    data() {
        return {
            isLoading: false,
            activeGroupTicket: null,
            inboxTickets: [],
            activeTicket: null,
            commentModalTicket: null,
            allSelected: false,
            indeterminate: false,
            selected: [],
            ticketsStatus: SUPPORT_TICKET_STATUS,
            search: '',
            page: null,
        }
    },
    methods: {
        getTickets() {
            this.isLoading = true;

            api.default.get('/support_tickets')
                .then(response => {

                    this.inboxTickets = response.data.data

                    const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, { cluster: 'eu', });

                    _.forEach(this.inboxTickets, (ticket) => {

                        // const channel = pusher.subscribe('support.ticket.' + room.id);
                        // channel.bind('message',  (data)=> {this.messageArrived(data); });

                    })
                }).finally(() => {
                    this.$root.$emit('ibg:loading-complete-tickets', this.groupTickets[0])
                    this.isLoading = false;
                    
                    
                })
        },
        showTicketDetails($event, ticket) {

            $event.stopPropagation();

            const detailDiv = document.getElementById(`ticket_content_${ticket.id}`);

            if (detailDiv) {
                detailDiv.classList.add('show');
            }

            if(this.isClient && this.lastMessageIsUnread(ticket)) {

                let lastMessage = ticket.support_ticket_messages[ticket.support_ticket_messages.length-1];

                const data = { status: 1 }

                api.default.put(`/support_ticket_messages/${lastMessage.id}`, data)
                    .then((response) => {

                        lastMessage.status = response.data.status

                    })
            }

        },
        hideTicketDetails($event, ticket) {

            const detailDiv = document.getElementById(`ticket_content_${ticket.id}`);


            if (detailDiv) {
                detailDiv.classList.remove('show');
            }
            this.$router.replace({ 'query': null });
        },
        getParentByClass(element, className) {
            const parentElement = element.closest('.' + className);

            if (parentElement) {
                return parentElement;
            }

            return null
        },
        getParentByTagName(element, tagName) {

            let parent = element.parentNode; // Start from the parent node of the given element
            while (parent) {
                if (parent.tagName.toLowerCase() === tagName.toLowerCase()) {
                    return parent;
                }
                parent = parent.parentNode; // Move up to the next parent node
            }
            return null; // If no parent with the specified tag name is found

        },
        getNextSiblingByClass(element, className) {
            let nextSibling = element.nextSibling;
            while (nextSibling) {
                if (nextSibling.tagName === 'DIV' && nextSibling.classList.contains(className)) {
                    return nextSibling;
                }
                nextSibling = nextSibling.nextSibling;
            }
            return null;
        },
        setActiveGroup(item) {

            if(item) {
                this.activeGroupTicket = item;
            }

        },
        getTicketDate(date, format = 'YYYY-MM-DD') {
            
            const myDate = moment(date);

            const currentDate = moment();


            const isSameDay = myDate.isSame(currentDate, 'day');

            if(!isSameDay && format != 'YYYY-MM-DD') {
                format = 'DD MMM '+format;
            }

            const formattedDate = myDate.format(format);

            return formattedDate;

        },
        getTicketMessageDate(date, format = 'YYYY-MM-DD') {
            
            const myDate = moment(date);

            const formattedDate = myDate.format(format);

            return formattedDate;

        },
        truncate(str, strLen, separator = null) {
            let fullStr = str;

            if (fullStr.length <= strLen) return fullStr;

            separator = separator || '...';

            let sepLen = separator.length,
                charsToShow = strLen - sepLen,
                frontChars = Math.ceil(charsToShow / 2),
                backChars = Math.floor(charsToShow / 2);

            return fullStr.substr(0, frontChars) +
                separator +
                fullStr.substr(fullStr.length - backChars);
        },
        addMessageToTicketList(data) {
            console.log('addMessageToTicketList', this.activeTicket, data);
        },
        getTicketLastMessage(ticket) {
            let lastMessage = ticket.support_ticket_messages.length ? ticket.support_ticket_messages[(ticket.support_ticket_messages.length-1)] : null

            return lastMessage;
        },
        lastMessageIsUnread(ticket) {
            let lastMessageIsUnread = false;
            let lasMessage = this.getTicketLastMessage(ticket);

            if ( this.isAdmin ) lastMessageIsUnread = lasMessage && lasMessage.status == 0;
            else if (this.isClient) lastMessageIsUnread = lasMessage && lasMessage.status == 0 && lasMessage.reply;

            return lastMessageIsUnread
        },
        // List actions
        reloadTicketsList() {
            
            if(!this.activeGroupTicket) return;

            this.isLoading = true;

            let url = `/support_tickets?type=${this.activeGroupTicket.typeCode}&search=${this.search}`;

            if(this.page) {
                url = url+`&page=${this.page}`;
            }

            api.default.get(url)
                .then(response => {
                    this.activeGroupTicket.data = response.data;
                    const ticketGroup = this.activeGroupTicket;

                    this.activeGroupTicket = null;
                    this.$nextTick(() => {
                        this.activeGroupTicket = ticketGroup;
                    });

                    this.isLoading = false;

                })
        },
        toggleAllSelected(checked) {

            this.selected = checked ? this.activeGroupTicket.data.data.slice() : []

        },
        isSelected(ticket) {
            return _.find(this.selected, (o) => { return o.id == ticket.id; });
        },
        toggleSelectTicket($event, ticket) {
            $event.stopPropagation();
            $event.preventDefault();

            if(this.isSelected(ticket)) {
                //  Remove from selected list
                const indexToRemove = this.selected.findIndex((o) => o.id == ticket.id);
                
                if(indexToRemove != -1) this.selected.splice(indexToRemove, 1);
            } else {
                //  Add from selected list
                this.selected.push(ticket);
            }
        },
        nextPage($event) {

            if(this.activeGroupTicket.data.next_page_url) {
                this.page = this.activeGroupTicket.data.current_page + 1;
            } else {
                return;
            }

            this.reloadTicketsList();
        },
        prevPage($event) {

            if(this.activeGroupTicket.data.prev_page_url) {
                this.page = this.activeGroupTicket.data.current_page - 1;
            } else {
                return;
            }

            this.reloadTicketsList();
        },

        // Item Actions
        prevTicket($event, ticket) {

            const index = this.activeGroupTicket.data.data.findIndex((item) => item.id === ticket.id);

            const prevIndex = index-1;

            if(index !== -1 && (prevIndex !== -1)) {

                const buttonHide = this.$refs[`hide-ticket-details-${ticket.id}`];

                if (buttonHide) {
                    buttonHide[0].click();

                    const prevTicket = document.getElementById(`ticket_subject_detail${this.activeGroupTicket.data.data[prevIndex].id}`);
                    // ticket_content_detail
                    if(prevTicket) {
                        prevTicket.click();
                    }
                }
            }
        },
        nextTicket($event, ticket) {
            
            const index = this.activeGroupTicket.data.data.findIndex((item) => item.id === ticket.id);
            const nextIndex = index+1;

            if(index !== -1 && (nextIndex < this.activeGroupTicket.data.data.length)) {

                const buttonHide = this.$refs[`hide-ticket-details-${ticket.id}`];

                if (buttonHide) {
                    buttonHide[0].click();

                    const nextTicket = document.getElementById(`ticket_subject_detail${this.activeGroupTicket.data.data[nextIndex].id}`);
                    if(nextTicket) {
                        nextTicket.click();
                    }
                }
            }
        },
        markTicket($event, ticket) {
            $event.stopPropagation();
            $event.preventDefault();

            if(!ticket) return;

            const data = {important: !ticket.important ? 1 : 0 }

            api.supportTickets.put(ticket.id, data)
                .then((response) => {

                    ticket.important = data.important;
                    this.$bvToast.toast(response.message, {
                        title: 'Ticket Updated',
                        variant: 'success'
                    });

                })
        },
        openTicket($event, ticket) {
            $event.stopPropagation();
            $event.preventDefault();

            if(!ticket) return;

            const data = {status: this.ticketsStatus.TICKET_OPEN}

            api.supportTickets.put(ticket.id, data)
                .then((response) => {

                    this.$bvToast.toast(response.message, {
                        title: 'Ticket Open',
                        variant: 'success'
                    });
                    this.$root.$emit('ibg:refresh-group-tickets');
                })
        },
        closeTicket($event, ticket) {
            $event.stopPropagation();
            $event.preventDefault();

            if(!ticket) return;

            const data = {status: this.ticketsStatus.TICKET_CLOSE}

            api.supportTickets.put(ticket.id, data)
                .then((response) => {

                    this.$bvToast.toast(response.message, {
                        title: 'Ticket Closed',
                        variant: 'success'
                    });
                    this.$root.$emit('ibg:refresh-group-tickets');
                })
        },
        bulkCloseTicket($event) {
            $event.preventDefault();

            if(!this.selected.length) { return; }

            const data = {status: this.ticketsStatus.TICKET_CLOSE}
            let items = [];

            this.selected.forEach(item => {
                items.push(item.id);
            });


            api.supportTickets.bulkUpdate(items, data)
                .then((response) => {

                    this.$bvToast.toast(response.message, {
                        title: 'Tickets Closed',
                        variant: 'success'
                    });

                    this.selected = [];
                    this.$root.$emit('ibg:refresh-group-tickets');
                })
                .catch(error => {

                    if (error.response.data) {
                        if (error.response.status == 401) {
                            this.$store.dispatch('Auth/resetUser', false);
                            this.$router.go(this.$router.currentRoute)
                        } else if (error.response.status == 500) {

                            this.$bvToast.toast('something went wrong!', {
                                title: 'Error',
                                variant: 'danger'
                            });
                        } else {
                            this.$bvToast.toast(error.response.data.message, {
                                title: 'Error',
                                variant: 'danger'
                            })
                        }
                    }
                })

        },
        deleteTicket($event, ticket) {
            $event.stopPropagation();
            $event.preventDefault();

            if(!ticket) return;

            this.$swal({
                title: "Delete Ticket",
                text: 'Are you sure you want to delete the ticket?',
                icon: 'warning',
                confirmButtonText: "Yes, please delete",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                showCloseButton: true,
                showLoaderOnConfirm: true,
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-secondary'
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    api.supportTickets.delete(ticket.id)
                        .then((response) => {
                            this.$swal('Ticket Deleted', response.message, 'success')
                            this.$root.$emit('ibg:refresh-group-tickets');
                        })
                }
            })

            
        },
        bulkDeleteTicket($event) {
            $event.preventDefault();

            if(!this.selected.length) { return; }

            let items = [];

            this.selected.forEach(item => {
                items.push(item.id);
            });

            this.$swal({
                title: "Delete Tickets Selected",
                text: 'Are you sure you want to delete tickets selected?',
                icon: 'warning',
                confirmButtonText: "Yes, delete",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                showCloseButton: true,
                showLoaderOnConfirm: true,
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-secondary'
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    api.supportTickets.bulkDelete(items)
                        .then((response) => {

                            this.$bvToast.toast(response.message, {
                                title: 'Tickets Deleted',
                                variant: 'success'
                            });

                            this.selected = [];
                            this.$root.$emit('ibg:refresh-group-tickets');
                        })
                        .catch(error => {

                            if (error.response.data) {
                                if (error.response.status == 401) {
                                    this.$store.dispatch('Auth/resetUser', false);
                                    this.$router.go(this.$router.currentRoute)
                                } else if (error.response.status == 500) {

                                    this.$bvToast.toast('something went wrong!', {
                                        title: 'Error',
                                        variant: 'danger'
                                    });
                                } else {
                                    this.$bvToast.toast(error.response.data.message, {
                                        title: 'Error',
                                        variant: 'danger'
                                    })
                                }
                            }
                        })
                }
            })

            

        },
        restoreTicket($event, ticket) {
            $event.stopPropagation();
            $event.preventDefault();

            if(!ticket) return;


            api.supportTickets.restore(ticket.id)
                .then((response) => {

                    this.$bvToast.toast(response.message, {
                        title: 'Ticket Restore',
                        variant: 'success'
                    });
                    this.$root.$emit('ibg:refresh-group-tickets');
                })
        },
        resetSearch() {
            this.search = '';
        },
        showTicketCommentModal($event, ticket) {

            $event.preventDefault();

            if(!ticket) return;

            this.commentModalTicket = ticket;

            setTimeout(() => {
                this.$root.$emit('bv::show::modal', 'addSupportTicketCommentModal');
            }, 300)

        },
        hideTicketCommentModal(data) {

            if(this.commentModalTicket) {
                this.commentModalTicket = null;
                this.reloadTicketsList();
            }
            
        }
    },
    watch: {
      selected(newValue, oldValue) {

        if (newValue.length === 0) {
          this.indeterminate = false
          this.allSelected = false
        } else if (newValue.length === this.activeGroupTicket.data.data.length) {
          this.indeterminate = false
          this.allSelected = true
        } else {
          this.indeterminate = true
          this.allSelected = false
        }
      },
      search(newValue, oldValue) {
        this.reloadTicketsList();
        },
    }
};
</script>

<style>

.iq-email-listbox .iq-email-sender-list {
    margin: 15px 0 0;
    padding: 0;
    display: inline-block;
    width: 100%;
    overflow: hidden;
}

.iq-email-listbox .iq-email-sender-list>li {
    list-style-type: none;
    position: relative;
    padding: 15px 20px;
    border-top: 1px solid #eef0f4;
    float: left;
    display: inline-block;
    width: 100%;
    transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}


.support-ticket-content .chat-content {
    position: relative;
    /* height: auto; */
    max-height: 50vh;
    /* height: calc(74.5vh - 20rem) !important; */
    text-align: center;
    padding: 1.4rem;
    overflow-y: scroll;
    overflow-x: hidden;
}

.iq-email-listbox .custom-control-label::after {
    top: .75rem !important;
    left: -1.25rem !important;
}

.iq-checkbox-mail .custom-control-label::after {
    top: .25rem !important;
    left: -1.45rem !important;
}

li#select-all .custom-control-label::after {
    top: .8rem !important;
    left: -1.25rem !important;
}

#ticket-box-content .chat-message::after {
    border-color: transparent transparent transparent #f6386b;
}

.chat-message {
    width: 80%;
}
</style>