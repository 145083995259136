import Vue from 'vue'
import VueRouter from 'vue-router'
/* Layouts */
import Layout1 from '../layouts/Layout1'
import Default from '../layouts/BlankLayout'
/* Dashboards View */
import Dashboard from '../views/Dashboards/Dashboard1.vue'
import CookieGenerator from '../views/CookieGenerator.vue'
/* Authentic View */
import AuthLayout1 from '../layouts/AuthLayouts/AuthLayout1'
import AuthSignInLayout from '../layouts/AuthLayouts/AuthSignInLayout'

import SignIn1 from '../views/AuthPages/Default/SignIn1'
import SignUp1 from '../views/AuthPages/Default/SignUp1'
import RecoverPassword1 from '../views/AuthPages/Default/RecoverPassword1'
import ResetPassword from '../views/AuthPages/Default/ResetPassword'
import ConfirmMail1 from '../views/AuthPages/Default/ConfirmMail1'

/* User View */
import ProfileEdit from '../views/User/ProfileEdit'
import editUser from '../views/User/EditUser'
import Billing from '../views/User/Billing'
import AddUser from '../views/User/modals/AddUser'
import Settings from '../views/User/Settings'

/* single page notification */
import Notification from '../views/Notifications'
import AdminNotification from '../views/Notifications/admin'
import AdminChat from '../views/Chat/AdminChat'

/* Store */
import store from '../store/index'

/* Middleware */
import guest from './middleware/guest'
import auth from './middleware/auth'
import admin from './middleware/isAdmin'
import accounting from './middleware/isAccounting'
import manager from './middleware/isManager'
import middlewarePipeline from './middlewarePipeline'

/* Account view */
import AccountList from '../views/Account/AccountList'
import Accounts from '../views/Account/Accounts'
import AddAccount from '../views/Account/AddAccount'
import AccountTabs from '../views/Account/AccountTabs'

/* Checkout */
import Checkout from '../views/Apps/Ecommerce/Checkout'
import BillingPlans from '../views/Billings/Plans'
import PlatformList from '../views/Platforms/PlatformList'


/* Integration view */
import List from '../views/Integration/List' // New clone Integration list
import EventsReportCompose from '../views/Integration/Integration'
import ErrorPage from '../views/Pages/ErrorPage'
import MaintenanceModePage from '../views/Pages/MaintenanceModePage'
import Logs from '../views/logs/activities'

/* Lo Automations view */
import LoAutomationStats from '../views/Integration/LoAutomationStats'


/*  [Service: GE Sources] Source view */
import SourceList from '../views/GetEmails/SourceList'
import SourceRecords from '../views/GetEmails/SourceRecords'
import AutomationList from '../views/GetEmails/AutomationList'


/**Invoice */
import Invoices from '../views/invoices'
import InvoiceEdit from '../views/invoices/edit'
import InvoiceShow from '../views/invoices/show'

/**Invoice */
import InvoiceLog from '../views/InvoiceLog'

/**Invoice */
import ThankYou from '../views/AuthPages/rewardful'

import PaymentResponse from '../views/Pages/PaymentResponse'

/* Referral View */
import Referrals from '../views/Referrals'

/* Referral View */
import ReferralsReport from '../views/ReferralsReport'

/* Accounts Management */
import AccountManagement from '../views/Account/AccountManagementList'

/* Reports */
import RevenueReport from '../views/Reports/Revenues'
import SubscriptionsSummary from '../views/Reports/SubscriptionsSummary'

/* Live Validation */
import LiveValidations from '../views/LiveValidations'

import PlanList from '../views/Plan/PlanList'
import DiscountList from '../views/Discount/DiscountList'

import SupportTicket from '../views/Support/Tickets';
import AdminTicketChat from '../views/Chat/AdminTicketChat';

/* LiveOpens Report */
import LiveOpensEventsReport from '../views/Account/LiveOpensEventsReport'

/* Chat Report */
import ChatReport from '../views/Reports/ChatReport'


/* App */
import AppSettings from '../views/Apps/Settings'
import customBlankLayout from "@/layouts/CustomBlankLayout";

import { helper } from '@/helpers'
import IntegrationLogsViewPage from "@/views/Integration/IntegrationLogsViewPage";

const controller = new AbortController();
const signal = controller.signal;

Vue.use(VueRouter)

const childRoutes = (prop) => [
  {
    path: '',
    name: 'dashboard1.home',
    component: Dashboard,
    meta: {
      middleware: [
        auth
      ],
      title: 'Dashboard',
      page: 'Dashboard'
    }
  },
]
const registerRoutes = (prop) => [
  {
    path: 'signup/:via?',
    name: prop + '.sign-up1',
    component: SignUp1
  }
]
const authChildRoutes = (prop) => [
  {
    path: 'password-reset',
    name: prop + '.password-reset',
    component: RecoverPassword1
  },
  {
    path: 'password-reset/:token',
    name: prop + '.password-reset-token',
    component: ResetPassword
  },
  {
    path: 'confirm-mail1',
    name: prop + '.confirm-mail1',
    component: ConfirmMail1
  },
]

const userChildRoute = (prop) => [
  {
    path: 'edit',
    name: 'profile-edit',
    component: editUser,
    meta: {
      title: 'Edit User',
      page: 'Edit User'
    }
  },
  {
    path: 'profile',
    name: prop + '.edit',
    component: ProfileEdit,
    meta: {
      title: 'Profile',
      page: 'Profile'
    }
  },
  {
    path: 'billing',
    name: prop + '.billing',
    component: Billing,
    meta: {
      title: 'Billing',
      page: 'Billing'
    }
  },
  {
    path: 'add-user',
    name: prop + '.add',
    component: AddUser,
    meta: {
      middleware: [
        auth,
        admin
      ],
      title: 'Add User',
      page: 'Add User'
    }
  },
  {
    path: 'notifications/:id?',
    name: prop + '.notification',
    component: Notification,
    meta: {
      title: 'notifications',
      page: 'notifications'
    }
  },
  {
    path: 'settings',
    name: prop + '.settings',
    component: Settings,
    meta: {
      title: 'Profile Settings',
      page: 'Profile Settings'
    }
  },
]

const accountChildRoute = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: AccountList,
    meta: {
      middleware: [
        auth,
        manager,
      ],
      title: 'Organizations',
      page: 'Organizations'
    }
  },
  {
    path: 'add',
    name: prop + '.add',
    component: AddAccount,
    meta: {
      middleware: [
        admin,
        auth
      ]
    }
  },
]
const invoiceChildRoute = (prop) => [
  {
    path: '',
    name: prop + '.list',
    component: Invoices,
    meta: {
      middleware: [
        manager,
        auth
      ],
      title: 'invoices',
      page: 'invoices'
    }
  },
  {
    path: ':id',
    name: prop + '.edit',
    component: InvoiceEdit,
    meta: {
      middleware: [
        admin,
        auth
      ],
      title: 'invoice',
      page: 'invoice'
    }
  },
  {
    path: ':id/detail',
    name: prop + '.show',
    component: InvoiceShow,
    meta: {
      middleware: [
        auth
      ],
      title: 'invoice',
      page: 'invoice'
    }
  }
]
const accountV2ChildRoute = (prop) => [
  {
    path: '',
    name: prop + '',
    component: AccountTabs,
    meta: {
      middleware: [
        auth,
        manager,
      ],
      title: 'Accounts',
      page: 'Accounts'
    }
  }
]

const liveValidationRoute = (prop) => [
  {
    path: '',
    name: prop + '',
    component: LiveValidations,
    meta: {
      middleware: [
        auth,
      ],
      title: 'Live Validations',
      page: 'Live Validations'
    }
  }
]

const logsChildRoute = (prop) => [
  {
    path: 'activities',
    name: prop + '.list',
    component: Logs,
    meta: {
      middleware: [
        admin,
        auth
      ],
      title: 'Logs',
      page: 'Logs'
    }
  },
]

const invoiceLogChildRoute = (prop) => [
  {
    path: ':invoice_id/logs/:invoice_log_id',
    name: prop + '.log',
    component: InvoiceLog,
    meta: {
      middleware: [
        admin,
        auth
      ],
      title: 'Invoices Log',
      page: 'Invoices Log'
    }
  },
]

const CheckoutChildRoute = (prop) => [
  {
    path: '',
    name: prop + '.checkout',
    component: Checkout
  }
]

const integrationChildRoute = (prop) => [

  {
    path: 'list/:id?',
    name: prop + '.list',
    component: List,
    meta: {
      middleware: [
        auth
      ],
      title: 'Integration List',
      page: 'Integration List'
    }
  },
  {
    path: 'stats',
    name: prop + '.stats',
    component: EventsReportCompose,
    meta: {
      middleware: [
        auth
      ],
      title: 'Live events reports',
      page: 'Live events reports'
    }
  },
  {
    path: 'automation/stats',
    name: prop + '.automation-stats',
    component: LoAutomationStats,
    meta: {
      middleware: [
        auth
      ],
      title: 'LiveOpens Automation Stats',
      page: 'LiveOpens Automation Stats'
    }
  },
  {
    path: 'logs',
    name: prop + '.logs',
    component: IntegrationLogsViewPage,
    meta: {
      middleware: [
        auth,
        admin
      ],
      title: 'Integration Logs',
      page: 'Integration Logs'
    }
  }
]

const geChildRoute = (prop) => [
  {
    path: 'sources/:id?',
    name: prop + '.sources',
    component: SourceList,
    meta: {
      middleware: [
        auth,
      ],
      title: 'LiveLeads Sources',
      page: 'LiveLeads Sources'
    }
  },
  {
    path: 'sources/records/:id?',
    name: prop + '.source.details',
    component: SourceRecords,
    meta: {
      middleware: [
        auth,
      ],
      title: 'LiveLeads Records',
      page: 'LiveLeads Records'
    }
  },
  {
    path: 'records/:id?',
    name: prop + '.records',
    component: SourceRecords,
    meta: {
      middleware: [
        auth,
      ],
      title: 'LiveLeads Records',
      page: 'LiveLeads Records'
    }
  },
  {
    path: 'automations/:id?',
    name: prop + '.automations',
    component: AutomationList,
    meta: {
      middleware: [
        auth,
      ],
      title: 'LiveLeads Automations',
      page: 'LiveLeads Automations'
    }
  }
]

const notificationsChildRoute = (prop) => [
  {
    path: '',
    name: prop,
    component: Notification,
    meta: {
      middleware: [
        auth
      ],
      title: 'Notifications',
      page: 'Notifications'
    }
  },
  {
    path: 'admin',
    name: prop + '.admin',
    component: AdminNotification,
    meta: {
      middleware: [
        admin,
        auth
      ],
      title: 'Notifications',
      page: 'Notifications'
    }
  },
]

const accountManageChildRoute = (prop) => [

  {
    path: 'list/:id?',
    name: prop + '.list',
    component: AccountManagement,
    meta: {
      middleware: [
        auth,
        admin
      ],
      title: 'Account Management List',
      page: 'Account Management'
    }
  }
]

const reportsChildRoute = (prop) => [

  {
    path: 'revenues/',
    name: prop + '.revenues',
    component: RevenueReport,
    meta: {
      middleware: [
        auth,
        manager
      ],
      title: 'Revenue',
      page: 'Revenue'
    }
  },
  {
    path: 'subscriptions-summary/',
    name: prop + '.subscriptions_summary',
    component: SubscriptionsSummary,
    meta: {
      middleware: [
        auth,
        admin
      ],
      title: 'Subscriptions Summary',
      page: 'Subscriptions Summary'
    }
  },
  {
    path: 'liveopens',
    name: prop + '.liveopens',
    component: LiveOpensEventsReport,
    meta: {
      middleware: [
        auth,
        admin
      ],
      title: 'LiveOpens Report',
      page: 'LiveOpens Report'
    }
  },
  {
    path: 'chat',
    name: prop + '.chat',
    component: ChatReport,
    meta: {
      middleware: [
        auth,
        admin
      ],
      title: 'Chat Report',
      page: 'Chat Report'
    }
  }
]

const referralChildRoute = (prop) => [
  {
    path: '',
    name: prop + '.list',
    component: Referrals,
    meta: {
      middleware: [
        auth,
        admin
      ],
      title: 'Affiliates List',
      page: 'Affiliates List'
    }
  },
  {
    path: 'report',
    name: prop + '.report',
    component: ReferralsReport,
    meta: {
      middleware: [
        auth,
        admin
      ],
      title: 'Affiliates',
      page: 'Affiliates'
    }
  }
]

const chatsChildRoute = (prop) => [
  {
    path: 'admin',
    name: prop + '.admin',
    component: AdminChat,
    meta: {
      middleware: [
        admin,
        auth
      ],
      title: 'Chats',
      page: 'Chats'
    }
  },
]

const ticketsChildRoute = (prop) => [
  {
    path: 'tickets',
    name: prop + '.tickets',
    component: AdminTicketChat,
    meta: {
      middleware: [
        auth,
        admin
      ],
      title: 'Tickets',
      page: 'Tickets'
    }
  },
]

const routes = [
  {
    path: '/',
    name: 'dashboard1',
    component: Layout1,
    children: childRoutes('dashboard1'),
  },
  {
    path: '/auth',
    name: 'auth1',
    component: AuthSignInLayout,
    children: authChildRoutes('auth1')
  },
  {
    path: '/',
    name: 'auth1',
    component: AuthLayout1,
    children: registerRoutes('auth1')
  },
  {
    path: '/auth',
    component: AuthSignInLayout,
    children: [
      {
        path: 'sign-in',
        name: 'auth1.sign-in1',
        component: SignIn1,
      },
    ],
    meta: {
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/cookie/generator',
    name: 'cookie-generator',
    component: customBlankLayout,
    children: [
      {
        path: '',
        name: 'cookie.generator.main',
        component: CookieGenerator,
        meta: {
          title: 'Cookie Modal Generator',
          page: 'Cookie Modal Generator'
        }
      },
    ],
    meta: {
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/thank-you',
    name: 'thank_you',
    component: AuthSignInLayout,
    children: [
      {
        path: '/',
        component: ThankYou,
      },
    ],
    meta: {
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/payment/:status', //success/failed
    name: 'payment-response',
    component: AuthLayout1,
    children: [
      {
        path: '/',
        component: PaymentResponse, // PaymentResponse
      },
    ],
    meta: {
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/user',
    name: 'user',
    component: Layout1,
    meta: {
      middleware: [
        auth,
      ],
    },
    children: userChildRoute('user')
  },

  {
    path: '/services/:service',
    name: 'services',
    component: Layout1,
    meta: {

      middleware: [
        auth
      ]
    },
    children: [{
      path: 'plans',
      name: 'services.plan',
      component: BillingPlans,
      meta: {
        title: 'Change Plan',
        page: 'Change Plan',
      }

    }]
  },
  {
    path: '/account',
    name: 'account',
    component: Layout1,
    children: accountChildRoute('account')
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: Layout1,
    children: accountV2ChildRoute('accounts'),
  },
  {
    path: '/live-validations',
    name: 'live_validations',
    component: Layout1,
    children: liveValidationRoute('live_validations'),
  },
  {
    path: '/reports',
    name: 'reports',
    component: Layout1,
    children: reportsChildRoute('reports'),
  },
  {
    path: '/plans',
    name: 'plans',
    component: Layout1,
    children: [
      {
        path: '',
        name: 'plans' + '',
        component: PlanList,
        meta: {
          middleware: [
            auth,
            manager,
          ],
          title: 'Plans',
          page: 'Plans'
        }
      }
    ],
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: Layout1,
    children: invoiceChildRoute('invoices')
  },
  {
    path: '/discounts',
    name: 'discounts',
    component: Layout1,
    children: [
      {
        path: '',
        name: 'discounts' + '',
        component: DiscountList,
        meta: {
          middleware: [
            auth,
            manager,
          ],
          title: 'Manage Discounts',
          page: 'Manage Discounts'
        }
      }
    ],
  },
  {
    path: '/logs',
    name: 'logs',
    component: Layout1,
    children: logsChildRoute('logs')
  },
  {
    path: '/checkout',
    name: 'checkout',
    // component: Checkout,
    component: Default,
    children: CheckoutChildRoute('checkout')
  },
  {
    path: '/integration',
    name: 'integration',
    component: Layout1,
    children: integrationChildRoute('integration'),
  },
  {
    path: '/liveleads',
    name: 'source',
    component: Layout1,
    children: geChildRoute('liveleads'),
  },
  {
    path: '/account-management',
    name: 'account-management',
    component: Layout1,
    children: accountManageChildRoute('account-management'),
  },
  {
    path: '/referrals',
    name: 'referral',
    component: Layout1,
    children: referralChildRoute('referral'),
  },
  {
    path: '/invoices',
    name: 'itemsLog',
    component: Layout1,
    children: invoiceLogChildRoute('invoices'),
  },
  {
    path: '/maintenance-mode',
    name: 'MaintenanceMode',
    component: MaintenanceModePage,
  },
  {
    path: '/notifications',
    component: Layout1,
    children: notificationsChildRoute('notifications')
  },
  {
    path: '/settings',
    component: Layout1,
    children: [
      {
        path: '',
        name: 'app.settings',
        component: AppSettings,
        meta: {
          middleware: [
            auth,
            admin
          ],
          title: 'App Settings',
          page: 'App Settings',
        }
      },
    ],
  },
  {
    path: '/platforms',
    component: Layout1,
    children: [
      {
        path: '',
        name: 'platforms.index',
        component: PlatformList,
        meta: {
          middleware: [
            auth,
          ],
          title: 'Platforms',
          page: 'Platforms',
        }
      },
    ],
  },
  {
    path: '/chats',
    component: Layout1,
    children: chatsChildRoute('chats')
  },
  {
    path: '/my-tickets',
    name: 'my-tickets',
    component: Layout1,
    children: [
      {
        path: '',
        name: 'support' + '.tickets',
        component: SupportTicket,
        meta: {
          middleware: [
            auth,
          ],
          title: 'Support Tickets',
          page: 'Support Tickets'
        }
      }
    ],
  },
  {
    path: '/chats',
    component: Layout1,
    children: ticketsChildRoute('chats')
  },
  {
    path: '*',
    name: '404',
    component: ErrorPage,
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware

  const context = {
    to,
    from,
    next,
    store
  }


  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })

})

router.afterEach((to, from) => {
  // cancel the request
  controller.abort()

  if(from.name === to.name && to.name == 'services.plan') {
    window.location.href = to.path
  }

  helper.checkAccountAlerts(router.app.$swal);

  let currentPageTitle = to.meta && to.meta.page ? `${to.meta?.page}` : '--';
  store.dispatch('updatePageTitle', currentPageTitle);
})

export default router
