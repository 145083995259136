<template>

    <b-col v-if="client">

        <b-card class="my-3" header="">
            <div class="row">
                <div class="align-self-center col-md-6">
                    <h2 class="">Welcome <span class="text-bold">{{ filterCapitalize(profileFirstname, true) }}</span>
                    </h2>
                </div>
            </div>

        </b-card>

        <b-tabs :pills="true" class="nav-fill mt-5 mt-md-0" id="pills-tab-1">
            <b-tab :active="true" id="pills-oe-service-tab-fill" href="#pills-oe-service-fill"
                ariaControls="pills-oe-service-fill" role="tab" :ariaSelected="true" title="LiveOpens" class="mb-3">

                <b-row v-if="!accountAlreadyUsedService(LIVE_OPENS)">
                    <b-col md="12">
                        <AnnounceService :service-code="LIVE_OPENS"></AnnounceService>
                    </b-col>
                </b-row>

                <b-row v-else-if="accountAlreadyUsedService(LIVE_OPENS)">
                    <b-col md="12">
                        <LiveOpenersConsumptionBar v-if="showLiveOpenersDetails" :service="LIVE_OPENS"
                            :options="openEventsData">
                        </LiveOpenersConsumptionBar>
                    </b-col>
                </b-row>

                <b-row v-if="accountAlreadyUsedService(LIVE_OPENS)">
                    <b-col lg="12" md="12">
                        <EventsChart :dates="option"></EventsChart>
                    </b-col>
                </b-row>

            </b-tab>
            <b-tab :active="false" id="pills-ge-service-tab-fill" :disabled="!accountAlreadyUsedService(LIVE_LEADS)"
                href="#pills-ge-service-fill" ariaControls="pills-ge-service-fill" role="tab" :ariaSelected="false"
                title="LiveLeads" class="mb-3">

                <b-row v-if="!accountAlreadyUsedService(LIVE_LEADS)">
                    <b-col md="12">
                        <AnnounceService :service-code="LIVE_LEADS"></AnnounceService>
                    </b-col>
                </b-row>

                <b-row v-else-if="accountAlreadyUsedService(LIVE_LEADS)">
                    <b-col md="12">
                        <LiveLeadsConsumptionBar v-if="showLiveLeadsDetails" :service="LIVE_LEADS"
                            :options="liveLeadsData">
                        </LiveLeadsConsumptionBar>
                    </b-col>
                </b-row>

                <b-row v-if="accountAlreadyUsedService(LIVE_LEADS)">
                    <b-col md="12" class="text-center">

                        <RecordsChart :dates="option"></RecordsChart>

                    </b-col>
                </b-row>

            </b-tab>
        </b-tabs>

    </b-col>

</template>
<script>
import { mapGetters,mapState,mapMutations } from 'vuex'
import {SERVICES } from "@/constantes";

import AnnounceService from "@/components/inboxgeek/sections/AnnounceService.vue"
import LiveOpenersConsumptionBar from '@/components/charts/ServiceConsumptionBar.vue'
import EventsChart from '@/components/charts/EventsChart.vue'
import LiveLeadsConsumptionBar from '@/components/charts/ServiceConsumptionBar.vue'
import RecordsChart from '@/components/charts/RecordsChart.vue'
import api from '@/api/RestClient'
import { helper } from '@/helpers'

export default {
    name: 'ClientDashboard',
    props: {},
    components: {
        AnnounceService,
        LiveOpenersConsumptionBar,
        EventsChart,
        AnnounceService,
        LiveLeadsConsumptionBar,
        RecordsChart
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            client: 'Auth/isClient',
            isAccounting: 'Auth/isAccounting',
            isAccountManager: 'Auth/isAccountManager',
            user: 'Auth/user',
            liveOpenersSubscribed: 'Auth/liveOpenersSubscribed',
            liveLeadsSubscribed: 'Auth/liveLeadsSubscribed',
            UserSubscriptions: 'Auth/UserSubscriptions',
            lastSubscriptions: 'Auth/lastSubscriptions'
        }),
        profileFirstname() {
            if (!this.profile) {
                return ';)';
            }
            return this.profile?.first_name;
        }
    },
    data() {
        return {
            LIVE_OPENS: SERVICES.LIVE_OPENS.id,
            LIVE_LEADS: SERVICES.LIVE_LEADS.id,
            showLiveOpenersDetails: false,
            organizations: [],
            openEventsData: null,
            liveLeadsData: null,
            option: {
                to: helper.dateToYMD(new Date()),
                from: helper.dateToYMD(helper.AddOrSubractDays(new Date(), 6, false))
            },
            profile: null,
            showCardService: false,
            showLiveOpenersDetails: false,
            showLiveLeadsDetails: false,

            subscriptionUsedLiveOpens: null,
            subscriptionUsedLiveLeads: null
            
        }
    },
    mounted() {
        if (this.user.profile) {
            this.profile = this.user.profile
        }

        this.getConsumptionData(this.LIVE_OPENS);
        this.getConsumptionData(this.LIVE_LEADS);
    },
    methods: {
        filterCapitalize (value, all = false) {
            return helper.customCapitalize(value, all)
        },
        accountAlreadyUsedService(serviceID) {

            if (serviceID == this.LIVE_OPENS) {
                return typeof this.subscriptionUsedLiveOpens !== 'undefined' && this.subscriptionUsedLiveOpens;
            }

            if (serviceID == this.LIVE_LEADS) {
                return typeof this.subscriptionUsedLiveLeads !== 'undefined' && this.subscriptionUsedLiveLeads;
            }

            return false;
        },
        getConsumptionData(service = this.LIVE_OPENS) {

            api.stats.getConsumptionDataService(service)
                .then(response => {
                    if (response.success) {
                        const data = response.data;

                        let dataFormatted = this.formatSeviceData(data);
                        let serviceName = null;

                        if (this.client) {
                            let result = null;

                            if (service == this.LIVE_OPENS) {
                                this.openEventsData = dataFormatted;
                                result = this.isNotServicePlanTrial(service, this.openEventsData);
                            } else if (service == this.LIVE_LEADS) {
                                this.liveLeadsData = dataFormatted;
                                result = this.isNotServicePlanTrial(service, this.liveLeadsData);
                            }

                            if (!result.state) {

                                this.showAlertEndingTrial(service, result);
                            }
                        }

                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        // handle error
                    }
                })
                .finally(() => {

                    this.fetchSubscriptionAlreadyUsed(service);

                })
        },
        formatSeviceData(data) {

            let options = {
                events: data.events,
                limit: data.limit ? data.limit : 0,
                remaining: data.remaining,
                contacts: data.contacts,
                records: data.records,
                percent: data.percent,
                balance: data.plan && data.plan.balance ? data.plan.balance : 0,
                plan: data.plan,
                freeAccount: (data.plan && data.plan.is_trial) || !data.plan,
                account: data.account ? data.account : null,
            }

            return options;
        },
        isNotServicePlanTrial(service = this.LIVE_OPENS, serviceData) {
            let subscription = this.UserSubscriptions[service];
            let result = {state: typeof service.plan !== 'undefined' && !service.plan.is_trial, data: serviceData, subscription: subscription}

            return result;
        },
        showAlertEndingTrial(service, data) {

            let date = data.subscription ? data.subscription.ends_at : '--';

            let labelFirst = '--';
            let labelSecond = '--';

            if (service == 1) {
                labelFirst = 'events';
                labelSecond = 'sending event';
            } else if (service == 2) {
                labelFirst = 'records';
                labelSecond = 'receiving records';

            }

            let alertMessage = ``;
            alertMessage += `<p>It appears <span class="badge badge-primary">${serviceName}</span> is out of <span class="font-italic">${labelFirst}</span> until <strong>${date}</strong>. If you\'d like to activate your overconsumption feature to continue <span class="font-italic">${labelSecond}</span> until your next billing cycle, please visit your dashboard</p>`;

            this.$swal({
                title: 'Uh, oh...',
                customClass: "remaining-alert",
                html: alertMessage,
                type: 'info',
                confirmButtonText: 'Got it!',
                showCloseButton: true,
            })
        },
        fetchSubscriptionAlreadyUsed(serviceId) {
            if(this.client) {
                api.subscriptions.accountAlreadyUseService(this.user.account_id, serviceId)
                .then((response) => {
                    if (response.success && response.data) {
                        if(serviceId == this.LIVE_OPENS) {
                            this.subscriptionUsedLiveOpens = response.data;
                        }
                        if(serviceId == this.LIVE_LEADS) {
                            this.subscriptionUsedLiveLeads = response.data;
                        }

                    }
                })
                .finally(() => {
                    this.showCards();
                })
            }
            
        },
        showCards() {
            this.showCardService = false;
            this.showLiveOpenersDetails = false;
            this.showLiveLeadsDetails = false;

            setTimeout(() => {
                this.$nextTick(() => {
                    this.showCardService = true;
                    this.showLiveOpenersDetails = true;
                    this.showLiveLeadsDetails = true;
                });
            }, 1300);

        }
    }
}

</script>