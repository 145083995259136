<template>
    <b-form-group
        v-if="display"
        :label="listLabel"
        label-for="list"
        class="col-md-12">

        <multiselect
            v-model="list" tag-placeholder="Empty"
            :placeholder="listPlaceholder" label="name" track-by="code"
            :options="lists" :multiple="isMultiple" return="code"
            :allow-empty="true"
            :taggable="true"
            :disabled="disabled || listdisabled"
            :loading="isLoading" @select="handleSelectListOption"
            @remove="handleRemoveListOption" :max="limitListSelected" :readonly="disabled">


            <template slot="option" slot-scope="props">
                <div class="option__desc">

                    <span class="option__title">

                        <span v-if="props.option.type">

                            <i v-if="props.option.type == 'segment'" class="ri-flashlight-line" style="color:#5ad"></i>
                            <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                        </span>

                        <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                        <span style="font-wight:bold;"> {{ props.option.name }} </span>

                        <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>


                    </span>

                </div>

            </template>


        </multiselect>
        <small id="alertLists" class="text-danger"></small>
    </b-form-group>
</template>
<script>
import Multiselect from "vue-multiselect";
import { PLATFORMS_CODE, PLATFORMS_ITEMS } from "@/constantes";
import _ from 'lodash';
import { services } from '@/helpers';
import api from '@/api/RestClient'

export default {
    name: 'MultiselectLists',
    components: {
        Multiselect
    },
    props: {
        account: {
            type: [String],
            default: () => null,
        },
        platform: {
            type: [String],
            default: () => null,
        },
        propList: {
            type: [Array],
            default: () => null,
        },
        propLists: {
            type: [Array],
            default: () => null,
        },
        propItem: {
            type: [Object],
            default: () => null,
        },

        propOnSelect: {
            type: [Function],
            default: () => null,
        },
        propOnRemove: {
            type: [Function],
            default: () => null,
        },
        disabled: {
            type: [Boolean],
            default: () => false,
        },
        isMultiple: {
            type: [Boolean],
            default: () => true,
        },

    },
    data() {
        return {
            currentPlatform: null,
            listLabel: 'List: *',
            listPlaceholder: 'Select Lists',
            listdisabled: false,

            list: [],
            lists: [],

            segments: [],

            isLoading: true,
            display: false,
            limitListSelected: false,
        }
    },
    mounted() {
        this.forceRender()
        this.initComponent()
        this.fetchItems()
    },
    methods: {
        initComponent() {

            if (typeof this.platform !== "undefined") this.currentPlatform = _.find(PLATFORMS_ITEMS, (o) => { return o.name == this.platform })

            this.limitListSelected = ([PLATFORMS_CODE.AWEBER, PLATFORMS_CODE.EMAIL_OCTOPUS].includes(this.currentPlatform.code))
                ? 1 : false;

            services.default.initializeListLabels(this, this.currentPlatform?.code)

            if (typeof this.propLists !== "undefined") {
                this.setLists(this.formatLists(this.propLists, this.platform))
            }
        },
        setLists(items) {
            if (Array.isArray(items)) this.lists = items
        },

        fetchItems() {
            this.listdisabled = true;
            this.isLoading = true;

            let service = 'lists';

            if (this.platform == 'Convertkit') service = 'tags';

            let requestParams = services.default.getListRequestParams(service, {
                "code": this.currentPlatform.code,
                "platform": this.currentPlatform.name,
                "comp": this.$parent,
                item: this?.propItem ?? {}
            })

            const that = this
            api.platforms.fetchServices(requestParams)
                .then((result) => {
                    if (result.success && result.data) {
                        let data = result.data;
                        let platform = _.find(PLATFORMS_ITEMS, function (o) { return o.code == that.currentPlatform.code; });
                        if(this.platform == 'Convertkit'){
                            data = data.tags;
                        }
                        let lists = services.default.formatLists(data, platform)

                        lists = _.map(lists, (list) => {
                            let alreadySelected = _.find(that.list, function (o) { return o.code == list.code; });

                            if (alreadySelected) list.$isDisabled = true

                            return list
                        })

                        if (lists.length) that.setLists(lists)
                    }
                })
                .then(() => {

                    let platform_name = that.currentPlatform.name.toLowerCase()

                    if (that.lists.length && ['mailchimp'].includes(platform_name)) {
                        that.fetchSegments(that.lists[0].code)
                    }

                }).catch(function (error) {
                    console.log(error);
                }).finally(() => {
                    this.listdisabled = false;
                    this.isLoading = false;
                })
        },
        fetchSegments(list_id) {
            this.listdisabled = true;
            this.isLoading = true;

            let service = 'segments';

            let requestParams = {
                "entities": service,
                "platform_name": this.currentPlatform.name.toLowerCase(),
                "platform_id": this.propItem.platform_id,
                "list_id": list_id,
            }

            const that = this
            api.platforms.fetchServices(requestParams)
                .then((response) => {

                    let segments = response.data.map((segment) => {
                        return {
                            'code': segment.id,
                            'name': segment.name
                        };
                    });

                    let listAndSegments = _.union(that.lists, segments)

                    if (listAndSegments.length) that.setLists(listAndSegments)

                }).catch((error) => {
                    this.isLoading = false;
                    console.log('failed to fetch segments');
                }).finally(() => {
                    this.listdisabled = false;
                    this.isLoading = false;
                })

        },
        formatLists(items, listAuto = true) {
            var defaultService = services.default

            if (typeof this.platform === "undefined" || !this.platform) return [];

            let strId = 'list_id';
            let strName = 'list_name'

            const that = this;
            return _.map(items, (obj, key) => {
                let item = {}

                item = defaultService.maps.listsMap(obj, strId, strName,'type')

                if (listAuto) that.list.push(item)
                return item
            })
        },

        handleSelectListOption(itemSelected) {
            setTimeout(() => {
                this.$emit('onSelect', this.list)
                if (typeof this.propOnSelect === "function") {
                    return this.propOnSelect(itemSelected, this.list)
                }
            }, 500)
        },
        handleRemoveListOption(itemRemoved) {
            let toEnable = _.find(this.lists, function (o) { return o.code == itemRemoved.code; });
            if (toEnable) toEnable.$isDisabled = false

            setTimeout(() => {
                this.$emit('onRemove', this.list)
                if (typeof this.propOnRemove === "function") {
                    return this.propOnRemove(itemRemoved)
                }
            }, 500)
        },
        forceRender() {
            this.display = false;
            this.$nextTick(() => {
                this.display = true
            })
        }
    },
    watch: {

    }
}
</script>
