<template>
<div>
    <b-form @submit="onFilter">
        <b-row class="mt-2">
            <b-col md="12" lg="12">
                <h4>Filters</h4>
                <b-row>
                    <b-col :md="3">
                        <b-form-group label="Choose platform:" label-for="input-3">
                            <multiselect :loading="isLoading" :disabled="isLoading" tag-placeholder="Manage platform" placeholder="Choose platform" label="name" track-by="code" v-model="form.platform" deselect-label="Can't remove this value" :allow-empty="true" :options="platforms" :multiple="true" :taggable="true">
                            </multiselect>
                        </b-form-group>
                    </b-col>
                    <b-col :md="3">
                        <b-form-group id="input-group-platform_account" label="Platform account:" label-for="platform_account">
                            <b-form-input v-model="form.platform_account" type="text" class="mb-2 mr-sm-2 mb-sm-0" placeholder="Platform Account"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col :md="3">
                        <b-form-group label="Create at date from:" label-for="input-3">
                            <b-form-input :max="form.date_to" v-model="form.date_from" type="date" class="mb-2 mr-sm-2 mb-sm-0" placeholder="Created date from"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col :md="3">
                        <b-form-group label="Created at date to:" label-for="input-3">
                            <b-form-input :min="form.date_from" v-model="form.date_to" type="date" class="mb-2 mr-sm-2 mb-sm-0" placeholder="Created date to"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col :md="cols.daily_cap">
                        <b-form-group label="Daily cap:" label-for="input-3">
                            <b-form-input type="number" v-model="form.daily_cap" id="inline-form-input-name" class="mb-2 mr-sm-2 mb-sm-0" placeholder="Daily cap"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col :md="cols.status" class="text-left">
                        <b-form-group id="label" label="Status:" label-for="input-3">
                            <multiselect tag-placeholder="Manage status" placeholder="Choose status" label="name" track-by="code" v-model="form.status" deselect-label="Can't remove this value" :allow-empty="true" :options="statusOptions" :multiple="false" :taggable="false">
                            </multiselect>
                        </b-form-group>
                    </b-col>
                    <b-col md="3" class="text-left" v-if="isAdmin">
                        <b-form-group id="label" label="Account type:" label-for="input-3">
                            <multiselect tag-placeholder="Manage status" placeholder="Choose status" label="name" track-by="code" v-model="form.account_type" deselect-label="Can't remove this value" :allow-empty="true" :options="account_types" :multiple="false" :taggable="false">
                            </multiselect>
                        </b-form-group>
                    </b-col>
                    <b-col md="3" class="text-left" v-if="isAdmin">
                        <b-form-group id="label" label="Subscription:" label-for="input-3">
                            <multiselect tag-placeholder="Manage Subscription" placeholder="Choose plan" label="name" track-by="code" v-model="form.plans" deselect-label="Can't remove this value" :allow-empty="true" :options="plans" :multiple="true" :taggable="false">
                            </multiselect>
                        </b-form-group>
                    </b-col>
                    <b-col md="1" class="text-center">
                        <label id="label-btn" class="sr-only" for="inline-form-input-name">Name</label>
                        <b-button type="submit" :disabled="onFilterDisable" variant="primary" style="height: 3.3em; margin-top: 2.3em">
                            <b-spinner small label="Small Spinner" type="grow" v-show="onFilterDisable"></b-spinner> <i class="fa fa-paper-plane m-0" v-show="!onFilterDisable"></i>
                        </b-button>
                    </b-col>

                </b-row>
            </b-col>
        </b-row>
    </b-form>
    <b-row class="mb-3">
        <b-col lg="12">
            <Columns :cols="integrationFilterColumns.options" :selected="integrationFilterColumns.selected"  label="Displayed columns" @onSelect="onSelect"></Columns>
        </b-col>
    </b-row>
</div>


</template>
<script>
import api from '@/api/RestClient'
import {
    sofbox
} from '@/config/pluginInit'
import Multiselect from 'vue-multiselect'
import store from '@/store/index'
import { helper } from '@/helpers';
import Columns from "../../LiveReports/children/Columns"
import { mapGetters } from 'vuex'
import {SERVICES} from "@/constantes";

export default {
    name: 'BlankPage',
    props: {
        onFilterTable: {
            type: Boolean,
            default: false
        },
        integrationFilterColumns: {
            type: Object,
            default() {
                return {
                    options: [],
                    selected: [],
                    defaults: []
                };
            }
        },
        account: {
            type: Object,
            default: null,
        },
        platforms_array: {
            type: Array,
            default: () => []
        }
    },
    components: {
        Multiselect,
        Columns
    },
    mounted() {
        sofbox.index()
        this.fetchPlatforms();//eroc
        if (this.isAdmin) {
            this.cols.platform = 4;
            this.cols.create_to = 4;
            this.cols.create_from = 4;
            this.cols.daily_cap = 2;
            this.cols.status = 3;
            this.fetchPlans();
        }
    },
    data() {
        return {
            onFilterDisable: false,
            isLoading: true,
            form: {
                status: false,
                daily_cap: null,
                platform: null,
                platform_account: null,
                date_to: null,
                date_from: null,
                account_type: null,
                plans: [],
            },
            platforms: [],
            account_types: [{
                    code: 3,
                    name: 'test'
                },
                {
                    code: 1,
                    name: 'client'
                },
                {
                    code: 2,
                    name: 'internal'
                },

            ],
            cols: {
                platform: 3,
                create_to: 2,
                create_from: 2,
                daily_cap: 2,
                status: 2
            },
            plans: [],
            showOrHideColumns: {
                selected: [{ code: 'tag_name ', name: 'tag' }],
                options: [{ code: 'tag_name ', name: 'tag' }],
                defaults: [
                    "selected",
                    "name",
                    "tag_name",
                    "platform",
                    "lists",
                    "created_at",
                    "active",
                    "actions"
                ]
            }
        }
    },
    methods: {
        platformName(name) {
            return helper.platformFormatName(name);
        },
        onFilter(e) {
            e.preventDefault();
            this.onFilterDisable = true
            this.$emit('onFilter', this.form);
        },
        fetchPlatforms() {
            var data = JSON.parse(JSON.stringify(this.platforms_array))
            data.forEach((plat) => {
                    let platform = {};
                    platform.name = this.platformName(plat.name);
                    platform.code = plat.id;
                    this.platforms.push(platform);
                });
              this.isLoading = false;
        },
        finishFilter() {
            this.onFilterDisable = false
        },
        fetchPlans() {
            api.plans.getActive().then((response) => {
                response.data.forEach((plan) => {
                    this.plans.push({
                        name: plan.name,
                        code: plan.id
                    });
                });
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.isLoading = false;
            })
        },
        onSelect(col) {
            this.$emit('onSelectedColumns', col);
        }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isAccountManager: 'Auth/isAccountManager',
            platforms_co: 'platformConfigs'
        }),
        statusOptions() {
            let status = [
                {
                    code: 1,
                    name: 'Active'
                },
                {
                    code: 0,
                    name: 'Inactive'
                }
            ];

            if(this.isAdmin) {
                status.push({ code: -1, name: 'Deleted' })
            };

            return status;
        }
    },
    watch: {
        'integrationFilterColumns.options': {
            handler(newVal, oldVal) {
                //this.showOrHideColumns.options = newVal;
                setTimeout(() => {
                    //this.showOrHideColumns.selected.push({ code: 'tag_name ', name: 'tag' })
                }, 1000);
            }
        },
        'integrationFilterColumns.selected': {
            handler(newVal, oldVal) {
                //this.showOrHideColumns.selected = newVal;
            }
        }
    }
}
</script>

<style>
.form-border {
    border: 5px solid;
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 10px;
}
</style>
