<template>

    <b-form ref="form-settings" @submit.prevent="updateUserNotificationTypes" class="mb-5">
        <b-row class="text-primary">
            <b-col>
                <p class="information-border-*">

                    <span class="text-primary mb-3">Receive Email Notifications for:</span>

                    <span class="badge badge-primary mx-2 text-lowercase">
                        {{ user.email }}
                    </span>

                    <span v-if="user.account">
                        <small class="text-dark font-weight-bold">Organization:</small>
                        <span class="badge badge-primary mx-2 text-uppercase">
                            {{ user.account.name }}
                        </span>
                    </span>

                </p>

                <b-col v-for="(item, index) in notificationTypes" :key="index" md="12" class="mt-2 pl-0 py-1 d-flex" v-if="!exceptNotif.includes(item.name)">
                    <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color my-auto">
                        <div class="custom-switch-inner cursor-pointer">

                            <input type="checkbox" 
                                :id="`notifications-${notifAttr(item)}`"
                                class="custom-control-input bg-primary" 
                                v-model="form.notifications[notifAttr(item)]"
                                @input="updateUserNotificationTypes()" 
                                :class="updateSpinner ? 'disabled' : ''"
                                :disabled="updateSpinner">
                                
                            <label :for="`notifications-${notifAttr(item)}`" data-on-label="ON" data-off-label="OFF" class="custom-control-label"></label>
                        </div>

                        <label class="ml-2 text-primary cursor-pointer my-auto"
                            :for="`notifications-${notifAttr(item)}`" 
                            :disabled="updateSpinner">
                            {{ customCapitalize(item.name) }}
                        </label>
                    </div>

                    <input v-if="(currSettingEmail == item) || (form.emails[notifAttr(item)])" 
                        :id="'email' + index"
                        :ref="'email' + index" type="email" 
                        placeholder="Enter email" 
                        class="form-control ml-auto"
                        v-model="form.emails[notifAttr(item)]" style="width: 70%;"
                        :readonly="updateSpinner || !form.notifications[notifAttr(item)]">


                    <button v-if="!form.emails[notifAttr(item)] && currSettingEmail != item"
                        class="btn btn-sm mx-1 iq-bg-primary mb-1 my-auto"
                        :class="(currSettingEmail == item) || (form.emails[notifAttr(item)]) ? '' : 'ml-auto'"
                        @click="showEmail(item)" 
                        type="button" 
                        :disabled="currSettingEmail == item || updateSpinner">
                        <i class="ri-mail-add-fill"></i>
                        <span>Add Email</span>
                    </button>

                    <span v-else class="my-auto">
                        <button v-if="currSettingEmail == item" class="btn btn-sm mx-1 iq-bg-secondary mb-1 my-auto"
                            :class="(currSettingEmail == item) || (form.emails[notifAttr(item)]) ? '' : 'ml-auto'"
                            @click="currSettingEmail = null" 
                            type="button" 
                            :disabled="updateSpinner">
                            <i class="ri-close-line"></i>
                            <span>Cancel</span>
                        </button>
                        <button v-else class="btn btn-sm mx-1 iq-bg-danger mb-1 my-auto"
                            :class="(currSettingEmail == item) || (form.emails[notifAttr(item)]) ? '' : 'ml-auto'"
                            @click="clearEmail(item, index)" 
                            type="button" 
                            :disabled="updateSpinner">
                            <i class="ri-mail-close-line"></i>
                            <span>Clear</span>
                        </button>
                    </span>

                </b-col>

            </b-col>
        </b-row>
        
        <b-button type="submit" variant="primary" class="float-right mr-3 mt-2" :disabled="updateSpinner">Save
            <b-spinner small type="grow" v-show="updateSpinner"></b-spinner>
        </b-button>
        
    </b-form>

</template>
<script>

import api from '@/api/RestClient'
import { helper } from '@/helpers'
import { mapGetters } from 'vuex'
import _ from 'lodash';
import { DEFAULT_USER_SETTINGS } from "@/constantes";

export default {
    name: 'UserNotificationSettings',
    props: {
        notificationTypes: {
            type: Array,
            default: []
        },
        form: {
            type: Object,
            default: () => {}
        },
        updateSpinner: {
            type: Boolean,
            default: false
        },
        exceptNotif: {
            type: Array,
            default: ['No Payment', 'Checkout Session', 'Subscription', 'Live Report']
        },
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            user: 'Auth/user',
            isOwnerAccount: 'Auth/isOwnerAccount',
            UserApiToken : 'Auth/UserApiToken'
        }),
    },
    data() {
        return {
            update_spinner: false,
            profileSettings: {},
            errors: [],
            isLoading: true,
            currSettingEmail: null,

        }
    },
    beforeUpdate() {
    },
    mounted() {
        this.loadSettings();
    },
    updated() {
        this.errors = [];
        this.update_spinner = this.updateSpinner;
        console.log('updated', this.currSettingEmail)
    },
    methods: {
        loadSettings() {
            this.isLoading = true;
            const excepts = ['welcome emails', 'password reset', 'account disconnection', 'payment failed'];
            this.form.notifications;

            api.notificationTypes.profile()
                .then((response) => {

                    if (response.success) {
                        this.notifTypes = _.filter(response.data.notification_types, (o) => { return !excepts.includes(o.name) })
                        this.otherSettings = response.data.user_settings ? _.unionBy(response.data.user_settings, DEFAULT_USER_SETTINGS, 'nickname') : DEFAULT_USER_SETTINGS;
                        this.displaySettings(response.data)
                    }
                })
                .catch((error) => {
                    console.log(helper.formatErrorResponse(error))
                })
                .finally(() => {
                    helper.delay(2000).then(() => this.isLoading = false)
                })

        },
        updateUserNotificationTypes() {
            this.checkUpdateForm();

            let data = []

            helper.delay(500).then(() => {
                this.profileSettings.notification_types.forEach(notifType => {
                    let value = this.form.notifications[this.notifAttr(notifType)]
                    let email = this.form.emails[this.notifAttr(notifType)]

                    let settingData = { code: notifType.id, value: value }

                    if (email) {
                        settingData.email = email;
                    }

                    data.push(settingData)
                });


                if (helper.isEmpty(this.errors)) {
                    this.update_spinner = true;
                    const userID = this.$store.getters['Auth/user'].id;

                    this.update(userID, data);
                }
            })
        },
        notifAttr(notif) {
            return (typeof notif.name != 'undefined') ? notif.name.toLowerCase().split(" ").join("_") : '';
        },
        update(id, data) {
            this.update_spinner = true;

            api.notificationTypes.saveProfileNotifications(id, data).then(response => {
                if (response.success) {
                    this.displaySettings(response.data)
                    this.toast('Updated!', 'success', response.message);
                }
            }).catch(err => {
                helper.formatErrorResponse(err).forEach(error => {
                    console.log(error)
                    this.toast('Oh!', 'danger', 'Something went wrong')
                });

            })
                .finally(() => {
                    helper.delay(2000).then(() => {
                        this.currSettingEmail = null;
                        this.update_spinner = false
                    })
                })
        },
        displaySettings(data) {

            this.profileSettings = data

            // Notification Settings
            this.notifTypes.forEach(notifType => {
                let attr = this.notifAttr(notifType);
                let notif = _.find(this.profileSettings.profile_notifications, (o) => { return notifType.name == o.name; });

                this.form.notifications[attr] = (!notif && !["daily event limit reached"].includes(notifType.name)) || (typeof notif?.pivot?.active != 'undefined' && notif.pivot.active) ? true : false;
                this.form.emails[attr] = notif && notif?.pivot?.email ? notif.pivot.email : null;
                notifType.showEmail = this.form.emails[attr] ? true : false;
            });

            // User Settings
            this.otherSettings.forEach(setting => {
                let attr = this.notifAttr(setting);
                this.form.otherSettings[attr] = setting.value && parseInt(setting.value) == 1 ? true : false;
            });
        },
        toast(title = "success", variant = "success", message = "Account charged") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        },
        customCapitalize(str, forceAll = false) {
            return helper.customCapitalize(str, forceAll);
        },
        showEmail(item) {
            this.currSettingEmail = null;
            if (typeof item !== 'undefined') {
                this.currSettingEmail = item;
            }
        },
        clearEmail(item) {
            this.currSettingEmail = item;

            this.$nextTick(() => {
                this.currSettingEmail = null;
                this.form.emails[this.notifAttr(item)] = null;
                this.updateUserNotificationTypes();
            });
        },
        resetErrors() {
            this.errors = {}
        },
        checkUpdateForm() {
            this.resetErrors();
        },
    }
}

</script>