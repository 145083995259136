
// Platform services
import ActiveCampaignHelper from './activecampaignHelper'
import AweberHelper         from './aweberHelper'
import BlastableHelper      from './blastableHelper'
import CampaignerHelper     from './campaignerHelper'
import ConvertkitHelper     from './convertkitHelper'
import EmailOctopusHelper   from './emailoctopusHelper'
import ExpertsenderHelper   from './expertsenderHelper'
import GetResponseHelper    from './getresponseHelper'
import InboxSuiteHelper     from './inboxsuiteHelper'
import HubspotHelper        from './hubspotHelper'
import KeapHelper           from './keapHelper'
import KlaviyoHelper        from './klaviyoHelper'
import MailchimpHelper      from './mailchimpHelper'
import OntraportHelper      from './ontraportHelper'
import SendlaneHelper       from './sendlaneHelper'
import MailwizzHelper       from './mailwizzHelper'
import BrevoHelper          from './brevoHelper'
import InboxHelper          from './inboxHelper'
import GoHighLevelHelper    from './gohighlevelHelper'
import OngageHelper         from './ongageHelper'
import Beehiiv              from './beehiivHelper'
import IterableHelper       from './iterableHelper'
import listrakHelper        from "@/helpers/platforms/new/listrakHelper";
import maropostHelper       from "@/helpers/platforms/new/maropostHelper";
import sendgridHelper       from "@/helpers/platforms/new/sendgridHelper";
import omnisendHelper       from "@/helpers/platforms/new/omnisendHelper";
import mailsendHelper       from "@/helpers/platforms/new/mailsendHelper";
import webhookHelper        from "@/helpers/platforms/new/webhookHelper";

export default {

    get : ( platform ) =>{


        let platformHelpers = {
            aweber          : AweberHelper,
            activecampaign  : ActiveCampaignHelper,
            blastable       : BlastableHelper,
            campaigner      : CampaignerHelper,
            convertkit      : ConvertkitHelper,
            emailoctopus    : EmailOctopusHelper,
            expertsender    : ExpertsenderHelper,
            hubspot         : HubspotHelper,
            inboxsuite      : InboxSuiteHelper,
            getresponse     : GetResponseHelper,
            keap            : KeapHelper,
            klaviyo         : KlaviyoHelper,
            mailchimp       : MailchimpHelper,
            ontraport       : OntraportHelper,
            sendlane        : SendlaneHelper,
            mailwizz        : MailwizzHelper,
            brevo           : BrevoHelper,
            inbox           : InboxHelper,
            gohighlevel     : GoHighLevelHelper,
            beehiiv         : Beehiiv,
            ongage          : OngageHelper,
            listrak         : listrakHelper,
            maropost        : maropostHelper,
            sendgrid        : sendgridHelper,
            omnisend        : omnisendHelper,
            mailsend        : mailsendHelper,
            iterable        : IterableHelper,
            webhook         : webhookHelper
        };

        if ( platformHelpers[platform.name] )
        {

            let platformHelper = platformHelpers[platform.name];
            platformHelper.id = platform.id;

            return platformHelper;
        }

        return false;

    }

}
