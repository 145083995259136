<template>
  <ul class="d-flex align-content-stretch flex-wrap p-0" style="height: 70px;gap: 10px">
    <li  v-for="step in steps" :key="step.name"
         :id="'nav-' + step.name"
         class="col rounded d-flex align-items-center "
         :class="[ 'col', 'rounded', 'd-flex', 'align-items-center',
              completedStep(step) ? 'completed' : '',
              isCurrentStep(step) ? 'bg-primary active' : 'bg-light text-secondary inactive',

             ]" >

      <div class="d-flex align-items-center">

          <i v-if="!completedStep(step)" :class="step.icon + ' font-size-18 mx-2 icon'" ></i>
          <i v-else class="ri-check-fill font-size-18 mx-2 icon" ></i>

          <span class="mx-2 font-weight-bolder">{{ step.title }}</span>
      </div>

    </li>

  </ul>
</template>

<script>
export default {
  name: "AddIntegrationWizardModalNav",
  props:['steps','current_step_index'],
  methods:{
    isCurrentStep(step){
      return (this.current_step_index ===step.index);
    },
    completedStep(step){
      return (step.index < this.current_step_index );
    }
  }
}
</script>

<style scoped>
.active{
  color: white;
}
.active .icon {
  background: #88898d21;
  color: white;
  border-radius: 100%;
  padding: 0px 7px;
}
.inactive .icon {
  background: #88898d21;
  color: #c3c5ca;
  border-radius: 100%;
  padding: 0px 7px;
}
.completed {
  background-color: rgb(30, 41, 68) !important;
  color:white !important;
}

.completed .icon {
  background-color: rgb(30, 41, 68) !important;
}
</style>