import { PLATFORMS_CODE, COLUMNS_GRID } from "@/constantes";
import api from '@/api/RestClient';
import axios from 'axios'

export default function platform() {
    return {
        labels: {        },
        maps: {
            listsMap: (obj) => {
                
                let item = {}
                item.code = obj.id
                item.name = obj.name
                item.type = obj['type']
                return item
            },
        }, getTagAdded(result) {
            result = 'data' in result ? result.data : result;
            return { code: result.listId, name: result.name }
        },

        customShowAnotherCols(COMP) {
            if(typeof COMP.showCols.apiKey !== 'undefined')  COMP.showCols.apiKey = true;
            if(typeof COMP.showCols.accountId!== 'undefined')  COMP.showCols.accountId = true;

            if(typeof COMP.$refs["step-platform"] !== 'undefined') COMP.$refs["step-platform"].accountLabel = 'App URL';
            if(typeof COMP.$refs["step-platform"] !== 'undefined') COMP.$refs["step-platform"].accountPlaceholder = 'https://<<mumara-app-doamin.com>>';

            if(typeof COMP.accountLabel !== 'undefined') COMP.accountLabel = 'App URL';
            if(typeof COMP.accountLabel !== 'undefined') COMP.accountPlaceholder = 'https://<<mumara-app-doamin.com>>';
            
            COMP.tagLabel               = 'List *';
            COMP.tagPlaceholder         = 'List';
            COMP.selectTagPlaceholder   = 'List';
        },
    }
}