<template>

    <b-modal id="payment-method" ref="payment-method" size="lg" @show="initModal" @hidden="">
        <template #modal-title>{{ paymentMethod ? 'Update' : 'Add' }} Payment Method</template>

        <b-container>
            <div class="success text-center" v-if="success">
                <i class="fas fa-check-circle fa-8x text-success"></i>
                <h4>GREAT</h4>
                <h5>Payment method successfully added</h5>
            </div>

            <div class="alert alert-danger" v-if="fails" role="alert" v-for="error in errors">
                <div class="iq-alert-text">• {{ error }}</div> <br>
            </div>

            <div v-if="!success">
                <div class="modal-header require-PM" v-if="!paymentsMethod.length">
                    <h5 class="modal-title text-danger" id="exampleModalScrollableTitle">You do not have
                        a
                        credit card set
                        up yet</h5>
                </div>
                <form onsubmit="required()">
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <h5 class="name-card">Name on card :</h5>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="fname">First Name:<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" required="required"
                                    v-model="form.first_name">

                                <small ref="b-errors-first_name" class="text-danger small d-none">{{
                                    billingErrors.first_name }}</small>

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="fname">Last Name:<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" required="required" v-model="form.last_name">

                                <small ref="b-errors-last_name" class="text-danger small d-none">{{
                                    billingErrors.last_name }}</small>

                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="fname">Street Address:<span class="text-danger">*</span></label>
                                <textarea name="address" class="form-control" style="width: 100%;"
                                    v-model="form.address" required="required"></textarea>

                                <small ref="b-errors-line1" class="text-danger small d-none">{{
                                    billingErrors.line1 }}</small>

                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="fname">Country:<span class="text-danger">*</span></label>
                                <multiselect v-model="form.country" deselect-label="Can't remove this value"
                                    track-by="code" label="name" placeholder="Select Country" :options="countries"
                                    :searchable="true" :allow-empty="false" :loading="loading" required="required"
                                    @input="updateBillingCountry">
                                </multiselect>

                                <small ref="b-errors-country" class="text-danger small d-none">{{
                                    billingErrors.country }}</small>

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="fname">City:<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" required="required" v-model="form.city">
                                <small ref="b-errors-city" class="text-danger small d-none">{{
                                    billingErrors.city }}</small>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1" v-if="is_taxable">
                        <div class="col-md-6">
                            <b-form-checkbox name="check-button" v-model="has_gst_tax" switch size="sm">
                                is exempt GST tax?
                                <label data-on-label="On" data-off-label="Off"></label>
                            </b-form-checkbox>

                            <div class="form-group" v-show="has_gst_tax">
                                <label for="fname">GST Num:<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" placeholder="GST tax num" required="required"
                                    v-model="gst_tax_num">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <b-form-checkbox name="check-button" v-model="has_qst_tax" switch size="sm">
                                is exempt QST tax?
                                <label data-on-label="On" data-off-label="Off"></label>
                            </b-form-checkbox>

                            <div class="form-group" v-show="has_qst_tax">
                                <label for="fname">QST Num:<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" placeholder="QST tax num" required="required"
                                    v-model="qst_tax_num">
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="fname">State:<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" required="required" v-model="form.state">

                                <small ref="b-errors-state" class="text-danger small d-none">{{
                                    billingErrors.state }}</small>

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="fname">ZIP or Postal Code:<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" required="required"
                                    v-model="form.postal_code">

                                <small ref="b-errors-postal_code" class="text-danger small d-none">{{
                                    billingErrors.postal_code }}</small>

                            </div>

                        </div>
                        <div class="col-md-12 mt-3">
                            <div class="form-group">
                                <label for="nickname">Nickname:<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" required="required" v-model="form.nickname"
                                    placeholder="Add Nickname">
                                <small ref="b-errors-nickname" class="text-danger small d-none">{{
                                    billingErrors.nickname }}</small>
                            </div>
                        </div>
                    </div>
                </form>
                <div v-show="!paymentMethod" class="row mt-3">
                    <div class="col-md-12">
                        <label for="fname">Card:<span class="text-danger">*</span></label>
                        <div id="card"></div>
                    </div>
                </div>
                <br>

            </div>
            <b-row v-if="!success" class="mt-3">
                <div class="col-12 text-danger text-center mb-4">
                    **InboxGeek is a Canada-based company that may require you to enable international
                    credit card usage when purchasing our services. Learn more <a
                        href="https://help.inboxgeek.com/faqs_billing/" target="_blank" class="text-dark">here</a>
                </div>
            </b-row>
        </b-container>


        <template #modal-footer>
            <div class="ml-auto">
                <button v-if="!success" type="button" @click="saveNewPaymentMethod" class="btn btn-primary mx-2"
                    :disabled="loading">
                    <b-spinner small type="grow" v-show="loading"></b-spinner>
                    Save
                </button>
                <button type="button" @click="close" class="btn btn-dark mx-2" data-dismiss="modal">Close</button>
            </div>
        </template>
    </b-modal>

</template>
<script>
import { BILLING_COUNTRIES } from '@/utils/countries';
import Multiselect from "vue-multiselect"
import { mapGetters } from "vuex";
import { helper } from '@/helpers';
import { loadStripe } from '@stripe/stripe-js'
import api from '@/api/RestClient'
import { sofbox } from '@/config/pluginInit'

export default {
    name: 'PaymentMethodModal',
    props: {
        paymentMethod: {
            type: Object,
            default: null
        },
        paymentsMethod: {
            type: Array,
            default: []
        },
        address: {
            type: Object,
            default: null
        },
    },
    components: {
        Multiselect
    },
    computed: {
        ...mapGetters({
            user: 'Auth/user',
        })
    },
    data() {
        return {
            // success: false,
            loading: false,
            countries: BILLING_COUNTRIES,
            form: {
                first_name: '',
                last_name: '',
                address: '',
                city: '',
                postal_code: '',
                state: '',
                nickname: ''
            },
            is_taxable: false,
            has_qst_tax: false,
            has_gst_tax: false,
            gst_tax_num: null,
            qst_tax_num: null,
            
            errors: [],
            fails: false,
            success: false,
            billingErrors: [],

        }
    },
    methods: {
        async initcard() {
            this.stripe = await loadStripe(process.env.VUE_APP_STRIPESECRET);
            const elements = this.stripe.elements()
            this.cardElement = elements.create('card')
            this.cardElement.mount('#card');
        },
        initModal() {
            if(this.address) this.form = this.address;
            
            setTimeout(() => {
                this.initcard();
            }, 500)
        },
        resetForm() {
            
        },
        updateBillingCountry(value) {

            if (typeof value.code != 'undefined') {
                let country = this.selectBillingCountry(value.code);
                if (country) this.form.country = country;
            }
            this.is_taxable = ['CA'].includes(value.code)
            if (!this.is_taxable) {
                this.gst_tax_num = null;
                this.qst_tax_num = null;
                this.has_gst_tax = false;
                this.has_qst_tax = false;
            }
            this.$forceUpdate();
        },
        selectBillingCountry(countryCode = null) {
            let paymentMethod = this.paymentMethod;
            let country = null;

            if(!countryCode && paymentMethod) {
                if (typeof paymentMethod.card_country != 'undefined' && paymentMethod.card_country != '') countryCode = paymentMethod.card_country;
                else if( typeof paymentMethod.billing_details.country != 'undefined' && paymentMethod.billing_details.country != '') countryCode = paymentMethod.billing_details.country;
            }

            if (countryCode) {
                country = _.find(this.countries, (o) => { return o.code == countryCode })
                // if (country) this.form.country = country;
            }

            return country;
        },
        close(e) {
            e.stopPropagation();
            // this.modal = false;
            this.loading = false;
            this.success = false;
            this.errors = [];
            this.fails = false;
            this.$root.$emit('bv::hide::modal', 'payment-method')
        },
        async saveNewPaymentMethod(e) {
            this.loading = true;
            this.resetErrors(this.billingErrors);
            let on_update = false; 
            if(this.paymentMethod) {
                on_update = true;
            }
            const data = this.getBillingDetails(on_update);
            // this.loading = false;
            // console.log( data ); return;

            // Check if form is valid
            if(this.billingFormIsValid(data)) {

                sofbox.openLoader();

                // Update payment Method
                if (this.paymentMethod) {

                    const paymentMethodID = this.paymentMethod.id;

                    api.billing.updateBillingDetails(paymentMethodID, data)
                        .then(resp => {
                            if (resp.success) {

                                this.$swal('Update Billing Details', resp.message, 'success')
                                this.close(e)
                                // this.initPaymentMethod();
                                this.$emit('init-payment-method')
                                // save qst and gst tax 
                                if (this.qst_tax_num || this.gst_tax_num) {
                                    this.saveQstOrGST(this.user.account_id, this.qst_tax_num, this.gst_tax_num)
                                }

                            }
                        })
                        .catch((error) => {
                            this.$swal('Update Billing Details', error.response.data.message, 'error')
                        })
                        .finally(() => {
                            this.$emit('init-payment-method')

                            // this.renderServiceCards = false;
                            // this.$nextTick(() => {
                            //     this.renderServiceCards = true;
                            //     this.$refs['payment-methods'].initPaymentMethod();
                            // })
                        });

                } else {
                    // Add payment Method

                    const {
                        paymentMethod,
                        error
                    } = await this.stripe.createPaymentMethod(
                        'card', this.cardElement, {
                        billing_details: this.getBillingDetails(),
                        metadata:[`card_nickname-${this.form.nickname}`]
                    }
                    );

                    if (paymentMethod) {
                        this.callbackCreatePaymentMethod(paymentMethod)
                    }

                    if (error) {
                        this.loading = false;
                        this.errors.push(error.message);
                        this.fails = true;
                        sofbox.closeLoader();

                        console.log(error);
                    }
                }
            } else {
                this.showErrors(this.billingErrors);
                this.loading = false;
            }

        },
        getBillingDetails(on_update = false) {
            let billingDetails = {
                name: this.form.first_name + ' ' + this.form.last_name,
                email: this.user.email,
                address: {
                    line1: this.form.address,
                    country: this.form.country && typeof this.form.country.code != 'undefined' ? this.form.country.code : null,
                    city: this.form.city,
                    postal_code: this.form.postal_code,
                    state: this.form.state,
                }
            }

            if(on_update == true) {
                billingDetails.nickname = this.form.nickname
            }

            return billingDetails;
        },
        billingFormIsValid(form) {
            let errors = {};
            this.billingErrors = {};

            if (!form.name || !form.name.trim()) {
                errors.name = 'Name is required.';
            }
            if (!form.email || !form.email.trim()) {
                errors.email = 'Email is required.';
            }

            if (!this.form.first_name || !this.form.first_name.trim()) {
                this.billingErrors.first_name = 'First name is required.';
            }
            if (!this.form.last_name || !this.form.last_name.trim()) {
                this.billingErrors.last_name = 'Last Name is required.';
            }

            if (!this.nickname) {
                this.billingErrors.nickname = 'Card Nickname is required.';
            }

            const someAddressTruthy = this.checkProperties(form.address);

            if (!form.address || typeof form.address != 'object' || !someAddressTruthy) {
                errors.address = 'Address is required.';
            }

            this.billingErrors = _.merge(this.billingErrors, errors); 

            return helper.isEmpty(errors);
        },
        callbackCreatePaymentMethod(paymentMethod) {
            api.billing.savePaymentMethod({
                paymentMethod: paymentMethod
            })
                .then((response) => {
                    let data = response.data; //eric

                    if (data.success) {
                        // this.initPaymentMethod();
                        this.$emit('init-payment-method')

                        this.loading = false;
                        this.success = true;
                        this.errors = [];
                        this.fails = false;
                        // save qst and gst tax 
                        this.saveQstOrGST(this.user.account_id, this.qst_tax_num, this.gst_tax_num)
                        
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 401) {
                            this.$store.dispatch('Auth/resetUser', false);
                            this.$router.go(this.$router.currentRoute)
                        } else if (error.response.status == 500) {

                            this.$bvToast.toast('something went wrong!', {
                                title: 'Error',
                                variant: 'danger'
                            });
                        } else if (error.code == 'incorrect_number') {
                            this.loading = false;
                            this.errors = error.message;
                            this.fails = true;
                            this.success = false;
                        }

                        this.$bvToast.toast('something went wrong!', {
                            title: 'Error',
                            variant: 'danger'
                        })
                    }
                })
                .finally(() => {
                    this.loading = false;
                    // this.initPaymentMethod();
                    this.$emit('init-payment-method')
                });
        },
        saveQstOrGST(account_id, qst, gst) {
            let data = {
                'qst_tax_num': qst,
                'gst_tax_num': gst
            }
            
            if (!this.is_taxable) {
                data.is_exempt_gst_tax = 0;
                data.is_exempt_qst_tax = 0;
            }

            api.accounts.update(account_id, data).then(response => {
                console.log(response.data);
            });
        },
        resetErrors(errors) {
            _.forOwn(errors, (error, key) => {
                const ref = `b-errors-${key}`;
                if (typeof this.$refs[ref] !== 'undefined') this.$refs[ref].classList.add('d-none')
            });
        },
        checkProperties(obj) {
            let state = true;
            for (var key in obj) {
                let label = this.replaceAllOccurrences(key, '_', ' ');
                label = helper.capitalizeFirstLetter(label);

                if (obj[key] == null || obj[key] == "") {
                    this.billingErrors[key] = label + ' is required.'
                    state = false;
                }
            }
            return state;
        },
        replaceAllOccurrences(string, searchString, replaceString) {
            return string.split(searchString).join(replaceString);
        },
    },
    watch: {
        'has_qst_tax': function (newVal, oldVal) {
            if (!newVal) this.qst_tax_num = null
        },
        'has_gst_tax': function (newVal, oldVal) {
            if (!newVal) this.gst_tax_num = null
        }
    }
}

</script>