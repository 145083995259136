<template>
<div>
    <AppLoader />
    <vue-scroll-progress-bar @complete="handleComplete" height="0.2rem" backgroundColor="linear-gradient(to right, #067bfe, #0885ff)" style="z-index: 10000" />
    <div class="wrapper">
        <AppTourGuide></AppTourGuide>
        <!-- Sidebar  -->
        <SideBarStyle1 :items="sidebar" :logo="logo" />
        <!-- TOP Nav Bar -->
        <NavBarStyle :title="$store.getters.title" :homeURL="{ name: 'dashboard1.home' }" :logo="logo">

            <template slot="right">
                <ProfileIcon :user="user"></ProfileIcon>
            </template>
        </NavBarStyle>
        <!-- TOP Nav Bar END -->
        <div id="content-page" ref="content_page" class="content-page" :class="{'mt-5': tokenAs}">
            <transition name="router-anim">
                <router-view />
            </transition>
        </div>
       <SupportChatPopup v-if="is_client"></SupportChatPopup>

    </div>
    <FooterStyle1>
        <template v-slot:left>
            <li class="list-inline-item" v-show="false"><a href="#">Privacy Policy-test</a></li>
            <li class="list-inline-item" v-show="false"><a href="#">Terms of Use</a></li>
        </template>
        <template v-slot:right>
            Copyright 2022 <a href="#">Inboxgeek</a> All Rights Reserved.
        </template>
    </FooterStyle1>
</div>
</template>

<script>
import SupportChatPopup from '../components/inboxgeek/chat/SupportChatPopup';
import AppTourGuide from '../components/inboxgeek/AppTourGuide'
import AppLoader from '@/components/App/AppLoader'
import ProfileIcon from '../components/users/ProfileIcon'
import SideBarStyle from '@/components/App/AppSideBar'
import NavBarStyle from '@/components/App/AppNavBar'
import SideBarItems from '../FackApi/json/SideBar'
import profile from '../assets/images/user/1.jpeg'
import logo from '../assets/images/logo.png'
import {
    mapGetters
} from 'vuex'
import api from '@/api/RestClient'
import * as bot from '@/chatBots'
export default {

    name: 'Layout1',
    components: {
        AppTourGuide,
        AppLoader,
        SideBarStyle,
        NavBarStyle,
        ProfileIcon,
      SupportChatPopup
    },
    watch: {},
    mounted() {
        if (this.is_client) {
            //  bot.liveChat();
        }   
    },
    data() {
        return {
            steps: [],
            sidebar: SideBarItems,
            userProfile: profile,
            logo: logo,
            title: '***'
        }
    },
    computed: {
        ...mapGetters({
            is_client: 'Auth/isClient',
            currentUser: 'Auth/user',
            tokenAs: 'Auth/tokenAs',
        }),
        user: function () {
            return this.currentUser;
        }
    },
    methods: {
        handleComplete() {},
        rightSideBar() {}
    },
}
</script>

<style>
@import url("../assets/css/custom.css");
</style>