<template>
<div class="mt-2">
    <b-table :items="items" :busy="isBusy" :fields="fields" class="mt-3" :current-page="currentPage" :per-page="perPage" show-empty outlined>
        <template #table-busy>
            <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
            </div>
        </template>
        <template #empty="scope">
            <h6>No data found</h6>
        </template>
        <!-- A custom formatted data column cell -->
        <template #cell(status)="data">
            <span data-v-7f8d19ce="" class="badge" :class="statusClass(data.item.status)">{{ statusName(data.item.status) }}</span>
        </template>
    </b-table>
    <b-row>
        <b-col offset-md="6" offset-lg="6" sm="7" md="6" class="my-1">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0"></b-pagination>
        </b-col>
    </b-row>
</div>
</template>

<script>
import api from '@/api/RestClient'
export default {
    name: 'ResendAutomationList',
    props: {
        resend: {
            type: Object,
            default () {
                return {}
            }
        },
    },
    mounted() {
        this.resendDetails();
    },
    data() {
        return {
            isBusy: false,
            show_table: false,
            IN_STOPPING: 11,
            fields: ['id', 'email', 'status'],
            items: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    methods: {
        resendDetails() {
            this.isBusy = true;
            api.geAutomations.fetch_automation_resend_stats(this.resend.automation_id, this.resend.id).then((response) => {
                let items = response.data;
                this.items = items;
                this.totalRows = this.items.length
            }).catch((error) => {
                this.$root.$bvToast.toast(error.response.data.message, {
                    title: 'Error!',
                    variant: 'danger'
                })
            }).finally(() => {
                this.isBusy = false;

            });
        },
        statusClass(status) {
            let class_name = 'badge-primary';
            switch (status) {
                case 2:
                    class_name = 'badge-warning';
                    break;
                case 3:
                    class_name = 'badge-danger';
                    break;
                case 4:
                    class_name = 'badge-warning';
                    break;
                case 5:
                    class_name = 'badge-danger';
                    break;
                case 0:
                    class_name = 'badge-dark';
                    break;
                case 1:
                    class_name = 'badge-success';
                    break;

                default:
                    class_name = 'badge-default';
                    break;
            }
            return class_name;
        },
        statusName(status) {
            let name;
            switch (status) {
                case 2:
                    name = 'Integration no found';
                    break;
                case 3:
                    name = 'Platform error';
                    break;
                case 4:
                    name = 'Duplicate';
                    break;
                case 5:
                    name = 'Suppression';
                    break;
                case 1:
                    name = 'Sent';
                    break;

                default:
                    name = 'Received'
                    break;
            }
            return name;
        },
    }
}
</script>
