<template>
  <b-row>
    <div class="col-md-12 col-lg-12 consumption-bar">
      <div class="iq-card iq-card-block iq-card-stretch">
        <div class="iq-card-body p-4">
          <div class="row">
            <div class="col-lg-12">
              <h3>
                Hello there,
                <span class="text-primary">
                  {{ filterCapitalize(profileFirstname, true) }}
                </span>
              </h3>
              <p>You have used {{ percent }}% of your {{ labelEventService }} this month so far.</p>
            </div>
          </div>

          <div v-if="isAccountActive" class="row p-4" style="font-size: 0.7em;">
            <div class="col-lg-2">
              <div class="consumption-bar-plan-used pr-1">
                <div v-if="loading" class="d-flex justify-content-center">
                  <b-spinner small label="Small Spinner" type="grow"></b-spinner>
                </div>
                <h3 v-else class="p-0">
                  <span class="consumption-bar-balance">
                    <span>{{ events }} / {{ filterFormatNumber(balance) }} </span>
                    events sent
                  </span>
                </h3>
                <h5><strong>{{ percent }}%</strong> of plan Used</h5>
              </div>
            </div>
            <div class="col-lg-3 d-flex">
              <h6 class="consumption-bar-title">Events Remaining:</h6>
              <div v-if="loading" class="d-flex justify-content-center">
                <b-spinner small label="Small Spinner" type="grow"></b-spinner>
              </div>
              <h3 v-else class="consumption-bar-number">{{ filterFormatNumber(remaining) }}</h3>
              <!-- remaining -->
            </div>
            <div class="col-lg-4 ">
              <div class="d-flex">
                <h5 class="consumption-bar-title">Overconsumption Limit : </h5>
                <div v-if="loading" class="d-flex justify-content-center">
                  <b-spinner small label="Small Spinner" type="grow"></b-spinner>
                </div>
                <div v-else>
                  <h3 class="consumption-bar-number">

                    <span v-if="limit == -1"><small class="small">Unlimited</small></span>
                    <span v-else>{{ filterFormatNumber(limit) }}</span>

                    <small>

                      <b-button v-if="!freeAccount" v-b-modal.update-event-limit @click="getCurrentBalanceLimit"
                        class="bg-white border-0 text-primary" style="padding-left: 7px;padding-right: 2px;">

                        <i class="fas fa-pen"></i>
                      </b-button>
                    </small>
                  </h3>

                  <br>

                </div>
              </div>
              <small class="d-block">
              </small>
            </div>
            <div class="col-lg-3 d-flex">
              <h5 class="consumption-bar-title">Contacts :</h5>
              <div v-if="loading" class="d-flex justify-content-center">
                <b-spinner small label="Small Spinner" type="grow"></b-spinner>
              </div>
              <h3 v-else class="consumption-bar-number">{{ filterFormatNumber(contacts) }}</h3>
            </div>
          </div>
          <!-- <br> -->
          <div v-if="isAccountActive" class="row p-4">
            <div class="iq-progress-bar-linear d-inline-block w-100 float-left mt-3">
              <b-progress :max="100" height="1rem" data-toggle="tooltip" v-b-tooltip.hover top
                :title="`${events} (${Number(percent).toFixed(2)}%)` + ` | ${remainingFormatNumber} Events Remaining`">
                <b-progress-bar :value="percent" :label="`${events} (${Number(percent).toFixed(2)}%)`"></b-progress-bar>
              </b-progress>
            </div>
          </div>

        </div>

        test test

        <div v-if="isAccountActive" class="row  p-4">

          <div v-if="freeAccount" class="col-md-12">
            <span class="px-2">You are in a trial account until <strong>{{ plan.next_billing_date }}</strong>.</span>
            <br>
            <span class="px-2">You can change your plan here.</span>
          </div>

          <div class="col-md-12" :class="{ 'text-primary': !freeAccount, 'text-warning': freeAccount }">
            <span v-if="freeAccount" class="account-status">You are in free account you can <router-link
                :to="{ name: 'user.billing' }">change your plan</router-link> now</span>
            <span v-if="percent <= 20" class="account-status text-secondary">You've got plenty of events remaining for
              the month.</span>
            <span v-if="percent > 20 && percent <= 80" class="account-status text-secondary">You're right on schedule
              with your plan.</span>
            <span v-if="percent > 80" class="account-status text-warning">Whoa! You might need a few more events this
              month. Upgrade <router-link :to="{ name: 'user.plan' }">here</router-link>.</span>
            <p></p>
          </div>
        </div>

        <div v-if="!isAccountActive" class="row p-4">
          <div class="col-md-12">
            <b-alert :show="true" variant="danger" class="bg-white">
              <div class="iq-alert-icon">
                <i class="ri-information-line"></i>
              </div>
              <div class="iq-alert-text">Your Account is Currently <b>Inactive</b>.</div>
            </b-alert>
          </div>
        </div>
      </div>
    </div>


    <b-modal v-if="!freeAccount" id="update-event-limit" ref="updateLimitModal" hide-footer
      title="Update your Overconsumption limit">

      <b-form @submit="onSubmit" class="p-4 pb-0">

        <b-form-group id="input-group-1" label-for="input-1" description="">
          <b-row align-h="between" class="px-3">
            <label for="input-1">Overconsumption limit :</label>

            <b-form-checkbox switch v-model="is_unlimited">Unlimited</b-form-checkbox>


          </b-row>

          <b-form-input v-if="!is_unlimited" id="input-1" type="number" placeholder="Overconsumption limit"
            v-model="form.limit" required></b-form-input>
          <b-form-input v-else id="input-1" readonly placeholder="unlimited"></b-form-input>
        </b-form-group>
        <hr>

        <b-row class="text-right px-3 mb-0 pb-0" style="justify-content:end;">

          <b-button variant="primary" type="submit" class="text-right"> Update <b-spinner small type="grow"
              v-show="modal_loader"></b-spinner> </b-button>

        </b-row>

      </b-form>
    </b-modal>



  </b-row>

  <!-- Info modal -->
</template>

<script>
import api from '@/api/RestClient'
import {mapGetters} from 'vuex'
import {helper} from '@/helpers'
import {SERVICES_BY_ID} from "@/constantes";


export default {
  name: 'ConsumptionBar',
  mounted() {
    this.GetConsumptionData();
  },
  props: {
    service: {
       type: Number,
       default: 1
    }
  },
  data() {
    return {
      is_unlimited: false,
      form: {
        limit: 0
      },
      loading: false,
      modal_loader: false,
      events: 0,
      limit: 0,
      remaining: 0,
      contacts: 0,
      percent: 0,
      balance: 0,
      plan: null,
      progress: false,
      freeAccount: false,
      profile: null,
      isAccountActive: true,
      showEditOverconsumption: false
    }
  },
  methods: {
    GetConsumptionData() {
      this.progress = true;
      this.loading = true;
      api.stats.getConsumptionDataService(this.service)
        .then(response => {
          if (response.success) {
            const data = response.data;
            this.events = data.events;
            this.limit = data.limit ? data.limit : 0;
            this.remaining = data.remaining;
            this.contacts = data.contacts;
            this.percent = data.percent;
            this.balance = data.plan.balance;
            this.plan = data.plan;
            this.freeAccount = data.plan.is_trial == 1;
            this.$store.dispatch('updateFreeAccount', this.freeAccount);

            if (!this.clientHasBalance()) {
              // helper.showAlertRemaining(this, data)
            }

            // Show alert if account inactive
            if (!data.account.status) {
              this.isAccountActive = false
              helper.alertAccountInactive(this.$swal)
            }

            if (this.$store.getters['Auth/user'].profile) {
              this.profile = this.$store.getters['Auth/user'].profile
            }
          }
        })
        .catch(function (error) {
          if (error.response) {
            // handle error
          }
        })
        .finally(() => {
          this.progress = false;
          this.loading = false;
        });
    },
    clientHasBalance() {
      return this.isClient && ( (this.remaining + this.limit >  0) || this.limit !== -1)
    },
    onSubmit(e) {

      this.modal_loader = true;
      e.preventDefault();
      if (this.freeAccount) return;
      if (this.is_unlimited) this.form.limit = -1;

      const data = {
        limit: this.form.limit
      };

      api.balances.updateLimit(data).then(response => {

        if (response.success) {

          const data = response.data;
          this.limit = data.limit;

          this.$bvToast.toast('Limit successfully updated', {
            title: 'Success',
            variant: 'success'
          });

          this.$refs.updateLimitModal.hide();
        }

        this.modal_loader = false;

      }).catch(error => {
        this.modal_loader = false;

        this.$bvToast.toast('failed to update the limit', {
          title: 'Error',
          variant: 'danger'
        });
      })


    },
    getCurrentBalanceLimit() {
      this.is_unlimited = (this.limit == -1);

      this.form.limit = (this.is_unlimited) ? 0 : this.limit;
    },
    labelEventService() {
      return SERVICES_BY_ID[this.service].consumed;
    },
    filterCapitalize(value, all = null) {
      return helper.customCapitalize(value, all)
    },
    filterFormatNumber(value) {
      return helper.formatNumber(value)
    }
  },
  computed: {
    ...mapGetters({
      isClient: 'Auth/isClient',
    }),
    profileFirstname() {
      if (!this.profile) {
        return ';)';
      }
      return this.profile?.first_name;
    },
    remainingFormatNumber() {
      return this.remaining.toLocaleString();
    }
  }
}
</script>

<style>
.consumption-bar-balance {
  font-size: 12px;
}

.account-status {
  padding-left: 10px;
  display: block;
}

@media (max-width: 991px) {
  .consumption-bar {
    font-size: 12px;
  }

  .consumption-bar-number {
    font-size: 21px;
    line-height: 2;
  }

  .consumption-bar-plan-used {
    /*border: none;*/
  }

  .consumption-bar div.col-lg-3 {
    max-width: 50%;
  }

  .consumption-bar-title {
    display: block;
    width: 100%;
    white-space: nowrap;
  }
}
</style>
