import api from '@/api/RestClient';


export default {

    id : null,
    name : 'klaviyo' ,
    hasRecurrence:true,

    /*
    * --------------------------------------------------------
    * Fetch Reception Lists
    * --------------------------------------------------------
    * */
    fetchIntegrationReception : function () {

        return new Promise((resolve, reject) => {

            if( ! this.id ) {

                reject('Platform id not provided');
            }

            api
                .platforms
                .fetchServices({ platform_name: this.name , entities:'lists' ,platform_id:  this.id })
                .then(response => {
                    resolve({
                        success : response.success,
                        data : response.data
                    });

                })
                .catch( ( error ) => {


                    reject(error);

                } )

        });
    },

    /*
    * --------------------------------------------------------
    * Fetch Tags
    * --------------------------------------------------------
    * */
    fetchIntegrationTriggers : function ()  {


        return new Promise((resolve, reject) => {

            if( ! this.id ) {

                reject('Platform id not provided');
            }

            api
                .platforms
                .fetchServices({ platform_name: this.name , entities:'tags' ,platform_id:  this.id })
                .then(response => {
                    resolve({
                        success : response.success,
                        data : response.data
                    });

                })
                .catch( ( error ) => {


                    reject(error);

                } )

        });
    }
}



