<template>

    <b-container fluid>

        <b-row>

            <b-col md="12">

                <label class="mr-2">Custom Track Event: *</label>

                <b-form-input v-model="eventName" name="tag" placeholder="Custom Track Event" @input="updateValue">
                </b-form-input>
            </b-col>

        </b-row>

    </b-container>

</template>
<script>

import LimitPerDayInput from "@/components/inboxgeek/fields/integration/LimitPerDayInput";

export default {
    name: 'BrevoLoIntegrationStep',
    components: {
        LimitPerDayInput,
    },
    props: {
        form: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            eventName: null,
        }
    },
    methods: {
        handleSelectTag() {
            this.$emit('tag', this.integration)
        },
        updateValue() {
            this.$emit('tag', {tag: {code: null, name: this.eventName}, type: 'custom_event'})
            // this.$emit('customEvent', {code: null, name: this.eventName});
        },
        handleUpdateLimitPerDay(data) {
            this.$emit('limitPerDay', data)
        },
    }
}

</script>