<template>

    <div class="d-flex justify-content-center my-auto" 
        v-b-tooltip v-b-tooltip.hover
        :title="`${row.value}${(row.item.platform_account ? ' • ' + row.item.platform_account : '')}`">

        <span v-if="!row.value" class="badge badge-light mx-2">Not Defined</span>
        <span v-else class="badge badge-light mx-1 my-auto">
            <span v-if="getPlatformImage(row.value)" class="mx-2">
                <img style="width: 1.3em;" :src="getPlatformImage(row.value)">
            </span>

            <span class="mx-2">{{ row.item.platform_nickname ?? row.value }}</span>
            <span v-if="!row.value" class="mx-2">{{ row.item.platform_nickname ?? row.value }}</span>

        </span>

    </div>

</template>
<script>

import { helper } from '@/helpers';

export default {
    name: 'DtListPlatformCell',
    props: ['row'],
    methods: {
        getPlatformImage(platform) {
            try {
                const imgFileName = platform.toLowerCase();
                return require(`@/assets/images/platforms/${imgFileName}.png`);
            } catch (e) {
                return null;
            }
        }
    }
}

</script>