<template>

    <b-form ref="form-other-settings" @submit.prevent="updateOtherSettings" class="mb-5">
        <b-row class="text-primary">
            <b-col>

                <b-col v-for="(item, index) in otherSettings" :key="index" md="12" class="mt-2 pl-0 py-1 d-flex">
                    <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color my-auto">
                        <div class="custom-switch-inner cursor-pointer">
                            <input type="checkbox" :id="`user_settings-${notifAttr(item)}`"
                                class="custom-control-input bg-primary" 
                                v-model="form.otherSettings[notifAttr(item)]"
                                @input="updateOtherSettings()" 
                                :class="update_spinner ? 'disabled' : ''"
                                :disabled="update_spinner">

                            <label :for="`user_settings-${notifAttr(item)}`" data-on-label="ON" data-off-label="OFF" class="custom-control-label"></label>
                        </div>

                        <label class="ml-2 text-primary cursor-pointer my-auto"
                            :for="`user_settings-${notifAttr(item)}`" 
                            :disabled="update_spinner">
                            {{ customCapitalize(item.name) }}
                        </label>
                    </div>

                    <input v-if="(currSettingEmail == item) || (form.emails[notifAttr(item)])" 
                        :id="'email' + index"
                        :ref="'email' + index" 
                        type="email" 
                        placeholder="Enter email" 
                        class="form-control ml-auto"
                        v-model="form.emails[notifAttr(item)]" 
                        style="width: 70%;"
                        :readonly="update_spinner || !form.otherSettings[notifAttr(item)]">

                </b-col>

            </b-col>
        </b-row>
        <b-button type="submit" variant="primary" class="float-right mr-3 mt-2" :disabled="update_spinner">Save
            <b-spinner small type="grow" v-show="update_spinner"></b-spinner>
        </b-button>
    </b-form>

</template>
<script>

import api from '@/api/RestClient'
import { helper } from '@/helpers'
import { mapGetters } from 'vuex'
import _ from 'lodash';


export default {
    name: 'UserCustomSettings',
    props: {
        otherSettings: {
            type: Array,
            default: []
        },
        form: {
            type: Object,
            default: () => {}
        },
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            user: 'Auth/user',
            isOwnerAccount: 'Auth/isOwnerAccount',
            UserApiToken : 'Auth/UserApiToken'
        }),
    },
    data() {
        return {
            update_spinner: false,
            currSettingEmail: null,
            errors: [],

        }
    },
    methods: {
        updateOtherSettings() {

            let data = [];

            helper.delay(500).then(() => {

                this.otherSettings.forEach(setting => {

                    let value = this.form.otherSettings[this.notifAttr(setting)]
                    let settingData = { ...setting, value: value }

                    data.push(settingData)
                });

                if (helper.isEmpty(this.errors)) {
                    const userID = this.$store.getters['Auth/user'].id;
                    this.updateSettings(userID, data);
                }
            })

        },
        notifAttr(notif) {
            return notif ? notif.name.toLowerCase().split(" ").join("_") : ''; // convert to snake case
        },
        customCapitalize(str, forceAll = false) {
            return helper.customCapitalize(str, forceAll);
        },
        updateSettings(id, data) {

            this.update_spinner = true;

            api.userSettings.saveUserSettings(id, data)
                .then(response => {
                    if (response.success) {
                        console.log(response.data)
                        this.toast('Updated!', 'success', response.message);
                    }
                }).catch(error => {
                    let message = ''
                    if (error.response) {
                        if (error.response.status == 500) {
                            message = 'Something went wrong!';
                        } else {
                            message = error.response.message;
                        }

                        this.toast('Error!', 'danger', message);
                    }


                }).finally(() => {
                    helper.delay(2000).then(() => {
                        this.update_spinner = false
                    })
                });

        },
        toast(title = "success", variant = "success", message = "Account charged") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        },
    }
}

</script>