<template>

    <b-col v-if="isAdmin
        || isAccounting
        || isAccountManager">

        <b-row class="row">
            <!-- Daily events-->
            <b-col lg="12" md="12" v-if="isAdmin || isAccountManager">
                <b-tabs :pills="true" class="nav-fill" id="pills-chart">
                    <b-tab :active="true" id="liveOpenersChart-tab-fill" href="#liveOpenersChart"
                        ariaControls="liveOpenersChart" role="tab" :ariaSelected="true" title="LiveOpens" class="mb-3">

                        <!-- Top Revenue Stats LiveOpens -->
                        <b-row class="my-3">
                            <b-col v-for='(card, index) in cards' :key='index' md="6" lg="3">
                                <DashboardStatsCard :period="card.period" :valueUnit="card.valueUnit"
                                    :label="card.label" :itemClass="card.itemClass" :iconClass="card.iconClass">
                                </DashboardStatsCard>
                            </b-col>
                        </b-row>

                        <!-- LiveOpens Stats Events -->
                        <CustomKpis :options="events_daily"></CustomKpis>


                        <b-row>

                            <!-- LiveOpens Organization per subscription-->
                            <b-col lg="12" md="12">
                                <DashChart :options="lo_organization_per_subscription"></DashChart>
                            </b-col>
                            <!-- End LiveOpens Organization per subscription-->


                            <!-- active organization-->
                            <b-col lg="7" md="12">
                                <DashChart :options="lo_organization_stats"></DashChart>
                            </b-col>
                            <!-- end active organization-->

                            <!-- Organization paused/cancelled-->
                            <b-col lg="5" md="12">
                                <DashChart :options="lo_organization_cancelled_or_paused"></DashChart>
                            </b-col>
                            <!--end  Organization paused/cancelled-->

                        </b-row>

                    </b-tab>

                    <b-tab :active="false" id="liveLeadsChart-tab-fill" href="#liveLeadsChart-fill"
                        ariaControls="liveLeadsChart-fill" role="tab" :ariaSelected="false" title="LiveLeads"
                        class="mb-3" @click="loadLiveLeadsChart">

                        <!-- Top Revenue Stats LiveLeads -->
                        <b-row class="my-3">
                            <b-col v-for='(card, index) in cards' :key='index' md="6" lg="3">
                                <LlDashboardStatsCard :period="card.period" :valueUnit="card.valueUnit"
                                    :label="card.label" :itemClass="card.itemClass" :iconClass="card.iconClass"
                                    :service="LIVE_LEADS">
                                </LlDashboardStatsCard>
                            </b-col>
                        </b-row>

                        <!-- LiveLeads Stats Events -->
                        <LiveLeadsChart :options="liveLeads_daily" ref="liveLead"></LiveLeadsChart>

                        <b-row>

                            <!-- LiveLeads Organization per subscription-->
                            <b-col lg="12" md="12">
                                <DashChart :options="ll_organization_per_subscription"></DashChart>
                            </b-col>
                            <!-- End LiveLeads organization per subscription-->


                            <!-- active organization-->
                            <b-col lg="7" md="12">
                                <DashChart :options="ll_organization_stats"></DashChart>
                            </b-col>
                            <!-- end active organization-->

                            <!-- Organization paused/cancelled-->
                            <b-col lg="5" md="12">
                                <DashChart :options="ll_organization_cancelled_or_paused"></DashChart>
                            </b-col>
                            <!--end  Organization paused/cancelled-->

                        </b-row>

                    </b-tab>
                </b-tabs>

            </b-col>


            <!-- Revenues -->
            <b-col v-if="isAdmin" lg="12" md="12">
                <RevenueStatsChart :options="revenues"></RevenueStatsChart>
            </b-col>
            <!-- end Revenues-->
        </b-row>
    </b-col>

</template>
<script>
import { mapGetters,mapState,mapMutations } from 'vuex'
import DashboardStatsCard from '@/components/charts/DashboardStatsCard.vue'
import LlDashboardStatsCard from '@/components/charts/LlDashboardStatsCard.vue'
import CustomKpis from '@/components/charts/CustomKpis.vue'
import DashChart from './chart.vue'
import LiveLeadsChart from '@/components/charts/CustomKpis.vue'
import RevenueStatsChart from '@/components/charts/RevenueStatsChart.vue'
import { helper } from '@/helpers'
import { SERVICES } from "@/constantes";

const week = 1
const month = 2
const day = 0
const year = 3

export default {
    name: 'AdminDashboard',
    props: {},
    components: {
        DashboardStatsCard,
        LlDashboardStatsCard,
        CustomKpis,
        DashChart,
        LiveLeadsChart,
        RevenueStatsChart
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            client: 'Auth/isClient',
            isAccounting: 'Auth/isAccounting',
            isAccountManager: 'Auth/isAccountManager',
            user: 'Auth/user',
            liveOpenersSubscribed: 'Auth/liveOpenersSubscribed',
            liveLeadsSubscribed: 'Auth/liveLeadsSubscribed',
            UserSubscriptions: 'Auth/UserSubscriptions',
            lastSubscriptions: 'Auth/lastSubscriptions'
        }),
    },
    data() {
        return {
            LIVE_LEADS: SERVICES.LIVE_LEADS.id,
            cards: [
                {
                    label: 'Today',
                    itemClass: 'iq-bg-success',
                    iconClass: 'ri-calendar-line',
                    valueUnit: 'Events',
                    period: "today"
                },
                {
                    label: 'Yesterday',
                    itemClass: 'iq-bg-danger',
                    iconClass: 'ri-calendar-line',
                    valueUnit: 'Events',
                    period: "yesterday"
                },
                {
                    label: 'This Week',
                    itemClass: 'iq-bg-warning',
                    iconClass: 'ri-calendar-line',
                    valueUnit: 'Events',
                    period: "week"
                },
                {
                    label: 'This Month',
                    itemClass: 'iq-bg-primary',
                    iconClass: 'ri-calendar-line',
                    valueUnit: 'Events',
                    period: "month"
                },
            ],

            events_daily: {
                url: 'dashboard/admin/charts',
                period: day,
                title: 'Events',
                element: 'oxu5',
                axis_type: 'category',
                key: 5,
                itemLabel: "event_id",
                exceptPeriod: [year],
                exceptDatesFor: [week, month],
                indicator: ""
            },
            lo_organization_per_subscription: {
                url: 'dashboard/admin/stats',
                period: week,
                title: 'LiveOpens Organization/Subscription',
                element: 'oxu8',
                axis_type: 'category',
                key: 3,
                service: 1,
            },
            lo_organization_stats: {
                url: 'dashboard/admin/stats',
                period: week,
                title: 'LiveOpens Active Organizations',
                element: 'oxu2',
                axis_type: 'category',
                key: 2,
                service: 1,
            },
            lo_organization_cancelled_or_paused: {
                url: 'dashboard/admin/stats',
                period: week,
                title: 'LiveOpens Orgs cancelled/paused',
                element: 'oxu4',
                axis_type: 'category',
                key: 4,
                service: 1,
            },
            liveLeads_daily: {
                url: 'dashboard/admin/charts',
                period: day,
                title: 'LiveLeads Events',
                element: 'oxu7',
                axis_type: 'category',
                key: 7,
                itemLabel: "event_id",
                exceptPeriod: [year],
                exceptDatesFor: [week, month],
                indicator: ""
            },
            ll_organization_stats: {
                url: 'dashboard/admin/stats',
                period: week,
                title: 'LiveLeads Active Organizations',
                element: 'oxu22',
                axis_type: 'category',
                key: 2,
                service: 2,
            },
            ll_organization_per_subscription: {
                url: 'dashboard/admin/stats',
                period: week,
                title: 'LiveLeads Organization/Subscription',
                element: 'oxu88',
                axis_type: 'category',
                key: 3,
                service: 2,
            },
            ll_organization_cancelled_or_paused: {
                url: 'dashboard/admin/stats',
                period: week,
                title: 'LiveLeads Orgs cancelled/paused',
                element: 'oxu44',
                axis_type: 'category',
                key: 4,
                service: 2,
            },
            revenues: {
                url: 'stats/charts/revenues',
                period: day,
                title: 'Revenues',
                element: 'oxu6',
                axis_type: 'category',
                key: 6,
                exceptPeriod: [week],
                itemLabel: "plan_id",
                indicator: "$"
            },
        }
    },
    methods: {
        loadLiveLeadsChart() {
            if (this.$refs['liveLead'] && typeof this.$refs['liveLead'].getStats == 'function') {
                helper.delay(2000).then(() => {
                    this.$refs['liveLead'].getStats();
                })
            }
        },
    }
}

</script>