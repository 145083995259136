<template>
  <div>

    <b-row>
      <b-col>
        <label for="">Platform: *</label>
        <exist-platforms @platformSelected="handelPlatformSelectedEvent" :selectedPlatform="platform"
          :account="{ accountid: account }"></exist-platforms>
        <small v-if="errors?.platform" class="text-danger">A platform should be selected</small>
      </b-col>
    </b-row>

    <NavigationLoWizard

        :isLoading      = "isLoading"
        :valid          = "valid"
        :hideBackButton = "true"

        @cancel="$emit('cancel')"
        @next="next"

    ></NavigationLoWizard>

  </div>


</template>

<script>

import ExistPlatforms from "@/components/inboxgeek/ExistPlatforms";
import NavigationLoWizard from "@/components/inboxgeek/modals/Wizards/LoIntegration/NavigationLoWizard";

export default {
  name: "PlatformLoCreateIntegrationStep",
  props: ['account', 'form'],
  components: { ExistPlatforms ,NavigationLoWizard},
  mounted() {

    if(this.form.platform) {
      this.platform =  this.form.platform
      this.platform.code =  this.platform.id
    }

    // Listener of valid api key
    this.$root.$on('valid-api-key', (data) => {
      this.apiKeyIsValid = data.state;
      this.isLoading = false;
    });
  },
  data() {
    return {
      isLoading: false,
      apiKeyIsValid: false,
      errors: {
        platform: false
      },
      platform: null
    }
  },
  methods: {

    next() {

      this.errors = { 
        platform: false 
      };

      if (!this.platform || !this.platform?.name) {
        this.errors.platform = true;
        return;
      }

      this.$emit('next', { platform: this.platform });
    },
    handelPlatformSelectedEvent(platform) {
      if (platform) {
        this.isLoading = true;
        this.platform = platform;
        this.$emit('preloadLists', platform);
      }
    },
  },
  computed: {
    valid() {

      return !(!this.platform || !this.platform?.name);

    }
  }

}

</script>