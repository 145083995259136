<template>
<b-row>
    <b-form-group label="List *" label-for="list" class="col-md-12">

        <multiselect v-model="list" tag-placeholder="Empty" placeholder="Select list" label="name" track-by="code" :options="lists" :multiple="true" return="code" :allow-empty="true" :taggable="true" @select="handleSelectList" @input="handleSelectList" @remove="handleSelectList" :loading="isLoading">

            <template slot="option" slot-scope="props">
                <div class="option__desc">

                    <span class="option__title">

                        <span v-if="props.option.type">

                            <i v-if="props.option.type == 'segment'" class="ri-flashlight-line" style="color:#5ad"></i>
                            <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                        </span>

                        <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                        <span style="font-wight:bold;"> {{ props.option.name }} </span>

                        <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>

                    </span>

                </div>

            </template>

        </multiselect>

        <small v-if="errors?.list" class="text-danger">A list should be selected</small>
    </b-form-group>
</b-row>
</template>

<script>
import Multiselect from 'vue-multiselect'

import api from "@/api";

export default {
    name: 'getresponseLoListStep',
    components: {
        Multiselect
    },
    props: {
        form: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            list: null,
            lists: [],
            isLoading: false
        }
    },
    mounted() {
        this.getLists();
    },
    methods: {
        handleSelectList(itemSelected) {
            this.$emit('select', this.list)
        },
        getLists() {
            this.isLoading = true;
            let platform = this.form.platform.platform;
            let params = {
                entities: 'lists',
                platform_id: platform.id,
                platform_name: platform.name.toLowerCase(),
            };
            api.platforms.fetchServices(params).then((response) => {
                let lists = response.data
                this.lists = lists.map((list) => {
                    return {
                        'code': list.id,
                        'name': list.name,
                        'type': list.type,
                    }
                });
            }).catch((error) => {
                let message = error.response.data.message;
                this.$swal('Error Lists', message, 'error');
            }).finally(() => {
                this.isLoading = false;
            })
        },
    }
}
</script>
