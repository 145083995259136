<template>
    <b-container fluid>
        <span v-if="row.item.active  == 1" class="badge cursor-pointer badge-secondary badge-pill iq-bg-success">Active</span>
        <span v-else class="badge cursor-pointer badge-secondary badge-pill iq-bg-danger">Inactive</span>
    </b-container>
</template>

<script>

export default {
    name: 'PlanStatusCell',
    props: ['row'],
}
</script>
