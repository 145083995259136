<template>

    <b-container fluid>

        <b-table borderless hover :items="filteredItems.length ? filteredItems : accounts" :fields="fields" :current-page="currentPage" :per-page="perPage"
        :filter="filter" :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection" :busy="isLoading" stacked="md" show-empty @filtered="onFiltered">

            <template #table-busy>
                <div class="text-center p-4 d-flex justify-content-center align-items-center">
                    <b-spinner variant="primary"> </b-spinner>
                    <span class="text-primary p-2"><small> Fetching Accounts, Please wait...</small></span>
                </div>
            </template>

            <template #cell(type)="row">
                {{ row.item.type_name }}
            </template>

            <template #cell(billing_address_country)="row">
                {{ row.item.billing_address_country ? countryNameFromCode(row.item.billing_address_country) : '--' }}
            </template>

            <template #cell(user)="row">
                {{ row.item.user ? row.item.user.name : '--' }}
            </template>

            <template #cell(created_at)="row">
                {{ dateFormat(row.item.created_at) }}
            </template>

            <template #cell(status)="row">
                <StatusCell :row="row" />
            </template>

            <template #cell(referral)="row">
                {{ row.item.referral ? row.item.referral.name : '--' }}
            </template>

            <template #cell(actions)="row">

                <b-dropdown>
                    <template #button-content variant="light">
                        <!-- For Dropdown -->
                        <i v-if="!row.item.isLoading" class="ri-more-2-fill fa-lg mx-auto btn btn-light"></i>

                        <b-spinner v-else small label="Small Spinner" type="grow" class="m-2 text-dark"></b-spinner>
                        <!-- End Dropdown -->
                    </template>
                    <b-dropdown-item v-if="row.item.type_name == 'client' && isAdmin"
                        @click="showDetails('Subscriptions', row.item)">
                        <b-button v-b-tooltip.top="'Account Subscription'" variant="light iq-bg-light"
                            class="btn-sm mx-1 bg-white" size="sm"><i class="ri-indent-decrease"></i></b-button>
                        Subscriptions
                    </b-dropdown-item>

                    <b-dropdown-item v-show="isAdmin" @click="showDetails('Balances', row.item)">
                        <b-button v-b-tooltip.top="'Account Balance(s)'" variant="light iq-bg-light"
                            class="btn-sm mx-1 bg-white" size="sm"><i class="ri-bill-line"></i></b-button>
                        Balances
                    </b-dropdown-item>

                    <b-dropdown-item v-show="isAdmin" @click="showDetails('Discount', row.item)">
                        <b-button v-b-tooltip.top="'Show Account Discount(s)'" variant="light iq-bg-light"
                            class="btn-sm mx-1 bg-white" size="sm"><i class="ri-bill-line"></i></b-button>
                        Discount
                    </b-dropdown-item>

                    <b-dropdown-item @click="edit(row.item)">
                        <b-button v-b-tooltip.top="'Edit Account'" variant=" iq-bg-info mr-1 mb-1" size="sm"><i
                                class="ri-ball-pen-fill m-0"></i></b-button>
                        Edit
                    </b-dropdown-item>

                    <b-dropdown-item @click.prevent="showBillingInfo(row)">
                        <b-button v-b-tooltip.top="billing_info_title" variant=" iq-bg-secondary mr-1 mb-1" size="sm"><i
                                class="ri-arrow-down-circle-line m-0"></i></b-button>
                        {{ billing_info_title }}
                    </b-dropdown-item>

                    <b-dropdown-item v-if="(row.item.id != user.id) && row.item.user && isAdmin" @click="loginAs(row.item)">
                        <b-button v-b-tooltip.top="'Login as Owner: ' + row.item.user.email"
                            variant=" iq-bg-success mr-1 mb-1" size="sm"><i class="ri-login-box-line m-0"></i></b-button>
                        Login as Owner
                    </b-dropdown-item>

                    <b-dropdown-item v-show="isAdmin" @click="destroy(row.item)">
                        <b-button v-b-tooltip.top="'Delete Account'" variant=" iq-bg-danger mr-1 mb-1" size="sm"><i
                                class="ri-delete-bin-line m-0"></i></b-button>
                        Delete Account
                    </b-dropdown-item>
                </b-dropdown>

            </template>

            <!-- <template v-slot:row-details="{ row }"> -->
            <template v-slot:row-details="data">
                <iq-card class="text-left my-1">
                    <p v-if="data.item.billing_address_main" class="my-1">
                        <strong>{{ data.item.billing_address_main }}</strong>,
                        {{ data.item.billing_address_country ? countryNameFromCode(data.item.billing_address_country) : '--'
                        }},
                        {{ data.item.billing_address_state ?? '--' }},
                        {{ data.item.billing_address_city ?? '--' }}
                    </p>
                    <p v-if="['CA'].includes(data.item.billing_address_country)" class="my-1">
                        <strong>GST Tax:</strong> {{ data.item.gst_tax_num ?? '--' }},
                        <strong>QST Tax:</strong> {{ data.item.qst_tax_num ?? '--' }}
                    </p>
                </iq-card>
            </template>

        </b-table>

        <!-- START PAGINATION TABLE-->
        <b-row align-h="end">

            <b-col md="3" class="my-1">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="fill"
                    size="sm"
                    class="my-0"
                ></b-pagination>
            </b-col>
        </b-row>
        <!-- END PAGINATION TABLE-->
    </b-container>

</template>

<script>

import { mapGetters } from 'vuex';
import StatusCell from '@/components/inboxgeek/tables/StatusCell.vue'
import Subscriptions from '../widgets/Subscriptions.vue'
import Balances from '../widgets/Balances.vue'
import Discount from '../widgets/Discount.vue'
import { BILLING_COUNTRIES } from '@/utils/countries';
import { ACCOUNT_TYPE } from '../../../constantes';
import { SERVICES } from "@/constantes";
import moment from 'moment'

import api from '@/api/RestClient';
import { helper } from '@/helpers'


export default {
    name: 'AccountsTable',
    components: {
        StatusCell,
        Subscriptions,
        Balances,
        Discount
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            isAccounting: 'Auth/isAccounting',
            user: 'Auth/user',
            liveOpenersSubscribed: 'Auth/liveOpenersSubscribed',
            liveLeadsSubscribed: 'Auth/liveLeadsSubscribed',
        })
    },
    data() {
        return {
            isLoading: false,
            accounts: [],
            filteredItems: [],
            fields: [
                { key: 'id', label: '#', sortable: true, sortDirection: 'desc' },
                { key: 'name', label: 'Name', sortable: true, class: 'text-left' },
                { key: 'type', label: 'Type', sortable: true, class: 'text-center' },
                { key: 'referral', label: 'Affiliate', sortable: true, class: 'text-center' },
                { key: 'billing_address_country', label: 'Country', sortable: true, class: 'text-center' },
                { key: 'user', label: 'Owner', sortable: false, class: 'text-center' },
                { key: 'created_at', label: 'Creation date', sortable: true, class: 'text-center' },
                { key: 'status', label: 'Status', sortable: true, class: 'text-center' },
                { key: 'actions', label: 'Actions', class: 'text-center' }
            ],
            totalRows: 1,
            currentPage: 1,
            sortBy: "",
            perPage: 25,
            pageOptions: [25, 50, { value: 999, text: "Show all" }],
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            billing_info_title: 'Show Billing Info',
        }
    },
    mounted() {
        this.fetchAccounts();
        this.loadFields();

        this.$root.$on('accounts:filter', (filter) => {
            this.onAccountsFiltered(filter);
        })

        this.$root.$on('ibg:fetch:accounts', () => {
            this.fetchAccounts()
        })
    },
    methods: {
        fetchAccounts(){

            this.isLoading = true;

            api.accounts.all({per_page:-1})
                .then((response)=>{

                    this.accounts = response.data.map((account)=>{

                        account.type_name = this.typeName(account.type);
                        account._showDetails = false;

                        return account;
                    });

                    this.totalRows = this.accounts.length;

                    this.isLoading = false;
                }).catch((error)=>{
                    this.isLoading = false;
                    this.accounts = [];
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    });
                });

        },
        loadFields() {
            let fields = _.map(this.fields, (item) => {
                if (this.isAdmin || this.isAccounting) {
                    return item;
                } else
                    if (!["actions"].includes(item.key)) return item;
            })
            this.fields = fields;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        countryNameFromCode(code) {
            let country = _.find(BILLING_COUNTRIES, (o) => { return o.code == code; });
            if (country) return country.name;

            return code;
        },
        dateFormat(date) {
            return moment(date).format('YYYY-MM-DD'); //DD MMM YYYY
        },
        showDetails(component, account) {

            // TODO Remove all previous row isLoading 
            let itemsIsLoading = _.filter(this.accounts, (o) => { return o.isLoading });
            itemsIsLoading.forEach(item => {
                item.isLoading = false;
            });

            setTimeout(() => {

                let item = _.find(this.accounts, (o) => { return o.id == account.id })
                if (item) {
                    item.isLoading = false;
                }

                this.$emit('showDetails', {component, account});

            }, 500);

        },
        edit(account) {

            this.$emit('editAccount', account);

        },
        showBillingInfo(row) {
            // To disabled other (active script if button display for all rows)
            let activeDetailItems = _.filter(this.accounts, (o) => { return o._showDetails && o != row.item });
            activeDetailItems.forEach(item => {
                item._showDetails = false;
            });

            row.toggleDetails();
        },
        loginAs(item) {

            if (!item.user) return;

            // Get client token set store app update state and redirect to dashboard if success
            api.auth.loginAs(item.user.email)
                .then(response => {
                    helper.loginSuccessCallback(response, true);
                })
        },
        destroy(account) {
            this.$swal({
                customClass: {
                    confirmButton: 'btn btn-danger'
                },
                title: 'Are you sure?',
                text: 'are you sure you want to delete account ' + account.name,
                type: 'danger',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete this account',
                cancelButtonText: 'No',
                showCloseButton: true,
                confirmButtonColor: '#d33',
                showLoaderOnConfirm: true
            }).then((result) => {

                if (result.value) {

                    api.accounts.delete(account.id).then(response => {
                        let varian = response.success ? 'success' : 'danger';
                        this.toast('Delete Account', varian, response.message);
                        if (response.success) {
                          this.fetchAccounts();
                        }
                    }).catch(error => {
                        this.$bvToast.toast(error.response.data.message, {
                            title: 'Error',
                            variant: 'danger',
                        });
                    }).finally(() => {
                    });
                }
            })
        },
        typeName(id){

            const type = ACCOUNT_TYPE[id];
            return type.name;

        },
        filterItems(array, searchTerms, attributes) {
            const lowerCaseSearchTerms = searchTerms.map(term => term.toLowerCase());

            return array.filter(item => {
                return lowerCaseSearchTerms.some(term =>
                    attributes.some(attr =>
                        item[attr] && String(item[attr]).toLowerCase().includes(term)
                    )
                );
            });
        },
        onAccountsFiltered(filter = null) {

            if(filter) {
                const searchTerms = [filter];
                const attributes = ['name'];
                const filteredItems = this.filterItems(this.accounts, searchTerms, attributes);

                this.filteredItems = filteredItems;
                this.onFiltered(filteredItems);
            } else {
                this.filteredItems = [];
                this.onFiltered(this.accounts);
            }
        }
    }
}

</script>