<template>
    <div class="modal plan-changed" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="success" v-if="success">
                        <i class="fas fa-check-circle fa-8x success"></i>
                        <h4>GREAT</h4>
                        <h5>Plan successfully changed</h5>
                    </div>
                    <div class="loading" v-if="loading">
                        <div class="d-flex justify-content-center">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" v-if="success" style="display: flex;">
                    <button type="button" @click="close" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'SuccessPayment',
    props: ['success', 'loading'],
    methods: {
        close(e) {
            this.$emit('close', e);
        },
    }
}

</script>