<template>
    <ApexChart v-bind="{element, chartOption, isLive, columnsHide}" />
</template>

<script>

// Use Sofbox component
import ApexChart from "@/components/sofbox/charts/ApexChart"

export default {
    name: 'IbgApexChart',
    mounted() {
    },
    props: ['element', 'chartOption', 'isLive', 'columnsHide'],
    methods: {},
    data() {
        return {}
    }
}
</script>

<style></style>