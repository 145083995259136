var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{attrs:{"fluid":""}},[(_vm.renderDT)?_c('DataTable',_vm._b({ref:"dt-liveopens-reports",attrs:{"url":'/liveopens-report'}},'DataTable',{ 
            columns: _vm.columns, customCells: _vm.dtCells, filters: _vm.filters, sort: 'account_name',
            theadTop: [
                { colspan: 1, variant: null, srOnly: true, title: 'Name' },
                { colspan: 1, variant: null, srOnly: false, title: 'Generated Events' },
                { colspan: 2, variant: 'light', srOnly: false, title: 'Sent' },
                { colspan: 2, variant: 'light', srOnly: false, title: 'Balance' },
                { colspan: 2, variant: 'light', srOnly: false, title: 'Already Sent' },
                { colspan: 2, variant: 'light', srOnly: false, title: 'Already Tagged' },
                { colspan: 2, variant: 'light', srOnly: false, title: 'Daily Limit' },
                { colspan: 2, variant: 'light', srOnly: false, title: 'Time Limit' },
                { colspan: 2, variant: 'light', srOnly: false, title: 'Others' },
            ]
            },false)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }