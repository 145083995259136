<template>
  <b-container fluid>
    <b-tabs :pills="true" class="nav-fill" id="pills-tab-1">

      <b-tab :active="true" role="tab" :ariaSelected="true" title="General" class="my-3">

        <AppSettingCard v-bind="{settings, loading}" @handleSubmit="handleSubmit" @showAddSettingModal="handleAdd"></AppSettingCard>

       <PlatformConfigurationSettings></PlatformConfigurationSettings>
      </b-tab>


      <b-tab :active="false" role="tab" :ariaSelected="true" title="Support Chat" class="my-3">
        
        <SupportChatConfigurationSettings></SupportChatConfigurationSettings>
        
        <SupportChatAutoQuestion></SupportChatAutoQuestion>

      </b-tab>

      <b-tab :active="false" role="tab" :ariaSelected="true" title="Application Tour" class="my-3">
        <AppTourGuideSettings :appTourGuideStatus="appTourStatus" @updateAppTourGuidStatus="updateTourStatus">
        </AppTourGuideSettings>
      </b-tab>

      <b-tab :active="false" role="tab" :ariaSelected="true" title="Announces" class="my-3">
        <AnnouncementSettings></AnnouncementSettings>
        <AnnounceCardSettings></AnnounceCardSettings>
      </b-tab>

      <b-tab :active="false" role="tab" :ariaSelected="true" title="Walkthrough" class="my-3">
        <VideoWalkthroughSettings :videoStatus="videoStatus" @updateVideoStatus="updateVideoStatus">
        </VideoWalkthroughSettings>
      </b-tab>

      <b-tab :active="false" role="tab" :ariaSelected="true" title="App Campaign" class="my-3">
        <ActiveCampainSettings></ActiveCampainSettings>
      </b-tab>

      <!-- Notifications -->
      <b-tab :active="false" role="tab" :ariaSelected="true" title="Notifications" class="my-3">
        <NotificationSettings></NotificationSettings>
      </b-tab>


    </b-tabs>


    <!-- Start Add Setting Modal-->
    <b-modal :id="settingModal.id" :ref="settingModal.id" :title="settingModal.title" size="md" centered hide-footer
      class="bg-secondary py-0">
      <FormAppSetting col=12 :modal="settingModal" :types="settings" :type="settingType"
        @modalHide="closeModal(settingModal)" @handleSubmit="handleSubmit" />
    </b-modal>
    <!-- END Add Setting Modal-->

  </b-container>
</template>


<script>

import { sofbox } from "@/config/pluginInit";
import api from '@/api/RestClient'
import { mapGetters } from 'vuex'
import FormAppSetting from './children/FormAppSetting'
import AppTourGuideSettings from '@/components/inboxgeek/settings/AppTourGuideSettings'
import AnnouncementSettings from '@/components/inboxgeek/settings/AnnouncementSettings'
import AnnounceCardSettings from '@/components/inboxgeek/settings/AnnounceCardSettings'
import VideoWalkthroughSettings from '@/components/inboxgeek/settings/VideoWalkthroughSettings'
import ActiveCampainSettings from '@/components/inboxgeek/settings/ActiveCampainSettings'
import NotificationSettings from '@/components/inboxgeek/settings/NotificationSettings'
import DataTable from './children/DataTable.vue'
import Spinner1 from '@/components/loaders/spinner1'
import { helper } from '@/helpers'
import PlatformConfigurationSettings from '@/components/inboxgeek/settings/PlatformConfigurationSettings.vue';
import SupportChatConfigurationSettings from '@/components/inboxgeek/settings/SupportChatConfigurationSettings.vue';
import SupportChatAutoQuestion from '@/components/inboxgeek/settings/SupportChatAutoQuestion.vue';
import AppSettingCard from './children/AppSettingCard';
import _ from 'lodash'


export default {
  name: "AppSettings",
  components: {
    AnnounceCardSettings,
    VideoWalkthroughSettings,
    AnnouncementSettings,
    AppTourGuideSettings,
    ActiveCampainSettings,
    NotificationSettings,
    PlatformConfigurationSettings,
    SupportChatConfigurationSettings,
    SupportChatAutoQuestion,
    FormAppSetting,
    DataTable,
    Spinner1,
    AppSettingCard
  },
  beforeMount() {
  },
  mounted() {
    sofbox.index();
    this.fetchSettings()
    api.settings.getAppTourStatus()

      .then((response) => {
        if (response.success) {
          this.appTourStatus = (response.data == 1) ? true : false;
        } else {
          this.appTourStatus = false;
        }
      }).catch((response) => {
        this.appTourStatus = false;
      });


  },
  data() {
    return {
      loading: true,
      steps: [],
      appTourStatus: false,
      videoStatus: false,
      settingModal: {
        id: 'modal-add-setting',
        title: 'Add App Setting',
        item: {}
      },
      editModal: {
        id: 'modal-edit-setting',
        title: 'Edit App Setting',
        item: {}
      },
      app_settings: [],
      settingType: null,
      settings: [
        {
          name: 'General',
          value: 'general',
          icon: 'ri-function-line',
          data: []
        },
        {
          name: 'Emails',
          value: 'emails',
          icon: 'ri-mail-settings-line',
          data: []
        },
        {
          name: 'Credentials',
          value: 'credentials',
          icon: 'ri-key-fill',
          data: []
        }
      ],
      editSetting: null,
      removeSetting: null,
      errors: {},
    };
  },
  computed: {
    ...mapGetters({
      admin: 'Auth/isAdmin',
      client: 'Auth/isClient',
    })
  },
  methods: {
    fetchSettings() {
      this.loading = true


      api.settings.list({ page: 1, per_page: 50 })
        .then(response => {
          if ('success' in response) {
            this.app_settings = response.data.data
          }
        })
        .catch(errors => {
          // that.handleError(errors)
        })
        .finally(() => {

          _(this.settings).forEach(function (group) {
            group.data = [];
          });

          _(this.app_settings).forEach( (setting) => {

            let currentSetting = _.find(this.settings, (o) => { return o.value === setting.type })

            if (currentSetting) {
              currentSetting.data.push(setting)
            }

          });

          this.formatTeamEmails();

          setTimeout(() => { this.loading = false }, 1500)

        })
    },
    formatTeamEmails() {

      let emailSetting = _.find(this.settings, function (o) { return o.value == 'emails'; });

      let teamEmails = [];
      let types = {};
      if (emailSetting) {
        _(emailSetting.data).forEach(function (setting) {
          let alreadyAdd = _.find(teamEmails, function (o) { return o == setting.value; });
          if (!alreadyAdd) {
            teamEmails.push(setting.value)
            types[setting.value] = []
          }

          let alreadyAddType = _.find(types[setting.value], function (o) { return o == setting.name; });
          if (!alreadyAddType) {
            types[setting.value].push(setting.name)
          }
        });
      }
      return types
    },
    closeModal(modal) {
      this.$root.$emit('bv::refresh::table', modal.id)
    },
    handleAdd(type = null) {
      this.settingType = type;
      this.settingModal.title = `Add App Setting`
      this.$root.$emit('bv::show::modal', this.settingModal.id)
    },
    handleSubmit(data) {

      let swal = {
        icon: 'success',
        title: 'App Setting Added',
        text: 'Setting added successfully',
        type: 'success',
        confirmButtonText: 'OK',
        showCloseButton: true,
      }

      if (!data || !data.id) {
        swal.text = 'Something went wrong'
        swal.type = 'danger'
        swal.icon = 'warning'

        this.$swal(swal)
        return
      }

      if (data.id && !data.new) {
        swal.title = 'App Setting Updated'
        swal.text = 'Setting updated successfully'
      }

      this.$swal(swal).then((result) => {
        this.fetchSettings()
        this.$root.$emit('bv::hide::modal', this.settingModal.id)
      })
    },
    jsonToArray(json) {
      let result = JSON.parse(json)
      console.log(result)
      return JSON.parse(json)
    },
    updateTourStatus(status) {
      api.settings.updateAppTourStatus(status).then((response) => {
        if (response.success == true) {


          this.$bvToast.toast('App tour guide status successfully updated.', {
            title: 'Success',
            variant: 'success'
          });
        } else {
          this.$bvToast.toast('failed to update App tour guide status.', {
            title: 'failed',
            variant: 'error'
          });
        }
      })
    },
    updateVideoStatus(status) {
      api.settings.updateVideoStatus(status).then((response) => {
        if (response.success == true) {


          this.$bvToast.toast('App tour guide status successfully updated.', {
            title: 'Success',
            variant: 'success'
          });
        } else {
          this.$bvToast.toast('failed to update App tour guide status.', {
            title: 'failed',
            variant: 'error'
          });
        }
      })
    },
    handleRemoveSetting(param) {
      
      if (param.type !== 'credentials') {
        param.edit = true
        this.removeSetting = param;
      }

      this.onsubmit = true;
      // this.checkForm();

      if (helper.isEmpty(this.errors)) {
        if (this.removeSetting.id) {
          this.removeSettingItem()
        }
      } else {
        // Can emit error
        this.onsubmit = false;
      }
    },
    removeSettingItem() {
      let isValidForm = helper.isEmpty(this.errors)
      const id = this.removeSetting.id
      // let data = { id: this.removeSetting.id, name: this.removeSetting.name, value: this.removeSetting.value }

      if (!id) {
        this.onsubmit = false;
        return;
      }
      // console.log( 'data:id', id ); return;

      api.settings.delete(id)
        .then(data => {
          if ('success' in data) {
            console.log( 'data:delete', data );

            // this.callbackRemove(data.data);
          } else {
            console.log('error in remove settings');
          }
        })
        .catch(errors => {
          console.log('error in remove settings');
        })
        .finally(() => {
          this.onsubmit = false;
          this.removeSetting = null;
        })
    },
    callbackRemove(data, message = null) {

      let swal = {
        icon: 'success',
        title: 'App Setting Remove',
        text: message ?? 'Setting remove successfully',
        type: 'success',
        confirmButtonText: 'OK',
        showCloseButton: true,
      }

      if (!data || !data.id) {
        swal.text = message ?? 'Something went wrong'
        swal.type = 'danger'
        swal.icon = 'warning'

        this.$swal(swal)
        return
      }

      this.$swal(swal).then((result) => {
        this.fetchSettings()
        this.$root.$emit('bv::hide::modal', this.settingModal.id)
      })
    },
  },
  watch: {},
};
</script>


<style>
.actionClass {
  width: 18%;
}

.timeClass,
.createdatClass,
.accountClass {
  width: 10%;
}

textarea.form-control {
  line-height: 25px;
}

.cursor-default {
  cursor: default;
}

.table th,
.table td {
  padding: 0.55rem;
  vertical-align: top;
}

.action {
  width: 100%;
  height: 43.5px;
}</style>
