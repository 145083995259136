<template>
    <b-container fluid>

        <!-- Second section -->
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">Records</h4>
                    </template>
                    <template v-slot:headerAction class="mt-3">

                        <b-form v-if="is_manager" class="mx-3">
                            <AccountsMultiselect @onSelect="onSelectAccount" />
                        </b-form>

                    </template>
                    <template v-slot:body>

                        <!-- Custom Filters row -->
                        <b-row>
                            <b-col lg="12">
                                <div class="container-fluid">
                                    <b-row class="d-flex justify-content-end p-3">
                                        <b-button class="mb-2" v-b-tooltip.hover title="Filter" variant="primary"
                                            @click="isFilter = !isFilter"> <i class="fa fa-filter mr-0"></i> </b-button>
                                        <Transition name="fade">
                                            <b-col lg="12" md="12" v-show="isFilter" class="alert-secondary">

                                                <SearchFilters ref="dt-filters" v-bind="filtersProps()"
                                                    @onFilter="onFilter"></SearchFilters>
                                            </b-col>
                                        </Transition>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>
                        <!-- End Custom Filters row -->

                        <b-row>
                            <div class="ml-auto col-md-3 sm-12 mx-3">

                                <div class="user-list-files d-flex float-right">
                                    <div class="dt-buttons btn-group flex-wrap">
                                        <b-button v-b-tooltip.left title="Download CSV" variant="primary"
                                            @click="downloadCsv()" :disabled="loading || downloading">
                                            <span class="pr-2">
                                                <b-spinner v-if="downloading" small type="grow"></b-spinner>
                                            </span>
                                            <span>CSV</span>
                                        </b-button>

                                    </div>
                                </div>

                            </div>
                        </b-row>


                        <RecordsTable ref="dt-records" v-bind="{
                            accountId: admin?.account?.code ?? null, 
                            item: geSource ? geSource.id : null, 
                            filters}"></RecordsTable>

                    </template>
                </iq-card>

            </b-col>
        </b-row>

    </b-container>
</template>

<script>
import { sofbox } from '@/config/pluginInit'
import { mapGetters } from 'vuex'

import api from '@/api/RestClient'
import DataTable from '@/components/inboxgeek/tables/DataTable.vue'
import AccountsMultiselect from "@/views/children/AccountsMultiselect.vue";
import { excelParser } from "@/helpers/excel-parser";
import moment from 'moment'
import { helper } from '@/helpers'
import _ from 'lodash'
import SearchFilters from "./children/SearchFilters.vue";
import { GE_RECORD_STATUS_STATES, GE_RECORD_CLEAN_STATES,SERVICES } from "@/constantes";
import RecordsTable from './children/RecordsTable';

const PERIOD_DAY = 0;
const PERIOD_WEEK = 1;
const PERIOD_MONTH = 2;
const PERIOD_YEAR = 3;

export default {
    name: "SourceDetails",
    components: {
        AccountsMultiselect,
        DataTable,
        RecordsTable,
        SearchFilters
    },
    data() {
        return {
            admin: {
                account: { code: null, name: '' }
            },
            loading: true,
            downloading: false,
            renderDT: true,
            geSource: null,
            isFilter: false,
            periodCode: -1,
            showChart: false,
            chartLoading: false,
            showDates: false,
            options: {
                label: 'source_code',
                period: PERIOD_DAY,
                dates: {},
                exceptPeriod: [PERIOD_YEAR],
                exceptDatesFor: [PERIOD_WEEK, PERIOD_MONTH]
            },
            tabsPeriod: [
                { code: PERIOD_DAY, name: 'Days', format: 'YYYY-MM-DD', offset: 30 },
                { code: PERIOD_WEEK, name: 'Weeks', format: 'YYYY-MM-DD', offset: 10 },
                { code: PERIOD_MONTH, name: 'Months', format: 'YYYY-MM', offset: 6 },
                { code: PERIOD_YEAR, name: 'Years', format: 'YYYY', offset: 1 },
            ],
            filters: { "status": "", "platform": [], "source": [], "date_to": "", "date_from": "" },
            is_manager: false
        }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            user: 'Auth/user',
            userSubscriptions : 'Auth/UserSubscriptions'
        })
    },
    mounted() {
        sofbox.index();

        this.setSerie();
        this.setDates()
        this.setOption();
        this.initIntervalDates();
        this.fetchSource();
        this.isManager();

        if(this.userSubscriptions[SERVICES.LIVE_LEADS.id] || this.isAdmin){
            let show_time_spent = false;

            if(  ! this.isAdmin ) {
                let options = JSON.parse(this.userSubscriptions[SERVICES.LIVE_LEADS.id].options);
                show_time_spent = options?.time_spent  ?? false;
            }
            

            if(this.isAdmin || show_time_spent){
                
                this.$refs['dt-records'].dtColumns.push( { label: 'Time Spent', key: 'time_spent', class: 'text-center', sortable: true, sortDirection: 'desc' });
            }
        }

        if(!this.isAdmin) {
            this.dtColumns = _.filter(this.dtColumns, (obj) => { return obj.key !== 'source.account.name'; })
        }
    },
    methods: {
        isManager() {
            this.is_manager = helper.hasRoles(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_ACCOUNTS_MANAGER']);
        },
        getCurrOrganization() {
            return this.isAdmin && this.admin.account ? this.admin.account.id : null
        },
        onSelectAccount(accountSelected) {
            let organization = null

            if (this.is_manager) {
                if (accountSelected) {
                    this.btnAddIsDisabled = false
                    this.admin.account = accountSelected
                    organization = this.admin.account.code
                } else {
                    this.admin.account.code = null;
                    // delete this.admin.account
                    this.btnAddIsDisabled = true
                    accountSelected = null
                }

                this.$refs['dt-records'].forceRenderTable();
            }
        },
        fetchSource() {
            this.chartLoading = true
            let sourceID = null

            let sourcesService = api.geSources.get();

            if (typeof this.$route.params.id !== 'undefined') {
                sourceID = this.$route.params.id
                sourcesService = api.geSources.get(sourceID)

                this.filtersProps(sourceID)
            }

            sourcesService
                .then(response => {


                    if (response.success && sourceID) {
                        this.geSource = response.data
                    }
                })
                .finally(() => {

                    this.loading = false

                })
        },
        setOption() {

            let activePeriod = _.find(this.tabsPeriod, (o) => { return this.options.period == o.code; });
            if (activePeriod) {
                activePeriod.default = true
            }

            if (typeof this.options.exceptPeriod !== "undefined") {
                this.tabsPeriod = _.filter(this.tabsPeriod, (o) => { return !this.options.exceptPeriod.includes(o.code); });
            }
            this.showDates = !this.options.exceptDatesFor.includes(this.options.period) ? true : false
        },
        initIntervalDates() {

            let date = moment();
            let minDate = date.clone();

            const period = this.options.period;
            let currentPeriod = _.find(this.tabsPeriod, function (o) { return period == o.code; });

            minDate.subtract(currentPeriod.offset, currentPeriod.name.toLowerCase())

            this.options.dates.to = date.format('YYYY-MM-DD');
            this.options.dates.from = minDate.format('YYYY-MM-DD');
            this.options.dates.max = date.format('YYYY-MM-DD');

            this.showDates = true;
        },
        setPeriodCode(val = 0) {
            this.periodCode = val;
        },
        onFilter(data) {
            this.loading = true

            this.filters = this.filterTable(data);

            // let refreshTable = this.mustBeFiltered(data);

            helper.delay(1000).then(() => {
                if (typeof this.$refs['dt-filters'].finishFilter === 'function') {
                    this.$refs['dt-filters'].finishFilter()
                }

                // if (refreshTable) {
                    // this.forceRenderTable();
                this.$refs['dt-records'].forceRenderTable();
                // }
                this.loading = false;
            })
        },
        filterTable(params) {

            if (!params.source.length) {
                this.geSource = null
            }

            let filters = {
                title: params.title ?? '',
                website: params.website ?? '',
                source: params.source ? _.map(params.source, 'code') : [],
                platform: params.platform ? _.map(params.platform, 'name') : [],
                date_to: params.date_to ?? '',
                date_from: params.date_from ?? '',
                status: params.status?.code ?? '',
                clean: params.clean?.code ?? '',
            };

            return filters;
        },
        setDates() {
            if (typeof this.$route.query.from !== 'undefined') {
                this.options.dates.from = this.$route.query.from;
            }

            if (typeof this.$route.query.to !== 'undefined') {
                this.options.dates.from = this.$route.query.to;
            }
        },
        setSerie() {
            if (typeof this.$route.query.serie !== 'undefined') {
                this.options.serie = this.$route.query.serie;
            }
        },
        filtersProps(sourceId = null) {
            let props = { from: this.options.dates.from, to: this.options.dates.to, source: sourceId }

            if (this.geSource) {
                props.source = this.geSource;
            }

            if (typeof this.options.serie !== 'undefined') {
                props.serie = this.options.serie;
            }

            return props;
        },
        downloadCsv(sourceID = null) {
            this.downloading = true;
            let alertItem = {
                title: 'Download Records',
                type: 'success',
                confirmButtonText: 'Ok',
                showCancelButton: false,
                showCloseButton: true,
            }

            let params = {}
            if (sourceID) {
                params.source = sourceID;
            }


            if (this.filters) {
                params.filters = JSON.stringify(this.filters);
            }

            if (this.filters.source.length) {
                params.source = this.filters.source.join(',')
            }


            let file = 'records'

            api.geRecords.downloadBySource(params)
                .then(response => {

                    if (!response.data.length) return

                    let fileName = `GE_${file}_${moment().format('YYYY-MM-DD')}`

                    this.exportData(response.data, fileName);
                    alertItem.text = `${fileName} has been download successfully.`
                })
                .catch(error => {
                    // console.log(error)
                    alertItem.type = `warning`
                    alertItem.text = error.response.data.message
                })
                .finally(() => {
                    helper.delay(1500).then(() => {
                        this.$swal(alertItem);
                        this.downloading = false;
                    })

                })
        },
        exportData(data, fileName = null, type = null) {

            excelParser().exportDataFromJSON(data, fileName, type);
        },
        hasRoles(roles = []) {
            return !_.isEmpty(_.filter(this.user.roles, function (r) {
                return roles.includes(r.slug);
            }))
        },
        // mustBeFiltered(data) {
        //     let refreshTable = false;

        //     Object.keys(data).forEach(key => {
        //         let item = data[key];
        //         if (!item) return;

        //         if (typeof item === 'string') {
        //             if (item || item !== 'undefined' || item !== '') {
        //                 refreshTable = true;
        //             }
        //         } else if (typeof item === 'object') {
        //             console.log( data, item.length, _.isEmpty(item) )
        //             if (!_.isEmpty(item)) {
        //                 refreshTable = true;
        //             }
        //         } else if (typeof item === 'boolean') {
        //             if (item) {
        //                 refreshTable = true;
        //             }
        //         }
        //     })

        //     return refreshTable;
        // }
    },
    watch: {
        'periodCode': {
            handler(newValue, oldValue) {
                this.options.period = newValue;

                this.resetChartData();
                this.initIntervalDates();
            }
        },
    },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}
</style>