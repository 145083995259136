<template>
    <b-row class="text-left">

        <b-form-group class="col-md-12">
            <label :for="'lists'" style="display:flex; justify-content: space-between;">
                <span>{{ withSegment ? 'List/Segment' : 'List' }}</span>
            </label>

            <multiselect id="range-1" v-model="list" tag-placeholder="Add this as new tag" placeholder="Add Tags"
                label="name" track-by="code" :options="lists" :multiple="true" :taggable="true" :allow-empty="false"
                :loading="omnisend_isLoading" @tag="tagInput">
            </multiselect>

            <p class="text-dark alert alert-warning my-2" style="padding: 1rem;">
                Please type into the field the name of your InboxGeek tag that already exists in Omnisend, then press
                'ENTER'
            </p>

            <small id="alertLists" class="text-danger"></small>
        </b-form-group>

        <b-col class=" text-center w-100" style="justify-content:center;">
            <b-button class="mx-2" variant="info" @click.prevent="displayLists()" :disabled="!withSegment">
                <i class="ri-list-check"></i> Display Lists only.
            </b-button>
            <b-button class="mx-2" variant="primary" @click.prevent="displayListsAndSegments()" :disabled="withSegment">
                <i class="ri-flashlight-line"></i> Display Segments.
            </b-button>
        </b-col>
    </b-row>
</template>

<script>

import Multiselect from 'vue-multiselect'
import _ from 'lodash';
import api from '@/api/RestClient';

export default {

    name: 'ListStepOmnisend',

    components: {
        Multiselect
    },
    props: ['lists', 'platform_id', 'platform', 'oAccountId', 'listAlready'],
    data() {
        return {
            list: [],
            withSegment: true,
            segments: [],
            selected_segments: [],
            items: [],
            list_selected: false,
            isLoading: false
        }
    },
    mounted() {

        if (this.listAlready) {
            this.list = this.listAlready
        }

        this.fetchLists(this.withSegment);
        this.items = this.lists;
    },
    methods: {
        fetchLists(withSegment = false) {

            this.isLoading = true;
            api.platforms.fetchServices({
                "platform_name": 'blastable',
                "entities": "lists",
                "platform": this.platform.code,
                "account": this.oAccountId.code,
                "platform_id": this.platform.p_id,
                "segment": withSegment
            }).then((response) => {

                if (response.data.data.records && response.data.data.records.length) {

                    // lists
                    this.items = response.data.data.records.map((list) => {
                        return {
                            'code': list.general.list_uid,
                            'name': list.general.name
                        };
                    });
                }

                this.isLoading = false;

            }).catch((error) => {
                this.isLoading = false;
                console.log('Failed to fetch lists');
            }).finally(() => {
                if (withSegment) this.fetchSegments();
            });
        },
        fetchSegments() {

            this.isLoading = true;
            api.platforms.fetchServices({

                "platform_name": 'blastable',
                "entities": "segments",
                "account": this.oAccountId.code,
                "platform": this.platform.code,
                "platform_id": this.platform.p_id,
                // "list_id"       : (typeof this.listAlready !== 'undefined') ? this.listAlready.code : this.list.code

            }).then((response) => {

                if (response.data.data.records && response.data.data.records.length) {
                    this.segments = response.data.data.records.map((segment) => {
                        return {
                            'code': segment.segment_uid,
                            'name': segment.name,
                            'type': 'segment'
                        };
                    });
                }

                // TODO Merge lists & segments
                this.items = _.merge(this.lists, this.segments);

                this.isLoading = false;

            }).catch((error) => {
                this.isLoading = false;
                console.log('Failed to fetch segments');
            }).finally(() => {

                this.selectCurrentSegment()

            });
        },
        selectCurrentSegment() {

            const that = this

            if (typeof that.listAlready !== 'undefined') {
                this.isLoading = true;

                setTimeout(() => {
                    let activeSegment = _.find(that.segments, function (o) { return o.name == that.listAlready.list_name });

                    if (activeSegment) {
                        that.selected_segments = []
                        that.withSegment = true
                        that.selected_segments.push(activeSegment)
                    }

                    this.isLoading = false;
                }, 400)
            }
        },
        displayLists() {
            const lists = _.filter(this.lists, (list) => typeof list.type == 'undefined' || list.type !== 'segment');
            this.items = lists;
            this.withSegment = false;
        },
        displayListsAndSegments() {
            this.fetchSegments();
            this.withSegment = true;
        }
    },
    watch: {
        list: {
            handler(newValue, oldValue) {

                if (newValue) {
                    // // TODO detect if segment is use emit corresponding event
                    // if (true) {
                    //     this.$emit('segmentSelected', this.list);
                    // }

                    this.$emit('listSelected', this.list);
                }

            },
        },

    }
}


</script>