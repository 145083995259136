<template>

    <span :class="statusClass(row.item.state)">
        {{ statusTitle(row.item.state) }}
        <b class="cursor-pointer-ek ml-1" data-toggle="tooltip" title="Recharge"
            v-if="[-1, 101].includes(row.item.state)" @click="recharge(row.item)">
            <i class="fa fa-refresh" v-show="row.item.state == -1"></i>
            <b-spinner small label="Small Spinner" type="grow" v-show="row.item.state != -1"></b-spinner>
        </b>
    </span>

</template>
<script>

import { INVOICE_STATUS } from "@/constantes";
import api from '@/api/RestClient';

export default {
    name: 'DtInvoiceStatusCell',
    props: ['row'],
    methods: {
        statusClass(status) {
            let statusData = INVOICE_STATUS.find(x => x.code == status);
            return statusData ? statusData.class : "";
        },
        statusTitle(status) {
            let statusData = INVOICE_STATUS.find(x => x.code == status);
            return statusData ? statusData.name : "";
        },
        recharge(item) {
            this.$swal({
                title: 'Are You Sure?',
                type: 'warning',
                text: 'This action will charge the current card on file for the amount listed on this invoice.',
                showCancelButton: true,
                confirmButtonText: 'Yes, Recharge',
                cancelButtonText: 'Cancel',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    this.rechargeInvoice(item)
                }
            })
        },
        rechargeInvoice(item) {
            item.state = 101;

            let data = {
                amount: item.amount,
            }
            
            api.invoices.pay(item.id, data)
                .then((response) => {
                    let title = 'Error';
                    let variant = 'error';
                    if (response.success) {
                        item.state = 1;
                        title = 'Success';
                        variant = 'success';
                    } else item.state = -1;
                    this.$bvToast.toast(response.message, {
                        title: title,
                        variant: variant
                    });
                    this.$root.$emit('bv::refresh::table', this.id)
                }).catch((err) => {
                    console.log(err)
                    item.state = -1;
                    this.$bvToast.toast('Something went wrong!', {
                        title: 'Error',
                        variant: 'danger'
                    });
                }).finally(() => {})
        },
    }
}

</script>