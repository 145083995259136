<template>
    <b-row class="text-left">

      <b-form-group  label="List" label-for="list" class="col-md-12">
          <multiselect 
          v-model="list" 
          tag-placeholder="Empty" 
          placeholder="List" 
          label="name"
          track-by="code" 
          :options="lists" 
          return="code" 
          allow-empty>


          <template slot="option" slot-scope="props">
              <div class="option__desc">

                  <span class="option__title">

                      <span v-if="props.option.type">

                          <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                              style="color:#5ad"></i>
                          <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                      </span>

                      <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                      <span style="font-wight:bold;"> {{ props.option.name }} </span>

                      <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>


                  </span>

              </div>

          </template>


          </multiselect>
      </b-form-group>

      <b-row class="w-100" style="justify-content:center;">
        <b-button v-if="!use_segments && list_selected" variant="primary" @click="use_segments = true"><i class="ri-flashlight-line"></i> I prefer using segments over lists.</b-button>
      </b-row>

      <b-form-group  v-if="use_segments" class="col-md-12">
        <label for="segment" style="display:flex;justify-content: space-between;">
            <span>Segment</span>  
            <b-form-checkbox switch size="sm" v-model="use_segments">Use Segment</b-form-checkbox>
        </label>
        
    <multiselect v-model="selected_segments" multiple taggable tag-placeholder="Segments" placeholder="select segments" label="name"
    track-by="code" :options="segments" :loading="isLoading" return="code" :allow-empty="true">


    <template slot="option" slot-scope="props">
        <div class="option__desc">

            <span class="option__title">

                <span v-if="props.option.type">

                    <i class="ri-flashlight-line" style="color:#5ad"></i>
                    

                </span>

                <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                <span style="font-wight:bold;"> {{ props.option.name }} </span>

                <span style="font-size:11px; color:#888"> {{ props.option.id }}</span>


            </span>

        </div>

    </template>


    </multiselect>
      </b-form-group>
    </b-row>

</template>

<script>

import Multiselect from 'vue-multiselect'
import _ from 'lodash';
import api from '@/api/RestClient';

export default {

    name: 'ListStepMailchimp',

    components: {
        Multiselect
    },
    props:['lists','platform_id','platform','oAccountId','auth2','listAlready'],
    data(){
      return {
        list: null,
        use_segments:false,
        segments:[],
        selected_segments:[],
        list_selected:false,
        isLoading: false
      }
    },
    mounted() {
        if(this.listAlready) {
            this.list = this.listAlready
        }
    },
    methods:{
        fetchSegments(){
            let platform_id = this.platform.p_id ?? this.platform.id ?? localStorage.getItem(`mailchimp.platform_id`) ;

            this.isLoading = true;

            console.log('>list',this.list);

            api.platforms.fetchServices({

                "platform_name" : 'mailchimp',
                "entities"      : "segments",
                "platform_id"   : platform_id,
                "account"       : this.oAccountId.code,
                "platform"      : this.platform.code,
                "list_id"       : (typeof this.listAlready !== 'undefined') ? this.listAlready.code : this.list.code

            }).then(( response ) => {

                this.segments = response.data.map((segment)=>{
                    return {
                        'code' : segment.id,
                        'name' : segment.name
                    };
                });

                this.isLoading = false;

            }).catch((error)=>{
                this.isLoading = false;
                console.log('failed to fetch segments');
            }).finally(() => {

                this.selectCurrentSegment()
                
            });
        },

        selectCurrentSegment() {

            const that = this

            if( typeof  that.listAlready !== 'undefined' ) {
                this.isLoading = true;

                setTimeout(() => {
                    let activeSegment = _.find(that.segments, function (o) { return o.name == that.listAlready.list_name });

                    if(activeSegment) {
                        that.selected_segments = []
                        that.use_segments = true
                        that.selected_segments.push(activeSegment)
                    }

                    this.isLoading = false;
                }, 400)
            }
        }
    },  
    watch : {
        list : {
            handler(newValue, oldValue) {

                if(newValue){

                    this.list_selected = true;
                    this.fetchSegments();  

                }else{
                    
                    this.selected_segments = [];
                    this.segments = [];
                }  

                this.$emit('listSelected',this.list);
            },
        },
        selected_segments : {
            handler(newValue,oldValue) {

                if(this.use_segments){

                    this.$emit('segmentSelected',this.selected_segments,this.list);
                }
                
            }
        },
        use_segments : {
            handler(newValue,oldValue) {

                if(newValue){
                    this.$emit('segmentSelected',[],this.list);
                    this.fetchSegments();  
                    
                }else{
                    this.selected_segments = [];
                    this.segments = [];

                    this.$emit('listSelected',this.list);
                    
                }
                
            }
        }


    }
}


</script>