<template>

    <b-container fluid>
        <DataTable v-if="renderDT" ref="lo-automations-table" :url="'/lo-automations'"
            v-bind="{ columns: columns, customCells: dtCells, organization: accountId, filters: filters }"
            @onClick="handleBtn" @filterUpdate="$emit('filterUpdate')"/>
    </b-container>

</template>
<script>
import DataTable from '@/components/inboxgeek/tables/DataTable'

import DtPlatform from './DtPlatform.vue';
import Actions from '@/views/Integration/children/LoAutomationActions.vue'



import {SERVICES} from "@/constantes";
import { mapGetters } from 'vuex'
import _ from 'lodash'

import api from '@/api/RestClient';
import { helper } from '@/helpers'

export default {
    name: 'LoAutomationStatsTable',
    components: {
        DataTable
    },
    props: {
        accountId: {
            type: Number,
            default: null
        },
        selectColumns: {
            type: Array,
            default: []
        },
        filters: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            renderDT: true,
            // filters: {},
            fields: [
                { label: 'Automation Name', key: 'automation_name', class: 'text-left', sortable: false, sortDirection: 'desc' },
                { label: 'Integration Name', key: 'integration_name', class: 'text-left', sortable: false, sortDirection: 'desc' },
                { label: 'Platform', key: 'platform', class: 'text-left', sortable: false, sortDirection: 'desc' },
                {
                    label: 'Daily Cap', key: 'daily_cap', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return (value == -1) ? 'Unlimited' : value;
                    }
                },
                { label: 'Total Event', key: 'total_events_sent', class: 'text-center', sortable: false, sortDirection: 'desc' },
                {
                    label: 'Total Sends', key: 'total_sends', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Total Delivered', key: 'total_delivered', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Delivered Rate', key: 'delivered_rate', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Total Opens', key: 'total_opens', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Opens Rate', key: 'opens_rate', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Unique Opens', key: 'total_unique_opens', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Unique Open Rate', key: 'unique_open_rate', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Total Clicks', key: 'total_clicks', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Clicks Rate', key: 'clicks_rate', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Bounces', key: 'total_bounces', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Complaints', key: 'total_complaints', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Complaints Rate', key: 'complaints_rate', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return this.getDtValueIfDefined(value);
                    }
                },
                {
                    label: 'Date', key: 'created_at', class: 'text-center', sortable: false, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let dt = new Date(value);
                        return helper.formatDate(dt)
                    }
                },
                { label: 'Actions', key: 'actions', class: 'text-center', sortable: false, sortDirection: 'desc' },
            ],
            dtCells: [
                {
                    key: 'platform',
                    component: DtPlatform,
                    event: null,
                },
                {
                    key: 'actions',
                    component: Actions,
                    event: 'onClick',
                }
            ],
            filteredColumns: [],
        }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            user: 'Auth/user',
        }),
        columns() {
            let columns = this.fields;

            return columns;
        }
    },
    mounted() {
    },
    methods: {
        forceRenderTable() {
            this.renderDT = false;

            this.$nextTick(() => {
                this.renderDT = true;
            });
        },
        handleBtn({row, event, ref}) {
            if(!row) return;

            this.$emit('onClick', { row, event, ref });
        },
        getDtValueIfDefined(value) {
            if(!value) {
                return '--';
            }

            const decimal = value && !(value % 1) ? 0 : 3;
            return value ? parseFloat(value).toFixed(decimal) : 0;
        },
        onSelect(columns) {

            let fields = _.map(this.fields, (field) => {
                if (this.selectColumns.includes(field.key)) {
                    field.class = "text-center";
                } else {
                    field.class = "text-center d-none";
                }
                return field;
            })
            this.fields = fields;

            this.saveDisplayColumnsPreference(this.selectColumns);
        },
        saveDisplayColumnsPreference(cols) {
            const userId = this.user.id;

            this.filteredColumns = this.getColumnsKeys(cols, 'code');

            api.users.saveLoAutomationStatsColumns(userId, cols)
                .then((response) => {
                    if (response.success && response.data) {
                        const columns = JSON.parse(response.data.columns)
                    }
                });

        },
        getColumnsKeys(data, attr = 'key') {
            return _.map(data, attr);
        },
        getFields() {
            return this.fields;
        }
    },
    watch: {
        'selectColumns': {
            handler(newValue, oldValue) {
                this.filteredColumns = newValue;
            }
        },
    }
}

</script>