<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card class="">
                    <template v-slot:headerTitle>
                        <b-row class="mt-4">
                            <b-col md="6">
                                <b-form-group label="Date" label-for="date">
                                    <b-form-input type="month" v-model="date" :max="dateMax"></b-form-input>
                                </b-form-group>
                            </b-col>
                            
                            <b-col md="4">
                                <b-form-group label="To" label-for="submit">
                                    <b-button class="action" variant="primary" :disabled="submitLoad" @click="onSubmit">
                                        <b-spinner small type="grow" v-if="submitLoad"></b-spinner>
                                        Submit
                                    </b-button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col ld="12">
                                <b-form-checkbox v-model="showFreePlan" name="show-free" switch>
                                    Show Free Plan?
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </template>
                    <template v-if="this.isAdmin" v-slot:headerAction>
                        <b-form class="mx-3 mt-3">
                            <AccountsMultiselect @onSelect="onSelectAccounts" />
                        </b-form>
                    </template>
                </iq-card>
                <iq-card>

                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12">

                                <SubscriptionSummariesTable ref="dt-subscription_summaries" v-bind="{ filters, organization: accountId }" @submitLoad="setSubmitLoad"></SubscriptionSummariesTable>
                                
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { sofbox } from "@/config/pluginInit";


import _ from 'lodash'

import SubscriptionSummariesTable from "./children/SubscriptionSummariesTable";
import AccountsMultiselect from "./children/AccountsMultiselect";

import { mapGetters } from 'vuex'

import { helper } from '@/helpers'
import { SERVICES } from "@/constantes";

var moment = require('moment');

export default {
    name: "SubscriptionsSummary",
    components: {
        AccountsMultiselect,
        SubscriptionSummariesTable,
    },
    mounted() {
        sofbox.index();
        this.initDates();
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isAccountManager: 'Auth/isAccountManager',
            user: 'Auth/user'
        }),
        dateIsCurrentMonth() {
            if(this.date) return this.date == moment().format('YYYY-MM');

            return false;
        }
    },
    methods: {
        onSelectAccounts(accountSelected) {

            if (this.isAdmin) {
                if (accountSelected) {
                    this.filters.accounts = [];
                    accountSelected.forEach(account => {
                        this.filters.accounts.push(account.code);
                    });
                } else {
                    accountSelected = [];
                }
            }

            this.$refs['dt-subscription_summaries'].forceRenderTable();

        },
        onSubmit() {
            this.submitLoad = true;

            this.$refs['dt-subscription_summaries'].forceRenderTable();
        },
        forceRenderTable() {
            this.renderDT = false;
            this.submitLoad = false;

            this.$nextTick(() => {
                this.renderDT = true;
            });
        },
        initDates() {

            let date = helper.dateToYMD(new Date());
            date = moment(date).format('YYYY-MM');
            this.date = date;
            this.dateMax = date;

        },
        getItemPlan(item, serviceId) {
            let subscriptionSummary = _.find(item.subscription_summaries, (o) => {
                return o.service_id == serviceId
            });
            let currentSubscription = _.find(item.subscriptions, (o) => {
                return o.service_id == serviceId
            });

            return subscriptionSummary && subscriptionSummary?.subscription && subscriptionSummary.subscription?.plan
                ? subscriptionSummary.subscription?.plan 
                : (currentSubscription && currentSubscription?.plan ? currentSubscription.plan : null);
        },
        setSubmitLoad(value = false) {
            if(typeof value == 'boolean') {
                this.submitLoad = value;
            }
        }
    },
    watch: {
        "date": {
            handler(newVal, oldVal) {
                this.filters.date = newVal;
            },
            deep: true
        },
        "showFreePlan": {
            handler(newVal, oldVal) {
                this.filters.showFree = newVal;
            },
            deep: true
        },
    },
    data() {
        return {
            renderDT: true,
            accountId: null,
            submitLoad: false,
            dtCells: [
            ],
            filters: {
                showFree: false
            },
            item: {},
            date: null,
            dateMax: null,
            dates: {
                to: null,
                from: null,
            },
            showFreePlan: false,
        };
    },
};
</script>

<style>
#myTabContent-5 {
    padding-bottom: 2em !important;
    /* margin: 0 5px; */
}

.actionClass {
    width: 18%;
}

.timeClass,
.createdatClass,
.accountClass {
    width: 10%;
}

textarea.form-control {
    line-height: 25px;
}

.cursor-default {
    cursor: default;
}

.table th,
.table td {
    padding: 0.55rem;
    vertical-align: top;
}

.action {
    width: 100%;
    height: 43.5px;
}

label[for=submit] {
    visibility: hidden;
}</style>
