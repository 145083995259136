<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title" v-show="false">Lists</h4>
                    </template>

                    <template v-slot:headerAction class="mt-3">

                        <b-form v-if="is_manager" class="mx-3">
                            <AccountsMultiselect @onComplete="onAccountsLoaded" @onSelect="onSelectAccount" />
                        </b-form>

                        <b-button id="addNewBtn" ref="addNewBtn" @click="showModalAddIntegration" variant="primary"
                            :disabled="btnAddIsDisabled" v-show=false>Add New
                        </b-button>

                    </template>

                    <template v-slot:body>
                        <b-row style="justify-content: end;">
                            <b-col lg="12">
                                <div class="container-fluid">
                                    <b-row style="justify-content: end; padding:10px 0px;">
                                        <b-col lg="11" md="11" v-show="isFilterLists" class="alert-secondary">
                                            <filtersList ref="lists-filters" :platforms_array="platforms_co"
                                                @onFilter="onFilterLists"></filtersList>
                                        </b-col>
                                        <b-col lg="1" class="mt-2">
                                            <b-button v-b-tooltip.hover title="Filter" variant="primary"
                                                @click="toggleInputsFilterLists"> <i class="fa fa-filter mx-0"></i>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12">
                                <DtLists ref="dt-lists" @onFinishedFilter="finishedFilterLists" />
                            </b-col>
                        </b-row>

                    </template>
                </iq-card>
            </b-col>
            <b-col md="12">
                <b-modal id="modal-time-line" ref="modal-timeLine" title="" :hide-footer=true>
                    <timeLine :integrationLogs="integrationLogs" @onGetAll="allIntegrationLogs"></timeLine>
                </b-modal>
            </b-col>
        </b-row>

        <ModalAddIntegration style="background-color: #f3f7fd" v-if="renderModalAdd" id="modal-add-integration"
            ref="modal-add-integration" :organizationId="getCurrentAccountID(admin)" @hide="forceRerenderModalAdd" />

    </b-container>
</template>

<script>
import {
    sofbox
} from "@/config/pluginInit";

import AccountsMultiselect from "@/views/children/AccountsMultiselect.vue";
import ModalAddIntegration from "./modals/ModalAddIntegration.vue";
import DtIntegrations from "./children/DtIntegrations.vue";
import DtLists from "./children/DtLists.vue";
import timeLine from "./children/timeLine.vue";
import filters from "./children/filtersIntegration.vue";
import filtersList from "./children/filtersList.vue";
import {
    mapGetters
} from 'vuex'
import {
    helper
} from '@/helpers'
import Spinner1 from '@/components/loaders/spinner1'
import _ from 'lodash'

import store from '@/store/index'
import api from '@/api/RestClient'
import Multiselect from 'vue-multiselect'

export default {
    name: "UiDataTable",
    components: {
        AccountsMultiselect,
        DtIntegrations,
        DtLists,
        Multiselect,
        ModalAddIntegration,
        Spinner1,
        timeLine,
        filters,
        filtersList
    },
    created() {
        let _url = window.location.href.replace('127.0.0.1', 'localhost')
        if (window.location.host.split(":")[0] == '127.0.0.1') window.location.href = _url
    },
    mounted() {
        sofbox.index();
        this.renderModalAdd = true;
        this.refreshTables();
        this.getConsumptionDetails();
        this.isManager();
    },
    methods: {
        isManager() {
            this.is_manager = helper.hasRoles(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_ACCOUNTS_MANAGER']);
        },
        refreshTables() {
            this.$root.$on('reloadIntegrationsAndList', () => {
                // this.$root.$emit('bv::refresh::table', this.$refs['dt-lists']?.id)
                this.$refs['dt-lists'].$refs[this.$refs['dt-lists']?.id].forceRenderTable();
                
                this.$root.$emit('bv::refresh::table', this.$refs['dt-integrations']?.id)
            })
        },
        canAuthorize() {
            var platform = this.auth2 ?.data ?.platform;
            if (platform ?.toLowerCase() == 'keap') this.auth2.platform_code = 9
            if (typeof platform == "undefined") return false;
            else return ((this.auth2 ?.data ?.validation_code == 401) && (['keap'].includes(platform ?.toLowerCase())))
        },

        // To auth2 - review script
        manageActionFromList() {
            const COMP = this;
            let action = COMP.$route.query.action;
            let uriData = {
                ...COMP.$route.query
            };

            if (action) {
                if (['aweber_integration', 'keap_integration', 'mailchimp_integration', 'hubspot_integration'].includes(action)) {
                    if (uriData ?.error) COMP.$swal('Authorization IG', 'IG App ' + uriData ?.error, 'warning');
                    else COMP.$swal('Authorization IG', 'IG App successfully authorized', 'success');
                    localStorage.setItem('oauth.params', JSON.stringify(uriData));
                    localStorage.setItem('oauth.refresh', true);
                    setTimeout(() => {
                        window.close();
                    }, 4000)
                }
            }
        },

        // *****************************************************/
        forceRerenderModalAdd() {
            this.renderModalAdd = false;
            this.$nextTick(() => {
                this.renderModalAdd = true
            })
            // Fixed set account after hide modal
            setTimeout(() => {
                this.onSelectAccount(this.admin.account)
            }, 500)
        },
        onAccountsLoaded(data) {
            const that = this;
            setTimeout(() => {
                that.manageActionFromList(that)
            }, 500);
        },
        onSelectAccount(accountSelected) {
            if (this.is_manager) {
                if (accountSelected) {
                    this.btnAddIsDisabled = false
                    this.admin.account = accountSelected

                    this.$refs['modal-add-integration'].setOrganization(accountSelected)
                    this.$refs['dt-lists'].setAccount(accountSelected)
                } else {
                    delete this.admin.account
                    this.btnAddIsDisabled = true
                    this.$refs['dt-lists'].setAccount(null)
                }
            }
        },
        getCurrentAccountID() {
            var accountId = (typeof this.admin.account.code !== "undefined") ? this.admin.account.code : null;
            accountId = (store.getters['Auth/isAdmin'] && accountId) ? accountId : null
            return accountId;
        },
        showModalAddIntegration(e) {
            e.preventDefault();

            if (this.showAlertBalance() || (this.isClient && !this.consumption_details.account.status)) {

                if (!this.consumption_details ?.account.status) {
                    helper.alertAccountInactive(this.$swal)
                } else if (this.showAlertBalance()) {
                    this.showAlertRemaining()
                }
            } else {
                if (this.isClient) {
                    this.clientHasIntegration()
                }

                this.$root.$emit('bv::show::modal', 'modal-add-integration')
            }
        },
        showAlertBalance() {
            return this.isClient &&
                (!this.remaining ||
                    (this.consumption_details.limit != -1 && (this.consumption_details.remaining + this.consumption_details.limit <= 0))
                )
        },
        getConsumptionDetails() {
            if (this.isClient) {
                api.stats.getConsumptionDataService()
                    .then(response => {
                        this.consumption_details = response.data;
                        this.remaining = response.data.remaining;
                        this.currentPlan = response.data.plan;
                    })
            }
        },
        getCurrentSubscription() {
            const accountID = this.isAdmin && (typeof this.admin.account.code !== 'undefined') ? this.admin.account.code : this.user.account_id;

            api.subscriptions.list(accountID).then((response) => {
                let subscription = _.find(response.data, function (o) {
                    return o.active == 1
                });
                if (typeof this.currentSubscription !== 'undefined') {
                    this.currentSubscription = subscription
                }
            })

        },
        showAlertRemaining() {
            const that = this
            let date = this.currentPlan ? this.currentPlan.next_billing_date ?? 'undefined' : '--';

            let alertMessage = `It\'s appears you are out of events until [${date}]. If you\'d like to activate your overconsumption feature to continue sending events until your next billing cycle, please visit your dashboard`;

            this.$swal({
                title: 'Uh, oh...',
                text: alertMessage,
                type: 'info',
                confirmButtonText: 'Got it!',
                showCloseButton: true,
            })
        },
        clientHasIntegration() {
            let queryParams = {
                page: 1,
                per_page: 1,
                account: this.user ? this.user.account_id : null
            }
            let popUpCreateInt = null;

            return api.integrations.list(queryParams)
                .then((response) => {
                    if (!response.data.data.length) {
                        popUpCreateInt = true
                    }
                })
                .finally(() => {

                    if (popUpCreateInt) {
                        helper.popUpCreateIntegration(this)
                    }

                })
        },
        onShowInvoiceHistories(item) {
            this.integrationLogs = item.integration_logs;
            this.$refs['modal-timeLine'].show();
        },
        allIntegrationLogs() {

        },
        onFilter(form) {
            this.$refs['dt-integrations'].filterTable(form);
        },
        finishedFilter() {
            this.$refs['integrations-filters'].finishFilter();
        },
        finishedFilterLists() {
            this.$refs['lists-filters'].finishFilter();
        },
        toggleInputsFilter(event) {
            const parentElement = event.target.parentElement;
            if (this.isFilter) $(parentElement).parents('.row').removeClass('alert-secondary');
            else $(parentElement).parents('.row').addClass('alert-secondary');
            this.isFilter = !this.isFilter
        },
        toggleInputsFilterLists(event) {
            const parentElement = event.target.parentElement;
            if (this.isFilterLists) $(parentElement).parents('.row').removeClass('alert-secondary');
            else $(parentElement).parents('.row').addClass('alert-secondary');
            this.isFilterLists = !this.isFilterLists
        },
        onFilterLists(form) {
            this.$refs['dt-lists'].filterTable(form);
        },
        hasRoles(roles = []) {
            return !_.isEmpty(_.filter(this.user.roles, function (r) {
                return roles.includes(r.slug);
            }))
        }
    },
    computed: {
        ...mapGetters({
            isClient: 'Auth/isClient',
            user: 'Auth/user',
            platforms_co: 'platformConfigs'
        })
    },
    data() {
        return {
            isFilterLists: false,
            isFilter: false,
            admin: {
                account: {
                    code: null,
                    name: ''
                }
            },
            isAdmin: store.getters['Auth/isAdmin'],
            btnAddIsDisabled: !store.getters['Auth/isAdmin'] ? false : true,
            renderModalAdd: false,

            parentcomponent: this,
            loader: false,
            remaining: null,
            consumption_details: null,
            // For auth2 btn
            auth2: {
                addNew: true,
                btnAuthorize: false,
                inProgress: false,
                state: '',
                btnLabel: '',
                listAccounts: [],
                platforms: [],
                platform: null,
                data: null
            },
            integrationLogs: [],
            currentPlan: null,
            currentSubscription: null,
            popUpCreateInt: false,
            is_manager: false
        };
    },
};
</script>

<style>
#myTabContent-5 {
    padding-bottom: 2em !important;
    /* margin: 0 5px; */
}

.actionClass {
    width: 18%;
}

.timeClass,
.createdatClass,
.accountClass {
    width: 10%;
}

textarea.form-control {
    line-height: 25px;
}

.cursor-default {
    cursor: default;
}

.table th,
.table td {
    padding: 0.55rem;
    vertical-align: top;
}

#edit-modal___BV_modal_body_ {
    background-color: #f3f7fd;
}

#label-btn {
    visibility: hidden;
}
</style>
