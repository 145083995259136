<template>

    <b-container class="px-0" fluid>
        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
            
            <div class="custom-switch-inner">
                
                <label class="mr-2">Limit per day</label>
                
                <input type="checkbox" 
                    class="custom-control-input bg-primary" 
                    v-model="limitPerDay" 
                    id="switch-limit-per-day" 
                    name="switch-limit-per-day"
                    @input="handleSwitchLimitPerDay($event.target)">

                <label class="custom-control-label" for="switch-limit-per-day" data-on-label="On" data-off-label="Off"></label>
            </div>
        </div>
        
        <b-form-input v-model="limit" type="number" name="limit" placeholder="limit per day" @input="updateValue" :disabled="!limitPerDay" ></b-form-input>
    </b-container>

</template>
<script>

export default {
    name: 'LimitPerDayInput',
    props: {},
    components: {},
    data() {
        return {
            limit: null,
            limitPerDay: null,
        }
    },
    methods: {
        handleSwitchLimitPerDay($event) {
            if (!$event.checked) {
                this.limit = null;
                this.limitPerDay = null;
            } else {
                this.limit = 0;
            }
        },
        updateValue() {
            this.$emit('limitPerDay', {limit: this.limit, limitPerDay: this.limitPerDay ? true : false});
        },
    }
}

</script>