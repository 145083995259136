export default {
  resetUser(context, payload) {
    context.commit('commitResetUser', payload)
  },
  updateToken(context, payload)  {
    context.commit('commitToken', payload)
  },
  updateisAdmin(context, payload) {
    context.commit('commitisAdmin', payload)
  },
  updateisClient(context, payload) {
    context.commit('commitisClient', payload)
  },
  updateUser(context, payload) {
    context.commit('commitUser', payload)
  },
  updateLoggedIn(context, payload) {
    context.commit('commitLoggedIn', payload)
  },
  updateIsSubscribed(context, payload) {
    context.commit('commitIsSubscribed', payload)
  },
  updateIsLoggedIn(context, payload) {
    context.commit('commitLoggedIn', payload)
  },
  updateIsAccounting(context, payload) {
    context.commit('commitIsAccounting', payload)
  },
  updateIsAccountManager(context, payload) {
    context.commit('commitIsAccountManager', payload)
  },
  updateIsManager(context, payload) {
    context.commit('commitIsManager', payload)
  },
  updateTokenAs(context, payload) {
    context.commit('commitTokenAs', payload)
  },
  updateUserAdmin(context, payload) {
    context.commit('commitUserAdmin', payload)
  },
  updateLiveOpenersSubscribed(context, payload) {
    context.commit('commitLiveOpenersSubscribed', payload)
  },
  updateLiveLeadsSubscribed(context, payload) {
    context.commit('commitLiveLeadsSubscribed', payload)
  },
  updateLivePlans(context, payload) {
    context.commit('commitLivePlans', payload)
  },
  updateLivePlanLimit(context,payload) {
    context.commit('commitLivePlanLimit', payload)
  },
  updateLastSubscriptions(context, payload) {
    context.commit('commitLastSubscriptions', payload)
  },
  updateUserSubscriptions(context, payload) {
    context.commit('commitUserSubscriptions', payload)
  },
  updateCurrentAccountOptions(context, payload) {
    context.commit('commitCurrentAccountOptions', payload)
  },

  updateUserApiToken(context, payload) {
    context.commit('commitUserApiToken', payload)
  },
  updateAccountServicePlans(context, payload) {
    context.commit('commitAccountServicePlans', payload)
  },
  initUserMetaDta(context, payload) {
    context.commit('commitUserMetaData', payload)
  },
  updateUserMetaDta(context, payload) {
    context.commit('commitAddUserMetaData', payload)
  },
  updateSupportChatRoomID(context, payload) {
    context.commit('commitSupportChatRoomID', payload)
  },
}
