<template>

    <b-container fluid>

       <fieldset>
        <div class="form-card text-left">
            <b-row>
                <!-- Tags -->
                 <b-form-group class="col-md-12 mb-3" label="Tag: *" label-for="list">
                    <div>
                        <multiselect v-model="integration.tag" :loading="isLoading" tag-placeholder="Create tag"
                            :allow-empty="false" deselect-label="Can't remove this value"
                            placeholder="Select tag" label="name" track-by="code" return="code" :options="tags"
                            :multiple="false" :close-on-select="true" :taggable="true" @tag="postTag" @select="handleSelectTag">

                            <template slot="singleLabel" slot-scope="props">
                                <div class="option__desc">

                                    <span class="option__title">

                                        <span v-if="props.option.type">
                                            <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                                                style="color:#5ad"></i>
                                            <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>
                                        </span>
                                        <span v-else>
                                            <i class="ri-list-check color-primary" style="color:#5ad"></i>
                                        </span>

                                        <span style="font-wight:bold;"> {{ props.option.name }} </span>

                                        <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>

                                    </span>

                                </div>
                            </template>

                            <template slot="option" slot-scope="props">
                                <div class="option__desc">

                                    <span class="option__title">

                                        <span v-if="props.option.type">

                                            <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                                                style="color:#5ad"></i>
                                            <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                                        </span>

                                        <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                                        <span style="font-wight:bold;"> {{ props.option.name }} </span>

                                        <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>


                                    </span>

                                </div>

                            </template>

                        </multiselect>
                        <small id="alertTag" class="text-danger d-none"></small>
                    </div>
                </b-form-group>
            </b-row>

        </div>
    </fieldset>
    </b-container>

</template>
<script>

import Multiselect from 'vue-multiselect'
import api from '@/api/RestClient';
import LimitPerDayInput from "@/components/inboxgeek/fields/integration/LimitPerDayInput";

export default {
    name: 'KeapLoIntegrationStep',
    components: {
        Multiselect,
        LimitPerDayInput
    },
    props: {
        form: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            integration: {
                name: null,
                tag: null,
                limit: null,
                rcurrence: null,
                type: 'Tag'
            },
            tags: [],
            isLoading: false,
            is_disabled_limit: false,
        }
    },
    mounted() {
        this.getTags();
    },
    methods: {
        handleSelectTag() {
            this.$emit('tag', this.integration)
        },
        handleUpdateLimitPerDay(data) {
            this.$emit('limitPerDay', data)
        },
        handleInputLimitPerDay() {
            this.$emit('limitPerDay', this.integration)
        },
        handleInputRecurrence() {
            this.$emit('handleRecurrence', this.integration)
        },
        handleInputName() {
            this.$emit('name', this.integration)
        },
        onSwitchLimitPerDay($event) {
            if (!$event.checked) {
                this.integration.limitPerDay = null
                this.integration.limit = null
            }
            else {
                this.integration.limit = 0
            }
            this.is_disabled_limit = !$event.checked
        },
        getTags() {
            this.isLoading = true;
            let platform = this.form.platform.platform;
            let params = {
                entities: 'tags',
                platform_id: platform.id,
                platform_name: platform.name.toLowerCase(),
            };
            api.platforms.fetchServices(params).then((response) => { 
                let tags = response.data
                this.tags = tags.map((tag) => {

                  let formattedTag = {
                    'code': tag.id,
                    'name': tag.name,
                    'type': tag.type ?? 'tag',

                  };

                  if(this.form.list.list.some(item => item.code === tag.id)){
                    formattedTag['$isDisabled'] = true;
                  }

                  return formattedTag;

                });
            }).catch((error) => {
                let message = error.response.data.message
                this.$swal('Oops!!', message, 'error');
            }).finally(() => { 
                this.isLoading = false;
            })
        },
        postTag(tag) {
            let tag_name = tag;
            let list = this.form.list.list;
            this.isLoading = true;
            let platform = this.form.platform.platform;
            let params = {
                entities: 'tags',
                listId: list.code,
                tag: tag,
                platform_id: platform.id,
                platform_name: platform.name.toLowerCase(),
            };
            api.platforms.addTag(params).then((response) => { 
                let tag = response.data
                this.integration.tag = { code: tag.id, name: tag.name, type: 'tag' };
                let message = response.message
                this.$swal('Good', message, 'success');
                this.$emit('tag', this.integration)
            }).catch((error) => {
                let message = error.response.data.message
                this.$swal('Oops!!', message, 'error');
            }).finally(() => { 
                this.isLoading = false;
            })
        },
    }
}

</script>