<template>
  <iq-card>
    <div v-if="loading" class="d-flex justify-content-center py-3">
      <b-spinner small label="Small Spinner" type="grow"></b-spinner>
    </div>
    <div v-else class="p-3 overflow-hidden" style="position: relative;">
      <div class="rounded-circle iq-card-icon" :class="itemClass">
        <i :class="iconClass"></i>
      </div>
      <span class="float-right line-height-6">{{ label }}</span>
      <div class="clearfix"></div>
      <div class="text-center">
        <h3 class="mb-0">
          <span class="revenue pr-2">{{ moneyDefaultFormat(revenue) }}</span>

          <span class="counter-unit">
            <b-spinner v-if="loadingRevenue" small label="Small Spinner" type="grow"></b-spinner>
            <span v-else>
              <i class="mr-1" :class="`${getCompareRevenueClass().icon} text-${getCompareRevenueClass().class}`"></i>
              <span :class="'text-' + getCompareRevenueClass().class">{{ getPercentProgress() }}%</span>
            </span>
          </span>
        </h3>
        <h2 class="mb-0"><span class="counter">{{ value }}</span><span class="counter-unit"> {{ valueUnit }}</span></h2>
      </div>
    </div>
  </iq-card>
</template>

<script>
import api from '@/api/RestClient'
import moment from 'moment'
import store from "../../store/index";
import { helper } from '@/helpers'

export default {
  name: 'DashboardStatsRow',
  components: {},
  props: {
    'label': String,
    'itemClass': String,
    'iconClass': String,
    'valueUnit': String,
    'period': {
      type: String,
      default: ""
    },
  },
  computed: {
  },
  data: () => ({
    loading: false,
    loadingRevenue: true,
    options: {
      from: 0,
      to: 0
    },
    prevOptions: {
      from: 0,
      to: 0
    },
    value: 0,
    revenue: 0,
    prevRevenue: 0
  }),
  mounted() {
    this.loadCardStats();
    this.loadRevenueStats();
  },
  methods: {
    loadCardStats() {
      this.options.token = store.getters['Auth/token'];

      this.options = this.getDatesByName(this.period);
      this.prevOptions = this.getPreviousDatesByName(this.period);

      // this.getTotalEventsStats(this.options)
    },
    loadRevenueStats() {
      this.loadingRevenue = true;

      const currentRevenue = this.getRevenues({...this.options, total_events: 1})
      const previousRevenue = this.getRevenues(this.prevOptions)
      Promise.all([currentRevenue, previousRevenue]).then((values) => {
        this.revenue = parseFloat(values[0].data.total)
        this.prevRevenue = parseFloat(values[1].data.total)

        if(values[0].data?.total_events) {
          this.value = parseFloat(values[0].data.total_events);
        }

      }).finally(() => {
        this.loadingRevenue = false
      });
    },
    getDatesByName(label) {
      let dates = {
        from: 0,
        to: 0,
        service: 1
      }
      switch (label) {
        case 'today':
          dates.from = moment().format('YYYY-MM-DD')
          dates.to = moment().format('YYYY-MM-DD')
          break;
        case 'yesterday':
          dates.from = moment().subtract(1, 'days').format('YYYY-MM-DD')
          dates.to = moment().subtract(1, 'days').format('YYYY-MM-DD')
          break;
        case 'week':
          dates.from = moment().startOf('isoWeek').format('YYYY-MM-DD')
          dates.to = moment().format('YYYY-MM-DD')
          break;
        case 'month':
          dates.from = moment().format("YYYY-MM-01");
          dates.to = moment().format('YYYY-MM-DD')
          break;
      }
      return dates;
    },
    getPreviousDatesByName(label) {
      let dates = {
        from: 0,
        to: 0,
        service: 1
      }
      switch (label) {
        case 'today':
          dates.from = moment().subtract(1, 'days').format('YYYY-MM-DD')
          dates.to = moment().subtract(1, 'days').format('YYYY-MM-DD')
          break;
        case 'yesterday':
          dates.from = moment().subtract(2, 'days').format('YYYY-MM-DD')
          dates.to = moment().subtract(2, 'days').format('YYYY-MM-DD')
          break;
        case 'week':
          dates.from = moment().startOf('isoWeek').subtract(1, 'weeks').format('YYYY-MM-DD')
          dates.to = moment().subtract(1, 'weeks').format('YYYY-MM-DD')
          break;
        case 'month':
          dates.from = moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD')
          dates.to = moment().subtract(1, 'months').format('YYYY-MM-DD')
          break;
      }
      return dates;
    },
    // getTotalEventsStats(options) {
    //   console.log('options', options); 
    //   // this.loading = false
    //   // return;
    //   this.loading = true;

    //   api.stats.getEventsTotal(options)
    //     .then((chartData) => {
    //       this.value = helper.formatDecimal(chartData?.data?.total ?? 0)
    //     })
    //     .catch(err => {
    //     })
    //     .finally(() => {
    //       this.loading = false
    //     })
    // },
    getRevenues(options) {
      return api.stats.revenues(options)
    },
    getCompareRevenueClass() {
      let textClass = '';
      let icon = '';

      if (this.revenue == this.prevRevenue) {
        textClass = 'dark'
        icon = 'ri-arrow-right-s-fill'
      } else if (this.revenue > this.prevRevenue) {
        textClass = 'success'
        icon = 'ri-arrow-up-s-fill'
      } else {
        textClass = 'danger'
        icon = 'ri-arrow-down-s-fill'
      }
      return { "class": textClass, "icon": icon }
    },
    getPercentProgress() {
      let change = (this.revenue - this.prevRevenue) / (this.revenue + this.prevRevenue) * 1;
      if (isNaN(change)) {
        change = 0;
      }
      return (change * 100).toFixed(2)
    },
    moneyDefaultFormat(amount) {
      return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    },
  },
}
</script>

<style lang="scss" scoped>
.counter-unit {
  font-size: 12px;
  color: #6c757d;
}
</style>
