<template>

    <iq-card>
        <template v-slot:headerTitle>
            <h4 class="card-title">Personal Information</h4>
        </template>
        <template v-slot:body>
            <div class="view pb-4" style="text-align: justify;" v-if="!edit">
                <b-row class="billing-detail">
                    <div class="col-lg-6">
                        <p class="billing-detail-plan">First Name</p>
                        <span class="h5">{{ profile.first_name }}</span>
                    </div>
                    <div class="col-lg-6">
                        <p class="billing-detail-plan">Last Name</p>
                        <span class="h5">{{ profile.last_name }}</span>
                    </div>
                </b-row>
                <b-row class="billing-detail">
                    <div class="col-lg-6">
                        <p class="billing-detail-plan">Email Address</p>
                        <span class="h5">{{ profile.email }}</span>
                    </div>
                    <div class="col-lg-6">
                        <p class="billing-detail-plan">Address</p>
                        <span class="h5">{{ profile.address }}</span>
                    </div>
                </b-row>
                <b-button variant="primary" class="float-right" @click="edit = true" :disabled="isAdmin">Edit</b-button>
            </div>
            <div class="edit" v-else>
                <b-form @submit.prevent="updateProfile" class="pb-4">
                    <b-row align-v="center">
                        <b-form-group class="col-sm-6" label="First Name:" label-for="fname">
                            <b-form-input id="fname" v-model="profile.first_name"></b-form-input>
                            <div class="text-danger" id="first_name">{{ errors.first_name }}
                            </div>
                        </b-form-group>
                        <b-form-group class="col-sm-6" label="Last Name:" label-for="lname">
                            <b-form-input id="lname" v-model="profile.last_name"></b-form-input>
                            <div class="text-danger" id="last_name">{{ errors.last_name }}
                            </div>
                        </b-form-group>
                        <b-form-group class="col-sm-12" label="Email Address:" label-for="lemail">
                            <b-form-input id="lemail" v-model="profile.email"></b-form-input>
                            <div class="text-danger" id="email_address">{{
                                errors.email_address }}
                            </div>
                        </b-form-group>
                        <b-form-group class="col-sm-12" label="Address:">
                            <b-form-textarea name="address" style="line-height: 22px;" rows="5"
                                v-model="profile.address">
                            </b-form-textarea>
                            <div class="text-danger" id="email_address">{{
                                errors.address }}
                            </div>
                        </b-form-group>
                    </b-row>
                    <b-button type="submit" variant="primary" class="float-right">Submit
                        <b-spinner small type="grow" v-show="update_spinner"></b-spinner>
                    </b-button>
                    <b-button variant="dark" class="float-right mr-2" @click="edit = false">Close</b-button>
                </b-form>
            </div>
        </template>
    </iq-card>

</template>
<script>

import api from '@/api/RestClient'
import { helper } from '@/helpers'
import { mapGetters } from 'vuex'

    
export default {
    name: 'PersonalInformationCard',
    props: {},
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            user: 'Auth/user',
        })
    },
    data() {
        return {
            edit: false,
            errors: [],
            update_spinner: false,
            profileId: null,
            profile: {
                first_name: '--',
                last_name: '--',
                email: '--',
                address: '--',
            },
        }
    },
    mounted() {
        this.loadProfileData();
    },
    methods: {
        loadProfileData() {
            // this.account = '--';
            // if (!this.isAdmin) {
                this.profileId = this.user.profile_id;
                this.profile.first_name = this.user.profile.first_name;
                this.profile.last_name = this.user.profile.last_name;
                this.profile.email = this.user.email;
                this.profile.address = this.user.profile.address;
            // }
        },
        updateProfile() {
            this.checkUpdateForm();
            if (helper.isEmpty(this.errors)) {
                this.update_spinner = true;
                const profileId = this.profileId;
                this.update(profileId, this.profile);
            }
        },
        checkUpdateForm() {
            this.resetErrors();
            if (!this.profile.first_name) {
                this.errors.first_name = 'First name required.';
            }
            if (!this.profile.last_name) {
                this.errors.last_name = 'Last name required.';
            }
            if (!this.profile.email) {
                this.errors.email_address = 'Email required.';
            }
            if (!this.profile.address) {
                this.errors.address = 'Address field required.';
            }

            if (!helper.validMail(this.profile.email)) {
                this.errors.email_address = 'Email format did not matched.';
            }

        },
        update(id, data) {
            if (!this.isAdmin) {
                api.profiles.put(id, data).then(response => {
                    // update user in store
                    this.edit = false;
                    this.$store.dispatch('Auth/updateUser', response.data)
                    this.toast('Updated!', 'success', response.message);
                }).catch(err => {
                    let errors = err.response.data.errors;
                    errors.forEach(error => {
                        this.toast('Oh!', 'danger', error);
                    });
                })
                    .finally(() => {
                        this.update_spinner = false;
                        this.delete_spinner = false;
                    })
            } else this.toast('Oh!', 'danger', 'Your are admin')
        },
        resetErrors() {
            this.errors = {}
        },
        toast(title = "success", variant = "success", message = "Account chargerd") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        }
    }
}

</script>