var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{attrs:{"fluid":""}},[(_vm.renderDT)?_c('DataTable',_vm._b({ref:"dt-subscriptions-summary",attrs:{"url":'/subscriptions_summary'}},'DataTable',{
            columns: _vm.columns, 
            customCells: [], 
            filters: _vm.filters, 
            organization: _vm.accountId, 
            theadTop: _vm.theadTop
        },false)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }