<template>
    <b-container fluid>

        <b-col sm="12">
            <iq-card class="p-2">

                <template v-slot:headerTitle>
                    <h4 class="card-title">Platforms</h4>

                </template>
                <template v-slot:headerAction>
                    <b-form v-if="isAdmin || isAccountManager" class="mx-3 my-2">
                        <AccountsMultiselect @onSelect="SelectAccount" />
                    </b-form>
                    <b-button v-b-modal.add-platform-modal variant="primary" class="text-right_ mr-1"
                        :disabled="!AllowToCreatePlatform"><i class="fa fa-plus"></i> Add New Platform</b-button>
                </template>
                <template v-slot:body>



                    <b-input v-model="search" placeholder="search" class="mt-0 mb-4"></b-input>



                    <b-row v-if="loading.platforms == true">

                        <div class="text-center p-4 d-flex justify-content-center align-items-center w-100">
                            <b-spinner variant="primary"> </b-spinner>
                            <span class="text-primary p-2"><small> Fetching platforms, please wait...</small></span>

                        </div>
                    </b-row>
                    <b-row v-else>

                        <b-col v-if="isAdmin && !account.accountid" class="w-100 m-4 text-center">No Account is selected ,
                            please select an account to fetch platforms</b-col>

                        <b-col v-else-if="filtered_platforms.length == 0" class="w-100 m-4 text-center">Oh It seems You
                            don't have any connected platform yet, create one by clicking on create new platform
                            button</b-col>

                        <b-col v-for="platform in filtered_platforms" :key="platform.id" cols="4">
                            <div class="bg-white rounded my-2 p-4 ig-p-card"
                                :class="{ 'inactive-platform': (isConnected(platform) == false) }">

                                <NetWorkState :platform="platform"></NetWorkState>

                                <span class="action-icon action-info" v-b-modal.update-platform-details
                                    @click="editPlatform(platform)"><i class="ri-pencil-line"></i></span>
                                <span class="action-icon action-danger" @click="deletePlatform(platform)"
                                    style="top:80px"><i class="ri-delete-bin-7-line"></i></span>

                                <b-row class="row mt-3" align-v="center">
                                    <b-col align-self="center" class="text-center" cols="3" style="height:7rem;">
                                        <img style="width: 70%;margin-top: 1rem;" :src="getImageURL(platform.name)">
                                    </b-col>
                                    <b-col cols="8">
                                        <h4>{{ platform.nickname }}</h4>
                                        <h6 :class="[isConnected(platform) ? 'text-primary' : 'secondary']"> {{
                                            platformName(platform.name) }}</h6>
                                        <small>{{ platform.key }}</small><br>
                                        <small>{{ platform.account }}</small>
                                    </b-col>
                                </b-row>

                            </div>
                        </b-col>
                    </b-row>



                </template>

            </iq-card>

        </b-col>

        <EditPlatformModal v-bind="{form, accountId: account.accountid, platform: currEditPlatform, platformApiKey: currPlatformApiKey, oauth}" @hideModalEdit="hideModalEdit"></EditPlatformModal>
        
        <b-modal id="add-platform-modal" ref="createPlatformModal" hide-footer title="Create new platform"
            style="padding-bottom: 10px;">

            <createNewPlatform :account="account" :oPlatforms="platforms" @platformCreated="platformCreated"
                @cancelCreated="$refs['createPlatformModal'].hide()"></createNewPlatform>


        </b-modal>


    </b-container>
</template>

<script>
import {
    sofbox
} from '../../config/pluginInit'
import Spinner1 from '../../components/loaders/spinner1'
import Multiselect from 'vue-multiselect'
import api from '@/api/RestClient';
import createNewPlatform from '@/components/inboxgeek/createNewPlatform';
import AccountsMultiselect from "@/views/children/AccountsMultiselect.vue";
import store from '@/store/index'
import { PLATFORMS_CODE, COLUMNS_GRID, PLATFORMS_ITEMS } from "@/constantes";
import NetWorkState from "./children/NetWorkState.vue";
import EditPlatformModal from "./modals/EditPlatformModal.vue";
import { mapGetters } from 'vuex'

import {
    helper
} from '@/helpers';
import { services } from '@/helpers';

export default {
    name: 'platformList',
    components: {
        Spinner1,
        Multiselect,
        createNewPlatform,
        AccountsMultiselect,
        NetWorkState,
        EditPlatformModal
    },
    async mounted() {

        sofbox.index()
        if (!this.isAdmin || !this.isAccountManager) {
            this.fetchExistingPlatforms();
        }
    },
    data() {
        return {
            loading: {
                modal: false,
                platforms: false,
            },
            platforms: [],
            loader: false,
            headerTitle: 'Platforms',
            filter: 7,

            form: {
                id: null,
                key: null,
                name: null,
                account: null,
                nickname: null,
            },
            platformEdit: null,

            
            account: {
                accountid: null
            },
            search: '',
            showModal: false,
            currEditPlatform: null,
            currPlatformApiKey: null,

            // Reconnection btn methods
            oauth: {
                state: '',
                inProgress: false,
            },
        }

    },
    methods: {
        
        SelectAccount(account) {

            if (!account) {

                this.account.accountid = null;
                this.platforms = null;

                return;
            }

            this.account.accountid = account.code;
            this.fetchExistingPlatforms(account.code);


        },
        platformCreated(platform) {
            this.fetchExistingPlatforms(this.account.accountid);
            this.$refs['createPlatformModal'].hide();
            this.$forceUpdate();

        },
        editPlatform(platform) {

            this.form.name = this.platformName(platform.name);
            this.form.id = platform.id;
            this.form.account = platform.account ?? null;
            this.form.key = platform.key;
            this.form.nickname = platform.nickname;
            this.platformEdit = platform;
            this.showModal = true;

            this.fetchPlatform(this.form.id);

        },
        fetchPlatform(platformID) {
            if (!platformID) return;

            api.platforms.fetch(platformID)
                .then((response) => {

                    if (response.success) {
                        this.currEditPlatform = response.data
                        this.currPlatformApiKey = this.isJSON(response.data.key) ? this.getPlatformApiAccess(response.data.key) : response.data.key
                    }

                });
        },
        isJSON(text) {
            if (typeof text !== "string") {
                return false;
            }
            try {
                JSON.parse(text);
                return true;
            } catch (error) {
                return false;
            }
        },
        getPlatformApiAccess(ApiKey) {
            let key = JSON.parse(ApiKey);

            return typeof key.accessToken != 'undefined' ? key.accessToken : key.access_token;
        },
        hideModalEdit() {
            // this.showApiKey = false;
            this.currEditPlatform = null;
            this.currPlatformApiKey = null;

            this.fetchExistingPlatforms(this.account.accountid);

        },
        fetchExistingPlatforms(account) {

            this.loading.platforms = true;


            return api.platforms.index(account).then((response) => {


                this.platforms = response.data;
                this.loading.platforms = false;

            }).catch((error) => {


                this.loading.platforms = false;

            });

        },
        onFiltered(filteredItems) {

            this.totalRows = filteredItems.length
            this.currentPage = 1

        },
        toast(title = "success", variant = "success", message = "Organization updated successfully") {

            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            });

        },
        onServiceSelected() {

            this.form.service_id = this.service.code;
        },
        getImageURL(platform) {

            try {

                return require(`@/assets/images/platforms/${platform.toLowerCase()}.png`);

            } catch (e) {

                return require(`@/assets/images/platforms/default.png`);

            }
        },
        deletePlatform(platform) {

            this.$swal({
                title: 'Are you sure?',
                text: 'If there are any active integrations associated with this platform, those integrations will be turned off.',
                type: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            })
                .then((result) => {

                    if (result && result.isConfirmed) {


                        api.platforms.delete(platform.id).then((response) => {

                            if (this.account?.accountid) {
                                this.fetchExistingPlatforms(this.account.accountid);

                            } else {
                                this.fetchExistingPlatforms();
                            }
                        });


                    }
                })

        },
        isConnected(platform) {
            return (platform.validation_code != 113 || platform.validation_code == null)
        },
        platformName(name) {

            return helper.platformFormatName(name);
        },
    },
    computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            isAccountManager: 'Auth/isAccountManager',
        }),
        filtered_platforms() {

          if(this.isAdmin && !this.account.accountid) return [];

            if (this.search != '') {

                return this.platforms.filter((platform) => {

                    if (
                        platform.name.toLowerCase().includes(this.search.toLowerCase()) ||
                        platform.nickname?.toLowerCase().includes(this.search.toLowerCase())  ||
                        platform.account?.toLowerCase().includes(this.search.toLowerCase())
                    ) {
                        return platform;
                    }
                })
            }
            return this.platforms;
        },
        AllowToCreatePlatform() {
            if (this.isAdmin || this.isAccountManager) {
                return (this.account?.accountid != null);
            }
            return true;
        },
        isOAuthPlatform() {
            let state = this.currEditPlatform 
                && this.currEditPlatform.name != null 
                && this.currEditPlatform.name.trim() != '' 
                && ['aweber', 'hubspot', 'keap', 'mailchimp'].includes(this.currEditPlatform.name.toLowerCase());
                console.log( 'isOAuthPlatform', this.currEditPlatform, state );
            return state;
        },
    }
}
</script>

<style>
.ig-p-card:hover {
    box-shadow: 0 0 14px #f6386b51;
    transition: box-shadow .3s ease-in-out;
}

.ig-p-card {
    box-shadow: 0 0.2rem 0.5rem rgb(227 231 239);
    transition: box-shadow .8s ease-in-out;
}

.inactive-platform {
    background-color: #f4f7fd !important;
    box-shadow: inset 0 0 20px #dde2ed !important;
}

.active-spot {
    background: #1add47;
}

.inactive-platform img {
    filter: grayscale(100%);
    opacity: 0.5;
}

.inactive-spot {
    background: #575757;
}

.spot-light {

    width: 15px;
    height: 14px;
    border-radius: 40px;
    position: absolute;
    left: 30px;
    top: 25px;

}

.text-status {
    position: absolute;
    left: 50px;
    top: 20px;
}

.action-info {
    color: #5ad;
}

.action-info:hover {
    color: #5ad;
    background-color: #00c9ff3b !important;
}

.action-danger {
    color: #ff0f0f;
    z-index: 50;
}

.action-danger:hover {
    color: #ff0f0f;
    background-color: #ff000021;
}

.action-icon {
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    right: 40px;
    top: 30px;
    border-radius: 25px;
    padding: 0px 10px;
}
</style>
