<template>

    <b-row>
        <b-form-group label-for="list" class="col-md-12">

            <BeehiivCustomFieldsInput 
                :list="list" 
                :customFields="listsFormatted" 
                :platform="platform" 
                @customFieldSelected="customFieldSelected"></BeehiivCustomFieldsInput>

            <small v-if="errors?.list" class="text-danger">A list should be selected</small>
        </b-form-group>
    </b-row>

</template>
<script>


import Multiselect from 'vue-multiselect'
import BeehiivCustomFieldsInput from '@/components/inboxgeek/fileds/BeehiivCustomFieldsInput';


export default {
    name: 'BeehiivLoListStep',
    components: {
        Multiselect,
        BeehiivCustomFieldsInput
    },
    props: {
        lists: {
            type: Array,
            default: () => []
        },
        form: {
            type: Object,
            default: () => {}
        },
        errors: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            isLoading: false,
            list: null,
            platform: null,
            listLabel: 'List: *',
            listPlaceholder: 'Select Lists',
        }
    },
    mounted() {
        if(this.form.platform && this.form.platform?.platform) {
            this.platform = this.form.platform.platform
            this.platform.code = this.platform.id
            this.platform.p_id = this.platform.id
        }
    },
    methods: {
        handleSelectList(itemSelected) {
            this.$emit('select', this.list)
        },
        customFieldSelected(custom_field){
            console.log('customFieldSelected:custom_field', custom_field)
            if (custom_field) {
                this.list = [{
                    code: custom_field.code,
                    name: custom_field.name
                }];
            } else {
                this.list = null;
            }
            this.$emit('select', this.list)
        },
    },
    computed: {
        listsFormatted() {
            let lists = this.lists.map((list) => {
              return {
                code: list.id,
                name: list.name,
                type: list.type ?? 'list'
              }
            });

            return lists;
        }
    }
}

</script>
