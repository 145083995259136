<template>
    <div class="px-2 acc-edit">


        <b-row class="billing-detail mb-2">
            <div class="col-4">
                <h5 class="billing-detail-plan mb-2">Current Subscription</h5>
                <div v-if="plan">
                    <h4 class="billing-detail-price">{{ plan.name }}</h4>
                    <span v-if="plan.price"
                        style="background: #eaecef; padding: 3px 10px; border-radius: 6px; color: #3c4948;">${{ plan.price
                        }}.00</span>
                    &nbsp;&nbsp;
                    <span v-if="plan.unit_price">${{ plan.unit_price }}/event</span>
                </div>
                <div v-else>
                    <span class="text-danger">
                        Unsubscribed
                    </span>
                </div>
            </div>
            <div class="col-6">
                <b-row>
                    <b-col>
                        <h5 class="billing-detail-plan mb-2">Next Billing Cycle</h5>
                    </b-col>
                    <b-col v-if='plan && plan.next_plan'>
                        <h5 class="billing-detail-plan mb-2">Next Subscription Plan</h5>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <span>
                            <span v-if="plan && plan.is_trial" class="billing-detail-price"> Trial ends on:</span>
                            <span v-else class="billing-detail-price"> Billing Date :</span>

                            <br>{{
                                isResubscribe(plan) ? (plan.next_billing_date ? plan.next_billing_date : `your
                            subscription ends on ${plan.subscription_end_date}`) : 'N/A'
                            }}</span>
                    </b-col>
                    <b-col v-if='plan && plan.next_plan'>

                        <span>

                            <h5 class="billing-detail-price">{{ plan && plan.next_plan.name }} </h5>
                            <span style="background: #eaecef; padding: 3px 10px; border-radius: 6px; color: #3c4948;">${{
                                plan.next_plan.price }}.00</span>
                            <span>&nbsp;${{ plan.next_plan.unit_price }}/event</span>
                        </span>
                    </b-col>
                </b-row>

                <br>


            </div>
            <div class="col-2">

                <router-link alt="Change my plan" v-if='paymentsMethod.length'
                    :to="{ name: 'services.plan', params: { service: service_id } }"
                    class="btn btn-primary w-100">Change</router-link>

                <button v-else class="btn btn-primary w-100" disabled>Change</button>
                <button @click="manageSubscription" class="btn text-danger p-0 w-100" v-if="plan && !plan.is_trial">
                    {{ plan.next_billing_date ? 'Cancel My Subscription' : 'Resubscribe' }}
                    <b-spinner small label="Small Spinner" v-show="subscription_loader" type="grow"></b-spinner>
                </button>

            </div>
            <div class="mt-2 mb-0" v-if="plan && plan.next_plan"
                style="background-color: #ffd40021 !important; padding: 5px 20px; width: 100%; color: #b95610; border-radius: 6px;">
                * Plan change will be applied on the next billing cycle</div>
        </b-row>

        <b-row v-if="plan && plan.is_trial">

            <b-col md="6">
                <span class="badge badge-primary">Trial</span>
            </b-col>

            <b-col v-show="remainingDays != null" md="6">
                <span class="badge" :class="{ 'badge-info': remainingDays >= 0, 'badge-danger': remainingDays < 0 }">
                    Trial ends on
                    {{ remainingDays }} day{{ (remainingDays > 1 ? 's' : '') }} left
                </span>
            </b-col>

            <b-col md="12">

                <FreeAccountProgress v-bind="{ percent: percentDay, day: remainingDays }">
                </FreeAccountProgress>

            </b-col>
        </b-row>
</div></template>

<script>
import {
    sofbox
} from '../../config/pluginInit'

import {
    loadStripe
} from '@stripe/stripe-js'
import api from '@/api/RestClient'
import {
    mapGetters
} from "vuex";
import moment from 'moment'
import FreeAccountProgress from '@/components/users/FreeAccountProgress'

export default {
    name: 'BillingDetailsOpenEvent',
    props:['service_id','plan'],
    components: {
  
        FreeAccountProgress
    },
    
    computed: {
        ...mapGetters('Auth', ['user'])
    },
    created() {
        this.account_id = this.user.account_id;
    },
    mounted() {
        sofbox.index();
        window.that = this;
        let name = this.user.name.split(' ');
        this.address = {
            first_name: name[0],
            last_name: name[1],
            address: this.user.address,
        }
        //this.currentPlan();
        this.initPaymentMethod();
        this.iniInvoices();

        this.calculateRemainingDays();
    },
    data() {
        return {
          
            errors: [],
            paymentsMethod: [],
            invoices: [],
            modal: false,
            fails: false,
            success: false,
            address: [],
            no_payment_method: true,
            loading: false,
            invoice_columns: [{
                key: 'due_date',
                label: 'Date',
                tdClass: 'text-center'
            },
            {
                key: 'amount',
                label: 'Amount',
                tdClass: 'text-center'
            },
            {
                key: 'status',
                label: 'Status',
                tdClass: 'text-center'
            },
            {
                key: 'description',
                label: 'Description',
                tdClass: ''
            },
            {
                key: 'action',
                label: 'Action',
                tdClass: 'text-center'
            },
            ],
            account_id: null,
            subscription_loader: false,
            cancelSubscriptionText: 'Cancel My Subscription',
            remainingDays: 0,
            percentDay: 0
        }
    },
    methods: {

        calculateRemainingDays(){

           if(this.plan ){
            let today = moment();
            let nextBilling = moment(this.plan.next_billing_date ?? this.plan.subscription_end_date, 'YYYY-MM-DD HH:mm:ss');
            const remainingDays = nextBilling.diff(today, 'days')
            this.remainingDays = remainingDays;
            this.percentDay = ((30 - remainingDays) * 100) / 30;
           }
          

        },
        
        initPaymentMethod() {
            api.billing.getPaymentMethods()
                .then(resp => {
                    let data = resp.data;
                    if (data.success && data.data) {
                        this.paymentsMethod = data.data;
                        // sofbox.index()
                    }
                    if (!this.paymentsMethod.length) {
                        this.openModal();
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 401) {
                            this.$store.dispatch('Auth/resetUser', false);
                            this.$router.go(this.$router.currentRoute)
                        } else if (error.response.status == 500) {

                            this.$bvToast.toast('something went wrong!', {
                                title: 'Error',
                                variant: 'danger'
                            });
                        }

                        this.$bvToast.toast('something went wrong!', {
                            title: 'Error',
                            variant: 'danger'
                        })
                    }
                })
                .finally(() => {
                    sofbox.closeLoader();
                });
        },
        DeletePaymentMethod(id, index) {
            this.$bvModal.msgBoxConfirm('Are you sure you want to delete this payment method.', {
                title: 'Please Confirm',
                size: 'sm',
                bodyClass: 'delete-payment-method-confirmation',
                okVariant: 'danger',
                cancelVariant: 'dark',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2 footer-modal-deleter-payment-method',
                hideHeaderClose: false,
                centered: true
            })
                .then(confirmation => {
                    if (confirmation)
                        api.billing.deletePaymentMethod(id).then(resp => {
                            let data = resp.data;
                         
                            if (data.success) {
                                this.paymentsMethod.splice(index, 1)
                                // this.paymentsMethod = data.data;
                            }
                        });
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 401) {
                            this.$store.dispatch('Auth/resetUser', false);
                            this.$router.go(this.$router.currentRoute)
                        } else if (error.response.status == 500) { }

                        this.$bvToast.toast('something went wrong!', {
                            title: 'Error',
                            variant: 'danger'
                        })
                    }
                });

        },
        async saveNewPaymentMethod() {
            this.loading = true;
            const {
                paymentMethod,
                error
            } = await this.stripe.createPaymentMethod(
                'card', this.cardElement, {
                billing_details: {
                    name: this.address.first_name + ' ' + this.address.last_name,
                    email: this.user.email,
                    address: {
                        line1: this.address.address
                    }
                }
            }
            );

            if (paymentMethod) {
                api.billing.savePaymentMethod({
                    paymentMethod: paymentMethod
                })
                    .then((response) => {
                        let data = response.data;
                        if (data.success) {
                            this.paymentsMethod.push(data.data);
                            this.loading = false;
                            this.success = true;
                            this.errors = [];
                            this.fails = false;
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.status == 401) {
                                this.$store.dispatch('Auth/resetUser', false);
                                this.$router.go(this.$router.currentRoute)
                            } else if (error.response.status == 500) {

                                this.$bvToast.toast('something went wrong!', {
                                    title: 'Error',
                                    variant: 'danger'
                                });
                            } else if (error.code == 'incorrect_number') {
                                this.loading = false;
                                this.errors = error.message;
                                this.fails = true;
                                this.success = false;
                            }

                            this.$bvToast.toast('something went wrong!', {
                                title: 'Error',
                                variant: 'danger'
                            })
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }

            if (error) {
                this.loading = false;
                this.errors.push(error.message);
                this.fails = true;
              
            }
        },
        close(e) {
            e.stopPropagation();
            this.modal = false;
            this.loading = false;
            this.success = false;
            this.errors = [];
            this.fails = false;
        },
        changeDefault(element, id) {
            if (element.target.checked)
                api.billing.changeDefaultMethod({
                    id: id,
                }).then((response) => {
                    let data = response.data;
                    if (data.success) {
                        this.paymentsMethod = data.data;
                    }
                });
            $.each(this.paymentsMethod, (index, pm) => {
                pm.default = false;
                if (pm.id == id)
                    pm.default = true;
            });
        },
        iniInvoices() {
            api.billing.getInvoices().then((response) => {
                let data = response.data;
                if (data.success) {
                    this.invoices = data.data;
                }
            });
        },
        manageSubscription() {
            if(this.plan.next_billing_date) {
                this.cancelSubscription();
            } else {
                this.resubscribeSubscription();
            }
        },
        resubscribeSubscription() {
            
            let endSubscriptionDate = this.plan.subscription_end_date;
            if (endSubscriptionDate != null && this.date_diff(endSubscriptionDate) <= 0) {
                window.location.href = '/user/plan';
                return;
            }

            this.$swal({
                title: "Please confirm the reactivation of the subscription",
                text: 'Click yes to enable',
                type: 'info',
                confirmButtonText: "Yes, please resubscribe",
                cancelButtonText: "No, I've changed my mind",
                showCancelButton: true,
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.subscription_loader = true;
                    this.resubscribe();
                }
            })
        },
        cancelSubscription() {
            console.log(this.plan.next_billing_date, this.plan);

            let text = `Click below to cancel your subscription`

            if (this.plan && this.plan.next_billing_date) {
                let nextBillingDate = moment(this.plan.next_billing_date)

                if(nextBillingDate.isSameOrAfter(moment(), 'day')) {
                    text += ` (You will still receive events until ${nextBillingDate.format('YYYY-MM-DD')})`;
                }
            }

            this.$swal({
                title: "Confirm Your Cancellation",
                text: text,
                type: 'danger',
                confirmButtonText: "Yes, please cancel",
                cancelButtonText: "No, I've changed my mind",
                showCancelButton: true,
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {

                if (result.isConfirmed) {
                    this.subscription_loader = true;
                    this.cancel();
                }
            })

            
        },
        cancel() {
            api.subscriptions.cancelSubscription(this.user.account_id, {service_id : this.service_id})
                .then(result => {
                    const title = result.success ? 'You have successfully cancelled your subscription' : 'Ooops!';
                    const message = result.message;
                    const type = result.success ? 'success' : 'info';
                    this.$swal(title, message, type);

                    if( result.success ) {
                        // const title = 'You have successfully cancelled your subscription';
                        // const message = 'You can subscribe at any point by returning to your billing dashboard';
                        // this.$swal(title, message, 'success');
                        // this.currentPlan();

                        // Update store UserSubscriptions after cancel
                        let subscriptions = this.$store.getters['Auth/UserSubscriptions'];
                        if( typeof subscriptions[this.service_id] != 'undefined' ) {
                            delete subscriptions[this.service_id];
                            // this.$store.dispatch('Auth/updateUserSubscriptions', subscriptions);
                        }
                    }
                    
                })
                .catch(function (error) {
                     this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        variant: 'danger'
                    });
                })
                .finally(() => {
                    this.subscription_loader = false;
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                });
        },
        resubscribe() {
            let plan = this.plan;
            api.subscriptions.reSubscription(this.user.account_id, { plan })
                .then(result => {
                    const title = result.success ? 'Good!' : 'Ooops!';
                    const message = result.message;
                    const type = result.success ? 'success' : 'info';
                    this.$swal(title, message, type);
                    // this.currentPlan();
                })
                .catch(function (error) {
                   this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        variant: 'danger'
                    });
                })
                .finally(() => {
                    this.subscription_loader = false;
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                });
        },
        date_diff(date) {
            var now = moment(new Date()); //todays date
            var end = moment(date); // another date
            var duration = moment.duration(end.diff(now));
            var days = duration.asDays();
            return days;
        },

        isResubscribe(plan) {
            let check = false;
            if (plan) {
                const date = plan.subscription_end_date ? plan.subscription_end_date : plan.next_billing_date;
                if (date == null) return false;
                check = this.date_diff(date) > 0 ? true : false;
            }
            return check;
        }

    },
    watch : {

        plan(new_value,old_value){
            if(new_value && new_value.next_billing_date){
            let today = moment(); 
            let nextBilling = moment(new_value.next_billing_date ?? this.plan.subscription_end_date, 'YYYY-MM-DD HH:mm:ss');
            const remainingDays = nextBilling.diff(today, 'days')
            this.remainingDays = remainingDays;
            this.percentDay = ((30 - remainingDays) * 100) / 30;
           }

        }

    }
}
</script>
<style>
.fa-infinity {
    font-size: 25px !important;
}

thead {
    text-align: center;
}</style>
