<template>
<div>

  <!-- Suppression List name Section -->
  <b-row class="mb-4">
    <b-col>
      <label for="">name: *</label>
      <input type="text" class="form-control" v-model="name">
      <small v-if="errors?.name" class="text-danger">Suppression list name is required</small>
    </b-col>
  </b-row>
  <!-- Platform Section -->

  <div v-if="form.initial.uploadFile">

    <SuppressionListField @suppressionListUploaded="suppressionListUploaded"></SuppressionListField>

  </div>

  <div v-else>
    <div v-if="currentPlatformListInput">
      <component :is="currentPlatformListInput.component" :form="form" :listsProp="lists" @ListSelected="handelListSelectedEvent"></component>
    </div>
    <div v-else>
      <div class=" p-4 rounded alert-info">
        <h5 style="color: #064953">Platform Under Development</h5>
        <p style="color: #064953">We are actively working on this platform. we anticipate making it available to you soon.</p>
      </div>
    </div>
  </div>


  <!-- Navigation buttons -->
  <hr>

  <div class="d-flex justify-content-end" style="gap:5px">
    <button class="btn btn-light" @click="$emit('cancel')">Cancel</button>
    <button class="btn btn-secondary d-flex align-items-center" @click="back">  <i class="ri-arrow-left-line"></i> Back</button>
    <button class="btn btn-primary d-flex align-items-center "  :disabled="!valid || loading"  @click="next"><b-spinner v-if="loading" small type="grow"></b-spinner> Next  <i class="ri-arrow-right-line"></i> </button>
  </div>
</div>
</template>

<script>
import SuppressionListField from "@/components/inboxgeek/fileds/SuppressionListField";
import KlaviyoListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/KlaviyoListInput";
import MaropostListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/MaropostListInput";
import ListrakListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/ListrakListInput";
import SendGridListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/SendGridListInput";
import AweberListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/AweberListInput";
import MailchimpListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/MailchimpListInput";
import IterableListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/IterableListInput";

import HighLevelListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/HighLevelListInput";
import KeapListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/KeapListInput";
import InboxListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/InboxListInput";

import BrevoListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/BrevoListInput";
import HubspotListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/HubspotListInput";
import OngageListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/OngageListInput";

import OntraportListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/OntraportListInput";
import SendlaneListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/SendlaneListInput";
import GetResponseListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/GetResponseListInput";

import OmniSendListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/OmniSendListInput";
import SendrAppListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/SendrAppListInput";
import MailSendListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/MailSendListInput";


import api from "@/api";
import CampaignerListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/CampaignerListInput";
import ActiveCampaignListInput
  from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/ActiveCampaignListInput";
import BlastableListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/BlastableListInput";
import ExpertSenderListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/ExpertSenderListInput";
import EmailOctopusListInput
  from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/EmailOctopusListInput";
import ConvertkitListInput from "@/components/inboxgeek/modals/Wizards/SuppressionList/listInputs/ConvertkitListInput";
export default {
  name: "PlatformListSuppressionListWizardModal",
  props:['form','account','lists'],
  components :{
    SuppressionListField,
    MaropostListInput,
    KlaviyoListInput,
    CampaignerListInput,
    ActiveCampaignListInput,
    BlastableListInput,
    ExpertSenderListInput,
    EmailOctopusListInput,
    ConvertkitListInput,
    MailSendListInput,
    ListrakListInput,
    SendGridListInput,
    AweberListInput,
    MailchimpListInput,
    HighLevelListInput,
    KeapListInput,
    InboxListInput,
    BrevoListInput,
    HubspotListInput,
    OngageListInput,
    OntraportListInput,
    SendlaneListInput,
    GetResponseListInput,
    OmniSendListInput,
    SendrAppListInput,
    IterableListInput
  },
  data(){
    return {
      selected_platform:null,
      loading:false,
      errors:{name:false,list:false},
      list:null,
      platforms:[
        {
          name:'Maropost',
          component:MaropostListInput.name
        },
        {
          name:'Klaviyo',
          component:KlaviyoListInput.name
        },
        {
          name:'Blastable',
          component:BlastableListInput.name
        },
        {
          name:'ActiveCampaign',
          component:ActiveCampaignListInput.name
        },
        {
          name:'Campaigner',
          component:CampaignerListInput.name
        },
        {
          name:'Ontraport',
          component:OntraportListInput.name
        },
        {
          name:'ExpertSender',
          component:ExpertSenderListInput.name
        },
        {
          name:'EmailOctopus',
          component:EmailOctopusListInput.name
        },
        {
          name:'Convertkit',
          component:ConvertkitListInput.name
        },
        {
          name:'MailSend',
          component:MailSendListInput.name
        },
        {
          name: 'Listrak',
          component: ListrakListInput.name
        },
        {
          name: 'SendGrid',
          component: SendGridListInput.name
        },
        {
          name: 'Aweber',
          component: AweberListInput.name
        },
        {
          name: 'Mailchimp',
          component: MailchimpListInput.name
        },
        {
          name: 'GoHighLevel',
          component: HighLevelListInput.name
        },
        {
          name: 'Keap',
          component: KeapListInput.name
        },
        {
          name: 'Inbox',
          component: InboxListInput.name
        },
        {
          name: 'Brevo',
          component: BrevoListInput.name
        },
        {
          name: 'Hubspot',
          component: HubspotListInput.name
        },
        {
          name: 'ongage',
          component: OngageListInput.name
        },
        {
          name: 'GetResponse',
          component: GetResponseListInput.name
        },
        {
          name: 'Sendlane',
          component: SendlaneListInput.name
        },
        {
          name: 'OmniSend',
          component: OmniSendListInput.name
        },
        {
          name: 'SendrApp',
          component: SendrAppListInput.name
        },
        {
          name: 'Iterable',
          component: IterableListInput.name
        }

      ],
      name:null
    }
  },
  methods:{

    next(){

      this.errors = {list:false,name:false};

      if(!this.list){
        this.errors.list = true;
        return;
      }

      if(!this.name){
        this.errors.name = true;
        return;
      }

      this.loading = true;
      this.form['account_id'] = this.account;

     if(! this.form['list']){
       this.form['list'] = {list:null};
     }
      this.form['list']['list'] = this.list;
      this.form['initial']['name'] = this.name;
      api.suppressionList.postPlatform(this.form)
          .then((response)=>{
            this.loading = false;
            this.$emit('next',{
              list:this.list,
              created_list:response.data.suppression_list
            });

            this.$emit('listCreated',{list:this.list});

          }).catch((error)=>{
            this.loading = false;
            let errors='';

            for (const propertyName in error.data.errors) {
              if (error.data.errors.hasOwnProperty(propertyName)) {
                const propertyValue = error.data.errors[propertyName];
                errors = errors +  propertyValue[0] + ', ';
              }
            }

            this.$swal('error', errors, 'error');
      });
    },

    back(){
      this.$emit('back');
    },
    handelListSelectedEvent(list){
      this.list = list;
      this.name = list.name;
    },
    suppressionListUploaded(list){

      this.list ={
        id:list.code,
        type:'file',
      };

    },
  },

  computed:{
    valid(){

      if(!this.list){
        return false;
      }
      return true;
    },
    currentPlatformListInput(){

      if(this.form.initial.uploadFile)  return null;

      let current_platform_inputs = null;

      this.platforms.forEach((platform)=>{

        if(platform.name === this.form['initial'].platform.name){
          current_platform_inputs = platform;
        }
      });


      return current_platform_inputs;
    }

  }
}
</script>

<style scoped>

</style>
