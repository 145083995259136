<template>

    <b-container fluid>
        <DataTable v-if="renderDT" :id="id" :ref="id" :url="'/ge_sources'"
            v-bind="{ columns: columns, customCells: dtCells, organization: accountId, filters: filters, selectable: true, rowSelect: [] }"
            @onSwitch="handleSwitchAttr" 
            @onClick="handleBtn" 
            @sortChanged="sortChanged" 
            @bulkAction="bulk"
            @contextChanged="$emit('hideChart')"></DataTable>
    </b-container>

</template>
<script>
import { helper } from '@/helpers';
import api from '@/api/RestClient';
import { mapGetters } from 'vuex';
import DtCheckboxItem from './DtCheckboxItem';
import DtPlatform from './DtPlatform';
import DtLists from './DtLists';
import IBGSwitchCell from '@/components/inboxgeek/tables/IBGSwitchCell';
import Actions from '@/views/GetEmails/children/LLSourceActions';
import DataTable from '@/components/inboxgeek/tables/DataTable.vue'

export default {
    name: 'LlSourcesTable',
    components: {
        DataTable
    },
    props: {
        id: {
            type: String,
            default: 'sources-table'
        },
        accountId: {
            type: Number,
            default: null
        },
        filters: {
            type: Object,
            default: {}
        }
    },
    computed: {
        ...mapGetters({
            isClient: 'Auth/isClient',
            user: 'Auth/user',
        }),
        columns() {
            let columns = [
                { label: '', key: 'select', class: 'text-center', sortable: false },
                { label: 'Organization', key: 'account_name', class: 'text-center', sortable: false, sortDirection: 'desc' },
                { label: 'Name', key: 'name', class: 'text-left', sortable: true, sortDirection: 'desc' },
                { label: 'Website', key: 'website', class: 'text-center', sortable: true, sortDirection: 'desc' },
                {
                    label: 'Daily Limit', key: 'limit', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        return value != -1 ? value : 'Unlimited'
                    }
                },
                {
                    label: 'Created at', key: 'created_at', class: 'text-center', sortable: true, sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        let dt = new Date(value);
                        return helper.formatDate(dt)
                    }
                },
                { label: '# of records', key: 'records', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { label: 'Status', key: 'active', class: 'text-center', sortable: true, sortDirection: 'desc' },
                { label: 'Actions', key: 'actions', class: 'text-center', sortable: false, sortDirection: 'desc' },
            ];

            if (this.isClient) {
                // Remove Organization
                _.remove(columns, {
                    key: 'account_name'
                });
            }

            return columns;
        }
    },
    data() {
        return {
            renderDT: true,
            dtCells: [
                {
                    key: 'select',
                    component: DtCheckboxItem,
                    event: 'onSelectItem',
                },
                {
                    key: 'platform',
                    component: DtPlatform,
                    event: null,
                },
                {
                    key: 'lists',
                    component: DtLists,
                    event: null,
                },
                {
                    key: 'active',
                    component: IBGSwitchCell,
                    event: 'onSwitch',
                    props: {
                        attribute: 'active',
                    }
                },
                {
                    key: 'clean',
                    component: IBGSwitchCell,
                    event: 'onSwitch',
                    props: {
                        attribute: 'clean',
                    }
                },
                {
                    key: 'actions',
                    component: Actions,
                    event: 'onClick',
                }
            ],
        }
    },
    methods: {
        handleSwitchAttr(data) {

            let showPreAlert = null;
            let checkShowAlert = null;


            if (!data.input || !data.row || !data.key) {
                return
            }

            checkShowAlert = !this.isClient ? { state: null } : helper.showAlertOutOfEventOrEmptyBalance(SERVICE_ID);
            if (checkShowAlert.state && checkShowAlert.alert && data.input.checked) {
                showPreAlert = true;
            }

            const updateRowState = (data) => {
                let itemCode = data.row.id
                let form = {}
                form[data.key] = data.input.checked ? true : false

                let toast = { message: 'Something went wrong!', title: 'Error', variant: 'danger' }

                return api.geSources.put(itemCode, form)
                    .then(result => {
                        toast = { message: result?.message, title: 'Success', variant: 'success' }
                    })
                    .catch((error) => {
                        data.input.checked = !data.input.checked;
                        let errors = [];
                        toast.message = '';

                        this.$bvToast.toast(error.response.data.message, {
                            title: 'Error',
                            variant: 'danger',
                        })
                    })
                    .finally(() => {
                        this.$root.$bvToast.toast(toast.message, toast);
                    });
            }

            if (showPreAlert && checkShowAlert?.state && checkShowAlert?.alert) {

                return (this.$swal(checkShowAlert.alert))
                    .then((result) => {
                        updateRowState(data)
                    })

            } else {

                updateRowState(data)

            }


        },
        handleBtn(data) {
            this.$emit('onClick', data);
        },
        sortChanged(ctx) {
            this.$root.$emit('sortChanged');
        },
        bulk(data) {

            let showAlert = null;
            let checkShowAlert = null;

            if (typeof data.action == 'undefined' || typeof data.items == 'undefined' || (data.items && !data.items.length)) {
                return
            }


            let bulkData = { active: -1 }

            let alertObject = {
                title: 'Are you sure?',
                text: `This action will permenantly delete ` + (this.$refs[this.id].itemsSelected.length ? ` ${(this.$refs[this.id].itemsSelected.length)} Source${(this.$refs[this.id].itemsSelected.length > 1 ? 's' : '')}` : ''),
                type: 'danger',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                showCloseButton: true,
                confirmButtonColor: '#e64141',
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !this.$swal.isLoading()
            }

            switch (data.action) {
                case 'turn-on':
                    checkShowAlert = !this.isClient ? { state: null } : helper.showAlertOutOfEventOrEmptyBalance(SERVICE_ID);

                    if (checkShowAlert.state && checkShowAlert.alert) {
                        showAlert = true;
                    }

                    alertObject.type = 'success'
                    alertObject.text = `If your ` + `${(this.$refs[this.id].itemsSelected.length ?? '')}` + ` Source${(this.$refs[this.id].itemsSelected.length > 1 ? 's' : 'Source')}` + ` shares contacts with any other synced list on the InboxGeek platform, events will begin sending as soon as today.`
                    alertObject.confirmButtonText = 'Turn on'
                    alertObject.confirmButtonColor = '#00ca00'
                    bulkData = { active: 1 }
                    break

                case 'turn-off':
                    alertObject.type = 'question'
                    alertObject.text = `If an Source is turned off for longer than 1:00a EST the following day, the contacts synced to that list will need 24 hours to re-sync once the Source is turned on again.`
                    alertObject.confirmButtonText = 'Turn off'
                    alertObject.confirmButtonColor = '#0084ff'
                    bulkData = { active: 0 }
                    break

                case 'delete':
                    bulkData = null
                    break

                default:
                    break
            }


            let swalObj = {
                type: 'success',
                confirmButtonText: 'OK',
                showCloseButton: true,
            }

            const that = this.$refs[this.id];

            alertObject.preConfirm = () => {

                that.$swal.update({
                    title: 'In Progress', text: 'Please wait, action in progress ...', showConfirmButton: false, allowOutsideClick: false, showCloseButton: false,
                })

                if (data.action == 'delete') {

                    return api.geSources.bulkDelete(data.items)
                        .then(result => {
                            swalObj.title = 'Delete!';
                            swalObj.text = result.message;
                            this.indeterminate = false
                        })
                        .catch(function (error) {
                            swalObj.type = 'warning';
                            swalObj.title = 'Warning!';
                            swalObj.text = 'Something went wrong!';
                            this.$bvToast.toast(error.response.data.message, {
                                title: 'Error',
                                variant: 'danger',
                            })
                        })
                }

                return api.geSources.bulk(data.items, bulkData)
                    .then(result => {
                        swalObj.title = 'Success!';
                        swalObj.text = result.message;
                        this.indeterminate = false
                    })
                    .catch(function (error) {
                        swalObj.type = 'warning';
                        swalObj.title = 'Warning!';
                        swalObj.text = 'Something went wrong!';
                        this.$bvToast.toast(error.response.data.message, {
                            title: 'Error',
                            variant: 'danger',
                        })
                    })

            };

            const bulkConfirmAction = (obj) => {

                that.$swal(alertObject)
                    .then((result) => {
                        this.$refs[this.id].itemInOperate = false;

                        if (result.isConfirmed) {
                            this.$refs[this.id].selected = [];
                            this.$refs[this.id].selectAll = false;
                            that.$swal(swalObj)
                            this.$refs[this.id].$root.$emit('bv::refresh::table', this.$refs[this.id].id);
                        }
                    })

            }

            if (showAlert && checkShowAlert?.state && checkShowAlert?.alert) {

                return (this.$swal(checkShowAlert.alert))
                    .then((result) => {
                        bulkConfirmAction(alertObject)
                    })
            } else {
                bulkConfirmAction(alertObject)
            }

        },
        forceRenderTable() {
            this.renderDT = false;
            this.$emit('hideChart')

            this.$nextTick(() => {
                this.renderDT = true;
            });
        },
    }
}

</script>