<template>
    <b-row>
        <b-form-group label="Group *" label-for="list" class="col-md-12">

            <multiselect v-model="list" tag-placeholder="Empty" placeholder="Select group" label="name" track-by="id"
                :options="lists" :multiple="true" return="id" :allow-empty="true" :taggable="true"
                @select="handleSelectList" @input="handleSelectList" @remove="handleSelectList" :loading="isLoading">

                <template slot="option" slot-scope="props">
                    <div class="option__desc">

                        <span class="option__title">

                            <span v-if="props.option.type">

                                <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                                    style="color:#5ad"></i>
                                <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                            </span>

                            <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                            <span style="font-wight:bold;"> {{ props.option.name }} </span>

                            <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>

                        </span>

                    </div>

                </template>

            </multiselect>

            <small v-if="errors?.list" class="text-danger">A list should be selected</small>

            <div class="alert alert-warning my-2" style="padding: 1rem;">
                <p class="m-0 text-dark">InboxGeek cannot recognize contact statuses of 'transactional only' and
                    'hard
                    bounced'. In your
                    Ontraport account, add a condition to your group to exclude all Bulk Email Statuses that
                    contain
                    'transactional only' and 'hard bounced'. Without this condition, InboxGeek will send an
                    event
                    for that contact but an email will not be triggered by Ontraport. </p>
            </div>
        </b-form-group>
    </b-row>
</template>

<script>
import Multiselect from 'vue-multiselect'

import api from "@/api";

export default {
    name: 'OntraportLoListStep',
    components: {
        Multiselect
    },
    props: {
        form: {
            type: Object,
            default: () => {}
        },
      lists:{

      }
    },
    data() {
        return {
          errors: {
            list: false
          },
            list: null,
            lists: [],
            isLoading: false
        }
    },
    mounted() {

    },
    methods: {
        handleSelectList(itemSelected) {
          console.log();
            this.$emit('select',this.list.map((list)=>{
              return {
                code:list.id,
                name:list.name,
                type:list.type
              }
            }));
        },
    }
}
</script>
