<template>

  <div class="d-flex justify-content-end" style="gap:5px">


    <button class="btn btn-light" @click="$emit('cancel')" :disabled="isLoading">Cancel</button>

    <button
        v-if="!hideBackButton"
        class="btn btn-secondary d-flex align-items-center"
        @click="$emit('back')">
      <i class="ri-arrow-left-line"></i> Back
    </button>

    <button class="btn btn-primary d-flex align-items-center " @click="$emit('next')" :disabled="!valid || isLoading">
      <b-spinner v-show="isLoading" small type="grow" class="mr-2"></b-spinner>Next <i class="ri-arrow-right-line"></i>
    </button>

  </div>

</template>

<script>

export default {
  name: 'NavigationLoWizard',

  props: {

    isLoading : {
      type  : Boolean,
      default: false
    },
    valid : {
      type  : Boolean,
      default: false
    },
    hideBackButton : {
      type  : Boolean,
      default: false
    }
  }

}
</script>