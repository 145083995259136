<template>

    <b-container fluid>

       <fieldset>
        <div class="form-card text-left">
            <b-row>
                <!-- Tags -->
                 <b-form-group class="col-md-12 mb-3" label="Journeys (workflows) : *" label-for="journey">
                    <div>
                        <multiselect v-model="integration.tag" :loading="isLoading"
                            :allow-empty="false" deselect-label="Can't remove this value"
                            placeholder="Select workflow" label="name" track-by="code" return="code" :options="journeys"
                            :multiple="false" :close-on-select="true" :taggable="true"  @select="handleSelectTag"
                                     tag-placeholder=""

                        >

                            <template slot="singleLabel" slot-scope="props">
                                <div class="option__desc">

                                    <span class="option__title">


                                      <i class="ri-list-check color-primary" style="color:#5ad"></i>

                                        <span style="font-wight:bold;"> {{ props.option.name }} </span>
                                        <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>

                                    </span>

                                </div>
                            </template>

                            <template slot="option" slot-scope="props">
                                <div class="option__desc">

                                    <span class="option__title">

                                        <span v-if="props.option.type">

                                            <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                                                style="color:#5ad"></i>
                                            <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                                        </span>

                                        <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                                        <span style="font-wight:bold;"> {{ props.option.name }} </span>

                                        <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>


                                    </span>

                                </div>

                            </template>

                        </multiselect>
                        <small id="alertTag" class="text-danger d-none"></small>
                    </div>
                </b-form-group>
            </b-row>

        </div>
    </fieldset>
    </b-container>

</template>
<script>

import Multiselect from 'vue-multiselect'
import api from '@/api/RestClient';
import LimitPerDayInput from "@/components/inboxgeek/fields/integration/LimitPerDayInput";

export default {
    name: 'IterableLoIntegrationStep',
    components: {
        Multiselect,
        LimitPerDayInput
    },
    props: {
        form: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            integration: {
                name: null,
                tag: null,
                limit: null,
                rcurrence: null,
                type: 'Trigger Journey'
            },
          journeys: [],
            isLoading: false,
            is_disabled_limit: false,
        }
    },
    mounted() {
        this.getJourneys();
    },
    methods: {
        handleSelectTag() {
            this.$emit('tag', this.integration)
        },
        handleUpdateLimitPerDay(data) {
            this.$emit('limitPerDay', data)
        },
        handleInputLimitPerDay() {
            this.$emit('limitPerDay', this.integration)
        },
        handleInputRecurrence() {
            this.$emit('handleRecurrence', this.integration)
        },
        handleInputName() {
            this.$emit('name', this.integration)
        },
        onSwitchLimitPerDay($event) {
            if (!$event.checked) {
                this.integration.limitPerDay = null
                this.integration.limit = null
            }
            else {
                this.integration.limit = 0
            }
            this.is_disabled_limit = !$event.checked
        },
        getJourneys() {
            this.isLoading = true;
            let platform = this.form.platform.platform;
            let params = {
                entities: 'tags',
                platform_id: platform.id,
                platform_name: platform.name.toLowerCase(),
            };
            api.platforms.fetchServices(params).then((response) => { 
                let journeys = response.data
                this.journeys = journeys.map((tag) => {
                            return {
                                'code': tag.id,
                                'name': tag.name,
                                'type': tag.type,
                            }
                        });
            }).catch((error) => {
                let message = error.response.data.message
                this.$swal('Oops!!', message, 'error');
            }).finally(() => { 
                this.isLoading = false;
            })
        }
    }
}

</script>