var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{attrs:{"fluid":""}},[(_vm.renderDT)?_c('DataTable',_vm._b({ref:"records-table",attrs:{"url":'/ge_records'}},'DataTable',{
            columns: _vm.dtColumns,
            customCells: _vm.dtCells,
            organization: _vm.accountId,
            item: _vm.item,
            filters: _vm.filters
        },false)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }