

<template>
    <b-alert v-if="userAdmin && tokenAs" variant="warning" class="row text-white bg-warning mb-0 py-1" :show="tokenAs">
        <b-col class=" d-flex" align-h="center" md="6" offset-md="3">
            <div class="iq-alert-text">
                Login As User : {{ user.name }} - Email: {{ user.email }}
            </div>
        </b-col>
        <b-col md="3">
            <div class="text-right" md="3" offset-md="3">
                <button class="iq-bg-danger iq-sign-btn btn-sm" @click.prevent="logoutAs" role="button">Sign out<i
                        class="ri-login-box-line ml-2"></i></button>
            </div>
        </b-col>
    </b-alert>
</template>
<script>
import {
    mapGetters
} from 'vuex'
export default {
    name: 'HeaderLoginAs',
    props: {
    },
    computed: {
        ...mapGetters({
            user: 'Auth/user',
            tokenAs: 'Auth/tokenAs',
            userAdmin: 'Auth/userAdmin'
        }),
    },
    methods: {
        logoutAs() {
            let user = this.userAdmin; 
            let subscribed = user.subscribed;
            let roles = user.roles.map(function (role) {
                return role.slug;
            });

            let admin = false;
            let client = false;
            let accounting = false;
            let account_manager = false;

            
            // if (jQuery.inArray('ROLE_SUPER_ADMIN', roles) != -1) admin = true;
            if (roles.includes('ROLE_SUPER_ADMIN') || roles.includes('ROLE_ADMIN')) admin = true;
            if (roles.includes('ROLE_ACCOUNTING')) accounting = true;
            if (roles.includes('ROLE_CLIENT')) client = true;
            if (roles.includes('ROLE_ACCOUNTS_MANAGER')) account_manager = true;

            user['role_id'] = 1;
            this.$store.dispatch('Auth/updateisAdmin', admin);
            this.$store.dispatch('Auth/updateisClient', client);
            this.$store.dispatch('Auth/updateUser', user);
            this.$store.dispatch('Auth/updateIsSubscribed', subscribed);
            this.$store.dispatch('Auth/updateTokenAs', null);
            this.$store.dispatch('Auth/updateUserAdmin', null);
            this.$store.dispatch('Auth/initUserMetaDta', user.meta_data ?? []);
            this.$store.dispatch('Auth/updateSupportChatRoomID', null);

            this.$root.$emit('refresh:notificationEvents')
            this.$router.push({ name: 'accounts' })
        },
    }
}
</script>