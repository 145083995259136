import { PLATFORMS_CODE, COLUMNS_GRID } from "@/constantes";
import api from '@/api/RestClient';

export default function platform(axios) {
    return {
        labels: {
        },
        customShowAnotherCols(COMP) {

            COMP.showCols.apiKey = true;
            COMP.showCols.accountId = false;
            COMP.showCols.event_link = true;
            COMP.showCols.tags = false

            COMP.tagLabel = 'Campaign ID *';
            COMP.tagPlaceholder = 'Campaign id';
            COMP.selectTagPlaceholder = 'Campaign id';

        },
        handleCheckAPI(that, btn, compStep) {
            if (that.form.platform.code == PLATFORMS_CODE.INBOXSUITE) {

                //send test api to validate api_token
                const ip = '192.168.100.100';
                const crete_date = '2022-12-01';
                const url_opt_in = "inboxgeek.com";
                const invalid_email = "inbox@geekcd";
                const api_key = that.form.apikey;
                const link = `https://intrust.inboxsuite.com/v2?apikey=${api_key}&email=${invalid_email}&ip=${ip}&regdate=${crete_date}&sourceurl=${url_opt_in}&status=11`;

                try {

                    axios.get(link).catch((error) => {

                        if (error.response.status == 401) {

                            that.$swal('Checking API Failed', 'This Api Token is not valid', 'error');

                            that.currentStep = 0;
                            compStep.nextSpinner = false;
                            btn.disabled = false;

                        } else {

                            compStep.nextSpinner = false;
                            btn.disabled = false;

                            that.nextStep(nextFs, currentFs, 0);

                        }
                    });



                } catch (error) {

                    that.$swal('Checking API Failed', 'Faild to check the API Token', 'error');
                    that.currentStep = 0;
                    compStep.nextSpinner = false
                    btn.disabled = false
                }
            } else {
                compStep.nextSpinner = false
                btn.disabled = false
                that.nextStep(nextFs, currentFs, 0)
            }
        },
        validateApiKey: (params) => {
            return axios.post(`/platforms/inboxsuite/validate/api-keys`, params)
                .then(response => response.data)
        },
    }
}