<template>
  <b-row>
    <div class="col-md-12 col-lg-12 consumption-bar">
      <div class="iq-card iq-card-block iq-card-stretch">
        <div class="iq-card-body p-4">

          <div v-if="widgetLoading">

            <div class="text-center p-4 d-flex justify-content-center align-items-center">
              <b-spinner variant="primary"> </b-spinner>
              <span class="text-primary p-2"><small> Please wait...</small></span>
            </div>

          </div>

          <div v-else-if="!accountAlreadyUsedService()">
            <b-row>
              <AnnounceService :service-code="service_id"></AnnounceService>
            </b-row>
          </div>

          <div v-else>

            <h4 class="mb-4">Subscription {{ service_name ?? 'LiveOpens' }} Details</h4>

            <!-- Warning Service Information -->
            <div v-show="subscriptionIsCancelOrFinish() && subscriptionUsed" class="text-primary surline mb-2 mx-0">
              <span class="pt-0">
                  <span>Warning: Your subscription has been <span>{{ subscriptionStatusLabel(subscriptionUsed) }}</span> as of 
                    <strong>{{ subscriptionUsed.ends_at && subscriptionUsed ? subscriptionUsed.ends_at : '--' }}</strong>.
                  </span>
              </span>
            </div>

            <ServiceInActiveWidget :service_id="service_id ?? 1"   :isAccountActive="isAccountActive"></ServiceInActiveWidget>
            <billingDetailsOpenEvent :service_id="service_id ?? 1" :plan="plan" :account="account" />

            <h5 class="mt-4 text-primary">Current Performance</h5>

            <ServiceStatConsumptionWidget 
                ref="service-stat-widget" 
                :service_id="service_id ?? 1"
                
                :remaining="remaining"
                :loading="loading"
                :limit="limit"
                :freeAccount="freeAccount"
                :contacts="totalContact"

                >
            </ServiceStatConsumptionWidget>


            <b-row class="px-4" style="justify-content: space-between;">
              <div v-if="loading" class="d-flex justify-content-center">
                <b-spinner small label="Small Spinner" type="grow"></b-spinner>
              </div>
              <h3 v-else class="p-0">
                <span class="consumption-bar-balance">
                  <span>{{ events }} / {{ filterFormatNumber(balance) }} </span>
                  <span v-if="service_id == 2">records received</span>
                  <span v-else>events sent</span>
                </span>
              </h3>
              <h5><strong>{{ percent }}%</strong> of plan Used</h5>
            </b-row>

            <ServiceProgressStateWidget 
            
            :service_id="service_id ?? 1" 

            :isAccountActive="isAccountActive"
            :events="events"
            :percent="percent"
            :remaining="remaining"
            
            ></ServiceProgressStateWidget>

          </div>

        </div>
      </div>
    </div>


  </b-row>

  <!-- Info modal -->
</template>

<script>
import api from '@/api/RestClient'
import { mapGetters } from 'vuex'
import { helper } from '@/helpers'
import billingDetailsOpenEvent from '@/components/charts/billingDetailsOpenEvent.vue'
import ServiceInActiveWidget from '@/components/inboxgeek/widgets/ServiceInActiveWidget.vue'
import ServiceStatConsumptionWidget from '@/components/inboxgeek/widgets/ServiceStatConsumptionWidget.vue'
import ServiceProgressStateWidget from '@/components/inboxgeek/widgets/ServiceProgressStateWidget.vue'
import ServiceAnnounceSvg from '@/components/inboxgeek/svg/ServiceAnnounceSvg.vue'
import AnnounceService from "@/components/inboxgeek/sections/AnnounceService.vue"
import {SERVICES} from "@/constantes";


const LIVE_OPEN_SERVICE = 1;
const LIVE_LEADS_SERVICE = 2;
const FINISH_STATUS = -2;
const CANCEL_STATUS = -1;

export default {
  name: 'ServiceConsumptionWidget',
  components: {
    billingDetailsOpenEvent,
    'ServiceInActiveWidget': ServiceInActiveWidget,
    'ServiceStatConsumptionWidget': ServiceStatConsumptionWidget,
    'ServiceProgressStateWidget': ServiceProgressStateWidget,
    "ServiceAnnounceSvg": ServiceAnnounceSvg,
    AnnounceService
  },
  mounted() {
    this.currentSubscription();
    this.GetConsumptionData();
    this.fetchSubscriptionAlreadyUsed();
  },
  props: ['service_id', 'service_name'],

  data() {
    return {
      is_unlimited: false,
      form: {
        limit: 0
      },
      has_service_subscription: false,
      loading: false,
      widgetLoading: true,
      modal_loader: false,
      events: 0,
      limit: 0,
      remaining: 0,
      contacts: 0,
      percent: 0,
      balance: 0,
      plan: null,
      progress: false,
      freeAccount: false,
      profile: null,
      isAccountActive: true,
      showEditOverconsumption: false,
      account : null,
      subscriptionUsed: null,
    }
  },
  methods: {
    GetConsumptionData() {
      this.progress = true;
      this.loading = true;
      api.stats.getConsumptionDataService(this.service_id)
        .then(response => {
          if (response.success) {
            const data = response.data;
            this.events = data.events;
            this.limit = data.limit ? data.limit : 0;
            this.remaining = data.remaining;
            this.contacts = data.contacts;
            this.percent = data.percent;
            this.balance = data.plan.balance;
            this.plan = data.plan;
           
            this.freeAccount = data.plan.is_trial;
            this.$store.dispatch('updateFreeAccount', this.freeAccount);


            if (this.$store.getters['Auth/user'].profile) {
              this.profile = this.$store.getters['Auth/user'].profile
            }
          }
        })
        .catch(function (error) {
          if (error.response) {
            // handle error
          }
        })
        .finally(() => {
          this.progress = false;
          this.loading = false;

          // Conditional display remaining alert
          if (this.$refs['service-stat-widget'] && typeof this.$refs['service-stat-widget'].showAlertRemaining == 'function') {
            helper.delay(3000).then(() => {
              this.$refs['service-stat-widget'].showAlertRemaining();
            })
          }

        });
    },
    clientHasBalance() {
      return this.isClient && (this.limit !== -1 && (this.remaining + this.limit <= 0))
    },
    onSubmit(e) {

      this.modal_loader = true;
      e.preventDefault();
      if (this.freeAccount) return;
      if (this.is_unlimited) this.form.limit = -1;

      const data = {
        limit: this.form.limit
      };

      api.balances.updateLimit(data).then(response => {

        if (response.success) {

          const data = response.data;
          this.limit = data.limit;

          this.$bvToast.toast('Limit successfully updated', {
            title: 'Success',
            variant: 'success'
          });

          this.$refs.updateLimitModal.hide();
        }

        this.modal_loader = false;

      }).catch(error => {
        this.modal_loader = false;

        this.$bvToast.toast('failed to update the limit', {
          title: 'Error',
          variant: 'danger'
        });
      })


    },
    getCurrentBalanceLimit() {
      this.is_unlimited = (this.limit == -1);

      this.form.limit = (this.is_unlimited) ? 0 : this.limit;
    },
    currentSubscription() {
      this.widgetLoading = true;

      api.accounts
        .getCurrentPlan(this.user.account_id, { service_id: this.service_id })
        .then(resp => {

          if (resp.data?.name) {

            this.has_service_subscription = true;
          } else {
            this.has_service_subscription = false;

          }

          this.widgetLoading = false;

        });

    },
    accountAlreadyUsedService() {
      return typeof this.subscriptionUsed !== 'undefined' && this.subscriptionUsed;
    },
    subscriptionIsCancelOrFinish() {
      let isCancel = (!this.UserSubscriptions[this.service] || this.UserSubscriptions[this.service] == 'undefined') && (this.subscriptionUsed.status && [CANCEL_STATUS, FINISH_STATUS].includes(this.subscriptionUsed.status));
      return isCancel;
    },
    fetchSubscriptionAlreadyUsed() {
      api.subscriptions.accountAlreadyUseService(this.user.account_id, this.service_id)
        .then((response) => {
          if(response.success && response.data) {
            this.subscriptionUsed = response.data;
          }
        })
    },
    subscriptionStatusLabel(subscription) {
      let label = '';

      if(subscription.status === CANCEL_STATUS) {
        label = 'Cancelled';
      }
      if(subscription.status === FINISH_STATUS) {
        label = 'Finished'
      }

      return label
    },
    filterFormatNumber(value) {
      return helper.formatNumber(value)
    }
  },
  computed: {
    ...mapGetters({
      user: 'Auth/user',
      isClient: 'Auth/isClient',
      lastSubscriptions: 'Auth/lastSubscriptions',
      UserSubscriptions: 'Auth/UserSubscriptions'
    }),
    profileFirstname() {
      if (!this.profile) {
        return ';)';
      }
      return this.profile?.first_name;
    },
    remainingFormatNumber() {
      return this.remaining.toLocaleString();
    },
    ShowAccounce() {
      return typeof this.lastSubscriptions[this.service_id] !== 'undefined';
    },
    totalContact(){
      if(this.service_id == SERVICES.LIVE_LEADS.id){
        return this.events;
      }
      return this.contacts
    }
  }

}
</script>

<style>
.consumption-bar-balance {
  font-size: 12px;
}

.account-status {
  padding-left: 10px;
  display: block;
}

@media (max-width: 991px) {
  .consumption-bar {
    font-size: 12px;
  }

  .consumption-bar-number {
    font-size: 21px;
    line-height: 2;
  }

  .consumption-bar-plan-used {
    /*border: none;*/
  }

  .consumption-bar div.col-lg-3 {
    max-width: 50%;
  }

  .consumption-bar-title {
    display: block;
    width: 100%;
    white-space: nowrap;
  }
}</style>
