<template>

    <b-container class="px-0" fluid>
        
        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
            <div class="custom-switch-inner">
                <label class="mr-2">Repeat Unique Events</label>

                <input type="checkbox" 
                    class="custom-control-input bg-primary" 
                    v-model="repeat"
                    id="recurrence-input" 
                    @input="onSwitchRepeatEvent($event.target)">
                    
                <label class="custom-control-label" for="recurrence-input" data-on-label="On" data-off-label="Off"></label>
                
            </div>
        </div>

        <b-form-input v-model="recurrence" type="number" name="recurrence" placeholder="Recurrence Events" @input="updateValue" :disabled="!repeat"></b-form-input>
            
    </b-container>

</template>
<script>

export default {
    name: 'RecurrenceInput',
    props: {},
    components: {},
    data() {
        return {
            recurrence: null,
            repeat: null,
        }
    },
    methods: {
        onSwitchRepeatEvent($event) {
            if (!$event.checked) {
                this.recurrence = null;
                this.repeat = null;
            } else {
                this.recurrence = 0;
            }
        },
        updateValue() {
            this.$emit('recurrence', {recurrence: this.recurrence, repeat: this.repeat ? true : false});
        },
    }
}

</script>