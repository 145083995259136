import { PLATFORMS_CODE, COLUMNS_GRID } from "@/constantes";
import api from '@/api/RestClient';

export default function platform(axios) {
    return {
        labels: {
            listID: "id",
            listName: "name",
            tagID: "tag_id",
            tagName: "tag_name",
        },
        maps: {
            tagsMap: (obj) => {
                let item = {}
                item.code = obj.tag_id
                item.name = obj.tag_name
                return item
            },
        },
        customShowAnotherCols(COMP) {
            if (typeof COMP.showCols.apiKey !== 'undefined') COMP.showCols.apiKey = true;
            if (typeof COMP.showCols.accountId !== 'undefined') COMP.showCols.accountId = true;
            if (typeof COMP.cols.apiKeyCol !== 'undefined') COMP.cols.apiKeyCol = COLUMNS_GRID.COL12;

            if (typeof COMP.$refs["step-platform"] !== 'undefined') COMP.$refs["step-platform"].accountLabel = 'App ID: *';
            if (typeof COMP.$refs["step-platform"] !== 'undefined') COMP.$refs["step-platform"].accountPlaceholder = 'App ID';

            if (typeof COMP.accountLabel !== 'undefined') COMP.accountLabel = 'App ID: *';
            if (typeof COMP.accountLabel !== 'undefined') COMP.accountPlaceholder = 'App ID';
        },
        getTagAdded(result) {
            result = 'data' in result ? result.data : result;
            return { code: result.tag_id, name: result.tag_name }
        }
    }
}