export default function (axios) {
  return {
    all: (params = {}) => {
      return axios.get(`/invoices`, { params })
        .then(response => response.data);
    },
    get: (id) => {
      return axios.get(`/invoices/${id}`)
        .then(response => response.data);
    },
    invoicesByAccount: (accountId) => {
      return axios.get(`/accounts/${accountId}/invoices`)
        .then(response => response.data);
    },
    download: (accountId, invoiceId) => {
      return axios.get(`/accounts/${accountId}/invoices/${invoiceId}/download`, { responseType: 'blob' })
        .then(response => response.data);
    },
    post: (data) => {
      return axios.post(`/invoices`, data)
        .then(response => response.data);
    },
    update: (id, data) => {
      return axios.put(`/invoices/${id}`, data)
        .then(response => response.data)
    },
    delete: (id) => {
      return axios.delete(`/invoices/${id}`)
        .then(response => response.data);
    },
    pay: (id, data) => {
      return axios.post(`/invoices/${id}/force-pay`, data)
        .then(response => response.data);
    },
    payByClient: (id) => {
      return axios.post(`/invoices/${id}/client-pay`)
        .then(response => response.data);
    },
    payByPayPalCheckout: (id) => {
      return axios.post(`paypal/invoices/${id}/handle-payment`)
        .then(response => response.data);
    },
    addItemToInvoice: (id, data) => {
      return axios.put(`/invoices/${id}/items`, data)
        .then(response => response.data)
    },
    updateItem: (id, data) => {
      return axios.put(`/items/${id}`, data)
        .then(response => response.data)
    },
    deleteItem: (itemId) => {
      return axios.delete(`/items/${itemId}`)
        .then(response => response.data)
    },
    refund: (id, data) => {
      return axios.put(`/invoices/${id}/refund`, data)
        .then(response => response.data)
    },
    markAsPaid: (id,data) => {
      return axios.put(`/invoices/${id}/mark-as-paid`, data)
          .then(response => response.data)
    }
  }
}
