import { PLATFORMS_CODE, COLUMNS_GRID } from "@/constantes";
import api from '@/api/RestClient';

export default function platform(axios) {
    return {
        labels: {
            listID: "id",
            // listName: "list_name",
            tagID: "tagId",
            // tagName: "list_name",
        },
        maps: {
            listsMap: (obj) => {
                let item = {}
                item.code = obj.id
                item.name = obj.name
                item.type = obj['type']
                return item
            },
        },
        getTagAdded(result) {
            result = 'data' in result ? result.data : result;
            return { code: result.tagId, name: result.name }
        },
        customShowAnotherCols(COMP) {
            if(typeof COMP.showCols.apiKey !== 'undefined')  COMP.showCols.apiKey = true;
            if(typeof COMP.showCols.accountId!== 'undefined')  COMP.showCols.accountId = false;
            
            if(typeof COMP.apiTokenLabel !== 'undefined') COMP.accountLabel = 'API key/Token: *';
            if(typeof COMP.apiTokenPlaceholder !== 'undefined') COMP.accountPlaceholder = 'API key';

            if(typeof COMP.cols.apiKeyCol !== 'undefined')  COMP.cols.apiKeyCol = COLUMNS_GRID.COL12;
        }
    }
}