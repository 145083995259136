<template>

    <multiselect v-model="list" tag-placeholder="Empty" :placeholder="listPlaceholder" label="name" track-by="code"
        :options="lists" :multiple="true" return="code" :allow-empty="true" :taggable="true" :disabled="listdisabled"
        @select="handleSelectList" @input="handleSelectList" @remove="handleSelectList">

        <template slot="option" slot-scope="props">
            <div class="option__desc">

                <span class="option__title">

                    <span v-if="props.option.type">

                        <i v-if="props.option.type == 'segment'" class="ri-flashlight-line" style="color:#5ad"></i>
                        <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                    </span>

                    <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                    <span style="font-wight:bold;"> {{ props.option.name }} </span>

                    <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>


                </span>

            </div>

        </template>

    </multiselect>

</template>
<script>

import Multiselect from 'vue-multiselect'

export default {
    name: 'IntegrationListMultiselect',
    props: ['lists', 'list-selected'],
    components: {
        Multiselect
    },
    data() {
        return {
            isLoading: false,
            list: null,
            listLabel: 'List: *',
            listPlaceholder: 'Select Lists',
            listdisabled: false,
        }
    },
    computed: {},
    mounted() {
        this.loadList();
    },
    methods: {
        loadList() {
            if(this.listSelected) {
                this.list = this.listSelected;
            }
        },
        handleSelectList(itemSelected) {
            this.$emit('select', this.list)
        },
    }
}

</script>