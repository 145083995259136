<template>
  <b-container fluid>
    <b-modal id="addSupportTicketWizardModal" ref="addSupportTicketWizardModal" title="Add Ticket" size="xl" centered
      hide-footer @hidden="resetModal">

      <b-form-group class="col-md-12">

        <b-row class="my-2">
          <b-col md="12">
            
            <b-alert v-if="isClient" variant="warning" show>

              <div class="iq-alert-text text-dark">
                <p>Follow these 3 steps to help resolve your ticket faster:</p>
                
                <ol>
                  <li>Search the Help Center for solutions. You may be able to solve an issue without having to wait for
                    an answer.</li>
                  <li>Select the team or account where you need help in the upper right. This gives your support agent the
                    context to help you better.</li>
                  <li>Include key details and provide steps to reproduce the problem. This helps us troubleshoot your
                    issue faster.</li>
                </ol>
              </div>

            </b-alert>


          </b-col>
          <b-col md="12">
            <label for="">Subject Support Ticket: *</label>
            <input type="text" class="form-control" v-model="subject">
            <small v-if="errors?.subject" class="text-danger">Support Subject is required</small>
          </b-col>

          <b-col md="12">
            <label for="">Type: *</label>
            <multiselect v-model="type" deselect-label="Can't remove this value" track-by="code" label="name" placeholder="Select Type of Ticket" :options="typesFormatted" :searchable="true" :allow-empty="true">
            </multiselect>
            <small v-if="errors?.type" class="text-danger">Select Type from List</small>
          </b-col>

          <b-col md="12" class="mt-3">
            <label for="">Content: *</label>

            <VueEditor 
              v-model="content" 
              useCustomImageHandler 
               class="w-100" @image-added="handleImageAdded"></VueEditor>

            <small v-if="errors?.content" class="text-danger">Support Content is required</small>
          </b-col>

        </b-row>
      </b-form-group>


      <!-- Navigation buttons -->
      <hr>

      <div class="d-flex justify-content-end" style="gap:5px">
        <button class="btn btn-primary d-flex align-items-center " :disabled="!valid || isLoading" @click="submit">
          <b-spinner class="mr-2" small type="grow" v-show="isLoading"></b-spinner>
          Create Ticket
        </button>
      </div>

    </b-modal>
  </b-container>
</template>


<script>

import api from "@/api";
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import Multiselect from "vue-multiselect"
import {SUPPORT_TICKET_TYPES} from "@/constantes";
import {helper} from '@/helpers'


export default {
  name:'CreateSupportTicketWizardModal',
  props:[],
  components:{
    VueEditor,
    Multiselect

  },
  data(){
    return{
      subject: null,
      content: null,
      isLoading:false,
      type: null,
      images: [],
      errors:{
        subject:false,
        content:false,
        type: false
      },
      onsubmit: false,
      ticketTypes: SUPPORT_TICKET_TYPES
    }
  },
  methods:{
    submit(e) {
      this.isLoading = true;
      e.preventDefault();
      this.onsubmit = true;

      if(!this.valid) {
        return;
      }

      let images = this.images.map((image)=>{
        return {
          'code':image.id,
          'name': image.name
        }
      });

      let data = {subject: this.subject,  content:this.content, type: this.type, images: images };

      api.supportTickets.post(data)
        .then(response => {
            
            this.$swal('Ticket Created', response.message, 'success')
            this.$root.$emit('ibg:refresh-group-tickets', response.data);
    				this.$root.$emit('bv::hide::modal', 'addSupportTicketWizardModal')
            
        }).catch(err => {
            console.log(err);
        })
        .finally(() => {
            this.onsubmit = false;
            this.isLoading = false;
        })
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {

      var formData = new FormData();
      formData.append("image", file);

      api.supportTickets.uploadFile(formData)
        .then((result) => {
          const url = result.data.url; // Get url from response
          const imageUrl = process.env.VUE_APP_BASE_URL+ '/' +url;
          Editor.insertEmbed(cursorLocation, "image", imageUrl);
          resetUploader();

          this.images.push( result.data );
        })
        .catch((err) => {
          console.log(err);
        })
        
    },
    resetModal() {
      this.content = null;
      this.subject = null;
      this.images = [];
    },
    towerCase(str) {
      return str.charAt(0).toLowerCase() + str.slice(1);
    }
  },
  computed:{
    ...mapGetters('Auth', ['user', 'isAdmin', 'isClient']),
    currentStep(){

      // update current steps when the index is changed

      let current_step = null;

      this.steps
          .forEach((step)=>{
            if(this.current_step_index === step.index){
              current_step = step;
            }
          });

      return current_step;
    },
    valid(){

      return this.subject && this.content;

    },
    typesFormatted() {
      const types = this.ticketTypes.map((type) => {
        return {code: this.towerCase(type.name), name: helper.customCapitalize(type.name)};
      })

      console.log(types);
      return types;
    }
  }
}

</script>
