<template>
    <b-container fluid>
        <span v-if="row.item.parent" class="badge cursor-pointer badge-secondary badge-pill iq-bg-secondary">
            {{ row.item.parent.name  || '' }}
        </span>
    </b-container>
</template>
<script>

export default {
    name: 'PlanParentNameCell',
    props: ['row'],
}
</script>