<template>

    <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
        <div class="custom-switch-inner">
            <input type="checkbox" data-toggle="tooltip" data-placement="top" title="Auto Refresh"
                :id="'customSwitch-dt-' + row.item.id" :data-id="row.item.id" :data-name="row.item.list_name"
                v-model="row.item.auto_refresh" :checked="canRefreshList(row.item) && row.item.auto_refresh"
                @input="onSwitchAutoRefresh(row.item, row.index, $event.target)"
                class="custom-control-input bg-primary auto_refresh_list"
                :class="row.item.auto_refresh == 1 && canRefreshList(row.item) ? 'checked' : ''"
                :disabled="!canRefreshList(row.item)">
            <label :for="'customSwitch-dt-' + row.item.id" data-on-label="ON" data-off-label="OFF"
                class="custom-control-label"></label>
        </div>
    </div>

</template>
<script>

import api from '@/api/RestClient'

export default {
    name: 'DtListSwitchRefreshCell',
    props: ['row'],
    methods: {
        canRefreshList(item) {
            try {
                if (!item.platform || item.platform.trim() == '') return true;

                let platform = item.platform.toLowerCase();
                return !['webhook', 'inboxsuite'].includes(platform);
            } catch (e) {
                console.error(e);
                return null;
            }
        },
        onSwitchAutoRefresh(item, index, input) {
            var listID = item?.id
            var listData = { auto_refresh: input.checked ? 1 : 0 }
            this.updateItem(listID, listData);
        },
        updateItem(id, data, item = null) {
            var toast = { message: 'Something went wrong!', title: 'Error', variant: 'danger' }

            return api.trackedLists.put(id, data)
                .then(result => {
                    toast = { message: result?.message, title: 'Success', variant: 'success' }
                })
                .catch((error) => {
                    this.$root.$emit('bv::refresh::table', this.id)
                })
                .finally(() => {
                    if (item) {
                        item.state = 'state' in data ? data.state : item.state;
                    }
                    this.$root.$bvToast.toast(toast.message, toast);
                });
        },
    }
}

</script>