<template>
  <b-row>
    <b-col>
      <LimitPerDayInput @limitPerDay="handleUpdateLimitPerDay"></LimitPerDayInput>
    </b-col>

    <b-col v-if="hasOption('time_limit')">
      <b-row>
        <b-col md="12">
          <div
              class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
            <div class="custom-switch-inner">
              <label class="mr-2">Time limit</label>
              <input type="checkbox" class="custom-control-input bg-primary"
                     v-model="time_limit.active" id="limit-limit"
                     @input="onSwitchTimeLimit($event.target)">
              <label class="custom-control-label" for="limit-limit" data-on-label="On"
                     data-off-label="Off">
              </label>
            </div>
          </div>
        </b-col>
        <b-col md="6" class="mb-3">
          <b-form-input v-model="time_limit.from" id="time_limit_from" :type="timeType"
                        value="13:45" placeholder="time from"
                        :disabled="!time_limit.active"></b-form-input>
          <small v-show="timeFromAlert" class="text-danger">This field is required in its correct time format</small>
        </b-col>
        <b-col md="6" class="mb-3">
          <b-form-input v-model="time_limit.to" id="time_limit_to" :type="timeType"
                        value="13:45" placeholder="time to" :disabled="!time_limit.active"></b-form-input>
          <small v-show="timeToAlert" class="text-danger">This field is required in its correct time format</small>
        </b-col>

      </b-row>
    </b-col>


    <b-col v-if="platform.hasRecurrence">
      <RecurrenceInput @recurrence="handleUpdateRecurrence" />
    </b-col>
  </b-row>

</template>

<script>
import LimitPerDayInput from "@/components/inboxgeek/fields/integration/LimitPerDayInput";
import RecurrenceInput from "@/components/inboxgeek/fields/integration/RecurrenceInput";
import {PLATFORMS_CODE, SERVICES} from "@/constantes";
import {mapGetters, mapState} from "vuex";


export default {
  name: "LoIntegrationOptionsWizardModal",
  props:['platform'],
  components:{
    LimitPerDayInput,
    RecurrenceInput
  },
  data(){
    return {
      time_limit:{
        active:false,
        from:null,
        to:null,

      },
      timeType:'text',
      timeToAlert:false,
      timeFromAlert:false
    }
  },
  methods: {

    handleUpdateLimitPerDay(data) {
      this.$emit('limitPerDay', data)
    },
    handleUpdateRecurrence(data) {
      this.$emit('recurrence', data)
    },
    onSwitchTimeLimit(event){

      if (!event.checked) {

        this.timeType         = "text"
        this.time_limit.from  = null
        this.time_limit.to    = null

      } else{

        this.timeType = "time"

      }
    },
    fetchOptions(){



      if(this.userSubscriptions[SERVICES.LIVE_OPENS.id]){

        this.options = JSON.parse(this.userSubscriptions[SERVICES.LIVE_OPENS.id].options);
      }



    },
    hasOption(option_name){

      if (this.options ) {
        return this.options[option_name] == 1;
      }

      return false;
    },
  },
  computed : {
    ...mapState(['subscription']),

    ...mapGetters({
      isClient: 'Auth/isClient',
      liveOpenersSubscribed: 'Auth/liveOpenersSubscribed',
      liveLeadsSubscribed: 'Auth/liveLeadsSubscribed',
      userSubscriptions: 'Auth/UserSubscriptions'
    }),
  },
  watch :{
    time_limit: {
      handler(newVal, oldVal) {
        this.$emit('timeLimitApplied',newVal)
      },
      deep: true // This ensures that changes to nested properties are detected
    }
  }
  }
</script>

<style scoped>

</style>