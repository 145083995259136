<template>

<div class="w-100">
  <b-row class="my-2">
    <b-col>
      <label for="name">Account ID :</label>
      <b-input v-model="platform.account" placeholder="https://<youraccountname>.api-us1.com"></b-input>
    </b-col>
  </b-row>

  <b-row class="my-2">
    <b-col>
      <label for="">API Key/Token :</label>
      <b-input v-model="platform.apikey" placeholder="Api key"></b-input>
    </b-col>
  </b-row>

  <b-row>
      <b-col class="text-right mt-2">
        <button :disabled="!isAllowed" class="btn btn-primary" @click.prevent="createPlatform"> <b-spinner v-if="isLoading" small type="grow"></b-spinner>  Create Platform</button>
      </b-col>
    </b-row>
  
  
  
  </div>
  
  
  </template>
  
  <script>

    import api from '@/api/RestClient'

    export default {
      name: 'ActiveCampaignInput',
      components: {
      },
      props: ['platform_nickname'],
      mounted(){
  
      },
      
      data(){
        return {
          isDisabled:false,
          isLoading:false,
          platform: {
            account:null,
            apikey:null
          }
        }
      },
      methods: {
        createPlatform(){
          this.isLoading = true;
          this.isDisabled = true;
          api.platforms
          .verifyToken('activecampaign',this.validatedAccountInput,this.platform.apikey)
          .then(()=>{
           
            this.$emit('createPlatform',{account : this.validatedAccountInput, apikey : this.platform.apikey});
            this.isLoading = false;
          }).catch((error)=>{
            this.isLoading = false;
            this.isDisabled = false;
            let swal = {
              icon: 'error',
              title: 'Failed',
              text: 'Failed to authenticate, please check your credentials and try again',
              type: 'error',
              confirmButtonText: 'OK',
              showCloseButton: true,
            }

            this.$swal(swal)

          });

         
        }
      },
        computed : {
            isAllowed(){
                return ( this.platform_nickname != null && this.platform_nickname.trim() != '' ) && ! this.isDisabled ; 
            },
            validatedAccountInput(){

              try {
                const parsedUrl = new URL(this.platform.account);

                const parts = parsedUrl.hostname.split('.');

                if (parts.length > 2) {
                  return parts[0];
                } else {
                  return this.platform.account; // No subdomain found
                }
              } catch (error) {
                return this.platform.account; // Invalid URL
              }
            }
        }
  
    }
  
  </script>