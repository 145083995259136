import { PLATFORMS_CODE, COLUMNS_GRID } from "@/constantes";
import api from '@/api/RestClient';
import axios from 'axios'

export default function platform() {
    return {
        labels: {
        },
        maps: {
            listsMap: (obj) => {
                
                let item = {}
                item.code = obj.id
                item.name = obj.name
                item.type = obj['type']
                return item
            },
        },
        customShowAnotherCols(COMP) {
            if(typeof COMP.showCols.apiKey !== 'undefined')  COMP.showCols.apiKey = true;
            if(typeof COMP.showCols.accountId!== 'undefined')  COMP.showCols.accountId = false;
        },
    }
}